import React, { FC, useEffect } from "react";
import { useAppSelector } from "../../hooks/redux.hooks";
import { DateObject } from "react-multi-date-picker";

// MUI
import { Box, Typography, Divider } from "@mui/material";

type IInvoicePrintProps = {
  ref: any;
  onClick: () => void;
  closeModal: () => void;
};

const InvoicePrint: FC<IInvoicePrintProps> = React.forwardRef(
  ({ onClick, closeModal }, ref) => {
    const { orderBasket } = useAppSelector((state) => state.order);

    const registerDate = new DateObject(
      new Date(orderBasket?.bookingRegDate as string)
    ).format("YYYY-MM-DD HH:mm");

    useEffect(() => {
      onClick();
      closeModal();
    }, []);

    return (
      <Box textAlign="center" ref={ref} p={4}>
        <Typography fontSize={24} fontWeight={600} mb={2}>
          Zo Book
        </Typography>
        <Typography fontSize={20} fontWeight={500} mb={2}>
          Order No :
        </Typography>
        <Typography fontSize={20} fontWeight={500} mb={2}>
          {orderBasket?.id}
        </Typography>
        <Divider sx={{ width: "100%", border: "1px dashed #888", my: 2 }} />
        {orderBasket?.itemList.map((item) => (
          <Box
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
            key={item.bookingDetailId}
          >
            <Typography fontWeight="bold" fontSize={18}>
              {item.serviceTitle}
            </Typography>
            <Typography fontWeight={500} fontSize={18}>
              &#163; {item.amount.toFixed(2)}
            </Typography>

            {item.additionalServiceList.map((additional) => (
              <Box
                width="100%"
                display="flex"
                justifyContent="space-between"
                pl={1.5}
                key={additional.id}
              >
                <Typography fontWeight={500} fontSize={16}>
                  {additional.additionalServiceCount} &#215;
                  {additional.additionalServiceTitle}
                </Typography>
                <Typography fontWeight={400} fontSize={16}>
                  &#163; {additional.totalPayFor.toFixed(2)}
                </Typography>
              </Box>
            ))}
          </Box>
        ))}
        <Divider sx={{ width: "100%", border: "1px dashed #888", my: 2 }} />
        <Box display="flex" justifyContent="space-between">
          <Typography fontWeight={500} fontSize={16}>
            Header Discount
          </Typography>
          <Typography fontWeight={500} fontSize={16}>
            &#163;{orderBasket?.headerDiscount.toFixed(2)}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography fontWeight={500} fontSize={16}>
            Discount
          </Typography>
          <Typography fontWeight={500} fontSize={16}>
            &#163;{orderBasket?.totalDiscount.toFixed(2)}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography fontWeight={500} fontSize={16}>
            Pre Payment
          </Typography>
          <Typography fontWeight={500} fontSize={16}>
            &#163;{orderBasket?.totalPrePayment.toFixed(2)}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Typography fontWeight={500} fontSize={16}>
            Total
          </Typography>
          <Typography fontWeight={500} fontSize={16}>
            &#163;{orderBasket?.totalForPay.toFixed(2)}
          </Typography>
        </Box>
        <Divider sx={{ width: "100%", border: "1px dashed #888", my: 2 }} />
        <Typography fontWeight={500} fontSize={20} textAlign="left">
          Customer Info :
        </Typography>

        <Typography fontWeight={500} fontSize={16} textAlign="left">
          {orderBasket?.customerTitle}
        </Typography>
        <Divider sx={{ width: "100%", border: "1px dashed #888", my: 2 }} />
        <Typography fontWeight={500} fontSize={16}>
          Order Placed At :
        </Typography>
        <Typography fontWeight={500} fontSize={16}>
          {registerDate}
        </Typography>
        <Divider sx={{ width: "100%", border: "1px dashed #888", my: 2 }} />
        <Typography fontWeight={500} fontSize={16}>
          Thanks for your order
        </Typography>
      </Box>
    );
  }
);

export default InvoicePrint;
