import React, { useState, FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { useAppSelector } from "../../hooks/redux.hooks";

// MUI
import { Grid, Typography, IconButton, Divider } from "@mui/material";

// components
import OneSectionLayout from "../../layout/OneSection.layouts";
import BaseButton from "../../components/base/BaseButton";
import BaseSelect from "../../components/base/BaseSelect";

import { useServiceSupplementaryForm } from "../../helpers/formik";
import {
  IPostServiceSupplementaryParam,
  deleteServiceSupplementary,
  fetchSupplementaryById,
  postServiceSupplementary,
} from "../../redux/services/services.actions";
import { IEditActionsApiMethodType } from "../../redux/shifts/shift.actions";

// icons
import { ReactComponent as EditIcon } from "../../assets/icons/edit-icon.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash-icon.svg";
import ConfirmModal from "../../components/modals/Confirm.modals";
import { selectInputItemsFormatter } from "../../helpers/utils";

const SupplementaryService: FC = () => {
  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { serviceId } = useParams();

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);

  // handlers
  const handleOpenConfirmModal = () => setOpenConfirmModal(true);
  const handleCloseConfirmModal = () => setOpenConfirmModal(false);

  const {
    serviceById,
    isFetchingService,
    isPostingService,
    isDeletingService,
    isDeletingSupplementary,
  } = useAppSelector((state) => state.service);

  const submit = () => {
    const parameters: IPostServiceSupplementaryParam = {
      body: { ...values, serviceId: Number(serviceId) },
      type: IEditActionsApiMethodType.POST,
      fetchParams: { serviceId: Number(serviceId) },
    };

    dispatch(postServiceSupplementary(parameters));
  };

  const { values, setFieldValue, handleSubmit } =
    useServiceSupplementaryForm(submit);

  const supplementaryInputItems = selectInputItemsFormatter(
    serviceById?.commonData?.supplementaryFieldList,
    "supplementaryFieldTitle",
    "id"
  );

  return (
    <OneSectionLayout>
      <Grid
        container
        item
        xs={12}
        zIndex={1}
        position="relative"
        top={-16}
        justifyContent="space-between"
      >
        <Typography
          height={70}
          display="flex"
          alignItems="center"
          color="grey.100"
        >
          Service Supplementary
        </Typography>
        <Grid
          item
          container
          xs={12}
          md={8}
          gap={2}
          alignItems="center"
          justifyContent="end"
          position="unset"
          bottom={-5}
          left={0}
          bgcolor="transparent"
          p={0}
          zIndex={10}
        >
          <BaseButton
            sx={{
              width: 150,
              bgcolor: "white",
              color: "primary.main",
              "&:hover": {
                bgcolor: "white",
              },
              border: 0,
              fontWeight: 500,
              height: 45,
            }}
            onClick={() => handleSubmit()}
            disabled={isPostingService || isFetchingService}
          >
            {isPostingService || isFetchingService ? (
              <Grid
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  zIndex: 10,
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible
                />
              </Grid>
            ) : (
              "Save"
            )}
          </BaseButton>

          <BaseButton
            sx={{
              width: 150,
              border: 0,
              display: "flex",
              boxShadow: 0,
            }}
            onClick={() => navigate(-1)}
          >
            Discard
          </BaseButton>
        </Grid>
      </Grid>
      <Grid container gap={2} mb={8} flexWrap="nowrap">
        <Grid
          item
          xs={4}
          gap={2}
          display="grid"
          gridTemplateColumns="1fr"
          height="fit-content"
          position="relative"
        >
          {isFetchingService && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              position="absolute"
              zIndex={1000}
              bgcolor="rgba(0,0,0, 0.05)"
              borderRadius={1}
              height="100%"
            >
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="30"
              />
            </Grid>
          )}

          <BaseSelect
            value={values.supplementaryFieldId}
            onChange={(e: any) =>
              setFieldValue("supplementaryFieldId", e.target.value)
            }
            name="supplementaryFieldId"
            sx={{ bgcolor: "grey.100" }}
            defaultVal="select Supplementary"
            inputItems={supplementaryInputItems}
            label="Supplementary"
            isFetching={isFetchingService}
          />
        </Grid>

        <Grid
          item
          xs={8}
          container
          flexDirection="column"
          rowGap={1}
          position="relative"
        >
          {(isDeletingService || isFetchingService) && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              position="absolute"
              zIndex={1000}
              bgcolor="rgba(0,0,0, 0.05)"
              borderRadius={1}
              height="100%"
            >
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="30"
              />
            </Grid>
          )}
          {serviceById?.supplementaryFieldList?.map((item, i) => (
            <React.Fragment key={item.id}>
              <Grid
                container
                flexWrap="nowrap"
                gap={1}
                height={40}
                alignItems="center"
              >
                <Grid item xs={1}>
                  <Typography color="primary.main" fontSize={16}>
                    #{item.id}
                  </Typography>
                </Grid>
                <Grid item xs={2.5}>
                  <Typography
                    fontSize={16}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {item.supplementaryFieldTitle}
                  </Typography>
                </Grid>
                {/* <Grid item xs={1.5}>
                  <Typography fontSize={16}>
                    {item.supplementaryValueType}
                  </Typography>
                </Grid> */}
                <Grid item xs={1.5} container alignItems="center" gap={1}>
                  <Typography fontSize={16}>{item.minValue}</Typography>-
                  <Typography fontSize={16}>{item.maxValue}</Typography>
                </Grid>
                <Grid item xs={1.5}>
                  <Typography fontSize={16}>
                    {item.isRequired ? "Required!" : ""}
                  </Typography>
                </Grid>
                {/* <Grid item xs={1.5}>
                  <Typography fontSize={{ xs: 14, md: 16 }}>
                    {item.serviceDuration} min
                  </Typography>
                </Grid> */}
                <Grid
                  item
                  flex={1}
                  container
                  flexWrap="nowrap"
                  justifyContent="end"
                >
                  {/* <IconButton
                    onClick={() =>
                      dispatch(
                        fetchSupplementaryById({
                          supplementaryId: item.id,
                        })
                      )
                    }
                  >
                    <EditIcon />
                  </IconButton> */}
                  <IconButton
                    onClick={() => {
                      handleOpenConfirmModal();
                      setDeleteId(item.id);
                    }}
                  >
                    <TrashIcon />
                  </IconButton>
                </Grid>
              </Grid>
              {i < serviceById?.supplementaryFieldList?.length - 1 && (
                <Divider sx={{ borderColor: "grey.300", width: "100%" }} />
              )}
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
      <ConfirmModal
        isDeleting={isDeletingSupplementary}
        open={openConfirmModal}
        handleClose={handleCloseConfirmModal}
        action={() =>
          dispatch(
            deleteServiceSupplementary({
              supplementaryId: deleteId,
              fetchParams: { serviceId: Number(serviceId) },
              closeModalHandler: handleCloseConfirmModal,
            })
          )
        }
      />
    </OneSectionLayout>
  );
};

export default SupplementaryService;
