import React, { FC, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// MUI
import { Grid, Typography } from "@mui/material";

// redux
import { ISubRoutes } from "../../App";
import { SideBarPropsTypes } from "./Main.sidebars";

// icon
import { ReactComponent as ServicesIcon } from "../../assets/icons/services-icon.svg";
import { ReactComponent as ServiceCategoryIcon } from "../../assets/icons/service-category-icon.svg";
import { ReactComponent as SupplementaryIcon } from "../../assets/icons/supplementary-icon.svg";

const serviceSubRoutes = [
  {
    id: 1,
    name: "services",
    url: "/services/overview",
    icon: <ServicesIcon />,
  },
  {
    id: 2,
    name: "categories",
    url: "/services/categories",
    icon: <ServiceCategoryIcon />,
  },
  {
    id: 3,
    name: "supplementary",
    url: "/services/supplementary",
    icon: <SupplementaryIcon />,
  },
];

const SubSidebar: FC<SideBarPropsTypes> = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const navigateHandler = (url: string) => {
    navigate(url);
  };

  const [routeList, setRouteList] = useState<ISubRoutes[]>();
  const [hasSubRoute, setHasSubRoute] = useState<boolean>();
  const splitPathname = pathname.split("/");

  const isInRoute = () => {
    if (
      splitPathname[2] === "categories" ||
      splitPathname[2] === "supplementary"
    )
      return `/${splitPathname[1]}/${splitPathname[2]}`;
    else return `/${splitPathname[1]}/overview`;
  };

  useEffect(() => {
    if (pathname.split("/")[1] === "services") {
      setHasSubRoute(true);
      setRouteList(serviceSubRoutes);
    } else {
      setHasSubRoute(false);
      setRouteList([]);
    }
  }, [pathname]);

  return (
    <Grid
      width={hasSubRoute ? 120 : 0}
      sx={{ transition: "all 0.5s", overflow: "hidden" }}
      item
      height="100%"
      container
      alignItems="center"
      justifyContent="start"
      flexDirection="column"
      rowGap={4}
      py={5}
    >
      {routeList?.map((route) => (
        <Grid
          item
          key={route.id}
          textAlign="center"
          onClick={() => navigateHandler(route.url)}
          sx={{
            transition: "all 0.5s",
            cursor: "pointer",
            opacity: hasSubRoute ? 1 : 0,
            transform: `translateX(${hasSubRoute ? 0 : "-80px"})`,
            svg: { height: 32 },
            "& svg path": {
              fill: isInRoute() === route.url ? "white" : "grey.300",
            },
          }}
        >
          {route.icon}
          <Typography
            fontSize={14}
            fontWeight={500}
            color={isInRoute() === route.url ? "white" : "grey.300"}
            textTransform="capitalize"
            noWrap
          >
            {route.name}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default SubSidebar;
