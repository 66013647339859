import React, { useState, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";

// MUI
import {
  Grid,
  InputAdornment,
  SelectChangeEvent,
  Popover,
} from "@mui/material";

// icons
import { ReactComponent as SearchIcon } from "../../assets/icons/Search.svg";
import { ReactComponent as ChevronIcon } from "../../assets/icons/chevron-icon.svg";
import BaseInput from "../base/BaseInput";
import BaseButton from "../base/BaseButton";

const SupplementarySubFieldListHeader = () => {
  const navigate = useNavigate();

  // const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
  //   null
  // );
  const [search, setSearch] = useState("");

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const addSubField = () => {
    navigate("create-subField");
  };

  // const open = Boolean(anchorEl);
  // const id = open ? "simple-popover" : undefined;

  return (
    <Grid
      p={1.25}
      gap={2}
      display="grid"
      gridTemplateColumns="300px 1fr"
      justifyItems="end"
    >
      {/* <BaseInput
        variant="outlined"
        p={1.25}
        fullWidth
        value={search}
        handleChange={handleSearch}
        bgcolor="white"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ zIndex: 10 }}>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        placeholder="Search among sub field"
      /> */}
      <Grid
        display="grid"
        gridTemplateColumns="auto 100px"
        gap={2}
        gridColumn="2/3"
      >
        <Grid gridColumn="1/2">
          <BaseButton sx={{ minWidth: 150 }} onClick={addSubField}>
            add sub Field
          </BaseButton>
        </Grid>
        {/* <Grid>
          <BaseButton
            variant="outlined"
            // onClick={handleClickFilters}
            sx={{
              justifyContent: "space-around",
              "&:hover": {
                border: 2,
              },
            }}
          >
            Filters
            <ChevronIcon />
          </BaseButton>
        </Grid> */}
        <BaseButton
          variant="outlined"
          sx={{
            "&:hover": {
              border: 2,
            },
          }}
        >
          export
        </BaseButton>
      </Grid>
    </Grid>
  );
};

export default SupplementarySubFieldListHeader;
