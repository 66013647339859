import React, { ChangeEvent, FC } from "react";

import { Box, Typography, Grid } from "@mui/material";
import BaseButton from "./BaseButton";

type CountInputPropTypes = {
  label?: string | JSX.Element;
  value: number;
  name: string;
  handleChange: any;
  step?: number;
  limit?: number;
  isActive?: boolean;
  bgcolor?: string;
};

const BaseCountInput: FC<CountInputPropTypes> = ({
  label,
  value,
  name,
  handleChange,
  step = 1,
  limit = Infinity,
  isActive,
  bgcolor = "inputBackground.main",
}) => {
  const changeHandler = (type: "minus" | "plus") => {
    if (type === "minus" && value === 0) return;
    if (type === "plus" && value === limit) return;
    if (type === "minus") {
      handleChange(name, value - step);
    } else {
      handleChange(name, value + step);
    }
  };
  return (
    <Grid
      bgcolor={bgcolor}
      borderRadius="5px"
      display="flex"
      justifyContent="space-between"
      px={0.75}
      py={0.5}
    >
      {label && (
        <Grid item xs={6} container alignItems="center">
          <Typography
            fontSize={16}
            fontWeight={400}
            color={isActive ? "grey.800" : "initial"}
          >
            {label}
          </Typography>
        </Grid>
      )}

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        item
        xs={label ? 5 : 12}
        minWidth={100}
      >
        <BaseButton
          onClick={() => changeHandler("minus")}
          sx={{
            bgcolor: "primary.main",
            width: 30,
            height: 30,
            minWidth: "auto",
            border: value === 0 ? 0 : 2,
          }}
          disabled={value === 0}
        >
          -
        </BaseButton>
        <Typography color={isActive ? "grey.800" : "initial"}>
          {value}
        </Typography>
        <BaseButton
          onClick={() => changeHandler("plus")}
          sx={{
            bgcolor: "primary.main",
            width: 30,
            height: 30,
            minWidth: "auto",
            border: value === limit ? 0 : 2,
          }}
          disabled={value === limit}
        >
          +
        </BaseButton>
      </Grid>
    </Grid>
  );
};

export default BaseCountInput;
