import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import ListCustomer from "./List.customer";
import CreateCustomer from "./Create.customer";
import { IPagination } from "../staffs/List.staffs";
import PageNotFound from "../errors/PageNotFound.error";
import { useAppSelector } from "../../hooks/redux.hooks";

const Customer = () => {
  const [pagination, setPagination] = useState<IPagination>({
    pageNumber: 1,
    pageSize: 10,
  });

  const { fetchCustomerError } = useAppSelector((state) => state.customer);

  return (
    <Routes>
      <Route
        element={
          <ListCustomer pagination={pagination} setPagination={setPagination} />
        }
        path="/"
      />
      <Route element={<CreateCustomer />} path="/create-customer" />
      <Route
        element={
          fetchCustomerError === 404 ? <PageNotFound /> : <CreateCustomer />
        }
        path="/edit-customer/:customerId"
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Customer;
