import React, { useState, ChangeEvent } from "react";
import { useNavigate } from "react-router";

// MUI
import {
  Grid,
  InputAdornment,
  SelectChangeEvent,
  Popover,
} from "@mui/material";

import BaseInput from "../base/BaseInput";
import BaseButton from "../base/BaseButton";
import BaseSelect from "../base/BaseSelect";

// icons
import { ReactComponent as SearchIcon } from "../../assets/icons/Search.svg";
import { ReactComponent as ChevronIcon } from "../../assets/icons/chevron-icon.svg";

const ShiftListHeader = () => {
  const navigate = useNavigate();

  const addShift = () => {
    navigate("create-shift");
  };

  return (
    <Grid
      p={1.25}
      gap={2}
      display="grid"
      gridTemplateColumns="350px 1fr"
      justifyItems="end"
    >
      <Grid
        display="grid"
        gridTemplateColumns="auto 150px"
        gridColumn="2/3"
        gap={2}
      >
        <Grid gridColumn="1/2">
          <BaseButton sx={{ minWidth: 150 }} onClick={addShift}>
            add shift
          </BaseButton>
        </Grid>
        <BaseButton
          variant="outlined"
          sx={{
            "&:hover": {
              border: 2,
            },
          }}
        >
          export
        </BaseButton>
      </Grid>
    </Grid>
  );
};

export default ShiftListHeader;
