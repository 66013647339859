import React, { FC, useState, ChangeEvent, useEffect } from "react";
import {
  URLSearchParamsInit,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

// MUI
import { Grid, Popover, InputAdornment } from "@mui/material";

// components
import BaseButton from "../base/BaseButton";
import BookingListFilterCard from "../cards/BookingListFilter.cards";
import BaseInput from "../base/BaseInput";

// icon
import { ReactComponent as ChevronIcon } from "../../assets/icons/chevron-icon.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/Search.svg";

type IIBookingListHeaderProps = {
  handleOpenModal: () => void;
};

const BookingListHeader: FC<IIBookingListHeaderProps> = ({
  handleOpenModal,
}) => {
  // states
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("customer");
  const fromDate = searchParams.get("fromDate");
  const toDate = searchParams.get("toDate");

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const navigate = useNavigate();

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchInputText = e.target.value;

    setSearchInputValue(searchInputText);
  };

  useEffect(() => {
    const changeCustomer = setTimeout(() => {
      const nextSearchParams: URLSearchParamsInit = searchInputValue
        ? { customer: searchInputValue }
        : {};

      if (fromDate !== null)
        Object.assign(nextSearchParams, { fromDate: fromDate });
      if (toDate !== null) Object.assign(nextSearchParams, { toDate: toDate });

      setSearchParams(nextSearchParams);
    }, 1000);

    return () => {
      clearTimeout(changeCustomer);
    };
  }, [searchInputValue]);

  const addBookingDetailLink = () => {
    navigate("/booking");
  };

  const handleClickFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilters = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Grid
      p={1.25}
      gap={2}
      display="grid"
      gridTemplateColumns="350px 1fr"
      justifyItems="end"
    >
      <BaseInput
        variant="outlined"
        p={1.25}
        fullWidth
        value={searchInputValue}
        onChange={handleSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ zIndex: 10 }}>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        placeholder="Search among orders"
      />
      <Grid display="grid" gridTemplateColumns="auto 150px 150px" gap={2}>
        <Grid gridColumn="1/2">
          <BaseButton sx={{ minWidth: 150 }} onClick={addBookingDetailLink}>
            add New Booking
          </BaseButton>
        </Grid>
        <Grid>
          <BaseButton
            variant="outlined"
            onClick={handleClickFilters}
            sx={{
              justifyContent: "space-around",
              "&:hover": {
                border: 2,
              },
            }}
          >
            Filters
            <ChevronIcon />
          </BaseButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleCloseFilters}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            PaperProps={{ sx: { borderRadius: "5px" } }}
            sx={{ zIndex: 90 }}
          >
            <BookingListFilterCard closePopover={handleCloseFilters} />
          </Popover>
        </Grid>
        <BaseButton
          onClick={handleOpenModal}
          variant="outlined"
          sx={{
            "&:hover": {
              border: 2,
            },
          }}
        >
          export
        </BaseButton>
      </Grid>
    </Grid>
  );
};

export default BookingListHeader;
