import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../hooks/redux.hooks";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { RotatingLines } from "react-loader-spinner";

// MUI
import { Grid, Typography } from "@mui/material";

// components
import OneSectionLayout from "../../layout/OneSection.layouts";
import BaseButton from "../../components/base/BaseButton";
import BaseInput from "../../components/base/BaseInput";

import { useShopForm } from "../../helpers/formik";
import {
  IEditActionsApiMethodType,
  IPostShopActionParam,
  fetchShopById,
  postShop,
} from "../../redux/shops/shops.actions";
import BaseTextInput from "../../components/base/BaseTextInput";
import BaseFileInput from "../../components/base/BaseFileInput";

// icons
import { ReactComponent as ImageInputIcon } from "../../assets/icons/image-input-icon.svg";

const CreateShop = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { shopId } = useParams();
  const navigate = useNavigate();

  const { isPostingShop, isFetchingShop, shopById } = useAppSelector(
    (state) => state.shop
  );
  const [logo, setLogo] = useState();
  const [banner, setBanner] = useState();

  useEffect(() => {
    if (shopId) dispatch(fetchShopById({ shopId: Number(shopId) }));
  }, [shopId]);

  const submitHandler = () => {
    const formData = new FormData();
    for (const key in values) {
      if (key !== "logoImage" && key !== "bannerImage")
        //@ts-ignore
        formData.append(key, values[key]);
    }

    if (logo) formData.append("Logo", logo);
    if (banner) formData.append("Banner", banner);

    const parameters: IPostShopActionParam = {
      type: IEditActionsApiMethodType.PATCH,
      body: formData,
    };

    validateForm();
    if (isValid) dispatch(postShop(parameters));
  };

  const { values, handleChange, handleSubmit, isValid, validateForm } =
    useShopForm(shopById, submitHandler);

  return (
    <OneSectionLayout>
      <Grid
        container
        item
        xs={12}
        zIndex={1}
        position="relative"
        top={-16}
        justifyContent="space-between"
      >
        <Typography
          height={70}
          display="flex"
          alignItems="center"
          color="grey.100"
        >
          {shopId ? "Edit " : "Add"} Shop {shopId && values.shopTitle}
        </Typography>
        <Grid
          item
          container
          xs={12}
          md={8}
          gap={2}
          alignItems="center"
          justifyContent="end"
          position="unset"
          bottom={-5}
          left={0}
          bgcolor="transparent"
          p={0}
          zIndex={10}
        >
          <BaseButton
            sx={{
              width: 150,
              bgcolor: "white",
              color: "primary.main",
              "&:hover": {
                bgcolor: "white",
              },
              border: 0,
              fontWeight: 500,
              height: 45,
            }}
            onClick={() => handleSubmit()}
            disabled={isPostingShop}
          >
            {isPostingShop ? (
              <Grid
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  zIndex: 10,
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="30"
                  visible
                />
              </Grid>
            ) : (
              "Save"
            )}
          </BaseButton>

          <BaseButton
            sx={{
              width: 150,
              border: 0,
              display: "flex",
              boxShadow: 0,
            }}
            onClick={() => navigate(-1)}
          >
            Discard
          </BaseButton>
        </Grid>
      </Grid>

      <Grid container rowGap={2} position="relative">
        {isFetchingShop && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            position="absolute"
            zIndex={1000}
            bgcolor="rgba(0,0,0, 0.05)"
            borderRadius={1}
            height="100%"
          >
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
            />
          </Grid>
        )}
        {/* Shop info */}
        <Grid item xs={12}>
          <Typography color="primary" fontWeight={500}>
            Shop Info
          </Typography>
        </Grid>
        <Grid container height={200} gap={2} justifyContent="space-around">
          <Grid item flexBasis={200}>
            <BaseFileInput
              attachment={logo}
              setAttachment={setLogo}
              icon={<ImageInputIcon />}
              hasImage={
                values?.logoImage
                  ? `https://booking.weeorder.co.uk/uploadfiles/Images/${values.logoImage}`
                  : null
              }
            />
          </Grid>
          <Grid item flex={1}>
            <BaseFileInput
              attachment={banner}
              setAttachment={setBanner}
              icon={<ImageInputIcon />}
              hasImage={
                values?.bannerImage
                  ? `https://booking.weeorder.co.uk/uploadfiles/Images/${values.bannerImage}`
                  : null
              }
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gap={2}
        >
          <BaseInput
            variant="outlined"
            value={values.shopTitle}
            name="shopTitle"
            onChange={handleChange}
            placeholder="Shop Name"
            label="Shop Name"
          />
          <BaseInput
            variant="outlined"
            value={values.currency}
            name=".currency"
            onChange={handleChange}
            placeholder="Currency"
            label="Currency"
          />
          <BaseInput
            variant="outlined"
            value={values.secretKey}
            name="secretKey"
            onChange={handleChange}
            placeholder="Secret Key"
            label="Secret Key"
          />
          <BaseInput
            variant="outlined"
            value={values.publishableKey}
            name="publishableKey"
            onChange={handleChange}
            placeholder="Publishable Key"
            label="Publishable Key"
          />
          <BaseInput
            variant="outlined"
            value={values.telephone}
            name="telephone"
            onChange={handleChange}
            placeholder="Telephone"
            label="Telephone"
          />
          <BaseInput
            variant="outlined"
            value={values.mobile}
            name="mobile"
            onChange={handleChange}
            placeholder="Mobile"
            label="Mobile"
          />
          <Grid item flexGrow={1} position="relative">
            <BaseTextInput
              value={values.description}
              onChange={handleChange}
              name="description"
              label="Description"
            />
          </Grid>
          <Grid item flexGrow={1} position="relative">
            <BaseTextInput
              value={values.address}
              onChange={handleChange}
              name="address"
              label="Address"
            />
          </Grid>
          <BaseInput
            variant="outlined"
            value={values.googleMapUrl}
            name="googleMapUrl"
            onChange={handleChange}
            placeholder="GoogleMapUrl"
            label="GoogleMapUrl"
          />
        </Grid>
      </Grid>
    </OneSectionLayout>
  );
};

export default CreateShop;
