import { createSlice } from "@reduxjs/toolkit";
import {
  IBooking,
  IBookingDetail,
  deleteBooking,
  deleteBookingDetail,
  fetchAllBooking,
  fetchAllBookingDetail,
  postBooking,
  postBookingDetail,
} from "./booking.actions";

export type IBookingState = {
  bookingData: IBooking | null;
  isFetchingBooking: boolean;
  fetchBookingError: any;
  isPostingBooking: boolean;
  postBookingError: any;
  isDeletingBooking: boolean;
  deleteError: any;
  bookingDetailData: IBookingDetail | null;
  isFetchingBookingDetail: boolean;
  fetchBookingDetailError: any;
  isPostingBookingDetail: boolean;
  postBookingDetailError: any;
  isDeletingBookingDetail: boolean;
};

const initialState: IBookingState = {
  bookingData: null,
  isFetchingBooking: false,
  fetchBookingError: null,
  isPostingBooking: false,
  postBookingError: null,
  isDeletingBooking: false,
  deleteError: null,
  bookingDetailData: null,
  isFetchingBookingDetail: false,
  fetchBookingDetailError: null,
  isPostingBookingDetail: false,
  postBookingDetailError: null,
  isDeletingBookingDetail: false,
};

const bookingSlice = createSlice({
  initialState,
  name: "booking",
  reducers: {},
  extraReducers: (builder) => {
    builder

      // fetch All Booking
      .addCase(fetchAllBooking.pending, (state) => {
        state.isFetchingBooking = true;
      })
      .addCase(fetchAllBooking.fulfilled, (state, action) => {
        state.isFetchingBooking = false;
        state.bookingData = action.payload;
      })
      .addCase(fetchAllBooking.rejected, (state, action) => {
        state.isFetchingBooking = false;
        state.fetchBookingError = action.payload;
      })

      // post Booking
      .addCase(postBooking.pending, (state) => {
        state.isPostingBooking = true;
      })
      .addCase(postBooking.fulfilled, (state) => {
        state.isPostingBooking = false;
      })
      .addCase(postBooking.rejected, (state, action) => {
        state.isPostingBooking = false;
        state.postBookingError = action.payload;
      })

      // delete Booking
      .addCase(deleteBooking.pending, (state) => {
        state.isDeletingBooking = true;
      })
      .addCase(deleteBooking.fulfilled, (state) => {
        state.isDeletingBooking = false;
      })
      .addCase(deleteBooking.rejected, (state, action) => {
        state.isDeletingBooking = false;
        state.deleteError = action.payload;
      })

      // fetch All Booking Detail
      .addCase(fetchAllBookingDetail.pending, (state) => {
        state.isFetchingBookingDetail = true;
      })
      .addCase(fetchAllBookingDetail.fulfilled, (state, action) => {
        state.isFetchingBookingDetail = false;
        state.bookingDetailData = action.payload;
      })
      .addCase(fetchAllBookingDetail.rejected, (state, action) => {
        state.isFetchingBookingDetail = false;
        state.fetchBookingDetailError = action.payload;
      })

      // post Booking Detail
      .addCase(postBookingDetail.pending, (state) => {
        state.isPostingBookingDetail = true;
      })
      .addCase(postBookingDetail.fulfilled, (state) => {
        state.isPostingBookingDetail = false;
      })
      .addCase(postBookingDetail.rejected, (state, action) => {
        state.isPostingBookingDetail = false;
        state.postBookingDetailError = action.payload;
      })

      // delete Booking Detail
      .addCase(deleteBookingDetail.pending, (state) => {
        state.isDeletingBookingDetail = true;
      })
      .addCase(deleteBookingDetail.fulfilled, (state) => {
        state.isDeletingBookingDetail = false;
      })
      .addCase(deleteBookingDetail.rejected, (state, action) => {
        state.isDeletingBookingDetail = false;
        state.deleteError = action.payload;
      });
  },
});

export default bookingSlice.reducer;
