import React, { FC, ReactNode, useState } from "react";

// MUI
import { Grid, Typography } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ISubRoutes } from "../App";

type ILayoutPropTypes = {
  routes?: ISubRoutes[];
  children?: ReactNode;
  bgcolor?: any;
};

const OneSectionLayout: FC<ILayoutPropTypes> = ({
  routes,
  bgcolor = "#ffffff",
  children,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const myLocationLastIndex =
    pathname.split("/").length >= 6
      ? pathname.split("/")[
          pathname.split("/").length - (pathname.split("/").length - 4)
        ]
      : pathname.split("/")[pathname.split("/").length - 1];

  const navigateHandler = (url: string) => {
    navigate(url);
  };

  return (
    <Grid
      item
      bgcolor={bgcolor}
      borderRadius={2.5}
      xs={12}
      flexGrow={1}
      container
      display={routes ? "grid" : "flex"}
      gridTemplateColumns="120px 1fr"
      height="100%"
      overflow="auto"
    >
      <Grid
        width={routes ? 120 : 0}
        sx={{ overflow: "hidden" }}
        item
        height="100%"
        container
        alignItems="center"
        justifyContent="start"
        flexDirection="column"
        rowGap={4}
        py={5}
        bgcolor="primary.main"
        borderRadius="10px"
        display={routes ? "flex" : "none"}
      >
        {routes?.map((route, idx) => (
          <Grid
            item
            key={route.id}
            textAlign="center"
            onClick={() => navigateHandler(route.url)}
            sx={{
              transition: "all 0.5s",
              cursor: "pointer",
              opacity: routes ? 1 : 0,
              transform: `translateX(${routes ? 0 : "-80px"})`,
              svg: { height: 32 },
              "& svg path": {
                fill:
                  (idx === 0 && Number(myLocationLastIndex)) ||
                  myLocationLastIndex === route.url
                    ? "white"
                    : "grey.300",
              },
            }}
          >
            {route.icon}
            <Typography
              fontSize={14}
              fontWeight={500}
              color={
                (idx === 0 && Number(myLocationLastIndex)) ||
                myLocationLastIndex === route.url
                  ? "white"
                  : "grey.300"
              }
              textTransform="capitalize"
              noWrap
            >
              {route.name}
            </Typography>
          </Grid>
        ))}
      </Grid>

      <Grid item position="relative" xs={12}>
        {routes ? null : (
          <Grid
            width="100%"
            height={70}
            position="absolute"
            bgcolor="primary.main"
            zIndex={1}
            borderRadius="10px"
          />
        )}

        <Grid
          item
          p={2}
          sx={{
            'thead[class*="ant-table-thead"] tr': {
              backgroundColor: "red !important",
            },
            position: "relative",
            height: "100%",
          }}
        >
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OneSectionLayout;
