import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosConfig from "../../axiosConfig";
import { IEditActionsApiMethodType } from "../shifts/shift.actions";
import { toast } from "react-toastify";
import { navigateV } from "../../App";

export type ICustomerData = {
  id: number;
  firstName: string;
  lastName: string;
  userName: string;
  password: string;
  customerStatus: number;
  shopId: number;
  mobile: string;
  phone: string;
  address: string;
  postCode: string;
  email: string;
};

export type ICustomerType = {
  data: ICustomerData[];
  count: number;
};

export type ICustomerFetchAllParams = {
  pageNumber?: number;
  pageSize?: number;
  customerStatus?: any;
  mobile?: any;
  lastName?: any;
};

export const fetchAllCustomer = createAsyncThunk(
  "customer/fetchAllCustomer",
  async (parameters: ICustomerFetchAllParams, ThunkApi: any) => {
    const { pageNumber, pageSize, customerStatus, mobile, lastName } =
      parameters;
    const { shopId } = ThunkApi.getState().user.user;

    const baseUrl = `Customer/${pageNumber || 1}/${pageSize || 1000}/${shopId}`;

    const requestUrlSearchParams = new URLSearchParams();

    if (customerStatus) {
      requestUrlSearchParams.set("customerstatus", customerStatus);
    }
    if (mobile) {
      requestUrlSearchParams.set("mobile", mobile);
    }
    if (lastName) {
      requestUrlSearchParams.set("lastname", lastName);
    }

    const requestUrl = `${baseUrl}${
      requestUrlSearchParams.toString()
        ? `?${requestUrlSearchParams.toString()}`
        : ""
    }`;

    try {
      const { data } = await axiosConfig.get(requestUrl);

      return data as ICustomerType;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

export type ICustomerPostParams = {
  type: IEditActionsApiMethodType;
  body: Object;
};

export const postCustomer = createAsyncThunk(
  "customer/postCustomer",
  async (parameters: ICustomerPostParams, ThunkApi) => {
    const { type, body } = parameters;
    const promiseToast = toast.loading("Saving...");
    try {
      const { data } = await axiosConfig[type]("Customer", body);
      toast.update(promiseToast, {
        render: data.resultMessage,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      navigateV(-1);
      return data.data;
    } catch (error: any) {
      toast.update(promiseToast, {
        render: error.response ? error.response.data.title : "Request Timeout!",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

export type ICustomerDeleteParams = {
  customerId: number;
  fetchParams: ICustomerFetchAllParams;
  closeModalHandler: any;
};

export const deleteCustomer = createAsyncThunk(
  "customer/deleteCustomer",
  async (parameters: ICustomerDeleteParams, ThunkApi) => {
    const { customerId, fetchParams, closeModalHandler } = parameters;
    const promiseToast = toast.loading("Deleting...");
    try {
      const { data } = await axiosConfig.delete(`Customer/${customerId}`);

      ThunkApi.dispatch(fetchAllCustomer(fetchParams));
      toast.update(promiseToast, {
        render: data.resultMessage,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      closeModalHandler();
      return data.data;
    } catch (error: any) {
      toast.update(promiseToast, {
        render: error.response ? error.response.data.title : "Request Timeout!",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);
