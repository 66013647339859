import React, { useState, useEffect } from "react";
import OneSectionLayout from "../../layout/OneSection.layouts";

// MUI
import { Grid, Typography, FormControlLabel, Switch } from "@mui/material";
import BaseButton from "../../components/base/BaseButton";
import { useShiftForm } from "../../helpers/formik";
import { useNavigate, useParams } from "react-router-dom";
import BaseInput from "../../components/base/BaseInput";
import {
  IEditActionsApiMethodType,
  IPostShiftActionParam,
  IShiftType,
  postShift,
} from "../../redux/shifts/shift.actions";
import axiosConfig from "../../axiosConfig";
import BaseDatePicker from "../../components/base/BaseDatePicker";
import { DateObject } from "react-multi-date-picker";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { useAppSelector } from "../../hooks/redux.hooks";
import { RotatingLines } from "react-loader-spinner";

const CreateShift = () => {
  // hooks
  const navigate = useNavigate();
  const { shiftId } = useParams();
  const dispatch = useDispatch<AppDispatch>();

  // states
  const { isPostingShift } = useAppSelector((state) => state.shift);
  const { user } = useAppSelector((state) => state.user);

  const [shift, setShift] = useState<IShiftType>();
  const [isFetchingShift, setIsFetchingShift] = useState(false);

  const submitHandler = () => {
    const parameters: IPostShiftActionParam = {
      body: {
        ...values,
        shopId: user?.shopId,
        shiftExpireDate: new DateObject(
          values.shiftExpireDate as string
        ).format("YYYY-MM-DD"),
      },
      type: shift?.id
        ? IEditActionsApiMethodType.PATCH
        : IEditActionsApiMethodType.POST,
    };

    dispatch(postShift(parameters));
  };
  const { values, handleChange, handleSubmit, setFieldValue } = useShiftForm(
    shift,
    submitHandler
  );

  const fetchShift = async () => {
    setIsFetchingShift(true);
    try {
      const { data } = await axiosConfig.get(`shift/${shiftId}`);
      setShift(data.data);
      setIsFetchingShift(false);
    } catch (error) {
      console.log(error);
      setIsFetchingShift(false);
    }
  };

  useEffect(() => {
    shiftId && fetchShift();
  }, [shiftId]);

  return (
    <OneSectionLayout>
      <Grid
        container
        item
        xs={12}
        zIndex={1}
        position="relative"
        top={-16}
        justifyContent="space-between"
      >
        <Typography
          height={70}
          display="flex"
          alignItems="center"
          color="grey.100"
        >
          {shiftId ? "Edit " : "Add"} Shift {shiftId && values.shiftTitle}
        </Typography>
        <Grid
          item
          container
          xs={12}
          md={8}
          gap={2}
          alignItems="center"
          justifyContent="end"
          position="unset"
          bottom={-5}
          left={0}
          bgcolor="transparent"
          p={0}
          zIndex={10}
        >
          <BaseButton
            sx={{
              width: 150,
              bgcolor: "white",
              color: "primary.main",
              "&:hover": {
                bgcolor: "white",
              },
              border: 0,
              fontWeight: 500,
              height: 45,
            }}
            onClick={() => handleSubmit()}
            disabled={isPostingShift}
          >
            {isPostingShift ? (
              <Grid
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  zIndex: 10,
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="30"
                  visible
                />
              </Grid>
            ) : (
              "Save"
            )}
          </BaseButton>

          <BaseButton
            sx={{
              width: 150,
              border: 0,
              display: "flex",
              boxShadow: 0,
            }}
            onClick={() => navigate("/shifts")}
          >
            Discard
          </BaseButton>
        </Grid>
      </Grid>

      <Grid container rowGap={2} position="relative">
        {isFetchingShift && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            position="absolute"
            zIndex={1000}
            bgcolor="rgba(0,0,0, 0.05)"
            borderRadius={1}
            height="100%"
          >
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
            />
          </Grid>
        )}
        {/* shift info */}
        <Grid item xs={12}>
          <Typography color="primary" fontWeight={500}>
            Shift Info
          </Typography>
        </Grid>
        <Grid container flexWrap="nowrap" gap={2} justifyContent="center">
          <Grid item xs={12} container flexWrap="nowrap" gap={2}>
            <Grid item xs={4}>
              <BaseInput
                variant="outlined"
                value={values.shiftTitle}
                name="shiftTitle"
                onChange={handleChange}
                placeholder="Shift Name"
                label="Shift Name"
              />
            </Grid>

            <Grid item xs={4}>
              <BaseDatePicker
                value={values.shiftExpireDate}
                setValue={(value: any) =>
                  setFieldValue("shiftExpireDate", value)
                }
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.shiftStatus ? true : false}
                    size="small"
                    name="shiftStatus"
                    onChange={() =>
                      setFieldValue("shiftStatus", values.shiftStatus ? 0 : 1)
                    }
                  />
                }
                label={<Typography fontWeight={400}>Shift Status</Typography>}
                labelPlacement="start"
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                  mx: 0,
                  bgcolor: "white",
                  borderRadius: 1,
                  py: 0.75,
                  px: 1.5,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </OneSectionLayout>
  );
};

export default CreateShift;
