import React, { FC, useEffect } from "react";
import { AppDispatch } from "../../redux/store";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../hooks/redux.hooks";
// import { DateObject } from "react-multi-date-picker";

// MUI
import { Grid, Stepper, Step, Box, Typography } from "@mui/material";

import BaseButton from "../../components/base/BaseButton";
import CustomerStep from "../../components/steps/Customer.steps";
import ServiceStep from "../../components/steps/Service.steps";
import DateAndTimeStep from "../../components/steps/DateAndTime.steps";
import BasketStep from "../../components/steps/Basket.steps";

import { setOrderDate } from "../../redux/order/order.reducer";
// import { fetchBasket } from "../../redux/order/order.actions";

// icons
import { ReactComponent as CustomerIcon } from "../../assets/icons/clients-icon.svg";
import { ReactComponent as ServicesIcon } from "../../assets/icons/services-icon.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar-icon.svg";
import { ReactComponent as InvoiceIcon } from "../../assets/icons/invoice-icon.svg";
import { fetchBasket } from "../../redux/order/order.actions";

const steps = [
  {
    label: "Customer",
    icon: <CustomerIcon />,
  },
  {
    label: "service",
    icon: <ServicesIcon />,
  },
  {
    label: "Date",
    icon: <CalendarIcon />,
  },
  {
    label: "Invoice",
    icon: <InvoiceIcon />,
  },
];

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

const Tab: FC<TabPanelProps> = ({ value, index, children, ...props }) => {
  return <React.Fragment>{value === index && children}</React.Fragment>;
};

const NewBooking = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { orderBasket, orderDate } = useAppSelector((state) => state.order);
  const [searchParams, setSearchParams] = useSearchParams();

  const bookingSearchParam = searchParams.get("bookingId");
  const activeSearchParam = searchParams.get("active");
  const customerSearchParam = searchParams.get("customerId");
  const serviceSearchParam = searchParams.get("service");
  const dateSearchParam = searchParams.get("date");
  const timeSearchParam = searchParams.get("time");
  const staffSearchParam = searchParams.get("staff");

  // states
  // const [nextButtonStatus, setNextButtonStatus] = useState(false);
  // const today = new DateObject();

  useEffect(() => {
    if (bookingSearchParam)
      dispatch(fetchBasket({ bookingId: bookingSearchParam }));
  }, [bookingSearchParam]);

  const orderDateFormat = dateSearchParam?.split("-");

  useEffect(() => {
    // if (dateSearchParam) {
    //   dispatch(
    //     setOrderDate({
    //       year: today.year,
    //       month: today.month.number,
    //       day: today.day,
    //     })
    //   );
    // }

    if (dateSearchParam && orderDateFormat)
      dispatch(
        setOrderDate({
          year: Number(orderDateFormat[0]),
          month: Number(orderDateFormat[1]),
          day: Number(orderDateFormat[2]),
        })
      );

    // if (!customerSearchParam && activeStep === 0) {
    //   setNextButtonStatus(true);
    // } else if (
    //   (!serviceSearchParam || !serviceCategorySearchParam) &&
    //   activeStep === 1
    // ) {
    //   setNextButtonStatus(true);
    // } else if (!staffSearchParam && activeStep === 2) {
    //   setNextButtonStatus(true);
    // } else if (activeStep === 3) {
    //   setNextButtonStatus(true);
    // } else {
    //   setNextButtonStatus(false);
    // }
  }, [dateSearchParam]);

  const basketHasItem = !!orderBasket?.itemList.length;

  return (
    <Grid display="grid" gridTemplateColumns="120px 1fr" height="100%" gap={2}>
      <Grid
        bgcolor="primary.main"
        height="100%"
        borderRadius={3}
        py={1.5}
        container
        justifyContent="center"
        alignItems="stretch"
      >
        <Stepper
          activeStep={Number(activeSearchParam)}
          orientation="vertical"
          sx={{ alignItems: "center" }}
          connector={
            <Grid
              sx={{
                height: 25,
                border: 1,
                my: 2,
                borderColor: "white",
                borderRadius: 3,
              }}
            />
          }
        >
          {steps.map((step, index) => {
            return (
              <Step key={step.label}>
                <BaseButton
                  onClick={() => {
                    searchParams.set("active", String(index));
                    setSearchParams(searchParams);
                  }}
                  sx={{
                    color:
                      Number(activeSearchParam) === index
                        ? "primary.main"
                        : "white",
                    width: 100,
                    borderRadius: 3,
                    justifyContent: "start",
                    flexDirection: "column",
                    gap: 1,
                    p: 0.5,
                    height: "auto",
                    boxShadow: 0,
                    "&:hover": {
                      bgcolor: "transparent",
                      boxShadow: 0,
                    },
                    "&: disabled": {
                      opacity: 0.6,
                      color: "white",
                    },
                  }}
                  disabled={
                    customerSearchParam && index === 3 && basketHasItem
                      ? false
                      : (!customerSearchParam && index > 0) ||
                        (!serviceSearchParam && index > 1) ||
                        (!staffSearchParam && index > 2)
                      ? true
                      : false
                  }
                >
                  <Box
                    sx={{
                      svg: {
                        width: 30,
                        height: 30,
                        path: {
                          fill:
                            Number(activeSearchParam) === index
                              ? "white"
                              : "#D7C2FE",
                        },
                      },
                    }}
                  >
                    {step.icon}
                  </Box>

                  <Typography
                    sx={{ flexBasis: "100%" }}
                    fontSize={17}
                    fontWeight={400}
                    color={
                      Number(activeSearchParam) === index ? "white" : "grey.300"
                    }
                  >
                    {step.label}
                  </Typography>
                </BaseButton>
              </Step>
            );
          })}
        </Stepper>
        {/* <BaseButton
          sx={{
            color: "white",
            width: 100,
            borderRadius: 3,
            justifyContent: "start",
            flexDirection: "column",
            gap: 1,
            p: 1,
            mt: "auto",
            fontWeight: 400,
            height: "auto",
            boxShadow: 0,
            border: 1,
            borderColor: "white",
            "&:hover": {
              bgcolor: "transparent",
              boxShadow: 0,
            },
            "&: disabled": {
              opacity: 0.6,
              color: "white",
            },
          }}
          onClick={() => dispatch(setActiveStep(activeStep + 1))}
          disabled={nextButtonStatus}
        >
          {activeStep === 3 ? "done" : "continue"}
        </BaseButton> */}
      </Grid>

      <Tab index={0} value={Number(activeSearchParam)}>
        <CustomerStep />
      </Tab>
      <Tab index={1} value={Number(activeSearchParam)}>
        <ServiceStep />
      </Tab>
      <Tab index={2} value={Number(activeSearchParam)}>
        <DateAndTimeStep />
      </Tab>
      <Tab index={3} value={Number(activeSearchParam)}>
        <BasketStep />
      </Tab>

      {/* <Tab index={3} value={activeStep}>
        <SupplementaryStep />
      </Tab> */}
    </Grid>
  );
};

export default NewBooking;
