import { FC, ReactNode } from "react";

import { Route, Routes } from "react-router";
import PageNotFound from "../../pages/errors/PageNotFound.error";
import BrokenError from "../../pages/errors/Broken.errors";
import InternalServerError from "../../pages/errors/InternalServer.error";

type RoutesContainerPropType = {
  children: ReactNode;
  FallbackComponent?: any;
};

const RoutesContainer: FC<RoutesContainerPropType> = ({
  children,
  FallbackComponent = <PageNotFound />,
}) => (
  <Routes>
    <Route path="*" element={FallbackComponent} />
    <Route path="/error-404" element={<PageNotFound />} />
    <Route path="/broken" element={<BrokenError />} />
    <Route path="/error-server" element={<InternalServerError />} />
    {children}
  </Routes>
);

export default RoutesContainer;
