import { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FormikProps } from "formik";

// MUI
import { Grid, Typography } from "@mui/material";
import { RotatingLines } from "react-loader-spinner";

import OneSectionLayout from "../../layout/OneSection.layouts";
import BaseButton from "../../components/base/BaseButton";

// types
import { IServiceFormTypes } from "../../helpers/formik";
import { useAppSelector } from "../../hooks/redux.hooks";
import SelectTimeCard from "../../components/cards/SelectTime.cards";

type DurationServicePropsTypes = {
  formik: FormikProps<IServiceFormTypes>;
};

const DurationService: FC<DurationServicePropsTypes> = ({ formik }) => {
  // hooks
  const navigate = useNavigate();

  const { isPostingService, isFetchingService } = useAppSelector(
    (state) => state.service
  );

  // states
  const { values, handleSubmit, setFieldValue } = formik;
  const [serviceDuration, setServiceDuration] = useState([
    {
      id: 1,
      type: "hour",
      title: "Hour",
      value: values.duration > 50 ? Math.floor(values.duration / 60) : 0,
    },
    {
      id: 2,
      type: "minute",
      title: "Minute",
      value: values.duration <= 50 ? values.duration : values.duration % 60,
    },
  ]);

  const [bookableBeforeTime, setBookableBeforeTime] = useState([
    {
      id: 1,
      type: "day",
      title: "Day",
      value: values.bookableBeforHours
        ? Math.floor(values.bookableBeforHours / 24)
        : 0,
    },
    {
      id: 3,
      type: "hour",
      title: "Hour",
      value: values.bookableBeforHours
        ? Math.floor(values.bookableBeforHours % 24)
        : 0,
    },
  ]);

  const [gapTime, setGapTime] = useState([
    {
      id: 1,
      type: "hour",
      title: "Hour",
      value:
        values.timeBetweenTwoServ > 55
          ? Math.floor(values.timeBetweenTwoServ / 60)
          : 0,
    },
    {
      id: 2,
      type: "minute",
      title: "Minute",
      value:
        values.timeBetweenTwoServ <= 55
          ? values.timeBetweenTwoServ
          : values.timeBetweenTwoServ % 60,
    },
  ]);

  // setting durations
  useEffect(() => {
    setServiceDuration([
      {
        id: 1,
        type: "hour",
        title: "Hour",
        value: values.duration > 50 ? Math.floor(values.duration / 60) : 0,
      },
      {
        id: 2,
        type: "minute",
        title: "Minute",
        value: values.duration <= 50 ? values.duration : values.duration % 60,
      },
    ]);

    setBookableBeforeTime([
      {
        id: 1,
        type: "day",
        title: "Day",
        value: values.bookableBeforHours
          ? Math.floor(values.bookableBeforHours / 24)
          : 0,
      },
      {
        id: 3,
        type: "hour",
        title: "Hour",
        value: values.bookableBeforHours
          ? Math.floor(values.bookableBeforHours % 24)
          : 0,
      },
    ]);

    setGapTime([
      {
        id: 1,
        type: "hour",
        title: "Hour",
        value:
          values.timeBetweenTwoServ > 55
            ? Math.floor(values.timeBetweenTwoServ / 60)
            : 0,
      },
      {
        id: 2,
        type: "minute",
        title: "Minute",
        value:
          values.timeBetweenTwoServ <= 55
            ? values.timeBetweenTwoServ
            : values.timeBetweenTwoServ % 60,
      },
    ]);
  }, [values]);

  // setting formik duration
  useEffect(() => {
    const finalResult = serviceDuration;
    let totalMin = 0;
    finalResult.map((item) => {
      if (item.type === "hour") {
        totalMin += item.value * 60;
      } else if (item.type === "minute") {
        totalMin += item.value;
      }

      return null;
    });
    setFieldValue("duration", totalMin);
  }, [serviceDuration]);

  // setting formik day and hours
  useEffect(() => {
    const finalResult = bookableBeforeTime;
    let totalHour = 0;
    finalResult.map((item) => {
      if (item.type === "hour") {
        totalHour += item.value;
      } else if (item.type === "day") {
        totalHour += item.value * 24;
      }
      return null;
    });

    setFieldValue("bookableBeforHours", totalHour);
  }, [bookableBeforeTime]);

  // setting formik gap time
  useEffect(() => {
    const finalResult = gapTime;
    let totalMin = 0;
    finalResult.map((item) => {
      if (item.type === "hour") {
        totalMin += item.value * 60;
      } else if (item.type === "minute") {
        totalMin += item.value;
      }
      return null;
    });
    setFieldValue("timeBetweenTwoServ", totalMin);
  }, [gapTime]);

  return (
    <OneSectionLayout>
      <Grid
        container
        item
        xs={12}
        zIndex={1}
        position="relative"
        top={-16}
        justifyContent="space-between"
      >
        <Typography
          height={70}
          display="flex"
          alignItems="center"
          color="grey.100"
        >
          Service Duration
        </Typography>
        <Grid
          item
          container
          xs={12}
          md={8}
          gap={2}
          alignItems="center"
          justifyContent="end"
          position="unset"
          bottom={-5}
          left={0}
          bgcolor="transparent"
          p={0}
          zIndex={10}
        >
          <BaseButton
            sx={{
              width: 150,
              bgcolor: "white",
              color: "primary.main",
              "&:hover": {
                bgcolor: "white",
              },
              border: 0,
              fontWeight: 500,
              height: 45,
            }}
            onClick={() => handleSubmit()}
            disabled={isPostingService}
          >
            {isPostingService ? (
              <Grid
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  zIndex: 10,
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="30"
                  visible
                />
              </Grid>
            ) : (
              "Save"
            )}
          </BaseButton>

          <BaseButton
            sx={{
              width: 150,
              border: 0,
              display: "flex",
              boxShadow: 0,
            }}
            onClick={() => navigate("/services/overview")}
          >
            Discard
          </BaseButton>
        </Grid>
      </Grid>
      <Grid
        display="grid"
        gridTemplateColumns="1fr 1fr"
        gap={2}
        position="relative"
      >
        {isFetchingService && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            position="absolute"
            zIndex={1000}
            bgcolor="rgba(0,0,0, 0.05)"
            borderRadius={1}
            height="100%"
          >
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
            />
          </Grid>
        )}
        <SelectTimeCard
          timeDataState={serviceDuration}
          timeDateSetState={setServiceDuration}
          label="Service Duration"
        />
        <SelectTimeCard
          timeDataState={gapTime}
          timeDateSetState={setGapTime}
          label="Gap Time"
        />
        <SelectTimeCard
          timeDataState={bookableBeforeTime}
          timeDateSetState={setBookableBeforeTime}
          label="Bookable Before Time"
        />
      </Grid>
    </OneSectionLayout>
  );
};

export default DurationService;
