import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import CreateService from "./Create.services";

import OverviewService from "./Overview.services";
import { useState } from "react";
import { IPagination } from "../staffs/List.staffs";
import Categories from "./categories/Categories";
import Supplementary from "./supplementary/Supplementary";
import PageNotFound from "../errors/PageNotFound.error";
import { useAppSelector } from "../../hooks/redux.hooks";

const LazyList = lazy(() => import("./List.services"));

const Services = () => {
  const [pagination, setPagination] = useState<IPagination>({
    pageNumber: 1,
    pageSize: 10,
  });
  const { serviceError } = useAppSelector((state) => state.service);

  return (
    <Routes>
      <Route
        element={
          <LazyList pagination={pagination} setPagination={setPagination} />
        }
        path="/overview"
      />
      <Route element={<CreateService />} path="/create-service" />
      <Route
        element={serviceError === 404 ? <PageNotFound /> : <OverviewService />}
        path="/edit-service/:serviceId/*"
      />
      <Route element={<Categories />} path="/categories/*" />
      <Route element={<Supplementary />} path="/supplementary/*" />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Services;
