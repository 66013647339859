import React, { useState, ChangeEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DateObject } from "react-multi-date-picker";
import { RotatingLines } from "react-loader-spinner";
import { AppDispatch } from "../../../redux/store";
import { useAppSelector } from "../../../hooks/redux.hooks";

// MUI
import {
  Grid,
  IconButton,
  Typography,
  FormControlLabel,
  Switch,
  InputAdornment,
  Divider,
} from "@mui/material";

import {
  IPostServiceAdditionalParam,
  deleteServiceAdditional,
  fetchServiceAdditionalById,
  postServiceAdditional,
} from "../../../redux/services/services.actions";
import { IEditActionsApiMethodType } from "../../../redux/shifts/shift.actions";
import { useServiceAdditionalForm } from "../../../helpers/formik";

// icons
import { ReactComponent as EditIcon } from "../../../assets/icons/edit-icon.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash-icon.svg";
import { ReactComponent as DurationIcon } from "../../../assets/icons/duration-icon.svg";

// components
import OneSectionLayout from "../../../layout/OneSection.layouts";
import BaseButton from "../../../components/base/BaseButton";
import BaseDatePicker from "../../../components/base/BaseDatePicker";
import BaseSelect from "../../../components/base/BaseSelect";
import BaseInput from "../../../components/base/BaseInput";
import { NumericFormatCustom } from "../../../components/base/BaseMaskInput";
import { clearServiceAdditionalById } from "../../../redux/services/services.reducer";
import ConfirmModal from "../../../components/modals/Confirm.modals";
import { selectInputItemsFormatter } from "../../../helpers/utils";

const ListAdditional = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { serviceId } = useParams();
  const {
    serviceById,
    isFetchingService,
    serviceAdditionalById,
    isPostingServiceAdditional,
    isDeletingServiceAdditional,
    isFetchingAdditionalService,
  } = useAppSelector((state) => state.service);

  const { user } = useAppSelector((state) => state.user);

  // states
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);

  // handlers
  const handleOpenConfirmModal = () => setOpenConfirmModal(true);
  const handleCloseConfirmModal = () => setOpenConfirmModal(false);

  // function getTableParentHeight() {
  //   const tableParentElement =
  //     document.getElementById("tableParent")?.clientHeight;

  //   return tableParentElement;
  // }

  // const [tableParentHeight, setTableParentHeight] = useState(
  //   getTableParentHeight()
  // );

  // const parameters: IFetchAllAdditionalServiceParams = {
  //   pageNumber: pagination.pageNumber,
  //   pageSize: pagination.pageSize,
  //   serviceId: serviceId || 1,
  // };

  // const columns: ColumnsType<IAdditionalServiceData> = [
  //   {
  //     title: "ID",
  //     dataIndex: "id",
  //     width: 60,
  //   },
  //   {
  //     title: "Title",
  //     dataIndex: "additionalServiceTitle",
  //     width: 150,
  //     ellipsis: true,
  //   },
  //   {
  //     title: "Max Count",
  //     width: 130,
  //     render(value, record, index) {
  //       return <Typography>{record.additionalServiceMaxCount}</Typography>;
  //     },
  //   },
  //   {
  //     title: "Duration",
  //     width: 130,
  //     dataIndex: "additionalServiceDuration",
  //     render(value, record, index) {
  //       return (
  //         <Grid
  //           container
  //           alignItems="center"
  //           gap={1}
  //           sx={{ svg: { width: 20, height: 20, path: { fill: "#aaa" } } }}
  //         >
  //           <DurationIcon />
  //           <Typography>{record.additionalServiceDuration}</Typography>
  //         </Grid>
  //       );
  //     },
  //   },
  //   {
  //     title: "Amount",
  //     width: 130,
  //     dataIndex: "additionalServiceAmount",
  //     render(value, record, index) {
  //       return <Typography>&#163; {record.additionalServiceAmount}</Typography>;
  //     },
  //   },
  //   {
  //     title: "Expire Date",
  //     width: 130,
  //     dataIndex: "expireDate",
  //     render(value, record, index) {
  //       return (
  //         <Typography fontSize={16}>
  //           {new DateObject(record.additionalServiceExpireDate).format()}
  //         </Typography>
  //       );
  //     },
  //   },
  //   {
  //     title: "Status",
  //     width: 130,
  //     render: (value, record) => {
  //       return (
  //         <Typography
  //           color={
  //             record.additionalServiceStatus ? "success.main" : "error.main"
  //           }
  //           fontSize={16}
  //           fontWeight={500}
  //           bgcolor={
  //             record.additionalServiceStatus ? "success.light" : "error.light"
  //           }
  //           py={0.5}
  //           px={1}
  //           textAlign="center"
  //           borderRadius={2}
  //           maxWidth={100}
  //         >
  //           {record.additionalServiceStatus ? "Active" : "InActive"}
  //         </Typography>
  //       );
  //     },
  //   },
  //   {
  //     title: "Action",
  //     key: "action",
  //     width: 150,
  //     render: (value, record) => (
  //       <Box display="flex" justifyContent="end">
  //         <IconButton
  //           sx={{
  //             svg: {
  //               width: 26,
  //               height: 26,
  //             },
  //           }}
  //           onClick={() => navigate(`edit-additional/${record.id}`)}
  //         >
  //           <EditIcon />
  //         </IconButton>
  //         <IconButton
  //           sx={{
  //             svg: {
  //               width: 26,
  //               height: 26,
  //             },
  //           }}
  //           onClick={() =>
  //             dispatch(
  //               deleteServiceAdditional({
  //                 serviceAdditionalId: record.id,
  //                 fetchParams: { ...parameters },
  //               })
  //             )
  //           }
  //         >
  //           <TrashIcon />
  //         </IconButton>
  //       </Box>
  //     ),
  //   },
  // ];

  // useEffect(() => {
  //   setTableParentHeight(getTableParentHeight());
  // }, [pagination]);

  const submit = () => {
    const formData = new FormData();
    for (const key in values) {
      if (key !== "additionalServiceExpireDate" && key !== "shopId")
        //@ts-ignore
        formData.append(key, values[key]);
    }
    formData.append(
      "additionalServiceExpireDate",
      new DateObject(values.additionalServiceExpireDate as string).format(
        "YYYY-MM-DD"
      )
    );
    formData.append("serviceId", String(serviceId));
    if (user) formData.append("shopId", String(user.shopId));

    const parameters: IPostServiceAdditionalParam = {
      type: serviceAdditionalById
        ? IEditActionsApiMethodType.PATCH
        : IEditActionsApiMethodType.POST,
      body: formData,
      fetchParams: { serviceId: Number(serviceId) },
    };

    validateForm();
    if (isValid) dispatch(postServiceAdditional(parameters));
  };

  const serviceTypeInputItems = selectInputItemsFormatter(
    serviceById?.commonData.serviceTypeList,
    "lookupValueTitle",
    "id"
  );

  const {
    values,
    handleChange,
    isValid,
    validateForm,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useServiceAdditionalForm(serviceAdditionalById, submit);
  return (
    // <ThreeSectionLayout
    //   header={<AdditionalServiceListHeader />}
    //   paging={
    //     <Paging
    //       data={additionalServicesData}
    //       pagination={pagination}
    //       setPagination={setPagination}
    //     />
    //   }
    //   bgcolor="inputBackground.main"
    // >
    //   {tableParentHeight && (
    //     <Table
    //       loading={isFetchingService}
    //       columns={columns}
    //       dataSource={additionalServicesData?.data}
    //       pagination={false}
    //       scroll={
    //         (additionalServicesData?.data?.length as number) > 0
    //           ? { x: 800, y: (tableParentHeight as number) - 70 }
    //           : { x: undefined, y: undefined }
    //       }
    //       className="shiftTable"
    //       rowKey="id"
    //     />
    //   )}
    // </ThreeSectionLayout>
    <OneSectionLayout>
      <Grid
        container
        item
        xs={12}
        zIndex={1}
        position="relative"
        top={-16}
        justifyContent="space-between"
      >
        <Typography
          height={70}
          display="flex"
          alignItems="center"
          color="grey.100"
        >
          Service Additional
        </Typography>
        <Grid
          item
          container
          xs={12}
          md={8}
          gap={2}
          alignItems="center"
          justifyContent="end"
          position="unset"
          bottom={-5}
          left={0}
          bgcolor="transparent"
          p={0}
          zIndex={10}
        >
          <BaseButton
            sx={{
              width: 150,
              bgcolor: "white",
              color: "primary.main",
              "&:hover": {
                bgcolor: "white",
              },
              border: 0,
              fontWeight: 500,
              height: 45,
            }}
            onClick={() => handleSubmit()}
            disabled={isPostingServiceAdditional || isFetchingService}
          >
            {isPostingServiceAdditional || isFetchingService ? (
              <Grid
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  zIndex: 10,
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="30"
                  visible
                />
              </Grid>
            ) : (
              "Save"
            )}
          </BaseButton>

          <BaseButton
            sx={{
              width: 150,
              border: 0,
              display: "flex",
              boxShadow: 0,
            }}
            onClick={() => navigate(-1)}
          >
            Discard
          </BaseButton>
        </Grid>
      </Grid>
      <Grid container gap={2} mb={8} flexWrap="nowrap">
        <Grid
          item
          xs={4}
          gap={2}
          display="grid"
          gridTemplateColumns="1fr"
          height="fit-content"
          position="relative"
        >
          {isFetchingAdditionalService && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              position="absolute"
              zIndex={1000}
              bgcolor="rgba(0,0,0, 0.05)"
              borderRadius={1}
              height="100%"
            >
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
              />
            </Grid>
          )}

          <BaseInput
            onChange={handleChange}
            name="additionalServiceTitle"
            value={values.additionalServiceTitle}
            variant="outlined"
            label="Title"
            placeholder="Title"
          />
          <BaseInput
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFieldValue(
                "additionalServiceDuration",
                Number(e.target.value)
              );
            }}
            name="additionalServiceDuration"
            value={values.additionalServiceDuration}
            variant="outlined"
            label="Duration"
            placeholder="Duration"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ zIndex: 100 }}>
                  Minute
                </InputAdornment>
              ),
            }}
          />
          <BaseInput
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFieldValue("additionalServiceAmount", Number(e.target.value));
            }}
            name="additionalServiceAmount"
            value={values.additionalServiceAmount}
            variant="outlined"
            label="Amount"
            placeholder="Amount"
            InputProps={{
              inputComponent: NumericFormatCustom as any,
            }}
          />
          <BaseInput
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFieldValue(
                "additionalServiceMaxCount",
                Number(e.target.value)
              );
            }}
            name="additionalServiceMaxCount"
            value={values.additionalServiceMaxCount}
            variant="outlined"
            label="Max Count"
            placeholder="Max Count"
          />
          <FormControlLabel
            label={<Typography fontWeight={400}>Status</Typography>}
            control={
              <Switch
                checked={values.additionalServiceStatus ? true : false}
                size="small"
                name="additionalServiceStatus"
                onChange={() =>
                  setFieldValue(
                    "additionalServiceStatus",
                    values.additionalServiceStatus ? 0 : 1
                  )
                }
              />
            }
            labelPlacement="start"
            sx={{
              px: 1,
              width: "100%",
              justifyContent: "space-between",
              mx: 0,
              bgcolor: "inputBackground.main",
              height: 45,
              borderRadius: 1,
            }}
          />
          <BaseDatePicker
            value={values.additionalServiceExpireDate}
            setValue={(value: any) =>
              setFieldValue("additionalServiceExpireDate", value)
            }
          />
          <BaseSelect
            value={values.additionalServiceType}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFieldValue("additionalServiceType", e.target.value)
            }
            defaultVal="Additional Service Type"
            inputItems={serviceTypeInputItems}
            sx={{ bgcolor: "grey.100", height: "fit-content" }}
            label="Additional Service Type"
          />
          <BaseButton
            variant="text"
            onClick={() => {
              dispatch(clearServiceAdditionalById());
              resetForm();
            }}
          >
            Reset
          </BaseButton>
        </Grid>
        <Grid
          item
          xs={8}
          container
          flexDirection="column"
          rowGap={1}
          position="relative"
        >
          {(isDeletingServiceAdditional || isFetchingService) && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              position="absolute"
              zIndex={1000}
              bgcolor="rgba(0,0,0, 0.05)"
              borderRadius={1}
              height="100%"
            >
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
              />
            </Grid>
          )}
          {serviceById?.additionalServiceList?.map((item, i) => (
            <React.Fragment key={item.id}>
              <Grid
                container
                flexWrap="nowrap"
                gap={1}
                height={40}
                alignItems="center"
              >
                <Grid item xs={1}>
                  <Typography color="primary.main" fontSize={16}>
                    #{item.id}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    fontSize={16}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {item.additionalServiceTitle}
                  </Typography>
                </Grid>
                <Grid item xs={1.5}>
                  <Typography fontSize={16}>
                    &#163; {item.additionalServiceAmount}
                  </Typography>
                </Grid>
                <Grid item xs={2.5}>
                  <Typography fontSize={16}>
                    {new DateObject(item.additionalServiceExpireDate).format()}
                  </Typography>
                </Grid>
                <Grid item xs={1.5}>
                  <Typography fontSize={16}>
                    {item.additionalServiceMaxCount}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={1.5}
                  container
                  alignItems="center"
                  sx={{
                    svg: {
                      width: 20,
                      height: 20,
                    },
                  }}
                >
                  <Typography fontSize={16} width={40} noWrap>
                    {item.additionalServiceDuration}
                  </Typography>
                  <DurationIcon />
                </Grid>
                <Grid
                  item
                  xs={2}
                  container
                  flexWrap="nowrap"
                  justifyContent="end"
                >
                  <IconButton
                    onClick={() =>
                      dispatch(
                        fetchServiceAdditionalById({
                          serviceAdditionalId: item.id,
                        })
                      )
                    }
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      handleOpenConfirmModal();
                      setDeleteId(item.id);
                    }}
                  >
                    <TrashIcon />
                  </IconButton>
                </Grid>
              </Grid>
              {i < serviceById?.additionalServiceList?.length - 1 && (
                <Divider sx={{ borderColor: "grey.300", width: "100%" }} />
              )}
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
      <ConfirmModal
        isDeleting={isDeletingServiceAdditional}
        open={openConfirmModal}
        handleClose={handleCloseConfirmModal}
        action={() =>
          dispatch(
            deleteServiceAdditional({
              serviceAdditionalId: deleteId,
              fetchParams: { serviceId: Number(serviceId) },
              closeModalHandler: handleCloseConfirmModal,
            })
          )
        }
      />
    </OneSectionLayout>
  );
};

export default ListAdditional;
