import { useRef } from "react";
import { useAppSelector } from "../../hooks/redux.hooks";
import { useSearchParams } from "react-router-dom";
// MUI
import { Grid, Typography, styled, Skeleton } from "@mui/material";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { NavContainer } from "./Calendar.cards";

// icons
import { ReactComponent as LeftIcon } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as RightIcon } from "../../assets/icons/arrow-right.svg";
import { ReactComponent as DurationStaffIcon } from "../../assets/icons/duration-staff-icon.svg";
import { ReactComponent as PriceStaffIcon } from "../../assets/icons/price-staff-icon.svg";
import { customerOrderDummyData } from "../../helpers/data";

const OrderStaffCard = () => {
  //hooks
  const [searchParams, setSearchParams] = useSearchParams();

  const bookingSearchParam = searchParams.get("bookingId");
  const customerSearchParam = searchParams.get("customerId");
  const customerNameSearchParam = searchParams.get("customer");
  const serviceCategorySearchParam = searchParams.get("serviceCategory");
  const serviceIdSearchParam = searchParams.get("serviceId");
  const serviceSearchParam = searchParams.get("service");
  const dateSearchParam = searchParams.get("date");
  const timeSearchParam = searchParams.get("time");
  const staffSearchParam = searchParams.get("staff");

  const navigationPrev = useRef(null);
  const navigationNext = useRef(null);
  const { orderAvailableData, isOrderAvailableDataFetching } = useAppSelector(
    (state) => state.order
  );

  const splitTimeSearchParam = timeSearchParam?.split("_");
  const selectedStaffs =
    splitTimeSearchParam && orderAvailableData?.serviceFreeList
      ? orderAvailableData?.serviceFreeList?.filter(
          (item) =>
            item.durationStart.split(":")[0] +
              ":" +
              item.durationStart.split(":")[1] ===
            splitTimeSearchParam[0].split("-").join(":")
        )
      : [];

  let nextSearchParams = {};

  if (bookingSearchParam) {
    nextSearchParams = {
      bookingId: bookingSearchParam,
      customerId: String(customerSearchParam),
      customer: String(customerNameSearchParam),
      serviceCategory: String(serviceCategorySearchParam),
      serviceId: String(serviceIdSearchParam),
      service: String(serviceSearchParam),
      date: String(dateSearchParam),
      time: String(timeSearchParam),
    };
  } else {
    nextSearchParams = {
      customerId: String(customerSearchParam),
      customer: String(customerNameSearchParam),
      serviceCategory: String(serviceCategorySearchParam),
      serviceId: String(serviceIdSearchParam),
      service: String(serviceSearchParam),
      date: String(dateSearchParam),
      time: String(timeSearchParam),
    };
  }

  const handleStaffPicker = (staff: number) => {
    Object.assign(nextSearchParams, { staff: String(staff), active: "2" });
    setSearchParams(nextSearchParams);
  };

  return (
    <Grid width="100%">
      <Typography fontSize={20} fontWeight={500} color="primary.main" mb={3}>
        Select Staff
      </Typography>

      <Grid
        sx={{
          width: "100%",
          ".swiper-slide": {
            height: "85%",
            width: 100,
            overflow: "hidden",
          },
          position: "relative",
        }}
      >
        <Swiper
          onInit={(swiper: any) => {
            swiper.params.navigation.prevEl = navigationPrev.current;
            swiper.params.navigation.nextEl = navigationNext.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          slidesPerView={3}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 10000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            0: {
              slidesPerView: 2,
              spaceBetween: 20,
              slidesPerGroup: 1,
            },
            1500: {
              slidesPerView: 4,
              spaceBetween: 20,
              slidesPerGroup: 2,
            },
          }}
          navigation={{
            prevEl: navigationPrev.current,
            nextEl: navigationNext.current,
          }}
          modules={[Navigation]}
          style={{
            width: "calc(100% - 80px)",
            padding: "0 4px",
          }}
        >
          {isOrderAvailableDataFetching ? (
            customerOrderDummyData.map((item) => (
              <SwiperSlide key={item.id}>
                <Skeleton
                  key={item.id}
                  animation="wave"
                  variant="rectangular"
                  height={100}
                  sx={{ borderRadius: 2 }}
                />
              </SwiperSlide>
            ))
          ) : selectedStaffs.length > 0 ? (
            selectedStaffs?.map((item, i) => {
              return (
                <SwiperSlide key={i}>
                  <Staffs
                    sx={{
                      bgcolor: item.isAvailable
                        ? staffSearchParam === String(item.staffId)
                          ? "primary.main"
                          : "white"
                        : "grey.200",
                      color: item.isAvailable
                        ? staffSearchParam === String(item.staffId)
                          ? "white"
                          : "grey.800"
                        : "grey.500",

                      cursor: item.isAvailable ? "pointer" : "default",
                    }}
                    onClick={() => {
                      if (item.isAvailable) handleStaffPicker(item.staffId);
                    }}
                    gap={2}
                  >
                    <Grid
                      item
                      xs={6}
                      container
                      alignItems="center"
                      height={84}
                      justifyContent="center"
                    >
                      <img
                        src={`https://booking.weeorder.co.uk/uploadfiles/Images/${item.staffImage}`}
                        alt=""
                        width={"100%"}
                        height={"100%"}
                        style={{
                          borderRadius: 5,
                          opacity: item.isAvailable ? 1 : 0.8,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography fontWeight={400} fontSize={18} noWrap>
                        {item.staffName}.{item.staffFamily}
                      </Typography>
                      <Typography
                        fontWeight={400}
                        fontSize={18}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          svg: {
                            width: 20,
                            height: 20,
                            path: {
                              fill: item.isAvailable
                                ? staffSearchParam === String(item.staffId)
                                  ? ""
                                  : "#777"
                                : "#b1b1b1",
                            },
                          },
                        }}
                      >
                        <DurationStaffIcon /> {item.staffDuration}
                      </Typography>
                      <Typography
                        fontWeight={400}
                        fontSize={18}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          svg: {
                            width: 20,
                            height: 20,
                            path: {
                              fill: item.isAvailable
                                ? staffSearchParam === String(item.staffId)
                                  ? ""
                                  : "#777"
                                : "#b1b1b1",
                            },
                          },
                        }}
                      >
                        <PriceStaffIcon />
                        {item.staffAmount}
                      </Typography>
                    </Grid>
                  </Staffs>
                </SwiperSlide>
              );
            })
          ) : (
            <SwiperSlide>
              <Grid
                bgcolor="white"
                height={100}
                container
                justifyContent="center"
                alignItems="center"
                borderRadius={2}
              >
                <Typography fontSize={18} fontWeight={500}>
                  No Data
                </Typography>
              </Grid>
            </SwiperSlide>
          )}
        </Swiper>
        <NavContainer sx={{ left: 0 }} ref={navigationPrev}>
          <LeftIcon />
        </NavContainer>
        <NavContainer sx={{ right: 0 }} ref={navigationNext}>
          <RightIcon />
        </NavContainer>
      </Grid>
    </Grid>
  );
};

const Staffs = styled(Grid)(({ theme }) =>
  theme.unstable_sx({
    minWidth: 60,
    color: "grey.800",
    borderRadius: 2,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    fontSize: 16,
    fontWeight: 500,
    flexWrap: "nowrap",
    p: 1,
  })
);

export default OrderStaffCard;
