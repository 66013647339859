import { RotatingLines } from "react-loader-spinner";

// MUI
import { Grid } from "@mui/material";

const WithSpinner =
  (WrappedComponent: any) =>
  ({ isLoading, ...otherProps }: any) => {
    return isLoading ? (
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 400,
          width: "100%",
        }}
      >
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="30"
          visible
        />
      </Grid>
    ) : (
      <WrappedComponent {...otherProps} />
    );
  };

export default WithSpinner;
