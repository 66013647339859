import React from "react";
//  ? Mui
import { Grid, IconButton, Divider, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

// icons
import CloseIcon from "@mui/icons-material/Close";
import BaseInput from "./BaseInput";
import { ReactComponent as RemoveIcon } from "../../assets/icons/remove-icon.svg";
import { TextMaskCustom } from "./BaseMaskInput";
import { TimeField } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

type ITimePickerPropsType = {
  disabled?: boolean;
  day?: any;
  hour: any;
  nameStart: string;
  nameEnd: string;
  formik: any;
  handleDelete: any;
};

const TimePickerInput: React.FC<ITimePickerPropsType> = ({
  disabled = false,
  day,
  hour,
  nameStart,
  nameEnd,
  formik,
  handleDelete,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid display="grid" gridTemplateColumns="300px 1fr" justifyItems="end">
        <Grid display="grid" gridTemplateColumns="130px 15px 130px">
          {" "}
          <BaseInput
            variant="outlined"
            type="time"
            disabled={disabled}
            name={nameStart}
            value={formik.values[nameStart]}
            onChange={formik.handleChange}
            bgcolor="transparent"
            sx={{
              ".MuiInputBase-input": { px: 0.5, py: 1 },
            }}
          />
          <Grid
            item
            container
            color="grey.700"
            alignItems="center"
            justifyContent="center"
          >
            <Divider
              sx={{
                borderWidth: 1,
                borderColor: "primary.main",
                width: "100%",
              }}
            />
          </Grid>
          <BaseInput
            variant="outlined"
            type="time"
            disabled={disabled}
            name={nameEnd}
            value={formik.values[nameEnd]}
            onChange={formik.handleChange}
            bgcolor="transparent"
            sx={{ ".MuiInputBase-input": { px: 0.5, py: 1 } }}
          />
        </Grid>

        <IconButton
          onClick={() => {
            day
              ? handleDelete(day, hour, nameStart, nameEnd)
              : handleDelete(hour, nameStart, nameEnd);
          }}
          sx={{ svg: { width: 24, height: 24 }, p: 0.75 }}
        >
          <RemoveIcon />
        </IconButton>
      </Grid>
      {/* <Grid container alignItems="center" justifyContent="center">
        <Grid item width="fit-content">
          <BaseInput
            variant="outlined"
            type="time"
            disabled={disabled}
            name={nameStart}
            value={formik.values[nameStart]}
            handleChange={formik.handleChange}
            bgcolor="transparent"
            sx={{
              ".MuiInputBase-input": { px: 0.5, py: 1 },
            }}
          />
        </Grid>

        <Grid
          item
          container
          xs={1}
          color="grey.700"
          alignItems="center"
          justifyContent="center"
        >
          <Divider
            sx={{ borderWidth: 1, borderColor: "primary.main", width: "50%" }}
          />
        </Grid>

        <Grid item>
          <BaseInput
            variant="outlined"
            type="time"
            disabled={disabled}
            name={nameEnd}
            value={formik.values[nameEnd]}
            handleChange={formik.handleChange}
            bgcolor="transparent"
            sx={{ ".MuiInputBase-input": { px: 0.5, py: 1 } }}
          />
        </Grid>
        <Grid item xs={1} container justifyContent="center" ml="auto">
          <IconButton
            onClick={() => {
              day
                ? handleDelete(day, hour, nameStart, nameEnd)
                : handleDelete(hour, nameStart, nameEnd);
            }}
            sx={{ svg: { width: 24, height: 24 } }}
          >
            <RemoveIcon />
          </IconButton>
        </Grid>
      </Grid> */}
    </LocalizationProvider>
  );
};

export default TimePickerInput;
