import React, { FC, useState, useEffect } from "react";

// MUI
import {
  Box,
  ClickAwayListener,
  IconButton,
  styled,
  Typography,
} from "@mui/material";

// icons
import ArrowDropUpRoundedIcon from "@mui/icons-material/ArrowDropUpRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";

// const initialSelectData = [
//   { id: 1, type: "day", title: "Day-TEST", value: 99 },
//   { id: 2, type: "hour", title: "Hour-TEST", value: 99 },
//   { id: 3, type: "minute", title: "Minute-TEST", value: 99 },
// ];

export type ITimeData = {
  id: number;
  type: string;
  title: string;
  value: number;
};

type SelectTimeCardPropsTypes = {
  timeDataState: ITimeData[];
  timeDateSetState: any;
  label: string;
};

const SelectTimeCard: FC<SelectTimeCardPropsTypes> = ({
  timeDataState,
  timeDateSetState,
  label = "Label",
}) => {
  const [open, setOpen] = useState(false);
  const [dropdownPlaceholder, setDropdownPlaceholder] = useState(
    `Select ${label}`
  );

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const incrementValue = (id: number) => {
    const newData = timeDataState.map((item) => {
      if (item.id === id && item.type === "day" && item.value < 30) {
        return { ...item, value: item.value + 1 };
      } else if (item.id === id && item.type === "hour" && item.value < 24) {
        return { ...item, value: item.value + 1 };
      } else if (item.id === id && item.type === "minute" && item.value < 55) {
        return { ...item, value: item.value + 5 };
      }
      return item;
    });
    timeDateSetState(newData);
  };

  const decrementValue = (id: number) => {
    const newData = timeDataState.map((item) => {
      if (item.id === id && item.type === "day" && item.value > 0) {
        return { ...item, value: item.value - 1 };
      } else if (item.id === id && item.type === "hour" && item.value > 0) {
        return { ...item, value: item.value - 1 };
      } else if (item.id === id && item.type === "minute" && item.value > 0) {
        return { ...item, value: item.value - 5 };
      }
      return item;
    });
    timeDateSetState(newData);
  };

  useEffect(() => {
    const newPlaceholder = timeDataState.map((item) => {
      if (item.value > 0) {
        return `${item.value} ${item.title}`;
      }
    });

    newPlaceholder.every((item) => item === undefined)
      ? setDropdownPlaceholder(`${label}`)
      : setDropdownPlaceholder(newPlaceholder.join(" "));
  }, [timeDataState]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setOpen(false);
      }}
    >
      <Box sx={{ position: "relative" }}>
        <Typography position="absolute" fontSize={12} top={-8} left={8}>
          {label}
        </Typography>
        <Select onClick={handleClick} sx={{}}>
          <Typography variant="h5" fontSize={16} color="grey.700">
            {dropdownPlaceholder}
          </Typography>

          {open ? (
            <ArrowDropUpRoundedIcon sx={{ color: "grey.700" }} />
          ) : (
            <ArrowDropDownRoundedIcon sx={{ color: "grey.700" }} />
          )}
        </Select>
        {open && (
          <SelectOptionBox
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Box
              sx={{
                position: "sticky",
                top: 0,
                bgcolor: "grey.100",
                borderBottom: "1px solid #c4c4c4",
                zIndex: 1,
                width: "100%",
                p: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              {timeDataState.map((item) => (
                <Box
                  key={item.id}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    my: 0,
                    pl: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                    }}
                  >
                    {item.title}
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      onClick={() => incrementValue(item.id)}
                      sx={{ p: 0 }}
                    >
                      <ArrowDropUpRoundedIcon
                        fontSize="large"
                        sx={{ display: "block" }}
                      />
                      <AddRoundedIcon
                        fontSize="large"
                        sx={{ display: "none" }}
                      />
                    </IconButton>
                    <Box
                      sx={{
                        bgcolor: "primary.light",
                        color: "primary.main",
                        fontSize: 16,
                        fontWeight: 600,
                        textAlign: "center",
                        borderRadius: 1,
                        px: 4,
                        py: 1,
                      }}
                    >
                      {item.value}
                    </Box>
                    <IconButton
                      onClick={() => decrementValue(item.id)}
                      sx={{ p: 0 }}
                    >
                      <ArrowDropDownRoundedIcon
                        fontSize="large"
                        sx={{ display: "block" }}
                      />
                      <RemoveRoundedIcon sx={{ display: "none" }} />
                    </IconButton>
                  </Box>
                </Box>
              ))}
            </Box>
          </SelectOptionBox>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default SelectTimeCard;

const Select = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    cursor: "pointer",
    borderRadius: "8px",
    padding: "13px 20px",
    alignItems: "center",
    justifyContent: "space-between",
    p: "10px",
    height: "auto",
    width: "100%",
    bgcolor: "inputBackground.main",
  })
);

const SelectOptionBox = styled(Box)(({ theme }) => ({
  marginTop: 10,
  height: "fit-content",
  maxHeight: "300px",
  backgroundColor: theme.palette.background.paper,
  borderRadius: 8,
  border: `1px solid #c4c4c4`,
  overflowY: "auto",
  position: "absolute",
  zIndex: 2,
  boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.25)",
}));
