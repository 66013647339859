import { createSlice } from "@reduxjs/toolkit";
import {
  ICustomerType,
  deleteCustomer,
  fetchAllCustomer,
  postCustomer,
} from "./customer.actions";

type ICustomerState = {
  customerData: ICustomerType | null;
  isFetchingCustomer: boolean;
  fetchCustomerError: any;
  isPostingCustomer: boolean;
  postCustomerError: any;
  isDeletingCustomer: boolean;
  deleteCustomerError: any;
};

const initialState: ICustomerState = {
  customerData: null,
  isFetchingCustomer: false,
  fetchCustomerError: null,
  isPostingCustomer: false,
  postCustomerError: null,
  isDeletingCustomer: false,
  deleteCustomerError: null,
};

const customerSlice = createSlice({
  initialState,
  name: "customer",
  reducers: {
    setFetchCustomerError: (state, action) => {
      state.fetchCustomerError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      // fetch all customers
      .addCase(fetchAllCustomer.pending, (state) => {
        state.isFetchingCustomer = true;
      })
      .addCase(fetchAllCustomer.fulfilled, (state, action) => {
        state.isFetchingCustomer = false;
        state.customerData = action.payload;
      })
      .addCase(fetchAllCustomer.rejected, (state, action) => {
        state.isFetchingCustomer = false;
        state.fetchCustomerError = action.payload;
      })

      // post customer
      .addCase(postCustomer.pending, (state) => {
        state.isPostingCustomer = true;
      })
      .addCase(postCustomer.fulfilled, (state) => {
        state.isPostingCustomer = false;
      })
      .addCase(postCustomer.rejected, (state, action) => {
        state.isPostingCustomer = false;
        state.postCustomerError = action.payload;
      })

      // delete customer
      .addCase(deleteCustomer.pending, (state) => {
        state.isDeletingCustomer = true;
      })
      .addCase(deleteCustomer.fulfilled, (state) => {
        state.isDeletingCustomer = false;
      })
      .addCase(deleteCustomer.rejected, (state, action) => {
        state.isDeletingCustomer = false;
        state.deleteCustomerError = action.payload;
      });
  },
});

export const { setFetchCustomerError } = customerSlice.actions;
export default customerSlice.reducer;
