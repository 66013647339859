import React, { FC, ChangeEvent } from "react";
import { FormikProps } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";

// MUI
import { Grid, Typography } from "@mui/material";

import { IServiceFormTypes } from "../../helpers/formik";
import OneSectionLayout from "../../layout/OneSection.layouts";
import BaseButton from "../../components/base/BaseButton";
import BaseSelect from "../../components/base/BaseSelect";
import BaseInput from "../../components/base/BaseInput";
import { NumericFormatCustom } from "../../components/base/BaseMaskInput";
import { useAppSelector } from "../../hooks/redux.hooks";
import { selectInputItemsFormatter } from "../../helpers/utils";
import { RotatingLines } from "react-loader-spinner";

type PriceServicePropsType = {
  formik: FormikProps<IServiceFormTypes>;
};

const PriceService: FC<PriceServicePropsType> = ({ formik }) => {
  // hooks
  const navigate = useNavigate();

  const { serviceById, isPostingService, isFetchingService } = useAppSelector(
    (state) => state.service
  );

  const { values, handleSubmit, setFieldValue } = formik;

  const payTypeInputItems = selectInputItemsFormatter(
    serviceById?.commonData.payTypeList,
    "lookupValueTitle",
    "id"
  );

  return (
    <OneSectionLayout>
      <Grid
        container
        item
        xs={12}
        zIndex={1}
        position="relative"
        top={-16}
        justifyContent="space-between"
      >
        <Typography
          height={70}
          display="flex"
          alignItems="center"
          color="grey.100"
        >
          Service Price
        </Typography>
        <Grid
          item
          container
          xs={12}
          md={8}
          gap={2}
          alignItems="center"
          justifyContent="end"
          position="unset"
          bottom={-5}
          left={0}
          bgcolor="transparent"
          p={0}
          zIndex={10}
        >
          <BaseButton
            sx={{
              width: 150,
              bgcolor: "white",
              color: "primary.main",
              "&:hover": {
                bgcolor: "white",
              },
              border: 0,
              fontWeight: 500,
              height: 45,
            }}
            disabled={isPostingService}
            onClick={() => handleSubmit()}
          >
            {isPostingService ? (
              <Grid
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  zIndex: 10,
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="30"
                  visible
                />
              </Grid>
            ) : (
              "Save"
            )}
          </BaseButton>

          <BaseButton
            sx={{
              width: 150,
              border: 0,
              display: "flex",
              boxShadow: 0,
            }}
            onClick={() => navigate("/services/overview")}
          >
            Discard
          </BaseButton>
        </Grid>
      </Grid>
      <Grid
        display="grid"
        gridTemplateColumns="1fr 1fr"
        gap={2}
        position="relative"
      >
        {isFetchingService && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            position="absolute"
            zIndex={1000}
            bgcolor="rgba(0,0,0, 0.05)"
            borderRadius={1}
            height="100%"
          >
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
            />
          </Grid>
        )}
        <BaseSelect
          value={values.paymentType}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFieldValue("paymentType", e.target.value)
          }
          defaultVal="Payment Type"
          inputItems={payTypeInputItems}
          sx={{ bgcolor: "grey.100", height: "fit-content" }}
          label="Payment Type"
        />
        <BaseInput
          variant="outlined"
          value={values.amount}
          name="amount"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFieldValue("amount", Number(e.target.value));
          }}
          placeholder="Amount"
          InputProps={{
            inputComponent: NumericFormatCustom as any,
          }}
          label="Amount"
        />
        <BaseInput
          variant="outlined"
          value={values.prePayment}
          name="prePayment"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFieldValue("prePayment", Number(e.target.value));
          }}
          placeholder="prePayment"
          InputProps={{
            inputComponent: NumericFormatCustom as any,
          }}
          label="Pre Payment"
        />
        <BaseInput
          variant="outlined"
          value={values.cancellationFee}
          name="cancellationFee"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFieldValue("cancellationFee", Number(e.target.value));
          }}
          placeholder="Cancellation Fee"
          InputProps={{
            inputComponent: NumericFormatCustom as any,
          }}
          label="Cancellation Fee"
        />
      </Grid>
    </OneSectionLayout>
  );
};

export default PriceService;
