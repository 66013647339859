import { createSlice } from "@reduxjs/toolkit";
import {
  IStaffServicesDataType,
  IStaffType,
  deleteStaff,
  deleteStaffService,
  fetchAllStaffs,
  fetchStaffById,
  fetchStaffServiceById,
  postStaff,
  postStaffHours,
  postStaffService,
} from "./staff.actions";
import { IStaffFormTypes } from "../../helpers/formik";

type IState = {
  staffsData: IStaffType | null;
  isFetchingStaff: boolean;
  staffError: any;
  isPostingStaff: boolean;
  isDeletingStaff: boolean;
  staffById: IStaffFormTypes | null;

  // staff service
  staffServiceById: IStaffServicesDataType | null;
  isFetchingStaffServiceById: boolean;
  isPostingStaffService: boolean;
  isDeletingStaffService: boolean;
  staffServiceError: any;

  // staff hours
  isPostingStaffHours: boolean;
  staffHoursError: any;
};

const initialState: IState = {
  staffsData: null,
  isFetchingStaff: false,
  staffError: null,
  isPostingStaff: false,
  isDeletingStaff: false,
  staffById: null,

  // staff services
  staffServiceById: null,
  isFetchingStaffServiceById: false,
  isPostingStaffService: false,
  isDeletingStaffService: false,
  staffServiceError: null,

  // staff hours
  isPostingStaffHours: false,
  staffHoursError: null,
};

const staffSlice = createSlice({
  initialState,
  name: "staff",
  reducers: {
    clearStaffServiceById: (state) => {
      state.staffServiceById = null;
    },
  },
  extraReducers: (builder) => {
    builder

      // fetch all staffs
      .addCase(fetchAllStaffs.pending, (state) => {
        state.isFetchingStaff = true;
      })
      .addCase(fetchAllStaffs.fulfilled, (state, action) => {
        state.isFetchingStaff = false;
        state.staffsData = action.payload;
      })
      .addCase(fetchAllStaffs.rejected, (state, action) => {
        state.isFetchingStaff = false;
        state.staffError = action.payload;
      })

      // post staff
      .addCase(postStaff.pending, (state) => {
        state.isPostingStaff = true;
        state.staffError = null;
      })
      .addCase(postStaff.fulfilled, (state) => {
        state.isPostingStaff = false;
      })
      .addCase(postStaff.rejected, (state, action) => {
        state.isPostingStaff = false;
        state.staffError = action.payload;
      })

      // delete staff
      .addCase(deleteStaff.pending, (state) => {
        state.isDeletingStaff = true;
      })
      .addCase(deleteStaff.fulfilled, (state) => {
        state.isDeletingStaff = false;
      })
      .addCase(deleteStaff.rejected, (state, action) => {
        state.isDeletingStaff = false;
        state.staffError = action.payload;
      })

      // fetch staff by id
      .addCase(fetchStaffById.pending, (state) => {
        state.isFetchingStaff = true;
      })
      .addCase(fetchStaffById.fulfilled, (state, action) => {
        state.isFetchingStaff = false;
        state.staffById = action.payload;
      })
      .addCase(fetchStaffById.rejected, (state, action) => {
        state.isFetchingStaff = false;
        state.staffError = action.payload;
      })

      // fetch staff service by id
      .addCase(fetchStaffServiceById.pending, (state) => {
        state.isFetchingStaffServiceById = true;
      })
      .addCase(fetchStaffServiceById.fulfilled, (state, action) => {
        state.isFetchingStaffServiceById = false;
        state.staffServiceById = action.payload;
      })
      .addCase(fetchStaffServiceById.rejected, (state, action) => {
        state.isFetchingStaffServiceById = false;
        state.staffServiceError = action.payload;
      })

      // post staff service
      .addCase(postStaffService.pending, (state) => {
        state.isPostingStaffService = true;
      })
      .addCase(postStaffService.fulfilled, (state) => {
        state.isPostingStaffService = false;
      })
      .addCase(postStaffService.rejected, (state, action) => {
        state.isPostingStaffService = false;
        state.staffServiceError = action.payload;
      })

      // delete staff service
      .addCase(deleteStaffService.pending, (state) => {
        state.isDeletingStaffService = true;
      })
      .addCase(deleteStaffService.fulfilled, (state) => {
        state.isDeletingStaffService = false;
      })
      .addCase(deleteStaffService.rejected, (state, action) => {
        state.isDeletingStaffService = false;
        state.staffServiceError = action.payload;
      })

      // post staff hours
      .addCase(postStaffHours.pending, (state) => {
        state.isPostingStaffHours = true;
      })
      .addCase(postStaffHours.fulfilled, (state) => {
        state.isPostingStaffHours = false;
      })
      .addCase(postStaffHours.rejected, (state, action) => {
        state.isPostingStaffHours = false;
        state.staffHoursError = action.payload;
      });
  },
});

export const { clearStaffServiceById } = staffSlice.actions;
export default staffSlice.reducer;
