import { FC, ChangeEvent, useState, useEffect } from "react";

// MUI
import { Grid, Typography } from "@mui/material";

// icon
import BaseSelect from "../base/BaseSelect";
import BaseButton from "../base/BaseButton";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { useSearchParams } from "react-router-dom";
import { fetchAllCustomer } from "../../redux/customer/customer.actions";
import { useAppSelector } from "../../hooks/redux.hooks";
import { selectInputItemsFormatter } from "../../helpers/utils";
import { DateObject } from "react-multi-date-picker";
import BaseDatePicker from "../base/BaseDatePicker";
import BaseCheckbox from "../base/BaseCheckbox";

type ListFilterPropTypes = {
  closePopover: () => void;
};

const BookingListFilterCard: FC<ListFilterPropTypes> = ({ closePopover }) => {
  const dispatch = useDispatch<AppDispatch>();

  // const { customerData } = useAppSelector((state) => state.customer);
  // states
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState({
    customerId:
      searchParams.get("customerId") !== null
        ? String(searchParams.get("customerId"))
        : 0,
    fromDate:
      searchParams.get("fromDate") !== null
        ? String(searchParams.get("fromDate"))
        : new DateObject().format("YYYY-MM-DD"),
    toDate:
      searchParams.get("toDate") !== null
        ? String(searchParams.get("toDate"))
        : new DateObject().format("YYYY-MM-DD"),
    fromDateEnabled: searchParams.get("fromDate") !== null ? true : false,
    toDateEnabled: searchParams.get("toDate") !== null ? true : false,
  });

  useEffect(() => {
    dispatch(fetchAllCustomer({}));
  }, []);

  // const customerInputItems = selectInputItemsFormatter(
  //   customerData?.data,
  //   "firstName",
  //   "id"
  // );

  const filterHandler = () => {
    const nextSearchParams = {};

    // if (filters.customerId)
    //   Object.assign(nextSearchParams, {
    //     customerId: String(filters.customerId),
    //   });
    if (filters.fromDateEnabled)
      Object.assign(nextSearchParams, {
        fromDate: String(filters.fromDate),
      });
    if (filters.toDateEnabled)
      Object.assign(nextSearchParams, {
        toDate: String(filters.toDate),
      });

    setSearchParams(nextSearchParams);

    closePopover();
  };

  const closePopoverHandler = () => {
    setSearchParams();

    closePopover();
  };

  return (
    <Grid
      p={2}
      width={290}
      border={2}
      borderColor="primary.main"
      borderRadius="5px"
      overflow="hidden"
      display="grid"
      gridTemplateColumns="1fr"
      gap={1}
    >
      {/* <BaseSelect
        value={filters.customerId}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setFilters({ ...filters, customerId: e.target.value });
        }}
        inputItems={customerInputItems}
        defaultVal="Select Customer"
        label="Customer"
      /> */}

      <Grid container gap={1} flexWrap="nowrap" alignItems="end">
        <BaseCheckbox
          onChange={(e) =>
            setFilters({
              ...filters,
              fromDateEnabled: !filters.fromDateEnabled,
            })
          }
          checked={filters.fromDateEnabled}
        />
        <Grid>
          <Typography fontSize={14}>From</Typography>
          <BaseDatePicker
            portal={true}
            value={new DateObject(filters.fromDate as string)}
            setValue={(value: any) =>
              setFilters({
                ...filters,
                fromDate: new DateObject(value as string).format("YYYY-MM-DD"),
              })
            }
            calendarPosition="bottom"
            format="DD MMM YYYY"
          />
        </Grid>
      </Grid>
      <Grid container gap={1} flexWrap="nowrap" alignItems="end">
        <BaseCheckbox
          onChange={(e) =>
            setFilters({
              ...filters,
              toDateEnabled: !filters.toDateEnabled,
            })
          }
          checked={filters.toDateEnabled}
        />

        <Grid>
          <Typography fontSize={14}>To</Typography>
          <BaseDatePicker
            portal={true}
            value={new DateObject(filters.toDate as string)}
            setValue={(value: any) =>
              setFilters({
                ...filters,
                toDate: new DateObject(value as string).format("YYYY-MM-DD"),
              })
            }
            calendarPosition="bottom"
            format="DD MMM YYYY"
          />
        </Grid>
      </Grid>
      <BaseButton onClick={filterHandler} sx={{ mt: 3 }}>
        apply
      </BaseButton>

      <BaseButton
        variant="text"
        sx={{ border: 2 }}
        onClick={closePopoverHandler}
      >
        discard
      </BaseButton>
    </Grid>
  );
};

export default BookingListFilterCard;
