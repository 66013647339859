import { ReactComponent as HomeIcon } from "./assets/icons/home-icon.svg";
import { ReactComponent as StaffsIcon } from "./assets/icons/staffs-icon.svg";
import { ReactComponent as ServicesIcon } from "./assets/icons/services-icon.svg";
import { ReactComponent as ShiftsIcon } from "./assets/icons/shifts-icon.svg";
import { ReactComponent as AvailabilityIcon } from "./assets/icons/availability-icon.svg";
import { ReactComponent as ClientsIcon } from "./assets/icons/clients-icon.svg";
import { ReactComponent as OrdersIcon } from "./assets/icons/booking-icon.svg";
import { ReactComponent as NewBookingIcon } from "./assets/icons/new-booking-icon.svg";

// pages
import Dashboard from "./pages/Dashboard";
import Staff from "./pages/staffs/Staff";
import Shift from "./pages/shifts/Shift";
import Availability from "./pages/availability/Availability";
import Service from "./pages/services/Service";
import Customer from "./pages/customer/Customer";
import Booking from "./pages/booking/Booking";
import NewBooking from "./pages/newBooking/NewBooking";

const routes = [
  {
    id: 1,
    name: "dashboard",
    url: "/",
    component: <Dashboard />,
    icon: <HomeIcon />,
  },
  {
    id: 2,
    name: "Booking",
    url: "/booking",
    icon: <NewBookingIcon />,
    component: <NewBooking />,
  },
  {
    id: 3,
    name: "Booking List",
    url: "/booking-list",
    icon: <OrdersIcon />,
    component: <Booking />,
  },
  {
    id: 4,
    name: "customer",
    url: "/customer",
    icon: <ClientsIcon />,
    component: <Customer />,
  },
  {
    id: 5,
    name: "services",
    url: "/services",
    icon: <ServicesIcon />,
    component: <Service />,
    subRoutes: [
      {
        id: 1,
        name: "services",
        url: "/services/overview",
        icon: <ServicesIcon />,
        component: <Service />,
      },
      {
        id: 2,
        name: "categories",
        url: "/services/categories",
        icon: <ServicesIcon />,
        component: <Service />,
      },
      {
        id: 3,
        name: "supplementary",
        url: "/services/supplementary",
        icon: <supplementaryIcon />,
        component: <Service />,
      },
    ],
  },
  {
    id: 6,
    name: "staffs",
    url: "/staffs",
    icon: <StaffsIcon />,
    component: <Staff />,
  },
  {
    id: 7,
    name: "shifts",
    url: "/shifts",
    icon: <ShiftsIcon />,
    component: <Shift />,
  },
  {
    id: 8,
    name: "availability",
    url: "/availability",
    icon: <AvailabilityIcon />,
    component: <Availability />,
  },
  // {
  //   id: 9,
  //   name: "shops",
  //   url: "/shops",
  //   icon: <AvailabilityIcon />,
  //   component: <Shops />,
  // },
];

export default routes;
