import { FC, useEffect, Suspense } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "./redux/store";
import { Navigate, Route, useNavigate } from "react-router-dom";

// components
import Layout from "./layout/Layout";
import RoutesContainer from "./components/containers/Routes.containers";
import { useAppSelector } from "./hooks/redux.hooks";
import LoginPage from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import { logoutUser } from "./redux/user/user.reducer";
import ErrorBoundary from "./components/error-boundary/ErrorBoundary";

import routes from "./routes";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import CreateShop from "./pages/shops/Create.shops";

export let navigateV: any;

function App(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  navigateV = navigate;

  const { user, lastSignIn } = useAppSelector((state) => state.user);

  const diffTime = lastSignIn ? Math.abs(new Date().getTime() - lastSignIn) : 0;

  const staySignedIn = Math.floor(diffTime / (1000 * 60 * 60)) < 8 ?? false;

  useEffect(() => {
    if (!staySignedIn) {
      dispatch(logoutUser());
    }
  }, []);

  useEffect(() => {
    const redirectTo = () => {
      if (window.innerWidth <= 768) {
        window.location.replace("https://bookingmobile.weeorder.co.uk");
      }
    };
    window.addEventListener("resize", redirectTo);
    return () => window.removeEventListener("resize", redirectTo);
  }, []);

  if (user)
    return (
      <ErrorBoundary>
        <Suspense fallback="...loading">
          <Layout routes={routes}>
            <AppRoutes routes={routes} />
          </Layout>
        </Suspense>
      </ErrorBoundary>
    );
  else
    return (
      <ErrorBoundary>
        <Suspense fallback="...loading">
          <RoutesContainer FallbackComponent={<Navigate to="/login" />}>
            <Route path="/login" element={<LoginPage />} />
          </RoutesContainer>
        </Suspense>
      </ErrorBoundary>
    );
}

export type ISubRoutes = {
  id: number;
  name: string;
  url: string;
  icon?: JSX.Element;
  component?: JSX.Element;
};

export type RouteType = {
  id: number;
  name: string;
  url: string;
  component?: JSX.Element;
  icon?: JSX.Element;
  subRoutes?: ISubRoutes[];
};

export type RoutesType = {
  routes: RouteType[];
};

const AppRoutes: FC<RoutesType> = ({ routes }) => {
  return (
    <RoutesContainer>
      <Route path="/" element={<Dashboard />} />
      {/* <Route path="/login" element={<Navigate to="/" />} /> */}
      <Route path="/shop/:shopId" element={<CreateShop />} />
      {routes?.map((route, idx) => {
        const { url, component } = route;
        return <Route key={idx} element={component} path={`${url}/*`} />;
      })}
    </RoutesContainer>
  );
};

export default App;
