import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import axiosConfig from "../../../../axiosConfig";
import { RotatingLines } from "react-loader-spinner";

// MUI
import { Grid, Typography, FormControlLabel, Switch } from "@mui/material";

//components
import BaseButton from "../../../../components/base/BaseButton";
import OneSectionLayout from "../../../../layout/OneSection.layouts";
import { useSupplementarySubFieldForm } from "../../../../helpers/formik";
import { useAppSelector } from "../../../../hooks/redux.hooks";
import BaseInput from "../../../../components/base/BaseInput";
import { postSupplementarySubField } from "../../../../redux/services/services.actions";
import { IEditActionsApiMethodType } from "../../../../redux/shifts/shift.actions";
import { AppDispatch } from "../../../../redux/store";
import { setSupplementarySubFieldError } from "../../../../redux/services/services.reducer";

const CreateSubField = () => {
  // hooks
  const navigate = useNavigate();
  const { supplementarySubFieldId, supplementaryId } = useParams();
  const dispatch = useDispatch<AppDispatch>();

  // selectors
  const { isPostingSupplementarySubField } = useAppSelector(
    (state) => state.service
  );
  const { user } = useAppSelector((state) => state.user);

  const [subFieldById, setSubFieldById] = useState();
  const [isFetchingSubField, setIsFetchingSubField] = useState(false);

  const fetchSupplementarySubFieldById = async () => {
    setIsFetchingSubField(true);
    try {
      const { data } = await axiosConfig.get(
        `SupplementaryField/Getbyidsupplementarysubfield/${supplementarySubFieldId}`
      );
      setIsFetchingSubField(false);
      setSubFieldById(data.data);
    } catch (error: any) {
      setIsFetchingSubField(false);
      dispatch(setSupplementarySubFieldError(error.response.status));
    }
  };

  const submit = () => {
    validateForm();

    const body = {
      ...values,
      shopId: user?.shopId,
      supplementaryFieldId: Number(supplementaryId),
    };

    const parameters = {
      type: supplementarySubFieldId
        ? IEditActionsApiMethodType.PATCH
        : IEditActionsApiMethodType.POST,
      body,
    };
    if (isValid) dispatch(postSupplementarySubField(parameters));
  };

  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    isValid,
    validateForm,
  } = useSupplementarySubFieldForm(subFieldById, submit);

  useEffect(() => {
    if (supplementarySubFieldId) fetchSupplementarySubFieldById();
  }, [supplementarySubFieldId]);

  return (
    <OneSectionLayout>
      <Grid
        container
        item
        xs={12}
        zIndex={1}
        position="relative"
        top={-16}
        justifyContent="space-between"
      >
        <Typography
          height={70}
          display="flex"
          alignItems="center"
          color="grey.100"
        >
          {supplementarySubFieldId ? "Edit" : "Add"} Supplementary Sub Field
        </Typography>
        <Grid
          item
          container
          xs={12}
          md={8}
          gap={2}
          alignItems="center"
          justifyContent="end"
          position="unset"
          bottom={-5}
          left={0}
          bgcolor="transparent"
          p={0}
          zIndex={10}
        >
          <BaseButton
            sx={{
              width: 150,
              bgcolor: "white",
              color: "primary.main",
              "&:hover": {
                bgcolor: "white",
              },
              border: 0,
              fontWeight: 500,
              height: 45,
            }}
            onClick={() => handleSubmit()}
            disabled={isPostingSupplementarySubField}
          >
            {isPostingSupplementarySubField ? (
              <Grid
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  zIndex: 10,
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="30"
                  visible
                />
              </Grid>
            ) : (
              "Save"
            )}
          </BaseButton>
          <BaseButton
            sx={{
              width: 150,
              border: 0,
              display: "flex",
              boxShadow: 0,
            }}
            onClick={() => navigate(-1)}
          >
            Discard
          </BaseButton>
        </Grid>
      </Grid>
      <Grid
        display="grid"
        gridTemplateColumns="1fr 1fr"
        gap={2}
        position="relative"
      >
        {isFetchingSubField && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            position="absolute"
            zIndex={1000}
            bgcolor="rgba(0,0,0, 0.05)"
            borderRadius={1}
            height={200}
          >
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
            />
          </Grid>
        )}
        <BaseInput
          value={values.supplementarySubFieldTitle}
          onChange={handleChange}
          variant="outlined"
          name="supplementarySubFieldTitle"
          placeholder="Title"
          label="Title"
        />
        <FormControlLabel
          label={<Typography fontWeight={400}>Default</Typography>}
          control={
            <Switch
              checked={values.isDefault ? true : false}
              name="Default"
              onChange={() =>
                setFieldValue("isDefault", values.isDefault ? 0 : 1)
              }
              size="small"
            />
          }
          labelPlacement="start"
          sx={{
            width: "100%",
            justifyContent: "space-between",
            mx: 0,
          }}
        />
      </Grid>
    </OneSectionLayout>
  );
};

export default CreateSubField;
