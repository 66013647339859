import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DateObject } from "react-multi-date-picker";
import { RotatingLines } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";

// MUI
import { FormControlLabel, Grid, Switch, Typography } from "@mui/material";

// icons
import { ReactComponent as AddIcon } from "../../assets/icons/add-icon.svg";
import { ReactComponent as SubtractIcon } from "../../assets/icons/subtract-icon.svg";

import BaseSelect from "../../components/base/BaseSelect";
import { useAvailabilityForm } from "../../helpers/formik";
import { IEditActionsApiMethodType } from "../../redux/shifts/shift.actions";
import { IAvailableData } from "./Create.availability";
import { useAppSelector } from "../../hooks/redux.hooks";
import BaseButton from "../../components/base/BaseButton";
import AvailabilityTimeCard from "../../components/cards/AvailableTime.cards";
import BaseDatePicker from "../../components/base/BaseDatePicker";
import { selectInputItemsFormatter } from "../../helpers/utils";
import { postAvailability } from "../../redux/availability/availability.actions";
import BaseTextInput from "../../components/base/BaseTextInput";

export type IHoursType = {
  id: number;
  [key: string]: any;
};

type IAvailableForm = {
  availableData: IAvailableData;
};

const AvailabilityForm: FC<IAvailableForm> = ({ availableData }) => {
  const { availableId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const hours: IHoursType[] = [
    {
      id: 0,
      "open-0": "",
      "close-0": "",
    },
  ];
  const [hoursData, setHoursData] = useState(hours);
  const [completed, setCompleted] = useState(false);

  const { isPostingAvailability } = useAppSelector(
    (state) => state.availability
  );
  const { servicesData, isFetchingService } = useAppSelector(
    (state) => state.service
  );
  const { staffsData, isFetchingStaff } = useAppSelector(
    (state) => state.staff
  );

  const selectedServiceAvailableHours = () => {
    const newHoursData = availableData?.lstServiceAvailableHour?.map(
      (hour, idx: number) => {
        const openTimeName = `open-${idx}`;
        const closeTimeName = `close-${idx}`;

        formik.setFieldValue(openTimeName, hour.startTime);
        formik.setFieldValue(closeTimeName, hour.endTime);
        return {
          id: idx,
          [openTimeName]: hour.startTime,
          [closeTimeName]: hour.endTime,
        };
      }
    );
    setCompleted(true);

    // mapping new fetched shift details
    if (availableData) setHoursData(newHoursData);
  };

  useEffect(() => {
    selectedServiceAvailableHours();
  }, []);

  const submitHandler = () => {
    const listAvailableTime = [];

    for (const name in values) {
      const splitName = name.split("-");
      if (splitName.length === 2 && splitName[0] === "open") {
        listAvailableTime.push({
          startTime: values[name],
          endTime: values[`close-${splitName[1]}`],
        });
      }
    }
    const body = {
      lstServiceAvailableHour: listAvailableTime,
      id: Number(availableId) || 0,
      serviceId: values.serviceId,
      optionId: 1,
      staffId: values.staffId,
      shopId: 1,
      availableDate: new DateObject(values.availableDate as string).format(
        "YYYY-MM-DD"
      ),
      availableType: values.availableType,
      serviceAvailableStatus: formik.values.serviceAvailableStatus,
      reason: values.reason,
    };

    const parameters = {
      body,
      type: availableId
        ? IEditActionsApiMethodType.PATCH
        : IEditActionsApiMethodType.POST,
    };

    formik.isValid && dispatch(postAvailability(parameters));
  };

  const formik = useAvailabilityForm(availableData, submitHandler);
  const { values, handleSubmit, setFieldValue, handleChange } = formik;

  const serviceInputItems = selectInputItemsFormatter(
    servicesData?.data,
    "serviceTitle",
    "id"
  );
  const staffInputItems = selectInputItemsFormatter(
    staffsData?.data,
    "staffName",
    "id"
  );

  const nextPrevButtonHandler = (day: number) => () => {
    const newDateObject = new DateObject(values.availableDate as string);
    newDateObject.add(day, "days");
    setFieldValue("availableDate", newDateObject);
  };

  return (
    <Grid container gap={2}>
      <Grid
        container
        item
        xs={12}
        zIndex={1}
        position="relative"
        top={-16}
        justifyContent="space-between"
      >
        <Typography
          height={70}
          display="flex"
          alignItems="center"
          color="grey.100"
        >
          {availableId ? "Edit " : "Add"} Availability
          {availableId && availableData?.id}
        </Typography>
        <Grid
          item
          container
          xs={8}
          gap={2}
          alignItems="center"
          justifyContent="end"
          position="unset"
          bottom={-5}
          left={0}
          bgcolor="transparent"
          p={0}
          zIndex={10}
        >
          <BaseButton
            sx={{
              width: 150,
              bgcolor: "white",
              color: "primary.main",
              "&:hover": {
                bgcolor: "white",
              },
              border: 0,
              fontWeight: 500,
              height: 45,
            }}
            onClick={() => handleSubmit()}
            disabled={isPostingAvailability}
          >
            {isPostingAvailability ? (
              <Grid
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  zIndex: 10,
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="30"
                  visible
                />
              </Grid>
            ) : (
              "Save"
            )}
          </BaseButton>

          <BaseButton
            sx={{
              width: 150,
              border: 0,
              display: "flex",
              boxShadow: 0,
            }}
            onClick={() => navigate("/availability")}
          >
            Discard
          </BaseButton>
        </Grid>
      </Grid>
      {/* availability section */}
      <Grid item xs={12}>
        <Typography color="primary" fontWeight={500}>
          Availability Info
        </Typography>
      </Grid>
      <Grid container flexWrap="nowrap" gap={2}>
        <Grid
          display="grid"
          gridTemplateColumns="auto 1fr auto"
          gap={1}
          borderRadius={1}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            px={1}
            borderRadius={2}
            sx={{
              svg: { width: 24, height: 24 },
              bgcolor: "inputBackground.main",
            }}
            onClick={nextPrevButtonHandler(-1)}
          >
            <SubtractIcon />
          </Grid>
          <BaseDatePicker
            value={new DateObject(values.availableDate as string)}
            setValue={(value: any) => setFieldValue("availableDate", value)}
            calendarPosition="bottom"
            format="YYYY MMM DD"
          />
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            px={1}
            borderRadius={2}
            sx={{
              svg: { width: 24, height: 24 },
              bgcolor: "inputBackground.main",
            }}
            onClick={nextPrevButtonHandler(1)}
          >
            <AddIcon />
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <BaseSelect
            onChange={(e: any) => setFieldValue("serviceId", e.target.value)}
            value={values.serviceId}
            inputItems={serviceInputItems}
            defaultVal="Select Service"
            sx={{ bgcolor: "grey.100" }}
            isFetching={isFetchingService}
            label="Service"
          />
        </Grid>
        <Grid item xs={3}>
          <BaseSelect
            onChange={(e: any) => setFieldValue("staffId", e.target.value)}
            value={values.staffId}
            inputItems={staffInputItems}
            isFetching={isFetchingStaff}
            defaultVal="Select Staff"
            sx={{ bgcolor: "grey.100" }}
            label="Staff"
          />
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            label={<Typography fontWeight={400}>Available</Typography>}
            control={
              <Switch
                checked={values.availableType ? true : false}
                size="small"
                name="availableType"
                onChange={() =>
                  setFieldValue("availableType", values.availableType ? 0 : 1)
                }
              />
            }
            labelPlacement="start"
            sx={{
              px: 1,
              width: "100%",
              justifyContent: "space-between",
              mx: 0,
              bgcolor: "inputBackground.main",
              height: "100%",
              borderRadius: 1,
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={3} position="relative">
        <BaseTextInput
          value={values.reason}
          onChange={handleChange}
          name="reason"
          label="Reason"
        />
      </Grid>
      <Grid item container flexWrap="nowrap" gap={2}>
        <Grid
          item
          xs={5}
          bgcolor="inputBackground.main"
          borderRadius="5px"
          container
        >
          {completed && (
            <AvailabilityTimeCard
              formik={formik}
              hoursData={hoursData}
              setHoursData={setHoursData}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AvailabilityForm;
