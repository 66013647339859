import { useState } from "react";
import { DateObject } from "react-multi-date-picker";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";

// MUI
import { Grid, Typography, IconButton, Divider } from "@mui/material";
import { useAppSelector } from "../../hooks/redux.hooks";

// icons
import { ReactComponent as TrashIcon } from "../../assets/icons/trash-icon.svg";
import { deleteBasketItem } from "../../redux/order/order.actions";
import { RotatingLines } from "react-loader-spinner";
import ConfirmModal from "../modals/Confirm.modals";
import { useSearchParams } from "react-router-dom";

const BasketCard = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { orderBasket, isFetchingBasket, isDeletingBasket } = useAppSelector(
    (state) => state.order
  );
  const [searchParams, _] = useSearchParams();
  const bookingSearchParam = searchParams.get("bookingId");

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);

  // handlers
  const handleOpenConfirmModal = () => setOpenConfirmModal(true);
  const handleCloseConfirmModal = () => setOpenConfirmModal(false);

  return (
    <Grid
      item
      xs={12}
      container
      rowGap={2}
      position="relative"
      height="100%"
      sx={{ overflowY: "auto", overflowX: "hidden" }}
      alignContent="start"
    >
      {isFetchingBasket && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          position="absolute"
          zIndex={1000}
          bgcolor="rgba(0,0,0, 0.05)"
          borderRadius={1}
          height="100%"
        >
          <RotatingLines
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
          />
        </Grid>
      )}
      {orderBasket?.itemList.map((item, i: number) => {
        const date = new DateObject(item.endDate).format("DD MMM YYYY");
        const startTime =
          item.startTime.split(":")[0] + ":" + item.startTime.split(":")[1];
        const endTime =
          item.endTime.split(":")[0] + ":" + item.endTime.split(":")[1];
        return (
          <Grid
            display="grid"
            gap={1}
            height={60}
            alignItems="center"
            bgcolor="white"
            borderRadius={2}
            overflow="hidden"
            key={i}
            gridTemplateColumns="80px 1fr 1.2fr 1fr 50px"
            width="100%"
          >
            <Grid item container alignItems="center">
              <img
                src={`https://booking.weeorder.co.uk/uploadfiles/Images/${item.serviceLogoImage}`}
                alt=""
                width="100%"
                height={60}
              />
            </Grid>
            <Grid item container flexDirection="column">
              <Typography
                fontSize={16}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {item.serviceTitle}
              </Typography>
              <Typography
                fontSize={16}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {/* {item.staffTitle} */}
              </Typography>
            </Grid>
            <Grid item container flexDirection="column">
              <Typography fontSize={16}>{date}</Typography>
              <Typography fontSize={16}>&#163; {item.amount}</Typography>
            </Grid>
            <Grid item container flexDirection="column">
              <Typography fontSize={16}>{startTime}</Typography>
              <Typography fontSize={16}>{endTime}</Typography>
            </Grid>

            <Grid item xs="auto" container flexWrap="nowrap">
              <IconButton
                onClick={() => {
                  handleOpenConfirmModal();
                  setDeleteId(item.bookingDetailId);
                }}
              >
                <TrashIcon />
              </IconButton>
            </Grid>

            {i < orderBasket.itemList.length - 1 && (
              <Divider sx={{ borderColor: "grey.300", width: "100%" }} />
            )}
          </Grid>
        );
      })}
      <ConfirmModal
        isDeleting={isDeletingBasket}
        open={openConfirmModal}
        handleClose={handleCloseConfirmModal}
        action={() =>
          dispatch(
            deleteBasketItem({
              itemId: deleteId,
              bookingId: Number(bookingSearchParam),
              closeModalHandler: handleCloseConfirmModal,
            })
          )
        }
      />
    </Grid>
  );
};

export default BasketCard;
