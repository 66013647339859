import { FC, useState } from "react";
import { DateObject } from "react-multi-date-picker";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../hooks/redux.hooks";
import { AppDispatch } from "../../redux/store";
import { useSearchParams } from "react-router-dom";

// MUI
import {
  Accordion,
  Grid,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";

// components
import PrePaymentAdditionalCard from "./PrePaymentAdditional.cards";

import { IBasketData, deleteBasketItem } from "../../redux/order/order.actions";

// icons
import { ReactComponent as TrashIcon } from "../../assets/icons/trash-icon.svg";
import { ReactComponent as DurationIcon } from "../../assets/icons/duration-icon.svg";
import ConfirmModal from "../modals/Confirm.modals";

type IPrePaymentCardProps = {
  item: IBasketData;
};

const PrePaymentCard: FC<IPrePaymentCardProps> = ({ item }) => {
  // hooks
  const dispatch = useDispatch<AppDispatch>();

  const { orderBasket, isDeletingBasket } = useAppSelector(
    (state) => state.order
  );

  const [searchParams, _] = useSearchParams();
  const bookingSearchParam = searchParams.get("bookingId");

  // states
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  // handlers
  const handleOpenConfirmModal = () => setOpenConfirmModal(true);
  const handleCloseConfirmModal = () => setOpenConfirmModal(false);

  const date = new DateObject(item.endDate).format("DD MMM YYYY");
  const startTime =
    item.startTime.split(":")[0] + ":" + item.startTime.split(":")[1];
  const endTime = item.endTime.split(":")[0] + ":" + item.endTime.split(":")[1];

  return (
    <Accordion
      sx={{
        borderRadius: "8px !important",
        overflow: "hidden",
        m: "0 !important",
        width: "100%",
        boxShadow: "0px 1px 4px 0px #00000840",
        bgcolor: "inputBackground.main",
        ".MuiCollapse-root": {
          visibility: "visible",
        },
        ".MuiAccordionSummary-root": {
          minHeight: "unset !important",
        },
        ".MuiAccordionSummary-content": {
          my: "0 !important",
        },
      }}
      expanded
      // onChange={selectedStaff}
    >
      <Grid
        item
        xs={12}
        display="grid"
        gridTemplateColumns="80px repeat(2, 1fr) 2fr repeat(2,1fr) 80px"
        overflow="hidden"
        gap={2}
        p={1.5}
        alignItems="center"
      >
        {/* <AccordionSummary sx={{ p: 0, minHeight: "unset" }}> */}
        <img
          src={`https://booking.weeorder.co.uk/uploadfiles/Images/${item.serviceLogoImage}`}
          width={60}
          height={60}
          alt=""
        />
        {/* </AccordionSummary> */}
        <Typography>{item.serviceTitle}</Typography>
        {/* <Typography>{item.staffTitle}</Typography> */}
        <Typography>{date}</Typography>
        <Typography
          gap={2}
          display="flex"
          alignItems="center"
          width="100%"
          sx={{
            svg: {
              width: 20,
              height: 20,
            },
          }}
        >
          <DurationIcon />
          {startTime} - {endTime}
        </Typography>
        <Typography>&#163; {item.amount.toFixed(2)}</Typography>
        <Typography>&#163; {item.prePayment.toFixed(2)}</Typography>

        <IconButton
          sx={{
            width: 30,
            height: 30,
            p: 0,
            svg: {
              width: 24,
              height: 24,
            },
          }}
          onClick={handleOpenConfirmModal}
        >
          <TrashIcon />
        </IconButton>
      </Grid>
      <Divider sx={{ mx: 0.75, borderColor: "grey.400" }} />
      {item.additionalServiceList.length > 0 && (
        <Grid
          display="grid"
          gridTemplateColumns="80px 150px 1fr 160px"
          gap={2}
          py={1.5}
        >
          <Grid />

          <Typography
            color="grey.700"
            fontSize={16}
            display="flex"
            alignItems="center"
            justifyContent="end"
          >
            Additional Services
          </Typography>

          {item.additionalServiceList.map((additional, index) => (
            <PrePaymentAdditionalCard
              key={additional.id}
              additional={additional}
            />
          ))}
        </Grid>
      )}
      {orderBasket?.productsList && orderBasket?.productsList.length > 0 && (
        <Grid
          display="grid"
          gridTemplateColumns="80px 150px 1fr 160px"
          gap={2}
          py={1.5}
        >
          <Grid />

          <Typography
            color="grey.700"
            fontSize={16}
            display="flex"
            alignItems="center"
            justifyContent="end"
          >
            Products
          </Typography>

          {orderBasket?.productsList.map((additional, index) => (
            <PrePaymentAdditionalCard key={index} additional={additional} />
          ))}
        </Grid>
      )}
      <ConfirmModal
        isDeleting={isDeletingBasket}
        open={openConfirmModal}
        handleClose={handleCloseConfirmModal}
        action={() =>
          dispatch(
            deleteBasketItem({
              itemId: item.bookingDetailId,
              bookingId: Number(bookingSearchParam),
              closeModalHandler: handleCloseConfirmModal,
            })
          )
        }
      />
    </Accordion>
  );
};

export default PrePaymentCard;
