import React, { FC, ReactNode } from "react";

import { Button, ButtonProps } from "@mui/material";

interface IBtn extends ButtonProps {
  children?: string | ReactNode;
  sx?: Object;
}

const BaseButton: FC<IBtn> = ({ children, sx, onClick, ...props }) => {
  return (
    <Button
      variant="contained"
      fullWidth
      sx={{
        textTransform: "capitalize",
        borderRadius: "5px",
        fontSize: 16,
        // p: 1,
        border: 2,
        borderColor: "primary.main",
        // height: 45,
        "&: disabled": {
          // bgcolor: "rgba(255,255,255, 0.4)",
          // color: "primary.main",
          opacity: 0.6,
        },
        "&:hover": {
          border: 2,
          borderColor: "primary.main",
        },
        ...sx,
      }}
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  );
};

export default BaseButton;
