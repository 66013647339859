import { ChangeEvent, useEffect, FC } from "react";
import { useNavigate } from "react-router-dom";
import { FormikProps } from "formik";
import { RotatingLines } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";

// MUI
import { Grid, Typography, FormControlLabel, Switch, Box } from "@mui/material";
import OneSectionLayout from "../../layout/OneSection.layouts";
import BaseButton from "../../components/base/BaseButton";
import BaseFileInput from "../../components/base/BaseFileInput";
import { IServiceFormTypes } from "../../helpers/formik";
import BaseInput from "../../components/base/BaseInput";
import BaseSelect from "../../components/base/BaseSelect";
import BaseTextInput from "../../components/base/BaseTextInput";
import BaseCountInput from "../../components/base/BaseCountInput";

// icons
import { ReactComponent as ImageInputIcon } from "../../assets/icons/image-input-icon.svg";
import { fetchAllServiceCategory } from "../../redux/services/services.actions";
import BaseDatePicker from "../../components/base/BaseDatePicker";
import { useAppSelector } from "../../hooks/redux.hooks";
import { selectInputItemsFormatter } from "../../helpers/utils";
import { CustomColorInput } from "./Create.services";

type EditServicePropsTypes = {
  formik: FormikProps<IServiceFormTypes>;
  attachment: any;
  setAttachment: any;
};

const EditService: FC<EditServicePropsTypes> = ({
  formik,
  attachment,
  setAttachment,
}) => {
  // hooks
  const navigate = useNavigate();
  const { serviceById, isFetchingService, isPostingService } = useAppSelector(
    (state) => state.service
  );

  const { values, handleChange, handleSubmit, setFieldValue } = formik;

  const serviceCategoryInputItems = selectInputItemsFormatter(
    serviceById?.commonData.serviceCategoryList,
    "serviceCategoryTitle",
    "id"
  );

  const serviceTypeInputItems = selectInputItemsFormatter(
    serviceById?.commonData.serviceTypeList,
    "lookupValueTitle",
    "id"
  );

  return (
    <OneSectionLayout>
      <Grid
        container
        item
        xs={12}
        zIndex={1}
        position="relative"
        top={-16}
        justifyContent="space-between"
      >
        <Typography
          height={70}
          display="flex"
          alignItems="center"
          color="grey.100"
        >
          Edit Service {serviceById?.serviceTitle}
        </Typography>
        <Grid
          item
          container
          xs={12}
          md={8}
          gap={2}
          alignItems="center"
          justifyContent="end"
          position="unset"
          bottom={-5}
          left={0}
          bgcolor="transparent"
          p={0}
          zIndex={10}
        >
          <BaseButton
            sx={{
              width: 150,
              bgcolor: "white",
              color: "primary.main",
              "&:hover": {
                bgcolor: "white",
              },
              border: 0,
              fontWeight: 500,
              height: 45,
            }}
            onClick={() => handleSubmit()}
            disabled={isPostingService}
          >
            {isPostingService ? (
              <Grid
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  zIndex: 10,
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="30"
                  visible
                />
              </Grid>
            ) : (
              "Save"
            )}
          </BaseButton>

          <BaseButton
            sx={{
              width: 150,
              border: 0,
              display: "flex",
              boxShadow: 0,
            }}
            onClick={() => navigate("/services/overview")}
          >
            Discard
          </BaseButton>
        </Grid>
      </Grid>
      <Grid container rowGap={2} position="relative">
        {isFetchingService && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            position="absolute"
            zIndex={1000}
            bgcolor="rgba(0,0,0, 0.05)"
            borderRadius={1}
            height="100%"
          >
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
            />
          </Grid>
        )}
        {/* Service section */}
        <Grid item xs={12}>
          <Typography color="primary" fontWeight={500}>
            Service Info
          </Typography>
        </Grid>
        <Grid container flexWrap="nowrap" gap={2} justifyContent="center">
          <Grid item xs={12} md={2}>
            <BaseFileInput
              attachment={attachment}
              setAttachment={setAttachment}
              icon={<ImageInputIcon />}
              hasImage={
                values?.serviceLogoImage
                  ? `https://booking.weeorder.co.uk/uploadfiles/Images/${values.serviceLogoImage}`
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} md={6} container spacing={2}>
            <Grid item xs={6}>
              <BaseInput
                variant="outlined"
                value={values.serviceTitle}
                name="serviceTitle"
                onChange={handleChange}
                placeholder="Title"
                label="Title"
              />
            </Grid>
            <Grid item xs={6}>
              <BaseSelect
                value={values.serviceCategoryId}
                name="serviceCategoryId"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setFieldValue("serviceCategoryId", e.target.value)
                }
                sx={{ bgcolor: "grey.100" }}
                defaultVal="Service Category"
                label="service category"
                inputItems={serviceCategoryInputItems}
              />
            </Grid>
            <Grid item xs={6}>
              <BaseSelect
                value={values.serviceType}
                name="serviceType"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setFieldValue("serviceType", e.target.value)
                }
                sx={{ bgcolor: "grey.100" }}
                defaultVal="Service Type"
                label="service type"
                inputItems={serviceTypeInputItems}
              />
            </Grid>
            <Grid item xs={6} sx={{ input: { height: "100% !important" } }}>
              <BaseDatePicker
                value={values.serviceExpireDate}
                setValue={(value: any) =>
                  setFieldValue("serviceExpireDate", value)
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={4} position="relative">
            <BaseTextInput
              value={values.serviceDescription}
              onChange={handleChange}
              name="serviceDescription"
              label="Description"
            />
          </Grid>
        </Grid>
        {/* Additional Details */}
        <Grid item xs={12}>
          <Typography color="primary" fontWeight={500}>
            Additional Details
          </Typography>
        </Grid>
        <Grid item xs={12} container gap={2} flexWrap="nowrap">
          <Grid item xs={4} container gap={2}>
            <Grid item xs={12}>
              <BaseCountInput
                value={values.countInTime}
                name="countInTime"
                handleChange={setFieldValue}
                label="Count In Time"
              />
            </Grid>
            <Grid item xs={12}>
              <BaseCountInput
                value={values.confirmDeadLine}
                name="confirmDeadLine"
                handleChange={setFieldValue}
                label="Confirm Deadline"
              />
            </Grid>
          </Grid>
          <Grid item xs={4} position="relative">
            <BaseTextInput
              value={values.agreementText}
              onChange={handleChange}
              name="agreementText"
              label="Agreement Note"
            />
          </Grid>
          <Grid item xs={4} container gap={2}>
            <Grid
              item
              xs={12}
              bgcolor="inputBackground.main"
              position="relative"
              borderRadius="5px"
              container
              alignItems="center"
              height={45}
            >
              <Typography color="grey.500" pl={1}>
                color
              </Typography>
              <CustomColorInput>
                <Typography>{values.serviceCalendarColor}</Typography>
                <input
                  type="color"
                  style={{
                    border: 0,
                    backgroundColor: "transparent",
                    width: 30,
                    height: 30,
                    borderRadius: 5,
                  }}
                  id="input-color"
                  value={values.serviceCalendarColor}
                  onChange={handleChange}
                  name="serviceCalendarColor"
                />
              </CustomColorInput>
            </Grid>
            <Grid item xs={12}>
              <BaseCountInput
                value={values.maxNumberOfGuest}
                name="maxNumberOfGuest"
                handleChange={setFieldValue}
                label="Max Num Of Guests"
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Notification */}
        <Grid item xs={12}>
          <Typography color="primary" fontWeight={500}>
            Notification
          </Typography>
        </Grid>
        <Grid
          display="grid"
          gridTemplateColumns="repeat(4,1fr)"
          gap={2}
          width="100%"
        >
          <FormControlLabel
            control={
              <Switch
                checked={values.serviceStatus ? true : false}
                size="small"
                name="serviceStatus"
                onChange={() =>
                  setFieldValue(
                    "serviceStatus",
                    values.serviceStatus ? false : true
                  )
                }
              />
            }
            label={<Typography fontWeight={400}>Status</Typography>}
            labelPlacement="start"
            sx={{
              width: "100%",
              justifyContent: "space-between",
              mx: 0,
              bgcolor: "inputBackground.main",
              p: 1,
              borderRadius: 2,
            }}
          />
          <FormControlLabel
            label={<Typography fontWeight={400}>Repeatable</Typography>}
            control={
              <Switch
                checked={values.isRepeatable ? true : false}
                name="isRepeatable"
                onChange={() =>
                  setFieldValue(
                    "isRepeatable",
                    values.isRepeatable ? false : true
                  )
                }
                size="small"
              />
            }
            labelPlacement="start"
            sx={{
              width: "100%",
              justifyContent: "space-between",
              mx: 0,
              bgcolor: "inputBackground.main",
              p: 1,
              borderRadius: 2,
            }}
          />
          <FormControlLabel
            label={<Typography fontWeight={400}>Staff Based</Typography>}
            control={
              <Switch
                checked={values.isStaffBased ? true : false}
                name="isStaffBased"
                onChange={() =>
                  setFieldValue(
                    "isStaffBased",
                    values.isStaffBased ? false : true
                  )
                }
                size="small"
              />
            }
            labelPlacement="start"
            sx={{
              width: "100%",
              justifyContent: "space-between",
              mx: 0,
              bgcolor: "inputBackground.main",
              p: 1,
              borderRadius: 2,
            }}
          />
          <FormControlLabel
            label={<Typography fontWeight={400}>Must Confirm</Typography>}
            control={
              <Switch
                checked={values.confirmType ? true : false}
                name="confirmType"
                onChange={() =>
                  setFieldValue(
                    "confirmType",
                    values.confirmType ? false : true
                  )
                }
                size="small"
              />
            }
            labelPlacement="start"
            sx={{
              width: "100%",
              justifyContent: "space-between",
              mx: 0,
              bgcolor: "inputBackground.main",
              p: 1,
              borderRadius: 2,
            }}
          />
        </Grid>
      </Grid>
    </OneSectionLayout>
  );
};

export default EditService;
