import { useState, useEffect, FC, useRef } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../hooks/redux.hooks";
import { DateObject } from "react-multi-date-picker";
import { useReactToPrint } from "react-to-print";
import { RotatingLines } from "react-loader-spinner";

// MUI
import { Typography, Box, IconButton } from "@mui/material";
import type { ColumnsType } from "antd/es/table";

import Table from "antd/es/table";

import ThreeSectionLayout from "../../layout/ThreeSection.layouts";
import Paging from "../../components/table/Paging";
import BookingListHeader from "../../components/headers/BookingList.headers";
import { IPagination } from "../staffs/List.staffs";
import InvoiceModal from "../../components/modals/Invoice.modals";

import { IBookingFetchAllParams } from "../../redux/booking/booking.actions";
import { IBookingData } from "../../redux/booking/booking.actions";
import { fetchAllBooking } from "../../redux/booking/booking.actions";
import { fetchBasket } from "../../redux/order/order.actions";

// icon
import { ReactComponent as PrintIcon } from "../../assets/icons/print-icon.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit-icon.svg";

type IListBookingProps = {
  pagination: IPagination;
  setPagination: any;
};

const ListBooking: FC<IListBookingProps> = ({ pagination, setPagination }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { bookingData, isFetchingBooking } = useAppSelector(
    (state) => state.booking
  );
  const { isFetchingBasket } = useAppSelector((state) => state.order);

  const [searchParams] = useSearchParams();
  const customerId = searchParams.get("customerId");
  const fromDate = searchParams.get("fromDate");
  const toDate = searchParams.get("toDate");

  // states
  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);

  // handlers
  const handleOpenInvoiceModal = () => setOpenInvoiceModal(true);
  const handleCloseInvoiceModal = () => setOpenInvoiceModal(false);

  function getTableParentHeight() {
    const tableParentElement =
      document.getElementById("tableParent")?.clientHeight;

    return tableParentElement;
  }

  const [tableParentHeight, setTableParentHeight] = useState(
    getTableParentHeight()
  );

  const parameters: IBookingFetchAllParams = {
    pageNumber: pagination.pageNumber,
    pageSize: pagination.pageSize,
  };

  const columns: ColumnsType<IBookingData> = [
    {
      title: "ID",
      dataIndex: "id",
      width: 60,
    },
    {
      title: "Customer",
      dataIndex: "customerTitle",
      ellipsis: true,
      width: 150,
    },
    {
      title: "Discount",
      width: 100,
      render(value, record, index) {
        return <Typography>&#163; {record.totalDiscount}</Typography>;
      },
    },
    {
      title: "Pre Payment",
      width: 120,
      render(value, record, index) {
        return <Typography>&#163; {record.totalPrePayment}</Typography>;
      },
    },
    {
      title: "Amount",
      width: 100,
      render(value, record, index) {
        return <Typography>&#163; {record.totalForPay}</Typography>;
      },
    },
    {
      title: "Date",
      width: 120,
      render(value, record, index) {
        return (
          <Typography fontSize={16}>
            {new DateObject(record.bookingRegDate).format("YYYY MMMM DD")}
          </Typography>
        );
      },
    },
    {
      title: "Status",
      width: 150,
      render(value, record, index) {
        return (
          <Typography
            color="success.main"
            fontSize={16}
            fontWeight={500}
            bgcolor="success.light"
            py={0.5}
            px={1}
            textAlign="center"
            borderRadius={2}
            width={150}
          >
            {record.bookingStatusTitle}
          </Typography>
        );
      },
    },
    {
      title: "Action",
      width: 150,
      render: (value, record) => (
        <Box display="flex" justifyContent="end">
          <IconButton
            sx={{
              width: 42,
              height: 42,
              svg: {
                width: 26,
                height: 26,
              },
            }}
            onClick={() => {
              navigate(
                `/booking?bookingId=${record.id}&customerId=${record.customerId}&customer=${record.customerTitle}&active=1`
              );
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            sx={{
              width: 42,
              height: 42,
              svg: {
                width: 26,
                height: 26,
              },
            }}
            onClick={() => {
              dispatch(
                fetchBasket({
                  bookingId: record.id,
                  printInvoice: handleOpenInvoiceModal,
                })
              );
            }}
          >
            {isFetchingBasket ? (
              <Box
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  zIndex: 10,
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  alignItems: "center",
                  p: 2,
                }}
              >
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="35"
                  visible
                />
              </Box>
            ) : (
              <PrintIcon />
            )}
          </IconButton>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    dispatch(
      fetchAllBooking({
        ...parameters,
        customerId,
        fromDate,
        toDate,
      })
    );

    setTableParentHeight(getTableParentHeight());
  }, [pagination, customerId, fromDate, toDate]);

  useEffect(() => {
    const windowResizeEventCallback = (e: any) => {
      const tableParentElement = getTableParentHeight();
      setTableParentHeight(tableParentElement);
    };
    window?.addEventListener("resize", windowResizeEventCallback);
    return () =>
      window.removeEventListener("resize", windowResizeEventCallback);
  }, []);

  return (
    <ThreeSectionLayout
      header={<BookingListHeader handleOpenModal={handlePrint} />}
      paging={
        <Paging
          data={bookingData}
          pagination={pagination}
          setPagination={setPagination}
        />
      }
    >
      {tableParentHeight && (
        <Table
          ref={componentRef}
          loading={isFetchingBooking}
          columns={columns}
          dataSource={bookingData?.data}
          pagination={false}
          scroll={
            (bookingData?.data.length as number) > 0
              ? { x: 800, y: (tableParentHeight as number) - 70 }
              : { x: undefined, y: undefined }
          }
          className="shiftTable"
          rowKey="id"
        />
      )}
      <InvoiceModal
        open={openInvoiceModal}
        handleClose={handleCloseInvoiceModal}
      />
    </ThreeSectionLayout>
  );
};

export default ListBooking;
