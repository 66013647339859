import { createSlice } from "@reduxjs/toolkit";
import {
  IAdditionalServiceData,
  IServiceCategoryType,
  IServiceCommonData,
  ISupplementaryType,
  IServiceType,
  deleteService,
  deleteServiceAdditional,
  deleteServiceCategory,
  deleteServiceSupplementary,
  deleteSupplementarySubField,
  fetchAllServiceCategory,
  fetchAllSupplementary,
  fetchAllServices,
  fetchServiceAdditionalById,
  fetchServiceById,
  fetchServiceCommonData,
  postService,
  postServiceAdditional,
  postServiceCategory,
  postServiceSupplementary,
  postSupplementarySubField,
  fetchSupplementaryById,
} from "./services.actions";
// import { IServiceSupplementarySubFieldType } from "./services.actions";
// import { fetchAllServiceSupplementarySubField } from "./services.actions";
import {
  IServiceFormTypes,
  ISupplementaryFormType,
} from "../../helpers/formik";

type IState = {
  // service
  servicesData: IServiceType | null;
  isFetchingService: boolean;
  serviceError: any;
  isPostingService: boolean;
  isDeletingService: boolean;
  serviceById: IServiceFormTypes | null;
  commonData: IServiceCommonData | null;

  // supplementary
  supplementaryData: ISupplementaryType | null;
  isFetchingSupplementary: boolean;
  isPostingSupplementary: boolean;
  supplementaryError: any;
  isDeletingSupplementary: boolean;
  supplementaryById: ISupplementaryFormType | null;

  // category
  serviceCategory: IServiceCategoryType | null;
  isFetchingServiceCategory: boolean;
  isPostingServiceCategory: boolean;
  isDeletingServiceCategory: boolean;
  serviceCategoryError: any;

  // supplementary sub field
  // serviceSupplementarySubField: IServiceSupplementarySubFieldType | null;
  // isFetchingServiceSupplementarySubField: boolean;
  isPostingSupplementarySubField: boolean;
  isDeletingSupplementarySubField: boolean;
  supplementarySubFieldError: any;

  // additional
  serviceAdditionalById: IAdditionalServiceData | null;
  isFetchingAdditionalService: boolean;
  isPostingServiceAdditional: boolean;
  isDeletingServiceAdditional: boolean;
  serviceAdditionalError: any;
};

const initialState: IState = {
  // service
  servicesData: null,
  isFetchingService: false,
  isPostingService: false,
  isDeletingService: false,
  serviceError: null,
  serviceById: null,
  commonData: null,

  // supplementary
  supplementaryData: null,
  isFetchingSupplementary: false,
  isPostingSupplementary: false,
  supplementaryError: null,
  isDeletingSupplementary: false,
  supplementaryById: null,

  // category
  serviceCategory: null,
  isFetchingServiceCategory: false,
  isPostingServiceCategory: false,
  isDeletingServiceCategory: false,
  serviceCategoryError: null,

  // supplementary sub field
  // serviceSupplementarySubField: null,
  // isFetchingServiceSupplementarySubField: false,
  isPostingSupplementarySubField: false,
  isDeletingSupplementarySubField: false,
  supplementarySubFieldError: null,

  // additional service
  serviceAdditionalById: null,
  isFetchingAdditionalService: false,
  isPostingServiceAdditional: false,
  isDeletingServiceAdditional: false,
  serviceAdditionalError: null,
};

const serviceSlice = createSlice({
  initialState,
  name: "service",
  reducers: {
    clearServiceAdditionalById: (state) => {
      state.serviceAdditionalById = null;
    },
    setServiceCategoryError: (state, action) => {
      state.serviceCategoryError = action.payload;
    },
    setSupplementarySubFieldError: (state, action) => {
      state.supplementarySubFieldError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch all services
      .addCase(fetchAllServices.pending, (state) => {
        state.isFetchingService = true;
        state.servicesData = null;
      })
      .addCase(fetchAllServices.fulfilled, (state, action) => {
        state.isFetchingService = false;
        state.servicesData = action.payload;
      })
      .addCase(fetchAllServices.rejected, (state, action) => {
        state.isFetchingService = false;
        state.serviceError = action.payload;
      })

      // post/patch service
      .addCase(postService.pending, (state) => {
        state.isPostingService = true;
      })
      .addCase(postService.fulfilled, (state) => {
        state.isPostingService = false;
      })
      .addCase(postService.rejected, (state, action) => {
        state.isPostingService = false;
        state.serviceError = action.payload;
      })

      // delete service
      .addCase(deleteService.pending, (state) => {
        state.isDeletingService = true;
      })
      .addCase(deleteService.fulfilled, (state) => {
        state.isDeletingService = false;
      })
      .addCase(deleteService.rejected, (state, action) => {
        state.isDeletingService = false;
        state.serviceError = action.payload;
      })

      // fetch service by id
      .addCase(fetchServiceById.pending, (state) => {
        state.isFetchingService = true;
      })
      .addCase(fetchServiceById.fulfilled, (state, action) => {
        state.isFetchingService = false;
        state.serviceById = action.payload;
      })
      .addCase(fetchServiceById.rejected, (state, action) => {
        state.isFetchingService = false;
        state.serviceError = action.payload;
      })

      // fetch service common Data
      .addCase(fetchServiceCommonData.pending, (state) => {
        state.isFetchingService = true;
      })
      .addCase(fetchServiceCommonData.fulfilled, (state, action) => {
        state.isFetchingService = false;
        state.commonData = action.payload;
      })
      .addCase(fetchServiceCommonData.rejected, (state, action) => {
        state.isFetchingService = false;
        state.serviceError = action.payload;
      })

      // fetch supplementary
      .addCase(fetchAllSupplementary.pending, (state) => {
        state.isFetchingSupplementary = true;
      })
      .addCase(fetchAllSupplementary.fulfilled, (state, action) => {
        state.isFetchingSupplementary = false;
        state.supplementaryData = action.payload;
      })
      .addCase(fetchAllSupplementary.rejected, (state, action) => {
        state.isFetchingSupplementary = false;
        state.supplementaryError = action.payload;
      })

      // fetch Supplementary by id
      .addCase(fetchSupplementaryById.pending, (state) => {
        state.isFetchingSupplementary = true;
      })
      .addCase(fetchSupplementaryById.fulfilled, (state, action) => {
        state.isFetchingSupplementary = false;
        state.supplementaryById = action.payload;
      })
      .addCase(fetchSupplementaryById.rejected, (state, action) => {
        state.isFetchingSupplementary = false;
        state.supplementaryError = action.payload;
      })

      // post serviceSupplementary
      .addCase(postServiceSupplementary.pending, (state) => {
        state.isPostingService = true;
      })
      .addCase(postServiceSupplementary.fulfilled, (state) => {
        state.isPostingService = false;
      })
      .addCase(postServiceSupplementary.rejected, (state, action) => {
        state.isPostingService = false;
        state.serviceError = action.payload;
      })

      // delete serviceSupplementary
      .addCase(deleteServiceSupplementary.pending, (state) => {
        state.isDeletingService = true;
      })
      .addCase(deleteServiceSupplementary.fulfilled, (state) => {
        state.isDeletingService = false;
      })
      .addCase(deleteServiceSupplementary.rejected, (state, action) => {
        state.isDeletingService = false;
        state.serviceError = action.payload;
      })

      // fetch service category
      .addCase(fetchAllServiceCategory.pending, (state) => {
        state.isFetchingServiceCategory = true;
      })
      .addCase(fetchAllServiceCategory.fulfilled, (state, action) => {
        state.isFetchingServiceCategory = false;
        state.serviceCategory = action.payload;
      })
      .addCase(fetchAllServiceCategory.rejected, (state, action) => {
        state.isFetchingServiceCategory = false;
        state.serviceCategoryError = action.payload;
      })

      // post service category
      .addCase(postServiceCategory.pending, (state) => {
        state.isPostingServiceCategory = true;
      })
      .addCase(postServiceCategory.fulfilled, (state) => {
        state.isPostingServiceCategory = false;
      })
      .addCase(postServiceCategory.rejected, (state, action) => {
        state.isPostingServiceCategory = false;
        state.serviceCategoryError = action.payload;
      })

      // delete service category
      .addCase(deleteServiceCategory.pending, (state) => {
        state.isDeletingServiceCategory = true;
      })
      .addCase(deleteServiceCategory.fulfilled, (state) => {
        state.isDeletingServiceCategory = false;
      })
      .addCase(deleteServiceCategory.rejected, (state, action) => {
        state.isDeletingServiceCategory = false;
        state.serviceCategoryError = action.payload;
      })

      // fetch service supplementary sub field
      // .addCase(fetchAllServiceSupplementarySubField.pending, (state) => {
      //   state.isFetchingServiceSupplementarySubField = true;
      // })
      // .addCase(
      //   fetchAllServiceSupplementarySubField.fulfilled,
      //   (state, action) => {
      //     state.isFetchingServiceSupplementarySubField = false;
      //     state.serviceSupplementarySubField = action.payload;
      //   }
      // )
      // .addCase(
      //   fetchAllServiceSupplementarySubField.rejected,
      //   (state, action) => {
      //     state.isFetchingServiceSupplementarySubField = false;
      //     state.supplementarySubFieldError = action.payload;
      //   }
      // )

      // post serviceSupplementarySubField
      .addCase(postSupplementarySubField.pending, (state) => {
        state.isPostingSupplementarySubField = true;
      })
      .addCase(postSupplementarySubField.fulfilled, (state) => {
        state.isPostingSupplementarySubField = false;
      })
      .addCase(postSupplementarySubField.rejected, (state, action) => {
        state.isPostingSupplementarySubField = false;
        state.supplementarySubFieldError = action.payload;
      })

      // delete serviceSupplementarySubField
      .addCase(deleteSupplementarySubField.pending, (state) => {
        state.isDeletingSupplementarySubField = true;
      })
      .addCase(deleteSupplementarySubField.fulfilled, (state) => {
        state.isDeletingSupplementarySubField = false;
      })
      .addCase(deleteSupplementarySubField.rejected, (state, action) => {
        state.isDeletingSupplementarySubField = false;
        state.supplementarySubFieldError = action.payload;
      })

      // fetch additional services
      .addCase(fetchServiceAdditionalById.pending, (state) => {
        state.isFetchingAdditionalService = true;
      })
      .addCase(fetchServiceAdditionalById.fulfilled, (state, action) => {
        state.isFetchingAdditionalService = false;
        state.serviceAdditionalById = action.payload;
      })
      .addCase(fetchServiceAdditionalById.rejected, (state, action) => {
        state.isFetchingAdditionalService = false;
        state.serviceAdditionalError = action.payload;
      })

      // post serviceAdditional
      .addCase(postServiceAdditional.pending, (state) => {
        state.isPostingServiceAdditional = true;
      })
      .addCase(postServiceAdditional.fulfilled, (state) => {
        state.isPostingServiceAdditional = false;
      })
      .addCase(postServiceAdditional.rejected, (state, action) => {
        state.isPostingServiceAdditional = false;
        state.serviceAdditionalError = action.payload;
      })

      // delete serviceAdditional
      .addCase(deleteServiceAdditional.pending, (state) => {
        state.isDeletingServiceAdditional = true;
      })
      .addCase(deleteServiceAdditional.fulfilled, (state) => {
        state.isDeletingServiceAdditional = false;
      })
      .addCase(deleteServiceAdditional.rejected, (state, action) => {
        state.isDeletingServiceAdditional = false;
        state.serviceAdditionalError = action.payload;
      });
  },
});

export const {
  clearServiceAdditionalById,
  setServiceCategoryError,
  setSupplementarySubFieldError,
} = serviceSlice.actions;
export default serviceSlice.reducer;
