import { createSlice } from "@reduxjs/toolkit";
import { IUserData, loginUser } from "./user.actions";

type IState = {
  user: IUserData | null;
  isLoggingIn: boolean;
  hasSubRoute: boolean;
  loginError: any;
  lastSignIn: any;
};

const initialState: IState = {
  user: null,
  isLoggingIn: false,
  hasSubRoute: false,
  loginError: null,
  lastSignIn: null,
};

const userSlice = createSlice({
  initialState,
  name: "user",
  reducers: {
    logoutUser: (state) => {
      state.user = null;
    },
    toggleSidebar: (state, action) => {
      state.hasSubRoute = action.payload;
    },
    clearLoginError: (state) => {
      state.loginError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoggingIn = true;
        state.loginError = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isLoggingIn = false;
        state.user = action.payload;
        state.lastSignIn = new Date().getTime();
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoggingIn = false;
        state.loginError = action.payload;
        state.lastSignIn = null;
      });
  },
});

export default userSlice.reducer;
export const { logoutUser, toggleSidebar, clearLoginError } = userSlice.actions;
