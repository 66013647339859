import { FC } from "react";

import {
  FormControl,
  InputLabel,
  OutlinedTextFieldProps,
  TextField,
} from "@mui/material";
import type { Value } from "react-multi-date-picker";

// icons

interface IInput extends OutlinedTextFieldProps {
  width?: number | string;
  label?: string | JSX.Element;
  inputWidth?: string | number;
  sx?: Object;
  p?: string | number;
  bgcolor?: string;
  err?: string;
}

const BaseInput: FC<IInput> = ({
  width,
  label,
  inputWidth = "100%",
  sx,
  p = 1,
  bgcolor = "inputBackground.main",
  err,
  ...props
}) => {
  return (
    <FormControl
      variant="standard"
      sx={{
        height: "100%",
        width: "100%",
        ".MuiInputBase-root": {
          height: 42,
        },
        ".MuiInputBase-input": {
          p,
          px: 2,
          height: "100%",
          color: "grey.700",
          zIndex: 1,
          width: inputWidth,
        },
        "input::placeholder": {
          color: err ? "error.main" : "initial",
        },
        "&:hover fieldSet": {
          borderColor: err ? "#EB5757 !important" : "transparent !important",
        },
        fieldSet: {
          border: err ? 2 : 0,
          borderColor: err ? "error.main" : "transparent !important",
          borderRadius: "5px",
          bgcolor,
          // zIndex: 0,
        },
        ...sx,
      }}
    >
      <TextField
        sx={{ height: "100%" }}
        label={label}
        size="small"
        {...props}
      />
    </FormControl>
  );
};

export default BaseInput;
