import ReactDOM from "react-dom/client";
import App from "./App";
import { injectStore } from "./axiosConfig";
import { ToastContainer, Zoom } from "react-toastify";

// redux
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";

// react-router
import { BrowserRouter } from "react-router-dom";

// MUI
import { ThemeProvider, CssBaseline } from "@mui/material";
import theme from "./theme/theme";

import "./font.css";

import "react-toastify/dist/ReactToastify.css";

injectStore(store);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            transition={Zoom}
          />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </BrowserRouter>
);
