import { useState } from "react";
import { useAppSelector } from "../../hooks/redux.hooks";
import { Route, Routes } from "react-router-dom";
import ListShift from "./List.shift";
import CreateShift from "./Create.shift";
import PageNotFound from "../errors/PageNotFound.error";

const Shift = () => {
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const { shiftError } = useAppSelector((state) => state.shift);

  return (
    <Routes>
      <Route
        element={
          <ListShift pagination={pagination} setPagination={setPagination} />
        }
        path="/"
      />
      <Route element={<CreateShift />} path="/create-shift" />
      <Route
        element={shiftError === 404 ? <PageNotFound /> : <CreateShift />}
        path="/edit-shift/:shiftId"
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Shift;
