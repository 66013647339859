import { FC, useRef } from "react";
import { useReactToPrint } from "react-to-print";

// MUI
import { Modal, Box } from "@mui/material";
import InvoicePrint from "../prints/Invoice.prints";

type IInvoiceModalProps = {
  open: boolean;
  handleClose: any;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  overflowY: "auto",
  maxHeight: "80%",
};

const InvoiceModal: FC<IInvoiceModalProps> = ({ open, handleClose }) => {
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{ zIndex: -1 }}
      disableEnforceFocus
    >
      <Box sx={style}>
        <InvoicePrint
          ref={componentRef}
          onClick={handlePrint}
          closeModal={handleClose}
        />
      </Box>
    </Modal>
  );
};

export default InvoiceModal;
