import { ChangeEvent, FC } from "react";
import { FormikProps } from "formik";
import { RotatingLines } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/redux.hooks";

// MUI
import { Grid, Typography } from "@mui/material";

import OneSectionLayout from "../../layout/OneSection.layouts";
import BaseButton from "../../components/base/BaseButton";
import BaseInput from "../../components/base/BaseInput";
import { NumericFormatCustom } from "../../components/base/BaseMaskInput";
import BaseSelect from "../../components/base/BaseSelect";
import { selectInputItemsFormatter } from "../../helpers/utils";

import { IStaffFormTypes } from "../../helpers/formik";

type PaymentStaffPropsTypes = {
  formik: FormikProps<IStaffFormTypes>;
};

const PaymentStaff: FC<PaymentStaffPropsTypes> = ({ formik }) => {
  // hooks
  const navigate = useNavigate();

  const { staffById, isPostingStaff, isFetchingStaff } = useAppSelector(
    (state) => state.staff
  );

  const { values, handleSubmit, setFieldValue } = formik;

  const payTypeInputItems = selectInputItemsFormatter(
    staffById?.payTypeList,
    "lookupValueTitle",
    "id"
  );

  return (
    <OneSectionLayout>
      <Grid
        container
        item
        xs={12}
        zIndex={1}
        position="relative"
        top={-16}
        justifyContent="space-between"
      >
        <Typography
          height={70}
          display="flex"
          alignItems="center"
          color="grey.100"
        >
          Payment
        </Typography>
        <Grid
          item
          container
          xs={12}
          md={8}
          gap={2}
          alignItems="center"
          justifyContent="end"
          position="unset"
          bottom={-5}
          left={0}
          bgcolor="transparent"
          p={0}
          zIndex={10}
        >
          <BaseButton
            sx={{
              width: 150,
              bgcolor: "white",
              color: "primary.main",
              "&:hover": {
                bgcolor: "white",
              },
              border: 0,
              fontWeight: 500,
              height: 45,
            }}
            onClick={() => handleSubmit()}
          >
            {isPostingStaff ? (
              <Grid
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  zIndex: 10,
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="30"
                  visible
                />
              </Grid>
            ) : (
              "Save"
            )}
          </BaseButton>

          <BaseButton
            sx={{
              width: 150,
              border: 0,
              display: "flex",
              boxShadow: 0,
            }}
            onClick={() => navigate("/staffs")}
          >
            Discard
          </BaseButton>
        </Grid>
      </Grid>

      <Grid
        display="grid"
        gridTemplateColumns="1fr 1fr"
        gap={2}
        position="relative"
      >
        {isFetchingStaff && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            position="absolute"
            zIndex={1000}
            bgcolor="rgba(0,0,0, 0.05)"
            borderRadius={1}
            height="100%"
          >
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
            />
          </Grid>
        )}
        <BaseSelect
          value={values.payTypeId}
          onChange={(e: any) => setFieldValue("payTypeId", e.target.value)}
          name="payTypeId"
          sx={{ bgcolor: "grey.100" }}
          defaultVal="Pay Type"
          inputItems={payTypeInputItems}
          label="Pay Type"
          isFetching={isFetchingStaff}
        />
        <BaseInput
          value={values.weeklyHours}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFieldValue("weeklyHours", Number(e.target.value));
          }}
          name="weeklyHours"
          placeholder="Weekly Hours"
          variant="outlined"
          label="Weekly Hours"
        />
        <BaseInput
          value={values.annualSalary}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFieldValue("annualSalary", Number(e.target.value));
          }}
          name="annualSalary"
          sx={{ border: 0 }}
          InputProps={{
            inputComponent: NumericFormatCustom as any,
          }}
          variant="outlined"
          label="Annual Salary"
        />
        <BaseInput
          value={values.hourlyRate}
          name="hourlyRate"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFieldValue("hourlyRate", Number(e.target.value));
          }}
          placeholder="Hourly rate"
          variant="outlined"
          InputProps={{
            inputComponent: NumericFormatCustom as any,
          }}
          label="Hour's Rate"
        />
        <BaseInput
          value={values.extraTimeHourlyRate}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFieldValue("extraTimeHourlyRate", Number(e.target.value));
          }}
          name="extraTimeHourlyRate"
          placeholder="Overtime Rate"
          variant="outlined"
          InputProps={{
            inputComponent: NumericFormatCustom as any,
          }}
          label="Overtime Rate"
        />
      </Grid>
    </OneSectionLayout>
  );
};

export default PaymentStaff;
