import {
  IAdditionalServiceData,
  IServiceCommonData,
  ISupplementaryData,
} from "./../redux/services/services.actions";
import { useFormik, FormikErrors } from "formik";
import { DateObject, Value } from "react-multi-date-picker";
import * as yup from "yup";
import { IStaffServicesDataType } from "../redux/staffs/staff.actions";
import { IShopData } from "../redux/shops/shops.actions";

// auth
type IAuthTypes = {
  mobileNumber: string;
  deviceId: number;
  userName: string;
  userPass: string;
  otpCode: string;
  languageId: number;
  domain: string;
  version: string;
  licence: string;
};

export const useAuthForm = (submitHandler?: any) => {
  const initialValues: IAuthTypes = {
    mobileNumber: "",
    deviceId: 1,
    userName: "",
    userPass: "",
    otpCode: "",
    languageId: 1,
    domain: "",
    version: "",
    licence: "",
  };
  let schema = yup.object().shape({
    userName: yup.string().required("Required"),
    userPass: yup.string().required("Required"),
  });

  return useFormik({
    initialValues,
    validationSchema: schema,
    validateOnChange: false,
    onSubmit: submitHandler,
  });
};

// staff
export type IStaffFormTypes = {
  id: number;
  staffName: string;
  staffFamily: string;
  displayName: string;
  staffEmail: string;
  mobile: string;
  staffCalendarColor: string;
  isOnlineBookable: boolean;
  payType: string;
  annualSalary: number;
  weeklyHours: number;
  hourlyRate: number;
  extraTimeHourlyRate: number;
  isActive: boolean;
  address: string;
  bio: string;
  memberOfId: number;
  notificationEmail: boolean;
  notiFicationSms: boolean;
  payTypeId: number;
  image: any;
  staffImage: string;
  staffHourList: IStaffHoursFormTypes[];
  staffServiceList: IStaffServicesDataType[];
  payTypeList: { id: number; lookupValueTitle: string }[];
  serviceList: {
    id: number;
    serviceTitle: string;
    amount: number;
    duration: number;
  }[];
};

export const useStaffForm = (
  editStaff?: IStaffFormTypes | null,
  submitHandler?: any
) => {
  const initialValues = editStaff || {
    id: 0,
    staffName: "",
    staffFamily: "",
    displayName: "",
    staffEmail: "",
    mobile: "",
    staffCalendarColor: "#ffffff",
    isOnlineBookable: true,
    payType: "",
    annualSalary: 0,
    weeklyHours: 0,
    hourlyRate: 0,
    extraTimeHourlyRate: 0,
    isActive: true,
    address: "",
    bio: "",
    memberOfId: 0,
    notificationEmail: true,
    notiFicationSms: true,
    payTypeId: 0,
    image: "",
    staffImage: "",
    staffHourList: [],
    staffServiceList: [],
    payTypeList: [],
    serviceList: [],
  };
  let schema = yup.object().shape({
    staffName: yup.string().required("Required"),
    staffEmail: yup
      .string()
      .email()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "email must be following format: example@gmail.com"
      ),
  });

  return useFormik({
    initialValues,
    validateOnChange: false,
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });
};

// staff hours
export type IStaffHoursFormTypes = {
  id: 0;
  staffId: 0;
  [key: string]: any;
};

export const useStaffHoursForm = (submitHandler?: any) => {
  const initialValues: IStaffHoursFormTypes = {
    id: 0,
    staffId: 0,
  };

  const validate = (values: IStaffHoursFormTypes) => {
    let errors: FormikErrors<IStaffHoursFormTypes> = {};

    return errors;
  };

  return useFormik<any>({
    initialValues,
    validate,
    onSubmit: submitHandler,
  });
};

// staff services
type IStaffServicesFormTypes = {
  serviceId: number;
  staffId: number;
  shopId: number;
  staffServiceAmount: number;
  staffServiceDuration: number;
};

export const useStaffServicesForm = (
  editStaffService?: IStaffServicesFormTypes | any,
  submitHandler?: any
) => {
  const initialValues: IStaffServicesFormTypes = editStaffService || {
    serviceId: 0,
    staffId: 0,
    shopId: 0,
    staffServiceAmount: 0,
    staffServiceDuration: 0,
  };

  const validate = (values: IStaffServicesFormTypes) => {
    let errors: FormikErrors<IStaffServicesFormTypes> = {};

    return errors;
  };

  return useFormik({
    initialValues,
    validate,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });
};

// shift
type IShiftFormTypes = {
  id: number;
  shopId: number;
  shiftTitle: string;
  shiftStatus: number;
  shiftExpireDate: Value;
};

export const useShiftForm = (
  editShift?: IShiftFormTypes | any,
  submitHandler?: any
) => {
  const initialValues: IShiftFormTypes = editShift
    ? {
        ...editShift,
        shiftExpireDate:
          editShift?.shiftExpireDate &&
          new DateObject(editShift.shiftExpireDate).format(),
      }
    : {
        id: 0,
        shopId: 0,
        shiftTitle: "",
        shiftStatus: 0,
        shiftExpireDate: "",
      };

  const validate = (value: IShiftFormTypes) => {
    let errors: FormikErrors<IShiftFormTypes> = {};

    return errors;
  };

  return useFormik({
    initialValues,
    validate,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });
};

// service
export type IServiceFormTypes = {
  id: number;
  serviceTitle: string;
  serviceCategoryId: number;
  bookableBeforDay: number;
  bookableBeforHours: number;
  serviceStatus: boolean;
  amount: number;
  duration: number;
  serviceType: number;
  serviceDescription: string;
  paymentType: number;
  confirmType: boolean;
  maxNumberOfGuest: number;
  countInTime: number;
  prePayment: number;
  agreementText: string;
  serviceExpireDate: string;
  serviceTimingType: number;
  isStaffBased: boolean;
  isRepeatable: boolean;
  timeBetweenTwoServ: number;
  confirmDeadLine: number;
  serviceGuidId: number;
  cancellationFee: number;
  serviceCalendarColor: string;
  serviceLogoImage: string;
  additionalServiceList: IAdditionalServiceData[];
  supplementaryFieldList: ISupplementaryData[];
  commonData: IServiceCommonData;
};

export const useServiceForm = (
  editService?: IServiceFormTypes | any,
  submitHandler?: any
) => {
  const initialValues = editService
    ? {
        ...editService,
        serviceExpireDate:
          editService?.serviceExpireDate &&
          new DateObject(editService.serviceExpireDate).format(),
      }
    : {
        id: 0,
        serviceTitle: "",
        serviceCategoryId: 0,
        bookableBeforDay: 0,
        bookableBeforHours: 0,
        serviceStatus: true,
        amount: 0,
        duration: 0,
        serviceType: 0,
        serviceDescription: "",
        paymentType: 0,
        confirmType: true,
        maxNumberOfGuest: 0,
        countInTime: 1,
        prePayment: 0,
        agreementText: "",
        serviceExpireDate: "",
        serviceTimingType: 0,
        isStaffBased: true,
        isRepeatable: true,
        timeBetweenTwoServ: 0,
        confirmDeadLine: 0,
        serviceGuidId: 0,
        cancellationFee: 0,
        serviceCalendarColor: "#ff0000",
        serviceLogoImage: "",
      };

  const validate = (values: IServiceFormTypes) => {
    let errors: FormikErrors<IServiceFormTypes> = {};

    return errors;
  };

  return useFormik({
    initialValues,
    enableReinitialize: true,
    validate,
    onSubmit: submitHandler,
  });
};

export type IServiceSupplementaryForm = {
  serviceId: 0;
  supplementaryFieldId: 0;
};

export const useServiceSupplementaryForm = (submitHandler?: any) => {
  const initialValues = {
    serviceId: 0,
    supplementaryFieldId: 0,
  };

  return useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });
};

// shift detail
export type IShiftHourFormType = {
  lstShiftHours: Object[];
  shiftId: number;
  shopId: number;
};

export const useShiftHoursForm = (
  editShiftHour?: IShiftFormTypes,
  submitHandler?: any
) => {
  const initialValues = editShiftHour || {
    lstShiftHours: [
      {
        dayOfWeek: 0,
        startTime: {},
        endTime: {},
      },
    ],
    shiftId: 0,
    shopId: 0,
  };

  return useFormik<any>({
    initialValues,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });
};

// supplementary
export type ISupplementaryFormType = {
  shopId: number;
  supplementaryFieldTitle: string;
  supplementaryFieldType: number;
  isRequired: boolean;
  orderNo: number;
  maxValue: number;
  minValue: number;
  supplementaryValueType: number;
  supplementaryStatus: number;
  serviceId: number;
  supplementarySubFieldList: ISubFieldFormType[];
};

export const useSupplementaryForm = (
  editSupplementary?: ISupplementaryFormType | any,
  submitHandler?: any
) => {
  const initialValues: ISupplementaryFormType = editSupplementary || {
    shopId: 1,
    supplementaryFieldTitle: "",
    supplementaryFieldType: 0,
    isRequired: true,
    orderNo: 0,
    maxValue: 0,
    minValue: 0,
    supplementaryValueType: 0,
    supplementaryStatus: 0,
    supplementarySubFieldList: [],
  };

  const validate = (values: ISupplementaryFormType) => {
    let errors: FormikErrors<ISupplementaryFormType> = {};

    return errors;
  };

  return useFormik({
    initialValues,
    validate,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });
};

// availability
export type IAvailabilityFormType = {
  lstServiceAvailableHour: Object[];
  id: number;
  serviceId: number;
  optionId: number;
  staffId: number;
  shopId: number;
  availableDate: Value;
  availableType: number;
  serviceAvailableStatus: boolean;
  reason: string;
  [key: string]: any;
};

export const useAvailabilityForm = (
  editAvailability?: IAvailabilityFormType | any,
  submitHandler?: any
) => {
  const initialValues: IAvailabilityFormType = editAvailability
    ? {
        ...editAvailability,
        availableDate:
          editAvailability?.availableDate &&
          new DateObject(editAvailability.availableDate).format(),
      }
    : {
        lstServiceAvailableHour: [],
        id: 0,
        serviceId: 0,
        optionId: 0,
        staffId: 0,
        shopId: 0,
        availableDate: new DateObject(),
        availableType: 0,
        serviceAvailableStatus: true,
        reason: "",
        "open-0": "",
        "close-0": "",
      };

  // console.log(editAvailability);

  const validate = (values: IAvailabilityFormType) => {
    let errors: FormikErrors<IAvailabilityFormType> = {};

    return errors;
  };

  return useFormik({
    initialValues,
    validate,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });
};

// availability
export type ISubFieldFormType = {
  id: number;
  supplementaryFieldId: number;
  shopId: number;
  supplementarySubFieldTitle: string;
  isDefault: true;
  orderNo: number;
};

export const useSupplementarySubFieldForm = (
  editSubField?: ISubFieldFormType | any,
  submitHandler?: any
) => {
  const initialValues: ISubFieldFormType = editSubField || {
    id: 0,
    supplementaryFieldId: 0,
    shopId: 0,
    supplementarySubFieldTitle: "",
    isDefault: true,
    orderNo: 0,
  };

  const validate = (values: ISubFieldFormType) => {
    let errors: FormikErrors<ISubFieldFormType> = {};

    if (!values.supplementarySubFieldTitle) {
      errors.supplementarySubFieldTitle = "required";
    }

    return errors;
  };

  return useFormik({
    initialValues,
    validate,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });
};

export type ICustomerForm = {
  firstName: string;
  lastName: string;
  userName: string;
  password: string;
  customerStatus: number;
  shopId: number;
  mobile: string;
  phone: string;
  address: string;
  postCode: string;
  email: string;
};

export const useCustomerForm = (
  editCustomer?: ICustomerForm | any,
  submitHandler?: any
) => {
  const initialValues = editCustomer || {
    firstName: "",
    lastName: "",
    userName: "",
    password: "",
    customerStatus: 0,
    shopId: 1,
    mobile: "",
    phone: "",
    address: "",
    postCode: "",
    email: "",
  };

  const validate = (values: ICustomerForm) => {
    let errors: FormikErrors<ICustomerForm> = {};

    if (!values.firstName) {
      errors.firstName = "required";
    }

    return errors;
  };

  return useFormik({
    initialValues,
    validate,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });
};

export type IBookingForm = {
  customerId: number;
  bookingStatus: number;
  bookingRegDate: string;
  shopId: number;
  totalDiscount: number;
  totalForPay: number;
  headerDiscount: number;
  totalPrePayment: number;
  discountId: number;
  isDeleted: number;
};

export const useBookingForm = (
  editBooking?: IBookingForm | null,
  submitHandler?: any
) => {
  const initialValues = editBooking || {
    customerId: 0,
    bookingStatus: 0,
    bookingRegDate: "",
    shopId: 0,
    totalDiscount: 0,
    totalForPay: 0,
    headerDiscount: 0,
    totalPrePayment: 0,
    discountId: 0,
    isDeleted: 0,
  };

  const validate = (values: IBookingForm) => {
    let errors: FormikErrors<IBookingForm> = {};

    return errors;
  };

  return useFormik({
    initialValues,
    validate,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });
};

export type IBookingDetailForm = {
  bookingId: number;
  staffId: number;
  serviceId: number;
  bookingDetailStartDate: string;
  bookingDetailEndDate: string;
  bookingDetailStartTime: string;
  bookingDetailEndTime: string;
  numberOfGuest: number;
  bookingDetailStatus: number;
  agreementConfirmed: boolean;
  totalPayFor: number;
  discountId: number;
  discountAmount: number;
  prePayment: number;
};

export const useBookingDetailForm = (
  editBookingDetail?: IBookingDetailForm | null,
  submitHandler?: any
) => {
  const initialValues = editBookingDetail || {
    bookingId: 0,
    staffId: 0,
    serviceId: 0,
    bookingDetailStartDate: "2023-06-02T10:33:23.163Z",
    bookingDetailEndDate: "2023-06-02T10:33:23.163Z",
    bookingDetailStartTime: "",
    bookingDetailEndTime: "",
    numberOfGuest: 0,
    bookingDetailStatus: 0,
    agreementConfirmed: true,
    totalPayFor: 0,
    discountId: 0,
    discountAmount: 0,
    prePayment: 0,
  };

  const validate = (values: IBookingDetailForm) => {
    let errors: FormikErrors<IBookingDetailForm> = {};

    return errors;
  };

  return useFormik({
    initialValues,
    validate,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });
};

export type IOrderSupplementaryForm = {
  [key: string]: string;
};

export const useOrderSupplementaryForm = (submitHandler?: any) => {
  const initialValues: IOrderSupplementaryForm = {};

  return useFormik({
    initialValues,
    onSubmit: submitHandler,
  });
};

export type IOrderAdditionalForm = {
  [key: string]: string | number;
};

export const useOrderAdditionalForm = (submitHandler?: any) => {
  const initialValues: IOrderAdditionalForm = {};

  return useFormik({
    initialValues,
    onSubmit: submitHandler,
  });
};

export type IServiceCategoryForm = {
  expireDate: any;
  id: number;
  serviceCategoryStatus: number;
  serviceCategoryTitle: string;
  shopId: number;
};

export const useServiceCategoryForm = (
  editServiceCategory?: IServiceCategoryForm | any,
  submitHandler?: any
) => {
  const initialValues: IServiceCategoryForm = editServiceCategory
    ? {
        ...editServiceCategory,
        expireDate:
          editServiceCategory?.expireDate &&
          new DateObject(editServiceCategory.expireDate).format(),
      }
    : {
        serviceCategoryTitle: "",
        id: 0,
        expireDate: "",
        serviceCategoryStatus: 1,
      };

  return useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });
};

export const useServiceAdditionalForm = (
  editServiceAdditional: IAdditionalServiceData | any,
  submitHandler?: any
) => {
  const initialValues: IAdditionalServiceData = editServiceAdditional
    ? {
        ...editServiceAdditional,
        additionalServiceExpireDate:
          editServiceAdditional?.additionalServiceExpireDate &&
          new DateObject(
            editServiceAdditional.additionalServiceExpireDate
          ).format(),
      }
    : {
        additionalServiceTitle: "",
        additionalServiceDuration: 0,
        additionalServiceAmount: 0,
        additionalServiceMaxCount: 0,
        additionalServiceType: 0,
        additionalServiceStatus: 0,
        additionalServiceExpireDate: "",
        shopId: 1,
      };

  return useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });
};

export type IBasketDiscount = { headerDiscount: number; discountId: number };

export const useDiscountForm = (
  editDiscount: IBasketDiscount,
  submitHandler: any
) => {
  const initialValues = editDiscount || {
    headerDiscount: 0,
    discountId: 0,
  };

  return useFormik({
    initialValues,
    onSubmit: submitHandler,
  });
};

export const useShopForm = (
  editShop: IShopData | null,
  submitHandler?: any
) => {
  const initialValues = editShop || {
    id: 1,
    shopTitle: "",
    secretKey: "",
    publishableKey: "",
    currency: "",
    description: "",
    address: "",
    googleMapUrl: "",
    mobile: "",
    telephone: "",
    logoImage: "",
    bannerImage: "",
  };

  return useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });
};
