export const groupBy = function (xs: any, key: any) {
  return xs.reduce(function (rv: any, x: any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const dayFunc = (dayOfWeek: number) => {
  switch (dayOfWeek) {
    case 1:
      return "sun";
    case 2:
      return "mon";
    case 3:
      return "tue";
    case 4:
      return "wed";
    case 5:
      return "thu";
    case 6:
      return "fri";
    case 7:
      return "sat";
    default:
      return "wrong case";
  }
};

export const selectInputItemsFormatter = (
  data: any,
  title: string,
  value: string
) => {
  const newData = data?.map((item: any) => ({
    id: item[value],
    title: item[title],
    value: item[value],
  }));

  return newData;
};
