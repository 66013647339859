import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosConfig from "../../axiosConfig";
import { toast } from "react-toastify";

export type IShopData = {
  id: number;
  shopTitle: string;
  secretKey: string;
  publishableKey: string;
  currency: string;
  description: string;
  address: string;
  telephone: string;
  mobile: string;
  googleMapUrl: string;
  logoImage: "";
  bannerImage: "";
};

type IFetchShopParams = {
  pageNumber?: number;
  pageSize?: number;
};

export type IShopType = {
  count: number;
  data: IShopData[];
  id?: number;
};

export enum IEditActionsApiMethodType {
  POST = "post",
  PATCH = "patch",
}

export const fetchAllShops = createAsyncThunk(
  "shops/fetchAllShops",
  async (parameters: IFetchShopParams, ThunkApi: any) => {
    const { pageNumber, pageSize } = parameters;
    const { shopId } = ThunkApi.getState().user.user;
    try {
      const { data } = await axiosConfig.get<IShopType>(
        `Shops/${pageNumber || 1}/${pageSize || 1000}/${shopId}`
      );

      return data as IShopType;
    } catch (error: any) {
      return error.response.resultMessage;
    }
  }
);

type IFetchShopByIdParams = {
  shopId: number;
};

export const fetchShopById = createAsyncThunk(
  "shops/fetchShopById",
  async (parameters: IFetchShopByIdParams, ThunkApi) => {
    const { shopId } = parameters;
    try {
      const { data } = await axiosConfig.get(`Customer/shops/${shopId}`);

      return data.data;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

export type IPostShopActionParam = {
  type: IEditActionsApiMethodType;
  body: Object;
};

export const postShop = createAsyncThunk(
  "shops/postShop",
  async (parameters: IPostShopActionParam, ThunkApi) => {
    const { type, body } = parameters;
    const promiseToast = toast.loading("Saving...");
    try {
      const { data } = await axiosConfig[type]("customer/shops", body);
      toast.update(promiseToast, {
        render: data.resultMessage,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      // navigateV(-1);
      return data;
    } catch (error: any) {
      toast.update(promiseToast, {
        render: error.response ? error.response.data.title : "Request Timeout!",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
      return error.response.resultMessage;
    }
  }
);

export type IDeleteShopParams = {
  shopId: number;
  fetchParams: IFetchShopParams;
  closeModalHandler: any;
};

export const deleteShop = createAsyncThunk(
  "shop/deleteShop",
  async (parameters: IDeleteShopParams, ThunkApi) => {
    const { shopId, fetchParams, closeModalHandler } = parameters;
    const promiseToast = toast.loading("Deleting...");
    try {
      const { data } = await axiosConfig.delete(`shop/${shopId}`);
      toast.update(promiseToast, {
        render: data.resultMessage,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      ThunkApi.dispatch(fetchAllShops(fetchParams));
      closeModalHandler();
      return data;
    } catch (error: any) {
      toast.update(promiseToast, {
        render: error.response ? error.response.data.title : "Request Timeout!",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
      return error.response.resultMessage;
    }
  }
);
