import { useState, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { IPagination } from "../../staffs/List.staffs";
import CreateServiceCategory from "./Create.serviceCategory";
import PageNotFound from "../../errors/PageNotFound.error";
import { useAppSelector } from "../../../hooks/redux.hooks";

const LazyList = lazy(() => import("./List.categories"));

const Categories = () => {
  const [pagination, setPagination] = useState<IPagination>({
    pageNumber: 1,
    pageSize: 10,
  });

  const { serviceCategoryError } = useAppSelector((state) => state.service);

  return (
    <Routes>
      <Route
        element={
          <LazyList pagination={pagination} setPagination={setPagination} />
        }
        path="/"
      />
      <Route
        element={<CreateServiceCategory />}
        path="/create-service-category"
      />
      <Route
        element={
          serviceCategoryError === 404 ? (
            <PageNotFound />
          ) : (
            <CreateServiceCategory />
          )
        }
        path="/edit-service-category/:serviceCategoryId"
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Categories;
