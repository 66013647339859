import { ChangeEvent, FC, useState } from "react";

// MUI
import { FormControlLabel, Grid, Switch, Typography } from "@mui/material";

import BaseButton from "../base/BaseButton";
import { useSearchParams } from "react-router-dom";
import BaseInput from "../base/BaseInput";
import BaseCheckbox from "../base/BaseCheckbox";

type CustomerListFilterPropTypes = {
  closePopover: () => void;
};

const CustomerListFilterCard: FC<CustomerListFilterPropTypes> = ({
  closePopover,
}) => {
  // states
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("lastName");
  const [filters, setFilters] = useState({
    customerStatus:
      searchParams.get("customerStatus") !== null
        ? Number(searchParams.get("customerStatus"))
        : 1,
    mobile:
      searchParams.get("mobile") !== null ? searchParams.get("mobile") : "",
    customerStatusEnabled:
      searchParams.get("customerStatus") !== null ? true : false,
    mobileEnabled: searchParams.get("mobile") !== null ? true : false,
  });

  // todo add filters checkbox
  const filterHandler = () => {
    const nextSearchParams = {};

    if (filters.customerStatus && filters.customerStatusEnabled)
      Object.assign(nextSearchParams, {
        customerStatus: String(filters.customerStatus),
      });

    if (filters.mobile && filters.mobileEnabled)
      Object.assign(nextSearchParams, {
        mobile: String(filters.mobile),
      });
    if (search) {
      Object.assign(nextSearchParams, { lastName: search });
    }

    setSearchParams(nextSearchParams);

    closePopover();
  };

  const closePopoverHandler = () => {
    if (search)
      setSearchParams({
        lastName: search,
      });
    else setSearchParams();

    closePopover();
  };

  return (
    <Grid
      p={2}
      width={290}
      border={2}
      borderColor="primary.main"
      borderRadius="5px"
      overflow="hidden"
      display="grid"
      gridTemplateColumns="1fr"
      gap={1}
    >
      <Grid item xs={12} container flexWrap="nowrap" gap={1}>
        <BaseCheckbox
          onChange={(e) =>
            setFilters({
              ...filters,
              mobileEnabled: !filters.mobileEnabled,
            })
          }
          checked={filters.mobileEnabled}
        />
        <BaseInput
          variant="outlined"
          label="mobile"
          value={filters.mobile}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFilters({ ...filters, mobile: e.target.value });
          }}
        />
      </Grid>
      <Grid container gap={1} flexWrap="nowrap" alignItems="end">
        <BaseCheckbox
          onChange={(e) =>
            setFilters({
              ...filters,
              customerStatusEnabled: !filters.customerStatusEnabled,
            })
          }
          checked={filters.customerStatusEnabled}
        />
        <FormControlLabel
          control={
            <Switch
              checked={filters.customerStatus ? true : false}
              size="small"
              name="customerStatus"
              onChange={() =>
                setFilters({
                  ...filters,
                  customerStatus: filters.customerStatus ? 0 : 1,
                })
              }
            />
          }
          label={<Typography fontWeight={400}>Staff Status</Typography>}
          labelPlacement="start"
          sx={{
            width: "100%",
            justifyContent: "space-between",
            mx: 0,
            p: 1,
            bgcolor: "inputBackground.main",
          }}
        />
      </Grid>
      <BaseButton onClick={filterHandler} sx={{ mt: 3 }}>
        apply
      </BaseButton>

      <BaseButton
        variant="text"
        sx={{ border: 2 }}
        onClick={closePopoverHandler}
      >
        discard
      </BaseButton>
    </Grid>
  );
};

export default CustomerListFilterCard;
