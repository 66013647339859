import { useState, useEffect } from "react";
import OneSectionLayout from "../../layout/OneSection.layouts";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import axiosConfig from "../../axiosConfig";

import { useParams } from "react-router-dom";

// actions
import { fetchAllServices } from "../../redux/services/services.actions";
import { fetchAllStaffs } from "../../redux/staffs/staff.actions";

import WithSpinner from "../../components/with-spinner/WithSpinner";
import AvailabilityForm from "./AvailabilityForm";
import { setAvailabilityError } from "../../redux/availability/availability.reducer";

export type IAvailableData = {
  availableDate: string;
  availableEndTime: string;
  availableStartTime: string;
  availableType: number;
  displayName: string;
  id: number;
  optionId: number;
  serviceAvailableStatus: false;
  serviceId: number;
  serviceTitle: string;
  shopId: number;
  staffId: number;
  lstServiceAvailableHour: {
    startTime: string;
    endTime: string;
  }[];
};

const WrappedForm = WithSpinner(AvailabilityForm);

const CreateAvailability = () => {
  // hooks
  const dispatch = useDispatch<AppDispatch>();
  const { availableId } = useParams();

  // states

  const [availableData, setAvailableData] = useState<IAvailableData>();
  const [isFetchingAvailability, setIsFetchingAvailability] = useState(false);

  const fetchAvailableData = () =>
    new Promise<IAvailableData[]>((resolve, reject) => {
      setIsFetchingAvailability(true);
      axiosConfig
        .get(`ServiceAvailable/${availableId}`)
        .then(({ data }) => {
          setIsFetchingAvailability(false);
          setAvailableData(data.data as IAvailableData);
          resolve(data.data);
        })
        .catch((error: any) => {
          setIsFetchingAvailability(false);
          dispatch(setAvailabilityError(error.response.status));
          reject(error);
          console.log(error);
        });
    });

  useEffect(() => {
    if (availableId) fetchAvailableData();
  }, [availableId]);

  useEffect(() => {
    dispatch(fetchAllServices({}));
    dispatch(fetchAllStaffs({}));
  }, []);

  return (
    <OneSectionLayout>
      <WrappedForm
        availableData={availableData}
        isLoading={isFetchingAvailability}
      />
    </OneSectionLayout>
  );
};

export default CreateAvailability;
