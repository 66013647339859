import React, { FC } from "react";
import DatePicker, { CalendarProps, DateObject } from "react-multi-date-picker";

// MUI
import { InputAdornment } from "@mui/material";
import BaseInput from "./BaseInput";

// icons
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar-icon.svg";

interface DatePickerPropsType extends CalendarProps {
  value?: any;
  setValue: any;
  calendarPosition?: any;
  fixMainPosition?: any;
  label?: any;
  isOpen?: boolean;
  portal?: boolean;
}

const BaseDatePicker: FC<DatePickerPropsType> = ({
  value,
  setValue,
  calendarPosition,
  fixMainPosition,
  label,
  portal = undefined,
  ...props
}) => {
  return (
    <DatePicker
      portal
      calendarPosition={calendarPosition}
      fixMainPosition={fixMainPosition}
      onChange={(val) => {
        setValue(val);
      }}
      value={new DateObject(value)}
      render={(val, openCalendar) => {
        return (
          <BaseInput
            sx={{ ".MuiInputBase-root": { height: 44 } }}
            variant="outlined"
            // value={new Date(value)}
            value={new DateObject(value).format()}
            onClick={openCalendar}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ zIndex: 1000 }}>
                  <CalendarIcon />
                </InputAdornment>
              ),
            }}
            label={label}
          />
        );
      }}
      {...props}
    />
  );
};

export default BaseDatePicker;
