import React, { ReactNode, ErrorInfo } from "react";

import { styled, Box, Typography, Theme } from "@mui/material";

import { ReactComponent as BrokenIcon } from "../../assets/icons/broken-page.svg";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // process error
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorImageOverlay>
          <BrokenIcon />
          {/* <ErrorImageContainer imageUrl="https://i.imgur.com/yW2W9SC.png" /> */}
          <Typography fontSize={24} color="primary.main">
            Sorry this Page is Broken
          </Typography>
        </ErrorImageOverlay>
      );
    }
    return this.props.children;
  }
}

export const ErrorImageOverlay = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    height: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    svg: {
      width: 200,
      height: 200,
    },
  })
);

export const ErrorImageContainer = styled(Box)(
  ({ theme, imageUrl }: { theme?: Theme; imageUrl: string }) =>
    //@ts-ignore
    theme.unstable_sx({
      display: "inline-block",
      backgroundImage: `url(${imageUrl})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      width: "40vh",
      height: "40vh",
    })
);

export default ErrorBoundary;
