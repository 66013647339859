import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosConfig from "../../axiosConfig";
import { IEditActionsApiMethodType } from "../shifts/shift.actions";
import { toast } from "react-toastify";
import { navigateV } from "../../App";

export type IServiceData = {
  id: number;
  shopId: number;
  serviceTitle: string;
  serviceCategoryId: number;
  bookableBeforDay: number;
  bookableBeforHours: number;
  serviceStatus: boolean;
  amount: number;
  duration: number;
  serviceType: number;
  serviceDescription: string;
  paymentType: null;
  confirmType: null;
  maxNumberOfGuest: number;
  countInTime: number;
  prePayment: null;
  amountFormulId: null;
  durationFormulId: null;
  agreementText: null;
  serviceExpireDate: null;
  serviceTimingType: null;
  isStaffBased: null;
  isRepeatable: null;
  timeBetweenTwoServ: number;
  confirmDeadLine: null;
  serviceGuidId: null;
  serviceLogoImage: null;
  cancellationFee: null;
  serviceCalendarColor: null;
};

type IServiceParamsType = {
  pageNumber?: number;
  pageSize?: number;
  serviceStatus?: any;
  serviceType?: any;
  serviceCategoryId?: any;
  search?: string | null;
};

export type IServiceType = {
  count: number;
  data: IServiceData[];
  id?: number;
};

export const fetchAllServices = createAsyncThunk(
  "services/fetchAllServices",
  async (parameters: IServiceParamsType, ThunkApi: any) => {
    const {
      pageNumber,
      pageSize,
      serviceStatus,
      serviceType,
      serviceCategoryId,
      search,
    } = parameters;
    const { shopId } = ThunkApi.getState().user.user;
    try {
      const baseUrl = `Service/${pageNumber || 1}/${
        pageSize || 1000
      }/${shopId}`;
      const requestUrlSearchParams = new URLSearchParams();

      if (search) {
        requestUrlSearchParams.set("servicetitle", search);
      }
      if (serviceStatus) {
        requestUrlSearchParams.set("serviceStatus", serviceStatus);
      }
      if (serviceType) {
        requestUrlSearchParams.set("servicetype", serviceType);
      }
      if (serviceCategoryId) {
        requestUrlSearchParams.set("servicecategoryid", serviceCategoryId);
      }

      const requestUrl = `${baseUrl}${
        requestUrlSearchParams.toString()
          ? `?${requestUrlSearchParams.toString()}`
          : ""
      }`;

      const { data } = await axiosConfig.get<IServiceType>(requestUrl);

      return data as IServiceType;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

export type IPostServiceActionParam = {
  type: IEditActionsApiMethodType;
  body: Object;
};

export const postService = createAsyncThunk(
  "services/postService",
  async (parameters: IPostServiceActionParam, ThunkApi) => {
    const { type, body } = parameters;
    const promiseToast = toast.loading("Saving...");
    try {
      const { data } = await axiosConfig[type]("Service", body);
      toast.update(promiseToast, {
        render: data.resultMessage,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      navigateV(-1);
      return data;
    } catch (error: any) {
      toast.update(promiseToast, {
        render: error.response ? error.response.data.title : "Request Timeout!",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

export type IDeleteServiceParams = {
  serviceId: number;
  fetchParams: IServiceParamsType;
  closeModalHandler: any;
};

export const deleteService = createAsyncThunk(
  "services/deleteService",
  async (parameters: IDeleteServiceParams, ThunkApi) => {
    const { serviceId, fetchParams, closeModalHandler } = parameters;
    const promiseToast = toast.loading("Deleting...");
    try {
      const { data } = await axiosConfig.delete(`Service/${serviceId}`);
      toast.update(promiseToast, {
        render: data.resultMessage,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      ThunkApi.dispatch(fetchAllServices(fetchParams));
      closeModalHandler();
      return data;
    } catch (error: any) {
      toast.update(promiseToast, {
        render: error.response ? error.response.data.title : "Request Timeout!",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

export type IGetServiceByIdParams = {
  serviceId: number | string;
};

export const fetchServiceById = createAsyncThunk(
  "services/fetchServiceById",
  async (parameters: IGetServiceByIdParams, ThunkApi) => {
    const { serviceId } = parameters;

    try {
      const { data } = await axiosConfig.get(`/Service/${serviceId}`);
      return data.data;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.response.status);
    }
  }
);

export type IDeleteServiceSupplementaryParamsType = {
  supplementaryId: number;
  fetchParams: IGetServiceByIdParams;
  closeModalHandler: any;
};

export const deleteServiceSupplementary = createAsyncThunk(
  "services/deleteServiceSupplementary",
  async (parameters: IDeleteServiceSupplementaryParamsType, ThunkApi) => {
    const { supplementaryId, fetchParams, closeModalHandler } = parameters;
    const promiseToast = toast.loading("Deleting...");
    try {
      const { data } = await axiosConfig.delete(
        `Service/DeleteServiceSupplementaryField/${supplementaryId}`
      );
      ThunkApi.dispatch(fetchServiceById(fetchParams));
      toast.update(promiseToast, {
        render: data.resultMessage,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      closeModalHandler();
      return data;
    } catch (error: any) {
      toast.update(promiseToast, {
        render: error.response ? error.response.data.title : "Request Timeout!",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

export type IPostServiceSupplementaryParam = {
  type: IEditActionsApiMethodType;
  body: Object;
  fetchParams: IGetServiceByIdParams;
};

export const postServiceSupplementary = createAsyncThunk(
  "services/postServiceSupplementary",
  async (parameters: IPostServiceSupplementaryParam, ThunkApi) => {
    const { type, body, fetchParams } = parameters;
    const promiseToast = toast.loading("Saving...");
    try {
      const { data } = await axiosConfig[type](
        `Service/ServiceSupplementary`,
        body
      );
      toast.update(promiseToast, {
        render: data.resultMessage,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      ThunkApi.dispatch(fetchServiceById(fetchParams));
      return data;
    } catch (error: any) {
      toast.update(promiseToast, {
        render: error.response ? error.response.data.title : "Request Timeout!",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

// supplementary
export type ISupplementaryData = {
  id: number;
  isRequired: boolean;
  maxValue: number;
  minValue: number;
  orderNo: number;
  serviceId: number;
  serviceTitle: string;
  shopId: number;
  supplementaryFieldTitle: string;
  supplementaryFieldType: number;
  supplementaryStatus: number;
  supplementaryValueType: number;
};

export type ISupplementaryParamsType = {
  pageNumber?: number;
  pageSize?: number;
};

export type ISupplementaryType = {
  data?: ISupplementaryData[];
  count: number;
  id?: number;
};

export const fetchAllSupplementary = createAsyncThunk(
  "services/fetchAllServiceSupplementary",
  async (parameters: ISupplementaryParamsType, ThunkApi) => {
    const { pageNumber, pageSize } = parameters;
    try {
      const { data } = await axiosConfig.get<ISupplementaryType>(
        `SupplementaryField/GetAll/${pageNumber || 1}/${pageSize || 1000}`
      );
      return data;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

export type IGetSupplementaryByIdParams = {
  supplementaryId: number;
};

export const fetchSupplementaryById = createAsyncThunk(
  "services/fetchSupplementaryById",
  async (parameters: IGetSupplementaryByIdParams, ThunkApi) => {
    const { supplementaryId } = parameters;
    try {
      const { data } = await axiosConfig.get(
        `SupplementaryField/${supplementaryId}`
      );

      return data.data;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.response.status);
    }
  }
);

export type IDeleteSupplementaryParamsType = {
  supplementaryId: number;
  fetchParams: ISupplementaryParamsType;
  closeModalHandler: any;
};

export const deleteSupplementary = createAsyncThunk(
  "services/deleteServiceSupplementary",
  async (parameters: IDeleteSupplementaryParamsType, ThunkApi) => {
    const { supplementaryId, fetchParams, closeModalHandler } = parameters;
    const promiseToast = toast.loading("Deleting...");
    try {
      const { data } = await axiosConfig.delete(
        `SupplementaryField/${supplementaryId}`
      );
      ThunkApi.dispatch(fetchAllSupplementary(fetchParams));
      toast.update(promiseToast, {
        render: data.resultMessage,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      closeModalHandler();
      return data;
    } catch (error: any) {
      toast.update(promiseToast, {
        render: error.response ? error.response.data.title : "Request Timeout!",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

export type IPostSupplementaryParam = {
  type: IEditActionsApiMethodType;
  body: Object;
};

export const postSupplementary = createAsyncThunk(
  "services/postSupplementary",
  async (parameters: IPostSupplementaryParam, ThunkApi) => {
    const { type, body } = parameters;
    const promiseToast = toast.loading("Saving...");
    try {
      const { data } = await axiosConfig[type](`SupplementaryField`, body);
      toast.update(promiseToast, {
        render: data.resultMessage,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      navigateV(-1);
      return data;
    } catch (error: any) {
      toast.update(promiseToast, {
        render: error.response ? error.response.data.title : "Request Timeout!",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

// service category

export type IServiceCategoryType = {
  id?: number;
  data: IServiceCategoryData[];
  count: number;
};

export type IServiceCategoryData = {
  categoryLogoImage: string;
  expireDate: string;
  id: number;
  serviceCategoryStatus: number;
  serviceCategoryTitle: string;
  shopId: number;
};

export type FetchServiceCategoryParamsType = {
  pageNumber?: number;
  pageSize?: number;
};

export const fetchAllServiceCategory = createAsyncThunk(
  "services/fetchAllServiceCategory",
  async (parameters: FetchServiceCategoryParamsType, ThunkApi) => {
    const { pageNumber, pageSize } = parameters;
    try {
      const { data } = await axiosConfig.get(
        `ServiceCategory/${pageNumber || 1}/${pageSize || 1000}/1`
      );

      return data;
    } catch (error: any) {
      ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

export type IPostServiceCategoryParam = {
  type: IEditActionsApiMethodType;
  body: Object;
};

export const postServiceCategory = createAsyncThunk(
  "services/postServiceCategory",
  async (parameters: IPostServiceCategoryParam, ThunkApi) => {
    const { type, body } = parameters;
    const promiseToast = toast.loading("Saving...");
    try {
      const { data } = await axiosConfig[type](`ServiceCategory`, body);
      toast.update(promiseToast, {
        render: data.resultMessage,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      navigateV(-1);
      return data;
    } catch (error: any) {
      toast.update(promiseToast, {
        render: error.response ? error.response.data.title : "Request Timeout!",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

export type IDeleteServiceCategoryParamsType = {
  categoryId: number;
  fetchParams: FetchServiceCategoryParamsType;
  closeModalHandler: any;
};

export const deleteServiceCategory = createAsyncThunk(
  "services/deleteServiceCategory",
  async (parameters: IDeleteServiceCategoryParamsType, ThunkApi) => {
    const { categoryId, fetchParams, closeModalHandler } = parameters;
    const promiseToast = toast.loading("Deleting...");
    try {
      const { data } = await axiosConfig.delete(
        `ServiceCategory/${categoryId}`
      );
      ThunkApi.dispatch(fetchAllServiceCategory(fetchParams));
      toast.update(promiseToast, {
        render: data.resultMessage,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      closeModalHandler();
      return data;
    } catch (error: any) {
      toast.update(promiseToast, {
        render: error.response ? error.response.data.title : "Request Timeout!",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

// export type IServiceSupplementarySubFieldData = {
//   id: number;
//   isDefault: boolean;
//   orderNo: number;
//   shopId: number;
//   supplementaryFieldId: number;
//   supplementarySubFieldTitle: string;
//   supplementarySubFieldTitleLookupValue: any;
// };

// export type IServiceSupplementarySubFieldParamsType = {
//   supplementaryId: number | string;
//   pageNumber: number;
//   pageSize: number;
// };

// export type IServiceSupplementarySubFieldType = {
//   data?: IServiceSupplementarySubFieldData[];
//   count: number;
//   id?: number;
// };

// export const fetchAllServiceSupplementarySubField = createAsyncThunk(
//   "services/fetchAllServiceSupplementarySubField",
//   async (parameters: IServiceSupplementarySubFieldParamsType, ThunkApi) => {
//     const { supplementaryId, pageNumber, pageSize } = parameters;
//     try {
//       const { data } = await axiosConfig.get<IServiceSupplementarySubFieldType>(
//         `SupplementarySubField/GetBysupplementaryfieldid/${supplementaryId}/${
//           pageNumber || 1
//         }/${pageSize || 1000}`
//       );
//       return data;
//     } catch (error: any) {
//       return ThunkApi.rejectWithValue(error.response.resultMessage);
//     }
//   }
// );

export type IPostSupplementarySubFieldParam = {
  type: IEditActionsApiMethodType;
  body: Object;
};

export const postSupplementarySubField = createAsyncThunk(
  "services/postSupplementarySubField",
  async (parameters: IPostSupplementarySubFieldParam, ThunkApi) => {
    const { type, body } = parameters;
    const promiseToast = toast.loading("Saving...");
    try {
      const { data } = await axiosConfig[type](
        type === IEditActionsApiMethodType.POST
          ? "SupplementaryField/AddSubField"
          : "SupplementaryField/EditSubField",
        body
      );
      toast.update(promiseToast, {
        render: data.resultMessage,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      navigateV(-1);
      return data;
    } catch (error: any) {
      toast.update(promiseToast, {
        render: error.response ? error.response.data.title : "Request Timeout!",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

export type IDeleteSupplementarySubFieldParamsType = {
  supplementarySubFieldId: number;
  fetchParams: IGetSupplementaryByIdParams;
  closeModalHandler: any;
};

export const deleteSupplementarySubField = createAsyncThunk(
  "services/deleteSupplementarySubField",
  async (parameters: IDeleteSupplementarySubFieldParamsType, ThunkApi) => {
    const { supplementarySubFieldId, fetchParams, closeModalHandler } =
      parameters;
    const promiseToast = toast.loading("Deleting...");
    try {
      const { data } = await axiosConfig.delete(
        `SupplementaryField/DeleteSubField/${supplementarySubFieldId}`
      );
      toast.update(promiseToast, {
        render: data.resultMessage,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      ThunkApi.dispatch(fetchSupplementaryById(fetchParams));
      closeModalHandler();
      return data;
    } catch (error: any) {
      toast.update(promiseToast, {
        render: error.response ? error.response.data.title : "Request Timeout!",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

export type IAdditionalServiceData = {
  id: number;
  serviceId: number;
  additionalServiceTitle: string;
  additionalServiceDuration: number;
  additionalServiceAmount: number;
  additionalServiceMaxCount: number;
  additionalServiceType: number;
  additionalServiceStatus: number;
  additionalServiceExpireDate: string;
  shopId: number;
};

export type ISelectInputType = {
  id: number;
  lookupValueTitle: string;
};

export type IServiceCommonData = {
  serviceTypeList: ISelectInputType[];
  payTypeList: ISelectInputType[];
  valueTypeList: ISelectInputType[];
  fieldTypeList: ISelectInputType[];
  serviceCategoryList: { id: number; serviceCategoryTitle: string }[];
  supplementaryFieldList: ISelectInputType[];
};

export type IServiceCommonType = {
  data: IServiceCommonData[];
  count?: number;
};

export const fetchServiceCommonData = createAsyncThunk(
  "services/fetchServicesCommonData",
  async (parameters, ThunkApi: any) => {
    const { shopId } = ThunkApi.getState().user.user;
    try {
      const { data } = await axiosConfig.get(
        `Service/CommonDataGetAll/${shopId}`
      );

      return data.data;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

export type IGetServiceAdditionalByIdParams = {
  serviceAdditionalId: number | string;
};

export const fetchServiceAdditionalById = createAsyncThunk(
  "staffs/fetchStaffServiceById",
  async (parameters: IGetServiceAdditionalByIdParams, ThunkApi) => {
    const { serviceAdditionalId } = parameters;
    try {
      const { data } = await axiosConfig.get(
        `Service/AdditionalService/${serviceAdditionalId}`
      );

      return data.data;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

export type IPostServiceAdditionalParam = {
  type: IEditActionsApiMethodType;
  body: Object;
  fetchParams: IGetServiceByIdParams;
};

export const postServiceAdditional = createAsyncThunk(
  "services/postServiceAdditional",
  async (parameters: IPostServiceAdditionalParam, ThunkApi) => {
    const { type, body, fetchParams } = parameters;
    const promiseToast = toast.loading("Saving...");
    try {
      const { data } = await axiosConfig[type](
        `Service/AdditionalService`,
        body
      );
      toast.update(promiseToast, {
        render: data.resultMessage,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      ThunkApi.dispatch(fetchServiceById(fetchParams));
      return data;
    } catch (error: any) {
      toast.update(promiseToast, {
        render: error.response ? error.response.data.title : "Request Timeout!",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

export type IDeleteServiceAdditionalParamsType = {
  serviceAdditionalId: number;
  fetchParams: IGetServiceByIdParams;
  closeModalHandler: any;
};

export const deleteServiceAdditional = createAsyncThunk(
  "services/deleteServiceAdditional",
  async (parameters: IDeleteServiceAdditionalParamsType, ThunkApi) => {
    const { serviceAdditionalId, fetchParams, closeModalHandler } = parameters;
    const promiseToast = toast.loading("Deleting...");
    try {
      const { data } = await axiosConfig.delete(
        `Service/AdditionalService/${serviceAdditionalId}`
      );
      toast.update(promiseToast, {
        render: data.resultMessage,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      ThunkApi.dispatch(fetchServiceById(fetchParams));
      closeModalHandler();
      return data;
    } catch (error: any) {
      toast.update(promiseToast, {
        render: error.response ? error.response.data.title : "Request Timeout!",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);
