import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosConfig from "../../axiosConfig";
import { groupBy } from "../../helpers/utils";

export type ILookupValuesData = {
  id: number;
  lookupId: string;
  lookupValueTitle: string;
  sortOrder: number;
  isVisible: boolean;
  lookupTitle: string;
};

export type IFetchLookupValuesByLookupIdParams = {
  lookupId: string;
  pageNumber?: number;
  pageSize?: number;
};

export const fetchLookupValuesByLookupId = createAsyncThunk(
  "lookup/fetchLookupValuesByLookupId",
  async (parameters: IFetchLookupValuesByLookupIdParams, ThunkApi) => {
    const { lookupId, pageNumber, pageSize } = parameters;
    try {
      const { data } = await axiosConfig.get(
        `LookupValue/${lookupId}/${pageNumber || 1}/${pageSize || 1000}`
      );
      const result = groupBy(data.data, "lookupId");
      return result;
    } catch (error: any) {
      return ThunkApi.dispatch(error.response);
    }
  }
);
