import { useState, useEffect } from "react";
import OneSectionLayout from "../../layout/OneSection.layouts";

// MUI
import { Grid, Typography, FormControlLabel, Switch } from "@mui/material";

import BaseButton from "../../components/base/BaseButton";
import { useNavigate, useParams } from "react-router-dom";
import { useCustomerForm } from "../../helpers/formik";
import BaseInput from "../../components/base/BaseInput";
import BaseTextInput from "../../components/base/BaseTextInput";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { postCustomer } from "../../redux/customer/customer.actions";
import { IEditActionsApiMethodType } from "../../redux/shifts/shift.actions";
import axiosConfig from "../../axiosConfig";
import { RotatingLines } from "react-loader-spinner";
import { useAppSelector } from "../../hooks/redux.hooks";
import { setFetchCustomerError } from "../../redux/customer/customer.reducer";

const CreateCustomer = () => {
  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { customerId } = useParams();

  const { isPostingCustomer } = useAppSelector((state) => state.customer);
  // states
  const [customer, setCustomer] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const fetchCustomerById = async () => {
    setIsFetching(true);
    try {
      const { data } = await axiosConfig.get(`/Customer/${customerId}`);
      setIsFetching(false);
      setCustomer(data.data);
    } catch (error: any) {
      setIsFetching(false);
      dispatch(setFetchCustomerError(error.response.status));
    }
  };
  useEffect(() => {
    if (customerId) fetchCustomerById();
  }, [customerId]);

  const submit = () => {
    const body = {
      ...values,
    };
    const parameters = {
      type: customerId
        ? IEditActionsApiMethodType.PATCH
        : IEditActionsApiMethodType.POST,
      body,
    };

    if (isValid) dispatch(postCustomer(parameters));
  };

  const { values, handleSubmit, handleChange, errors, setFieldValue, isValid } =
    useCustomerForm(customer, submit);

  return (
    <OneSectionLayout>
      <Grid
        container
        item
        xs={12}
        zIndex={1}
        position="relative"
        top={-16}
        justifyContent="space-between"
      >
        <Typography
          height={70}
          display="flex"
          alignItems="center"
          color="grey.100"
        >
          {customerId ? "Edit " : "Add"} Customer{" "}
          {customerId && values.firstName}
        </Typography>
        <Grid
          item
          container
          xs={12}
          md={8}
          gap={2}
          alignItems="center"
          justifyContent="end"
          position="unset"
          bottom={-5}
          left={0}
          bgcolor="transparent"
          p={0}
          zIndex={10}
        >
          <BaseButton
            sx={{
              width: 150,
              bgcolor: "white",
              color: "primary.main",
              "&:hover": {
                bgcolor: "white",
              },
              border: 0,
              fontWeight: 500,
              height: 45,
            }}
            onClick={() => handleSubmit()}
            disabled={isPostingCustomer}
          >
            {isPostingCustomer ? (
              <Grid
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  zIndex: 10,
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="30"
                  visible
                />
              </Grid>
            ) : (
              "Save"
            )}
          </BaseButton>

          <BaseButton
            sx={{
              width: 150,
              border: 0,
              display: "flex",
              boxShadow: 0,
            }}
            onClick={() => navigate(-1)}
          >
            Discard
          </BaseButton>
        </Grid>
      </Grid>
      <Grid
        display="grid"
        gridTemplateColumns="repeat(4, 1fr)"
        gap={2}
        position="relative"
      >
        {isFetching && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            position="absolute"
            zIndex={1000}
            bgcolor="rgba(0,0,0, 0.05)"
            borderRadius={1}
            height="100%"
          >
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
            />
          </Grid>
        )}
        <BaseInput
          value={values.firstName}
          onChange={handleChange}
          variant="outlined"
          name="firstName"
          placeholder={errors.firstName || "First Name"}
          label="First Name"
          err={errors.firstName}
          color={errors.firstName ? "error" : "primary"}
        />
        <BaseInput
          value={values.lastName}
          onChange={handleChange}
          variant="outlined"
          name="lastName"
          placeholder="Last Name"
          label="Last Name"
        />
        <BaseInput
          value={values.userName}
          onChange={handleChange}
          variant="outlined"
          name="userName"
          placeholder="Username"
          label="Username"
        />
        <BaseInput
          value={values.email}
          onChange={handleChange}
          variant="outlined"
          name="email"
          placeholder="Email"
          label="Email"
        />
        <BaseInput
          value={values.phone}
          onChange={handleChange}
          variant="outlined"
          name="phone"
          placeholder="Phone Number"
          label="Phone Number"
        />
        <BaseInput
          value={values.mobile}
          onChange={handleChange}
          variant="outlined"
          name="mobile"
          placeholder="Mobile Number"
          label="Mobile Number"
        />
        <BaseInput
          value={values.postCode}
          onChange={handleChange}
          variant="outlined"
          name="postCode"
          placeholder="Post Code"
          label="Post Code"
        />
        <FormControlLabel
          control={
            <Switch
              checked={values.customerStatus ? true : false}
              size="small"
              name="customerStatus"
              onChange={() =>
                setFieldValue("customerStatus", values.customerStatus ? 0 : 1)
              }
            />
          }
          label={<Typography fontWeight={400}>Status</Typography>}
          labelPlacement="start"
          sx={{
            width: "100%",
            justifyContent: "space-between",
            mx: 0,
            bgcolor: "inputBackground.main",
            p: 1,
            borderRadius: 2,
          }}
        />
        <Grid position="relative" gridColumn="1/3">
          <BaseTextInput
            name="address"
            onChange={handleChange}
            value={values.address}
            label="Address"
          />
          <Typography
            position="absolute"
            fontSize={12}
            top={values.address ? -8 : 14}
            left={values.address ? 8 : 15}
            sx={{ transition: "ease all 0.3s" }}
          >
            Address
          </Typography>
        </Grid>
      </Grid>
    </OneSectionLayout>
  );
};

export default CreateCustomer;
