import React, { useState, ChangeEvent, useEffect } from "react";
import OneSectionLayout from "../../../layout/OneSection.layouts";

import { Grid, Typography } from "@mui/material";
import BaseButton from "../../../components/base/BaseButton";
import { useNavigate, useParams } from "react-router-dom";
import { useSupplementaryForm } from "../../../helpers/formik";
import BaseInput from "../../../components/base/BaseInput";
import BaseSelect from "../../../components/base/BaseSelect";
import { IEditActionsApiMethodType } from "../../../redux/shifts/shift.actions";
import { useDispatch } from "react-redux";
import {
  fetchServiceCommonData,
  fetchSupplementaryById,
  postSupplementary,
} from "../../../redux/services/services.actions";
import { AppDispatch } from "../../../redux/store";
import axiosConfig from "../../../axiosConfig";
import { RotatingLines } from "react-loader-spinner";
import { useAppSelector } from "../../../hooks/redux.hooks";
import { selectInputItemsFormatter } from "../../../helpers/utils";

const CreateSupplementary = () => {
  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { supplementaryId } = useParams();

  // states

  const {
    isPostingSupplementary,
    commonData,
    supplementaryById,
    isFetchingSupplementary,
  } = useAppSelector((state) => state.service);
  const { user } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (supplementaryId)
      dispatch(
        fetchSupplementaryById({ supplementaryId: Number(supplementaryId) })
      );
  }, [supplementaryId]);

  useEffect(() => {
    dispatch(fetchServiceCommonData());
  }, []);

  const submit = () => {
    const parameters = {
      body: { ...values, shopId: user?.shopId },
      type: supplementaryId
        ? IEditActionsApiMethodType.PATCH
        : IEditActionsApiMethodType.POST,
    };

    dispatch(postSupplementary(parameters));
  };

  const { values, setFieldValue, handleSubmit, handleChange } =
    useSupplementaryForm(supplementaryById, submit);

  const valueTypeInputItems = selectInputItemsFormatter(
    commonData?.valueTypeList,
    "lookupValueTitle",
    "id"
  );

  const fieldTypeInputItems = selectInputItemsFormatter(
    commonData?.fieldTypeList,
    "lookupValueTitle",
    "id"
  );

  return (
    <OneSectionLayout>
      <Grid
        container
        item
        xs={12}
        zIndex={1}
        position="relative"
        top={-16}
        justifyContent="space-between"
      >
        <Typography
          height={70}
          display="flex"
          alignItems="center"
          color="grey.100"
        >
          {supplementaryId ? "Edit" : "Add"} Supplementary
        </Typography>
        <Grid
          item
          container
          xs={12}
          md={8}
          gap={2}
          alignItems="center"
          justifyContent="end"
          position="unset"
          bottom={-5}
          left={0}
          bgcolor="transparent"
          p={0}
          zIndex={10}
        >
          <BaseButton
            sx={{
              width: 150,
              bgcolor: "white",
              color: "primary.main",
              "&:hover": {
                bgcolor: "white",
              },
              border: 0,
              fontWeight: 500,
              height: 45,
            }}
            onClick={() => handleSubmit()}
            disabled={isPostingSupplementary}
          >
            {isPostingSupplementary ? (
              <Grid
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  zIndex: 10,
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="30"
                  visible
                />
              </Grid>
            ) : (
              "Save"
            )}
          </BaseButton>

          <BaseButton
            sx={{
              width: 150,
              border: 0,
              display: "flex",
              boxShadow: 0,
            }}
            onClick={() => navigate(-1)}
          >
            Discard
          </BaseButton>
        </Grid>
      </Grid>
      <Grid
        display="grid"
        gridTemplateColumns="1fr 1fr"
        gap={2}
        position="relative"
      >
        {isFetchingSupplementary && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            position="absolute"
            zIndex={1000}
            bgcolor="rgba(0,0,0, 0.05)"
            borderRadius={1}
            height="100%"
          >
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
            />
          </Grid>
        )}
        <BaseInput
          value={values.supplementaryFieldTitle}
          onChange={handleChange}
          name="supplementaryFieldTitle"
          variant="outlined"
          placeholder="Title"
          label="Title"
        />
        <BaseSelect
          value={values.supplementaryValueType}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFieldValue("supplementaryValueType", e.target.value)
          }
          defaultVal="Value Type"
          inputItems={valueTypeInputItems}
          label="Value Type"
        />
        <BaseSelect
          value={values.supplementaryFieldType}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFieldValue("supplementaryFieldType", e.target.value)
          }
          defaultVal="Field Type"
          inputItems={fieldTypeInputItems}
          label="Field Type"
        />
        <BaseInput
          value={values.orderNo}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFieldValue("orderNo", Number(e.target.value));
          }}
          name="orderNo"
          variant="outlined"
          placeholder="Order Number"
          label="Order Number"
        />
        <BaseInput
          value={values.minValue}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFieldValue("minValue", Number(e.target.value));
          }}
          name="minValue"
          variant="outlined"
          placeholder="Min Value"
          label="Min Value"
        />
        <BaseInput
          value={values.maxValue}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFieldValue("maxValue", Number(e.target.value));
          }}
          name="maxValue"
          variant="outlined"
          placeholder="Max Value"
          label="Max Value"
        />
      </Grid>
    </OneSectionLayout>
  );
};

export default CreateSupplementary;
