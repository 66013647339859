import React, { FC } from "react";
import { Collapse, Grid, IconButton } from "@mui/material";
import TimePickerInput from "../base/BaseTimePicker";
import { TransitionGroup } from "react-transition-group";

import { IHoursType } from "../../pages/availability/AvailabilityForm";

// icons
import { ReactComponent as AddIcon } from "../../assets/icons/add-icon.svg";

type AvailableTimePropsType = {
  formik: any;
  hoursData: IHoursType[];
  setHoursData: any;
};

const AvailabilityTimeCard: FC<AvailableTimePropsType> = ({
  formik,
  hoursData,
  setHoursData,
}) => {
  const addHour = () => {
    const numberOfRows = hoursData.length;
    let nextRowId = 0;

    // Find next row id
    if (numberOfRows !== 0) {
      const lastElementOfHour = hoursData[hoursData.length - 1];
      const keysOfLastElement = Object.keys(lastElementOfHour);
      let idOfLastElementInput = null;

      keysOfLastElement.forEach((key) => {
        const splitName = key.split("-");
        if (splitName.length === 2) {
          idOfLastElementInput = Number(splitName[1]);
        }
      });

      if (typeof idOfLastElementInput === "number") {
        nextRowId = idOfLastElementInput + 1;
      }
    }

    // Generate new row
    const nameOpen = `open-${nextRowId}`;
    const nameClose = `close-${nextRowId}`;

    formik.setFieldValue([nameOpen], "");
    formik.setFieldValue([nameClose], "");

    setHoursData([
      ...hoursData,
      {
        id: nextRowId,
        [nameOpen]: "",
        [nameClose]: "",
      },
    ]);
  };

  const deleteHour = (
    selectedRow: IHoursType,
    nameOpen: string,
    nameClose: string
  ) => {
    const existingItem = hoursData.find((el) => el.id === selectedRow.id);

    if (existingItem) {
      const newHoursData = hoursData.filter(
        (hour) => hour.id !== selectedRow.id
      );

      delete formik.values[nameOpen];
      delete formik.values[nameClose];
      setHoursData(newHoursData);
    }
  };

  return (
    <Grid container p={1}>
      <Grid item xs={10.5}>
        <TransitionGroup>
          {hoursData?.map((hour) => {
            let nameOpen = null;
            let nameClose = null;
            for (const key in hour) {
              const splitKey = key.split("-");
              if (splitKey.length > 1) {
                if (splitKey[0] === "open") nameOpen = key;
                else nameClose = key;
              }
            }

            return (
              <Collapse key={hour.id} sx={{ pr: 1 }}>
                <TimePickerInput
                  nameStart={nameOpen as string}
                  nameEnd={nameClose as string}
                  formik={formik}
                  hour={hour}
                  handleDelete={deleteHour}
                />
              </Collapse>
            );
          })}
        </TransitionGroup>
      </Grid>
      <Grid item container xs={1.5} gap={2} alignItems="start">
        <Grid
          container
          sx={{
            borderLeft: 2,
            borderColor: "grey.300",
            position: "relative",
            svg: { width: 24, height: 24 },
          }}
        >
          <IconButton onClick={addHour}>
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AvailabilityTimeCard;
