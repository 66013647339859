import { Grid } from "@mui/material";
import React, { ReactNode } from "react";

interface ILayoutProps {
  header?: JSX.Element;
  children?: ReactNode;
  paging?: JSX.Element;
  bgcolor?: string;
}

const ThreeSectionLayout: React.FC<ILayoutProps> = ({
  children,
  header,
  paging,
  bgcolor = "listBackground.main",
}) => {
  return (
    <Grid
      display="grid"
      sx={{
        gridTemplateRows: "auto 1fr auto",
        gridTemplateColumns: "1fr",
        height: "100%",
        gap: 2,
      }}
    >
      <Grid bgcolor={bgcolor} borderRadius={2.5}>
        {header}
      </Grid>
      <Grid
        width="100%"
        overflow="auto"
        bgcolor={bgcolor}
        borderRadius={2.5}
        position="relative"
        id="tableParent"
      >
        <Grid
          width="100%"
          height={65}
          position="absolute"
          bgcolor="primary.main"
          zIndex={1}
          borderRadius="10px"
        />
        <Grid
          px={2}
          sx={{
            'thead[class*="ant-table-thead"] tr': {
              backgroundColor: "red !important",
            },
          }}
          mt={1}
        >
          {children}
        </Grid>
      </Grid>
      <Grid bgcolor={bgcolor} borderRadius={2.5}>
        {paging}
      </Grid>
    </Grid>
  );
};

export default ThreeSectionLayout;
