import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosConfig from "../../axiosConfig";
import { IEditActionsApiMethodType } from "../shifts/shift.actions";
import { toast } from "react-toastify";
import { navigateV } from "../../App";

export type AvailabilityDataType = {
  availableDate: string;
  availableType: number;
  id: number;
  optionId: number;
  serviceAvailableStatus: boolean;
  serviceId: number;
  shopId: number;
  staffId: number;
};

export type IAvailabilityType = {
  count: number;
  data: AvailabilityDataType[];
  id?: number;
};

type IAvailabilityParamsType = {
  pageNumber: number;
  pageSize: number;
  staffId: any;
  serviceId: any;
  fromDate: any;
  toDate: any;
};

export const fetchAllAvailability = createAsyncThunk(
  "availability/fetchAllAvailability",
  async (parameters: IAvailabilityParamsType, thunkApi: any) => {
    const { pageNumber, pageSize, staffId, serviceId, fromDate, toDate } =
      parameters;
    const { shopId } = thunkApi.getState().user.user;

    const baseUrl = `ServiceAvailable/GetAll/${pageNumber || 1}/${
      pageSize || 1000
    }/${shopId}`;
    const requestUrlSearchParams = new URLSearchParams();

    if (staffId) {
      requestUrlSearchParams.set("staffid", staffId);
    }
    if (serviceId) {
      requestUrlSearchParams.set("serviceid", serviceId);
    }
    if (fromDate) {
      requestUrlSearchParams.set("fromdate", fromDate);
    }
    if (toDate) {
      requestUrlSearchParams.set("todate", toDate);
    }

    const requestUrl = `${baseUrl}${
      requestUrlSearchParams.toString()
        ? `?${requestUrlSearchParams.toString()}`
        : ""
    }`;
    try {
      const { data } = await axiosConfig.get(requestUrl);

      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

export type IPostAvailabilityParamsType = {
  type: IEditActionsApiMethodType;
  body: Object;
};

export const postAvailability = createAsyncThunk(
  "availability/postAvailability",
  async (parameters: IPostAvailabilityParamsType, thunkApi) => {
    const { type, body } = parameters;
    const promiseToast = toast.loading("Saving...");
    try {
      const { data } = await axiosConfig[type]("ServiceAvailable", body);
      toast.update(promiseToast, {
        render: data.resultMessage,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      navigateV(-1);
      return data;
    } catch (error: any) {
      toast.update(promiseToast, {
        render: error.response ? error.response.data.title : "Request Timeout!",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
      return thunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

export type IDeleteAvailabilityParams = {
  availabilityId: number;
  fetchParams: IAvailabilityParamsType;
  closeModalHandler: any;
};

export const deleteAvailability = createAsyncThunk(
  "availability/deleteAvailability",
  async (parameters: IDeleteAvailabilityParams, thunkApi) => {
    const { availabilityId, fetchParams, closeModalHandler } = parameters;
    const promiseToast = toast.loading("Deleting...");
    try {
      const { data } = await axiosConfig.delete(
        `ServiceAvailable/${availabilityId}`
      );
      toast.update(promiseToast, {
        render: data.resultMessage,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      thunkApi.dispatch(fetchAllAvailability(fetchParams));
      closeModalHandler();

      return data;
    } catch (error: any) {
      toast.update(promiseToast, {
        render: error.response ? error.response.data.title : "Request Timeout!",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
      return thunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);
