import { ChangeEvent, FC } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";

// MUI
import { Modal, Grid } from "@mui/material";

// components
import BaseInput from "../base/BaseInput";
import BaseButton from "../base/BaseButton";

// helpers
import { IBasketDiscount, useDiscountForm } from "../../helpers/formik";
import { useAppSelector } from "../../hooks/redux.hooks";
import { IPostBasketParams, postBasket } from "../../redux/order/order.actions";
import { RotatingLines } from "react-loader-spinner";
import { NumericFormatCustom } from "../base/BaseMaskInput";
import { useSearchParams } from "react-router-dom";

type IDiscountModalProps = {
  open: boolean;
  handleClose: any;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  overflowY: "auto",
  maxHeight: "80%",
  borderRadius: 1,
  p: 4,
};

const DiscountModal: FC<IDiscountModalProps> = ({ open, handleClose }) => {
  // hooks
  const dispatch = useDispatch<AppDispatch>();

  // states
  const { orderCustomer, isPostingBasket, orderBasket } = useAppSelector(
    (state) => state.order
  );

  const [searchParams, _] = useSearchParams();

  const bookingSearchParam = searchParams.get("bookingId");

  const initialDiscount: IBasketDiscount = {
    headerDiscount: orderBasket?.headerDiscount || 0,
    discountId: orderBasket?.discountId || 0,
  };

  const submit = () => {
    const body = {
      itemList: [],
      additionalServiceList: [],
      productsList: [],
      customerId: orderCustomer,
      bookingId: Number(bookingSearchParam),
      shopId: 1,
      discountId: values.discountId,
      headerDiscount: values.headerDiscount,
    };
    const parameters: IPostBasketParams = {
      body,
      mode: "discount",
      closeModal: handleClose,
    };

    dispatch(postBasket(parameters));
  };

  const { values, setFieldValue, handleSubmit } = useDiscountForm(
    initialDiscount,
    submit
  );

  return (
    <Modal open={open} onClose={handleClose}>
      <Grid container sx={style} gap={2}>
        <BaseInput
          variant="outlined"
          name="headerDiscount"
          value={values.headerDiscount}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFieldValue("headerDiscount", Number(e.target.value));
          }}
          label="Header Discount"
          InputProps={{
            inputComponent: NumericFormatCustom as any,
          }}
        />
        <BaseInput
          variant="outlined"
          name="discountId"
          value={values.discountId}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFieldValue("discountId", Number(e.target.value));
          }}
          label="Discount Code"
        />
        <BaseButton
          sx={{
            border: 0,
            fontWeight: 500,
            height: 45,
            mt: 4,
          }}
          onClick={() => handleSubmit()}
          disabled={isPostingBasket}
        >
          {isPostingBasket ? (
            <Grid
              sx={{
                position: "absolute",
                left: "50%",
                top: "50%",
                zIndex: 10,
                transform: "translate(-50%, -50%)",
                display: "flex",
                alignItems: "center",
              }}
            >
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="30"
                visible
              />
            </Grid>
          ) : (
            "Submit"
          )}
        </BaseButton>
      </Grid>
    </Modal>
  );
};

export default DiscountModal;
