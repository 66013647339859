import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { RotatingLines } from "react-loader-spinner";

// MUI
import { Grid, Typography, Box, Switch, FormControlLabel } from "@mui/material";

import BaseInput from "../../components/base/BaseInput";
import { useStaffForm } from "../../helpers/formik";
import BaseTextInput from "../../components/base/BaseTextInput";
import OneSectionLayout from "../../layout/OneSection.layouts";
import BaseButton from "../../components/base/BaseButton";
import { IPostStaffParams, postStaff } from "../../redux/staffs/staff.actions";
import { IEditActionsApiMethodType } from "../../redux/shifts/shift.actions";
import BaseFileInput from "../../components/base/BaseFileInput";

// icons
import { ReactComponent as ImageInputIcon } from "../../assets/icons/image-input-icon.svg";
import { useAppSelector } from "../../hooks/redux.hooks";

const CreateStaff = () => {
  // hooks
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { user } = useAppSelector((state) => state.user);
  const { isPostingStaff } = useAppSelector((state) => state.staff);

  // states
  const [attachment, setAttachment] = useState();

  const submitHandler = () => {
    const formData = new FormData();
    for (const key in values) {
      if (key !== "image" && key !== "staffImage")
        //@ts-ignore
        formData.append(key, values[key]);
    }
    if (user) formData.append("shopId", String(user.shopId));
    if (attachment) formData.append("image", attachment);
    const parameters: IPostStaffParams = {
      type: IEditActionsApiMethodType.POST,
      body: formData,
    };
    validateForm();
    if (isValid) dispatch(postStaff(parameters));
  };

  const { values, handleSubmit, handleChange, errors, isValid, validateForm } =
    useStaffForm(null, submitHandler);

  return (
    <OneSectionLayout>
      <Grid
        container
        item
        xs={12}
        zIndex={1}
        position="relative"
        top={-16}
        justifyContent="space-between"
      >
        <Typography
          height={70}
          display="flex"
          alignItems="center"
          color="grey.100"
        >
          Add Staff
        </Typography>
        <Grid
          item
          container
          xs={12}
          md={8}
          gap={2}
          alignItems="center"
          justifyContent="end"
          position="unset"
          bottom={-5}
          left={0}
          bgcolor="transparent"
          p={0}
          zIndex={10}
        >
          <BaseButton
            sx={{
              width: 150,
              bgcolor: "white",
              color: "primary.main",
              "&:hover": {
                bgcolor: "white",
              },
              border: 0,
              fontWeight: 500,
              height: 45,
            }}
            onClick={() => handleSubmit()}
            disabled={isPostingStaff}
          >
            {isPostingStaff ? (
              <Grid
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  zIndex: 10,
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="30"
                  visible
                />
              </Grid>
            ) : (
              "Save"
            )}
          </BaseButton>

          <BaseButton
            sx={{
              width: 150,
              border: 0,
              display: "flex",
              boxShadow: 0,
            }}
            onClick={() => navigate(-1)}
          >
            Discard
          </BaseButton>
        </Grid>
      </Grid>

      <Grid container rowGap={2} mb={8} maxWidth={1300}>
        {/* personal section */}
        <Grid item xs={12}>
          <Typography color="primary" fontWeight={500}>
            Personal Info
          </Typography>
        </Grid>
        <Grid container gap={2} alignContent="start">
          <Grid
            display="grid"
            gridTemplateColumns="120px 1fr"
            item
            xs={7}
            gap={2}
          >
            <BaseFileInput
              attachment={attachment}
              setAttachment={setAttachment}
              icon={<ImageInputIcon />}
            />
            <Grid display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={2}>
              <BaseInput
                variant="outlined"
                value={values.staffName}
                name="staffName"
                onChange={handleChange}
                placeholder="First Name"
                label="First Name"
                error={errors.staffName ? true : false}
                helperText={errors.staffName}
              />
              <BaseInput
                variant="outlined"
                value={values.staffFamily}
                name="staffFamily"
                onChange={handleChange}
                placeholder="Last Name"
                label="Last Name"
              />

              <BaseInput
                variant="outlined"
                value={values.displayName}
                name="displayName"
                onChange={handleChange}
                placeholder="Nickname"
                label="Nickname"
              />
            </Grid>
          </Grid>

          <Grid item flexGrow={1} position="relative">
            <BaseTextInput
              name="bio"
              onChange={handleChange}
              value={values.bio}
              label="Biography"
            />
          </Grid>
        </Grid>
        {/* contact info */}
        <Grid item xs={12}>
          <Typography color="primary" fontWeight={500}>
            Contact Info
          </Typography>
        </Grid>
        <Grid container gap={2}>
          <Grid
            item
            container
            xs={7}
            gap={2}
            flexWrap={{ xs: "wrap", md: "nowrap" }}
          >
            <Grid item xs={8} container gap={2}>
              <Grid item xs={12}>
                <BaseInput
                  variant="outlined"
                  value={values.staffEmail}
                  name="staffEmail"
                  onChange={handleChange}
                  placeholder="Email Address"
                  label="Email Address"
                  helperText={errors.staffEmail}
                  error={errors.staffEmail ? true : false}
                />
              </Grid>
              <Grid item xs={12}>
                <BaseInput
                  variant="outlined"
                  value={values.mobile}
                  onChange={handleChange}
                  name="mobile"
                  placeholder="Phone Number"
                  label="Phone Number"
                />
              </Grid>
            </Grid>
            <Grid item xs={4} position="relative">
              <BaseTextInput
                value={values.address}
                name="address"
                onChange={handleChange}
                label="Full Address"
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Notification */}
        <Grid item xs={12}>
          <Typography color="primary" fontWeight={500}>
            Notification
          </Typography>
        </Grid>
        <Grid container item xs={7} gap={2} flexWrap="nowrap">
          <Grid item xs={6}>
            <FormControlLabel
              label={<Typography fontWeight={400}>SMS</Typography>}
              control={
                <Switch
                  checked={values.notiFicationSms}
                  name="notiFicationSms"
                  onChange={handleChange}
                  size="small"
                />
              }
              labelPlacement="start"
              sx={{
                width: "100%",
                justifyContent: "space-between",
                mx: 0,
                bgcolor: "inputBackground.main",
                p: 1,
                borderRadius: 2,
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControlLabel
              label={<Typography fontWeight={400}>Email</Typography>}
              control={
                <Switch
                  checked={values.notificationEmail}
                  name="notificationEmail"
                  onChange={handleChange}
                  size="small"
                />
              }
              labelPlacement="start"
              sx={{
                width: "100%",
                justifyContent: "space-between",
                mx: 0,
                bgcolor: "inputBackground.main",
                p: 1,
                borderRadius: 2,
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography color="primary" fontWeight={500}>
            Status
          </Typography>
        </Grid>
        <Grid container item xs={7} gap={2} flexWrap="nowrap">
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={values.isOnlineBookable}
                  size="small"
                  name="isOnlineBookable"
                  onChange={handleChange}
                />
              }
              label={<Typography fontWeight={400}>Online Available</Typography>}
              labelPlacement="start"
              sx={{
                width: "100%",
                justifyContent: "space-between",
                mx: 0,
                bgcolor: "inputBackground.main",
                p: 1,
                borderRadius: 2,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              label={<Typography fontWeight={400}>Active</Typography>}
              control={
                <Switch
                  checked={values.isActive}
                  name="isActive"
                  onChange={handleChange}
                  size="small"
                />
              }
              labelPlacement="start"
              sx={{
                width: "100%",
                justifyContent: "space-between",
                mx: 0,
                bgcolor: "inputBackground.main",
                p: 1,
                borderRadius: 2,
              }}
            />
          </Grid>
        </Grid>
        {/* Calendar info */}
        <Grid item xs={12}>
          <Typography color="primary" fontWeight={500}>
            Calendar
          </Typography>
        </Grid>
        <Grid item xs={7} container gap={2} flexWrap="nowrap">
          {/* <Grid item xs={12} md={8}>
            <BaseSelect
              value={values.memberOfId}
              handleChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFieldValue("memberOfId", e.target.value)
              }
              name="memberOfId"
              sx={{ bgcolor: "grey.100" }}
              defaultVal="Select Service Category"
              label="service category"
            />
          </Grid> */}
          <Grid
            item
            xs={6}
            bgcolor="inputBackground.main"
            position="relative"
            borderRadius="5px"
            container
            alignItems="center"
            minHeight={45}
          >
            <Typography color="grey.500" pl={1}>
              color
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                gap: 1,
                bgcolor: "#fff",
                position: "absolute",
                top: "50%",
                right: 10,
                transform: "translateY(-50%)",
                borderRadius: "5px",
                p: 0.2,
                minWidth: 125,
                "#input-color::-webkit-color-swatch": {
                  border: "none",
                  borderRadius: "5px",
                  padding: 0,
                },
              }}
            >
              <Typography>{values.staffCalendarColor}</Typography>
              <input
                type="color"
                style={{
                  border: 0,
                  backgroundColor: "transparent",
                  width: 30,
                  height: 30,
                  borderRadius: 5,
                }}
                id="input-color"
                value={values.staffCalendarColor}
                onChange={handleChange}
                name="staffCalendarColor"
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </OneSectionLayout>
  );
};

export default CreateStaff;
