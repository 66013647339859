import { useRef, useEffect, useState, FC } from "react";
import { useSearchParams } from "react-router-dom";

// MUI
import { Grid, Typography, styled, Skeleton } from "@mui/material";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Navigation } from "swiper";
import { NavContainer } from "./Calendar.cards";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { useAppSelector } from "../../hooks/redux.hooks";

// icons
import { ReactComponent as LeftIcon } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as RightIcon } from "../../assets/icons/arrow-right.svg";
import { fetchOrderServiceAvailableData } from "../../redux/order/order.actions";
import { staffOrderTimesDummyData } from "../../helpers/data";

type IInsideSwiperProps = {
  uniqueTimes: IAvailableData[];
};

const InsideSwiper: FC<IInsideSwiperProps> = ({ uniqueTimes }) => {
  const swiper = useSwiper();
  const [searchParams, _] = useSearchParams();

  const timeSearchParam = searchParams.get("time");

  useEffect(() => {
    const indexOFActiveSlide = uniqueTimes
      .map((time) => {
        return (
          time.durationStart.split(":")[0] +
          "-" +
          time.durationStart.split(":")[1]
        );
      })
      .indexOf(timeSearchParam?.split("_")[0] as string);

    swiper.slideTo(indexOFActiveSlide || 0);
  }, [timeSearchParam, uniqueTimes]);

  return <div />;
};

type IAvailableData = {
  amount: number;
  durationEnd: string;
  durationStart: string;
  isAvailable: boolean;
  mobile: string;
  serviceId: number;
  staffFamil: string;
  staffId: number;
  staffName: string;
};

const OrderTimeCard = () => {
  //hooks
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams, setSearchParams] = useSearchParams();

  const bookingSearchParam = searchParams.get("bookingId");
  const customerSearchParam = searchParams.get("customerId");
  const customerNameSearchParam = searchParams.get("customer");
  const serviceCategorySearchParam = searchParams.get("serviceCategory");
  const serviceIdSearchParam = searchParams.get("serviceId");
  const serviceSearchParam = searchParams.get("service");
  const dateSearchParam = searchParams.get("date");
  const timeSearchParam = searchParams.get("time")?.split("_");

  const navigationPrev = useRef(null);
  const navigationNext = useRef(null);

  const { orderDate, orderAvailableData, isOrderAvailableDataFetching } =
    useAppSelector((state) => state.order);

  const searchDate = `${orderDate?.year}-${orderDate?.month}-${orderDate?.day}`;

  useEffect(() => {
    if (orderDate)
      dispatch(
        fetchOrderServiceAvailableData({
          searchDate,
          orderService: String(serviceIdSearchParam),
        })
      );
  }, [searchDate]);

  let nextSearchParams = {};

  if (bookingSearchParam) {
    nextSearchParams = {
      bookingId: bookingSearchParam,
      customerId: String(customerSearchParam),
      customer: String(customerNameSearchParam),
      serviceCategory: String(serviceCategorySearchParam),
      serviceId: String(serviceIdSearchParam),
      service: String(serviceSearchParam),
      date: String(dateSearchParam),
    };
  } else {
    nextSearchParams = {
      customerId: String(customerSearchParam),
      customer: String(customerNameSearchParam),
      serviceCategory: String(serviceCategorySearchParam),
      serviceId: String(serviceIdSearchParam),
      service: String(serviceSearchParam),
      date: String(dateSearchParam),
    };
  }

  const handleTimePicker = (date: { start: string; end: string }) => {
    // dispatch(setOrderTime(date));
    Object.assign(nextSearchParams, {
      time: `${date.start.split(":").join("-")}_${date.end
        .split(":")
        .join("-")}`,
      active: "2",
    });
    setSearchParams(nextSearchParams);
  };

  const uniqueTimes: IAvailableData[] = [
    //@ts-ignore
    ...new Map(
      orderAvailableData?.serviceFreeList?.map((item) => [
        item["durationStart"],
        item,
      ])
    ).values(),
  ];

  return (
    <Grid width="100%">
      <Typography fontSize={20} fontWeight={500} color="primary.main" mb={3}>
        Select Time
      </Typography>

      <Grid
        sx={{
          width: "100%",
          ".swiper-slide": {
            height: "85%",
            width: 100,
            overflow: "hidden",
            cursor: "pointer",
          },
          position: "relative",
        }}
      >
        <Swiper
          onInit={(swiper: any) => {
            swiper.params.navigation.prevEl = navigationPrev.current;
            swiper.params.navigation.nextEl = navigationNext.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          slidesPerView={3}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 10000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            0: {
              slidesPerView: 3,
              spaceBetween: 20,
              slidesPerGroup: 1,
            },
            1500: {
              slidesPerView: 5,
              spaceBetween: 20,
              slidesPerGroup: 3,
            },
          }}
          navigation={{
            prevEl: navigationPrev.current,
            nextEl: navigationNext.current,
          }}
          modules={[Navigation]}
          style={{
            width: "calc(100% - 80px)",
            padding: "0 4px",
          }}
        >
          <InsideSwiper uniqueTimes={uniqueTimes} />
          {isOrderAvailableDataFetching ? (
            staffOrderTimesDummyData.map((item, index) => (
              <SwiperSlide key={index}>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  height={50}
                  sx={{ borderRadius: 2 }}
                />
              </SwiperSlide>
            ))
          ) : uniqueTimes.length > 0 ? (
            uniqueTimes?.map((item, i) => {
              const startTime =
                item.durationStart.split(":")[0] +
                ":" +
                item.durationStart.split(":")[1];
              const endTime =
                item.durationEnd.split(":")[0] +
                ":" +
                item.durationEnd.split(":")[1];

              return (
                <SwiperSlide key={i}>
                  <Times
                    sx={{
                      bgcolor:
                        timeSearchParam &&
                        timeSearchParam[0].split("-").join(":") === startTime &&
                        timeSearchParam &&
                        timeSearchParam[1].split("-").join(":") === endTime
                          ? "primary.main"
                          : "white",
                      color:
                        timeSearchParam &&
                        timeSearchParam[0].split("-").join(":") === startTime &&
                        timeSearchParam &&
                        timeSearchParam[1].split("-").join(":") === endTime
                          ? "white"
                          : "initial",
                    }}
                    onClick={() => {
                      handleTimePicker({ start: startTime, end: endTime });
                    }}
                  >
                    {startTime} - {endTime}
                  </Times>
                </SwiperSlide>
              );
            })
          ) : (
            <SwiperSlide>
              <Grid
                bgcolor="white"
                height={50}
                container
                justifyContent="center"
                alignItems="center"
                borderRadius={2}
              >
                <Typography fontSize={18} fontWeight={500}>
                  No Data
                </Typography>
              </Grid>
            </SwiperSlide>
          )}
        </Swiper>
        <NavContainer sx={{ left: 0 }} ref={navigationPrev}>
          <LeftIcon />
        </NavContainer>
        <NavContainer sx={{ right: 0 }} ref={navigationNext}>
          <RightIcon />
        </NavContainer>
      </Grid>
    </Grid>
  );
};

export const Times = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    minWidth: 60,
    height: 50,
    color: "grey.800",
    borderRadius: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    fontSize: 16,
    fontWeight: 500,
  })
);

export default OrderTimeCard;
