import { FC, useState, useEffect, ChangeEvent } from "react";
import { DateObject } from "react-multi-date-picker";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";

// MUI
import { Grid, Typography } from "@mui/material";

import BaseSelect from "../base/BaseSelect";
import BaseDatePicker from "../base/BaseDatePicker";
import BaseButton from "../base/BaseButton";
import { fetchAllServices } from "../../redux/services/services.actions";
import { selectInputItemsFormatter } from "../../helpers/utils";
import { useAppSelector } from "../../hooks/redux.hooks";
import { fetchAllStaffs } from "../../redux/staffs/staff.actions";
import BaseCheckbox from "../base/BaseCheckbox";

type ListFilterPropTypes = {
  closePopover: () => void;
};

const AvailableListFilterCard: FC<ListFilterPropTypes> = ({ closePopover }) => {
  // hooks
  const dispatch = useDispatch<AppDispatch>();

  const { servicesData } = useAppSelector((state) => state.service);
  const { staffsData } = useAppSelector((state) => state.staff);

  // states
  const [searchParams, setSearchParams] = useSearchParams();
  //   const search = searchParams.get("displayName");

  const [filters, setFilters] = useState({
    staffId:
      searchParams.get("staffId") !== null ? searchParams.get("staffId") : 0,
    serviceId:
      searchParams.get("serviceId") !== null
        ? searchParams.get("serviceId")
        : 0,
    fromDate:
      searchParams.get("fromDate") !== null
        ? String(searchParams.get("fromDate"))
        : new DateObject().format(),
    toDate:
      searchParams.get("toDate") !== null
        ? String(searchParams.get("toDate"))
        : new DateObject().format(),
    staffEnabled: searchParams.get("staffId") !== null ? true : false,
    serviceEnabled: searchParams.get("serviceId") !== null ? true : false,
    fromDateEnabled: searchParams.get("fromDate") !== null ? true : false,
    toDateEnabled: searchParams.get("toDate") !== null ? true : false,
  });

  useEffect(() => {
    dispatch(fetchAllStaffs({}));
    dispatch(fetchAllServices({}));
  }, []);

  const staffInputItems = selectInputItemsFormatter(
    staffsData?.data,
    "staffName",
    "staffId"
  );

  const serviceInputItems = selectInputItemsFormatter(
    servicesData?.data,
    "serviceTitle",
    "id"
  );

  const filterHandler = () => {
    const nextSearchParams = {};

    if (filters.staffEnabled && filters.staffId)
      Object.assign(nextSearchParams, {
        staffId: String(filters.staffId),
      });
    if (filters.serviceEnabled && filters.serviceId)
      Object.assign(nextSearchParams, {
        serviceId: String(filters.serviceId),
      });
    if (filters.fromDateEnabled)
      Object.assign(nextSearchParams, {
        fromDate: String(filters.fromDate),
      });
    if (filters.toDateEnabled)
      Object.assign(nextSearchParams, {
        toDate: String(filters.toDate),
      });
    setSearchParams(nextSearchParams);

    closePopover();
  };

  const closePopoverHandler = () => {
    // if (search)
    //   setSearchParams({
    //     displayName: search,
    //   });
    // else setSearchParams();
    setSearchParams();
    closePopover();
  };

  return (
    <Grid
      p={2}
      width={290}
      border={2}
      borderColor="primary.main"
      borderRadius="5px"
      overflow="hidden"
      display="grid"
      gridTemplateColumns="1fr"
      gap={1}
    >
      <Grid item xs={12} container flexWrap="nowrap" gap={1}>
        <BaseCheckbox
          onChange={(e) =>
            setFilters({
              ...filters,
              staffEnabled: !filters.staffEnabled,
            })
          }
          checked={filters.staffEnabled}
        />
        <BaseSelect
          value={filters.staffId}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFilters({ ...filters, staffId: e.target.value });
          }}
          inputItems={staffInputItems}
          defaultVal="Select Staff"
          label="Staff"
        />
      </Grid>
      <Grid item xs={12} container flexWrap="nowrap" gap={1}>
        <BaseCheckbox
          onChange={(e) =>
            setFilters({
              ...filters,
              serviceEnabled: !filters.serviceEnabled,
            })
          }
          checked={filters.serviceEnabled}
        />
        <BaseSelect
          value={filters.serviceId}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFilters({ ...filters, serviceId: e.target.value });
          }}
          inputItems={serviceInputItems}
          defaultVal="Select Service"
          label="Service"
        />
      </Grid>
      <Grid container gap={1} flexWrap="nowrap" alignItems="end">
        <BaseCheckbox
          onChange={(e) =>
            setFilters({
              ...filters,
              fromDateEnabled: !filters.fromDateEnabled,
            })
          }
          checked={filters.fromDateEnabled}
        />
        <Grid>
          <Typography fontSize={14}>From</Typography>
          <BaseDatePicker
            portal={true}
            value={new DateObject(filters.fromDate as string)}
            setValue={(value: any) =>
              setFilters({
                ...filters,
                fromDate: new DateObject(value as string).format(),
              })
            }
            calendarPosition="bottom"
          />
        </Grid>
      </Grid>
      <Grid container gap={1} flexWrap="nowrap" alignItems="end">
        <BaseCheckbox
          onChange={(e) =>
            setFilters({
              ...filters,
              toDateEnabled: !filters.toDateEnabled,
            })
          }
          checked={filters.toDateEnabled}
        />
        <Grid>
          <Typography fontSize={14}>To</Typography>
          <BaseDatePicker
            portal={true}
            value={new DateObject(filters.toDate as string)}
            setValue={(value: any) =>
              setFilters({
                ...filters,
                toDate: new DateObject(value as string).format(),
              })
            }
            calendarPosition="bottom"
          />
        </Grid>
      </Grid>
      <BaseButton onClick={filterHandler} sx={{ mt: 3 }}>
        apply
      </BaseButton>

      <BaseButton
        variant="text"
        sx={{ border: 2 }}
        onClick={closePopoverHandler}
      >
        discard
      </BaseButton>
    </Grid>
  );
};

export default AvailableListFilterCard;
