import React, { FC, ChangeEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";

// MUI
import {
  Grid,
  Typography,
  Divider,
  IconButton,
  InputAdornment,
} from "@mui/material";

import OneSectionLayout from "../../layout/OneSection.layouts";
import BaseButton from "../../components/base/BaseButton";
import { useStaffServicesForm } from "../../helpers/formik";
import { useNavigate, useParams } from "react-router-dom";
import BaseInput from "../../components/base/BaseInput";
import BaseSelect from "../../components/base/BaseSelect";
import { selectInputItemsFormatter } from "../../helpers/utils";
import { useAppSelector } from "../../hooks/redux.hooks";
import { NumericFormatCustom } from "../../components/base/BaseMaskInput";
import {
  IPostStaffServiceParams,
  deleteStaffService,
  fetchStaffServiceById,
  postStaffService,
} from "../../redux/staffs/staff.actions";

// icons
import { ReactComponent as EditIcon } from "../../assets/icons/edit-icon.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash-icon.svg";
import { IEditActionsApiMethodType } from "../../redux/shifts/shift.actions";
import { RotatingLines } from "react-loader-spinner";
import { clearStaffServiceById } from "../../redux/staffs/staff.reducer";
import ConfirmModal from "../../components/modals/Confirm.modals";

type ServicesStaffPropsTypes = {};

const ServicesStaff: FC<ServicesStaffPropsTypes> = () => {
  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { staffId } = useParams();
  const [deleteId, setDeleteId] = useState(0);

  const {
    staffServiceById,
    staffById,
    isFetchingStaff,
    isFetchingStaffServiceById,
    isDeletingStaffService,
    isPostingStaffService,
  } = useAppSelector((state) => state.staff);

  const { user } = useAppSelector((state) => state.user);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  // handlers
  const handleOpenConfirmModal = () => setOpenConfirmModal(true);
  const handleCloseConfirmModal = () => setOpenConfirmModal(false);

  const submitHandler = () => {
    const body = staffServiceById
      ? {
          id: staffServiceById.id,
          amount: values.staffServiceAmount,
          duration: values.staffServiceDuration,
        }
      : {
          ...values,
          staffId: Number(staffId),
          shopId: user?.shopId,
          staffServiceAmount: Number(values.staffServiceAmount),
          staffServiceDuration: Number(values.staffServiceDuration),
        };
    const parameters: IPostStaffServiceParams = {
      body,
      fetchParams: { staffId: Number(staffId) },
      type: staffServiceById
        ? IEditActionsApiMethodType.PATCH
        : IEditActionsApiMethodType.POST,
      resetForm: resetForm,
    };

    dispatch(postStaffService(parameters));
  };

  const { values, handleSubmit, setFieldValue, resetForm } =
    useStaffServicesForm(staffServiceById, submitHandler);

  const serviceInputItems = selectInputItemsFormatter(
    staffById?.serviceList,
    "serviceTitle",
    "id"
  );

  const fetchStaffService = (staffServiceId: number) => {
    dispatch(fetchStaffServiceById({ staffServiceId }));
  };

  const deleteStaffServiceHandler = (staffServiceId: number) => {
    dispatch(
      deleteStaffService({
        staffServiceId,
        fetchParams: { staffId: Number(staffId) },
        closeModalHandler: handleCloseConfirmModal,
      })
    );
  };

  const selectedService = staffById?.serviceList.filter(
    (item) => item.id === values.serviceId
  )[0];

  useEffect(() => {
    selectedService &&
      setFieldValue("staffServiceAmount", selectedService?.amount);
    selectedService &&
      setFieldValue("staffServiceDuration", selectedService?.duration);
  }, [selectedService]);

  return (
    <OneSectionLayout>
      <Grid
        container
        item
        xs={12}
        zIndex={1}
        position="relative"
        top={-16}
        justifyContent="space-between"
      >
        <Typography
          height={70}
          display="flex"
          alignItems="center"
          color="grey.100"
        >
          Staff Services
        </Typography>
        <Grid
          item
          container
          xs={12}
          md={8}
          gap={2}
          alignItems="center"
          justifyContent="end"
          position="unset"
          bottom={-5}
          left={0}
          bgcolor="transparent"
          p={0}
          zIndex={10}
        >
          <BaseButton
            sx={{
              width: 150,
              bgcolor: "white",
              color: "primary.main",
              "&:hover": {
                bgcolor: "white",
              },
              border: 0,
              fontWeight: 500,
              height: 45,
            }}
            onClick={() => handleSubmit()}
            disabled={isPostingStaffService || isFetchingStaff}
          >
            {isPostingStaffService || isFetchingStaff ? (
              <Grid
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  zIndex: 10,
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="30"
                  visible
                />
              </Grid>
            ) : (
              "Save"
            )}
          </BaseButton>

          <BaseButton
            sx={{
              width: 150,
              border: 0,
              display: "flex",
              boxShadow: 0,
            }}
            onClick={() => navigate("/staffs")}
          >
            Discard
          </BaseButton>
        </Grid>
      </Grid>

      <Grid container gap={2} mb={8} flexWrap="nowrap">
        <Grid
          item
          xs={3}
          gap={2}
          display="grid"
          gridTemplateColumns="1fr"
          height="fit-content"
          position="relative"
        >
          {isFetchingStaffServiceById && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              position="absolute"
              zIndex={1000}
              bgcolor="rgba(0,0,0, 0.05)"
              borderRadius={1}
              height="100%"
            >
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
              />
            </Grid>
          )}

          <BaseSelect
            value={values.serviceId}
            onChange={(e: any) => setFieldValue("serviceId", e.target.value)}
            name="serviceId"
            sx={{ bgcolor: "grey.100" }}
            defaultVal="select Service"
            inputItems={serviceInputItems}
            label="Service"
            isFetching={isFetchingStaff}
          />

          <BaseInput
            value={
              staffServiceById
                ? staffServiceById.serviceAmount
                : (selectedService?.amount as number) || 0
            }
            sx={{ border: 0 }}
            InputProps={{
              inputComponent: NumericFormatCustom as any,
            }}
            variant="outlined"
            label="Service Amount"
            disabled
          />

          <BaseInput
            value={
              staffServiceById
                ? staffServiceById.serviceDuration
                : (selectedService?.duration as number) || 0
            }
            variant="outlined"
            label="Service Duration"
            disabled
            sx={{
              ".MuiInputAdornment-root p": {
                opacity: 0.5,
                zIndex: 100,
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">Minute</InputAdornment>
              ),
            }}
          />

          <BaseInput
            value={values.staffServiceAmount}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFieldValue("staffServiceAmount", Number(e.target.value));
            }}
            name="staffServiceAmount"
            sx={{ border: 0 }}
            InputProps={{
              inputComponent: NumericFormatCustom as any,
            }}
            variant="outlined"
            label="Staff Amount"
          />
          <BaseInput
            value={values.staffServiceDuration}
            name="staffServiceDuration"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFieldValue("staffServiceDuration", Number(e.target.value));
            }}
            variant="outlined"
            label="Staff Duration"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ zIndex: 100 }}>
                  Minute
                </InputAdornment>
              ),
            }}
          />
          <BaseButton
            variant="text"
            onClick={() => {
              dispatch(clearStaffServiceById());
              resetForm();
            }}
          >
            Reset
          </BaseButton>
        </Grid>

        <Grid
          item
          xs={9}
          container
          flexDirection="column"
          rowGap={1}
          position="relative"
        >
          {(isDeletingStaffService || isFetchingStaff) && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              position="absolute"
              zIndex={1000}
              bgcolor="rgba(0,0,0, 0.05)"
              borderRadius={1}
              height="100%"
            >
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
              />
            </Grid>
          )}
          {staffById?.staffServiceList?.map((item, i) => (
            <React.Fragment key={item.id}>
              {i === 0 && (
                <Grid
                  display="grid"
                  gridTemplateColumns="60px 150px repeat(5,1fr)"
                  gridRow="1fr 1fr"
                  gap={1}
                >
                  <Typography color="primary.main" fontSize={18}></Typography>
                  <Grid
                    fontSize={18}
                    gridColumn="2/5"
                    textAlign="center"
                    fontWeight={500}
                  >
                    Service
                    <Divider sx={{ borderWidth: 1, borderColor: "grey.600" }} />
                  </Grid>
                  <Grid
                    fontSize={18}
                    gridColumn="5/7"
                    textAlign="center"
                    fontWeight={500}
                  >
                    Staff
                    <Divider sx={{ borderWidth: 1, borderColor: "grey.600" }} />
                  </Grid>
                  <Typography color="primary.main" fontSize={18}></Typography>

                  <Typography color="primary.main" fontSize={18}>
                    #
                  </Typography>
                  <Typography fontSize={16} fontWeight={500}>
                    Title
                  </Typography>
                  <Typography fontSize={16} fontWeight={500}>
                    Amount
                  </Typography>
                  <Typography fontSize={16} fontWeight={500}>
                    Duration
                  </Typography>
                  <Typography fontSize={16} fontWeight={500}>
                    Amount
                  </Typography>
                  <Typography fontSize={16} fontWeight={500}>
                    Duration
                  </Typography>
                  <Typography fontSize={16} fontWeight={500} textAlign="end">
                    Actions
                  </Typography>
                </Grid>
              )}

              <Grid
                display="grid"
                gridTemplateColumns="60px 150px repeat(5,1fr)"
                gap={1}
              >
                <Typography color="primary.main" fontSize={16}>
                  {item.id}
                </Typography>
                <Typography
                  fontSize={16}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {item.serviceTitle}
                </Typography>
                <Typography fontSize={16}>
                  &#163; {item.serviceAmount}
                </Typography>
                <Typography fontSize={16}>
                  {item.serviceDuration} min
                </Typography>
                <Typography fontSize={16}>
                  &#163; {item.staffServiceAmount}
                </Typography>
                <Typography fontSize={16}>
                  {item.staffServiceDuration} min
                </Typography>

                <Grid item container flexWrap="nowrap" justifyContent="end">
                  <IconButton onClick={() => fetchStaffService(item.id)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      handleOpenConfirmModal();
                      setDeleteId(item.id);
                    }}
                  >
                    <TrashIcon />
                  </IconButton>
                </Grid>
              </Grid>
              {i < staffById?.staffServiceList?.length - 1 && (
                <Divider sx={{ borderColor: "grey.300", width: "100%" }} />
              )}
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
      <ConfirmModal
        isDeleting={isDeletingStaffService}
        open={openConfirmModal}
        handleClose={handleCloseConfirmModal}
        action={() => deleteStaffServiceHandler(deleteId)}
      />
    </OneSectionLayout>
  );
};

export default ServicesStaff;
