// MUI
import { Grid } from "@mui/material";

// icons
import { ReactComponent as OfflineIcon } from "../../assets/icons/offline2-icon.svg";

const Offline = () => {
  return (
    <Grid
      container
      justifyContent="center"
      height="60vh"
      sx={{ svg: { width: 108, height: 108 } }}
    >
      <OfflineIcon />
    </Grid>
  );
};

export default Offline;
