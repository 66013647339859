import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../hooks/redux.hooks";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { DateObject } from "react-multi-date-picker";

// MUI
import { Skeleton, Typography, Box, IconButton } from "@mui/material";
import type { ColumnsType } from "antd/es/table";
import Table from "antd/es/table";

import ThreeSectionLayout from "../../../layout/ThreeSection.layouts";
import { IPagination } from "../../staffs/List.staffs";
import {
  IBookingDetailData,
  IBookingDetailFetchAllParams,
  fetchAllBookingDetail,
} from "../../../redux/booking/booking.actions";

// icon
import { ReactComponent as EditIcon } from "../../../assets/icons/edit-icon.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash-icon.svg";
import { ReactComponent as BookingDetailIcon } from "../../../assets/icons/expertise-icon.svg";

import Paging from "../../../components/table/Paging";
import BookingDetailListHeader from "../../../components/headers/BookingDetailList.headers";

const ListBookingDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { bookingId } = useParams();

  const { bookingDetailData, isFetchingBookingDetail } = useAppSelector(
    (state) => state.booking
  );

  function getTableParentHeight() {
    const tableParentElement =
      document.getElementById("tableParent")?.clientHeight;

    return tableParentElement;
  }
  const [pagination, setPagination] = useState<IPagination>({
    pageNumber: 1,
    pageSize: 10,
  });

  const [tableParentHeight, setTableParentHeight] = useState(
    getTableParentHeight()
  );

  const parameters: IBookingDetailFetchAllParams = {
    pageNumber: pagination.pageNumber,
    pageSize: pagination.pageSize,
    bookingId: Number(bookingId),
  };

  const columns: ColumnsType<IBookingDetailData> = [
    {
      title: "ID",
      dataIndex: "id",
      width: 60,
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      width: 150,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      width: 150,
      // sorter: (a, b) => a.age - b.age,
    },
    {
      title: "Date",
      dataIndex: "bookingRegDate",
      width: 150,
      render(value, record, index) {
        return (
          <Typography fontSize={16}>
            {new DateObject(record.bookingDetailStartDate).format()}
          </Typography>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 100,
      render(value, record, index) {
        return (
          <Typography
            color={record.bookingDetailStatus ? "success.main" : "error.main"}
            fontSize={16}
            fontWeight={500}
            bgcolor={
              record.bookingDetailStatus ? "success.light" : "error.light"
            }
            py={0.5}
            px={1}
            textAlign="center"
            borderRadius={2}
            width={100}
          >
            {record.bookingDetailStatus ? "Active" : "InActive"}
          </Typography>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: 100,
      render: (value, record) => (
        <Box display="flex" justifyContent="end">
          <IconButton
            sx={{
              svg: {
                width: 26,
                height: 26,
              },
              "& svg path": {
                fill: "#785FDC",
              },
            }}
            onClick={() => navigate(`${record.id}/booking-detail`)}
          >
            <BookingDetailIcon />
          </IconButton>
          <IconButton
            sx={{
              svg: {
                width: 26,
                height: 26,
              },
            }}
            onClick={() => navigate(`edit-booking/${record.id}`)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            sx={{
              svg: {
                width: 26,
                height: 26,
              },
            }}
            // onClick={() =>
            //   dispatch(
            //     deleteBooking({
            //       bookingId: record.id,
            //       fetchParams: { ...parameters },
            //     })
            //   )
            // }
          >
            <TrashIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchAllBookingDetail(parameters));
    setTableParentHeight(getTableParentHeight());
  }, [pagination]);

  useEffect(() => {
    const windowResizeEventCallback = (e: any) => {
      const tableParentElement = getTableParentHeight();
      setTableParentHeight(tableParentElement);
    };
    window?.addEventListener("resize", windowResizeEventCallback);
    return () =>
      window.removeEventListener("resize", windowResizeEventCallback);
  }, []);

  return (
    <ThreeSectionLayout
      // header={<BookingDetailListHeader />}
      paging={
        <Paging
          data={bookingDetailData}
          pagination={pagination}
          setPagination={setPagination}
        />
      }
      bgcolor="inputBackground.main"
    >
      {tableParentHeight && (
        <Table
          columns={columns}
          dataSource={bookingDetailData?.data}
          pagination={false}
          scroll={
            (bookingDetailData?.data.length as number) > 0
              ? { x: 800, y: (tableParentHeight as number) - 70 }
              : { x: undefined, y: undefined }
          }
          className="shiftTable"
          rowKey="id"
        />
      )}
    </ThreeSectionLayout>
  );
};

export default ListBookingDetail;
