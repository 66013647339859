import { useState, useEffect, FC } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { useAppSelector } from "../../hooks/redux.hooks";
import { IPagination } from "../staffs/List.staffs";
import type { ColumnsType } from "antd/es/table";
import { DateObject } from "react-multi-date-picker";

// MUI
import { Box, IconButton } from "@mui/material";

// components
import ThreeSectionLayout from "../../layout/ThreeSection.layouts";
import AvailabilityListHeader from "../../components/headers/AvailabilityList.headers";
import { Table } from "antd";
import Paging from "../../components/table/Paging";

import {
  AvailabilityDataType,
  deleteAvailability,
  fetchAllAvailability,
} from "../../redux/availability/availability.actions";

// icons
import { ReactComponent as EditIcon } from "../../assets/icons/edit-icon.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash-icon.svg";
import ConfirmModal from "../../components/modals/Confirm.modals";

type IListAvailabilityProps = {
  pagination: IPagination;
  setPagination: any;
};

const ListAvailability: FC<IListAvailabilityProps> = ({
  pagination,
  setPagination,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams, setSearchParams] = useSearchParams();
  const staffId = searchParams.get("staffId");
  const serviceId = searchParams.get("serviceId");
  const fromDate = searchParams.get("fromDate");
  const toDate = searchParams.get("toDate");

  const { availabilityData, isFetchingAvailability, isDeletingAvailability } =
    useAppSelector((state) => state.availability);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);

  // handlers
  const handleOpenConfirmModal = () => setOpenConfirmModal(true);
  const handleCloseConfirmModal = () => setOpenConfirmModal(false);

  function getTableParentHeight() {
    const tableParentElement =
      document.getElementById("tableParent")?.clientHeight;

    return tableParentElement;
  }

  const [tableParentHeight, setTableParentHeight] = useState(
    getTableParentHeight()
  );

  const parameters = {
    pageNumber: pagination.pageNumber,
    pageSize: pagination.pageSize,
    hasPagination: true,
    staffId,
    serviceId,
    fromDate,
    toDate,
  };

  const columns: ColumnsType<AvailabilityDataType> = [
    {
      title: "ID",
      dataIndex: "id",
      width: 60,
    },
    {
      title: "Service",
      dataIndex: "serviceTitle",
      width: 260,
    },
    {
      title: "Staff",
      dataIndex: "displayName",
      width: 250,
      ellipsis: true,
    },
    {
      title: "Date",
      dataIndex: "availableDate",
      render: (value, record) => (
        <Box>{new DateObject(record.availableDate).format()}</Box>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (value, record) => (
        <Box display="flex" justifyContent="end">
          <IconButton
            sx={{
              svg: {
                width: 26,
                height: 26,
              },
            }}
            onClick={() =>
              navigate(`/availability/edit-availability/${record.id}`)
            }
          >
            <EditIcon />
          </IconButton>
          <IconButton
            sx={{
              svg: {
                width: 26,
                height: 26,
              },
            }}
            onClick={() => {
              handleOpenConfirmModal();
              setDeleteId(record.id);
            }}
          >
            <TrashIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchAllAvailability(parameters));
    setTableParentHeight(getTableParentHeight());
  }, [
    pagination.pageNumber,
    pagination.pageSize,
    staffId,
    serviceId,
    fromDate,
    toDate,
  ]);

  useEffect(() => {
    const windowResizeEventCallback = (e: any) => {
      const tableParentElement = getTableParentHeight();
      setTableParentHeight(tableParentElement);
    };
    window?.addEventListener("resize", windowResizeEventCallback);
    return () =>
      window.removeEventListener("resize", windowResizeEventCallback);
  }, []);

  return (
    <ThreeSectionLayout
      header={<AvailabilityListHeader />}
      paging={
        <Paging
          pagination={pagination}
          setPagination={setPagination}
          data={availabilityData}
        />
      }
    >
      {tableParentHeight && (
        <Table
          loading={isFetchingAvailability}
          columns={columns}
          dataSource={availabilityData?.data}
          pagination={false}
          scroll={
            (availabilityData?.data.length as number) > 0
              ? { x: 1000, y: (tableParentHeight as number) - 70 }
              : { x: undefined, y: undefined }
          }
          className="shiftTable"
          rowKey="id"
        />
      )}
      <ConfirmModal
        isDeleting={isDeletingAvailability}
        open={openConfirmModal}
        handleClose={handleCloseConfirmModal}
        action={() =>
          dispatch(
            deleteAvailability({
              availabilityId: deleteId,
              fetchParams: parameters,
              closeModalHandler: handleCloseConfirmModal,
            })
          )
        }
      />
    </ThreeSectionLayout>
  );
};

export default ListAvailability;
