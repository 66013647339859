// MUI
import { Grid } from "@mui/material";

// icons
import { ReactComponent as BrokenIcon } from "../../assets/icons/broken-page.svg";

const BrokenError = () => {
  return (
    <Grid
      container
      alignItems="start"
      justifyContent="center"
      height="60vh"
      sx={{ svg: { width: 200, height: 200 } }}
    >
      <BrokenIcon />
    </Grid>
  );
};

export default BrokenError;
