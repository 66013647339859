import { lazy, useState } from "react";
import { Route, Routes } from "react-router-dom";
import CreateSupplementary from "./Create.supplementary";
import SubField from "./subField/SubField";
import { IPagination } from "../../staffs/List.staffs";
import PageNotFound from "../../errors/PageNotFound.error";
import { useAppSelector } from "../../../hooks/redux.hooks";

const LazyList = lazy(() => import("./List.supplementary"));

const Supplementary = () => {
  const [pagination, setPagination] = useState<IPagination>({
    pageNumber: 1,
    pageSize: 10,
  });
  const { supplementaryError } = useAppSelector((state) => state.service);

  return (
    <Routes>
      <Route
        element={
          <LazyList pagination={pagination} setPagination={setPagination} />
        }
        path="/"
      />
      <Route element={<CreateSupplementary />} path="/create-supplementary" />
      <Route
        element={
          supplementaryError === 404 ? (
            <PageNotFound />
          ) : (
            <CreateSupplementary />
          )
        }
        path="/edit-supplementary/:supplementaryId"
      />
      <Route element={<SubField />} path=":supplementaryId/sub-field/*" />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Supplementary;
