import { createSlice } from "@reduxjs/toolkit";
import {
  IShiftType,
  deleteShift,
  fetchAllShifts,
  postShift,
  postShiftDetails,
} from "./shift.actions";

type IState = {
  shiftsData: IShiftType | null;
  isFetchingShift: boolean;
  shiftError: null | string | any;
  isPostingShift: boolean;
  isDeletingShift: boolean;
  isPostingShiftDetail: boolean;
  postShiftDetailError: null | string | any;
};

const initialState: IState = {
  shiftsData: null,
  isFetchingShift: false,
  shiftError: null,
  isPostingShift: false,
  isDeletingShift: false,
  isPostingShiftDetail: false,
  postShiftDetailError: null,
};

const shiftSlice = createSlice({
  initialState,
  name: "shift",
  reducers: {
    setShiftError: (state, action) => {
      state.shiftError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch all shifts
      .addCase(fetchAllShifts.pending, (state) => {
        state.isFetchingShift = true;
      })
      .addCase(fetchAllShifts.fulfilled, (state, action) => {
        state.isFetchingShift = false;
        state.shiftsData = action.payload;
      })
      .addCase(fetchAllShifts.rejected, (state, action) => {
        state.isFetchingShift = false;
        state.shiftError = action.payload;
      })

      // post shift
      .addCase(postShift.pending, (state) => {
        state.isPostingShift = true;
      })
      .addCase(postShift.fulfilled, (state) => {
        state.isPostingShift = false;
      })
      .addCase(postShift.rejected, (state, action) => {
        state.isPostingShift = false;
        state.shiftError = action.payload;
      })

      // post shift details
      .addCase(postShiftDetails.pending, (state) => {
        state.isPostingShiftDetail = true;
      })
      .addCase(postShiftDetails.fulfilled, (state) => {
        state.isPostingShiftDetail = false;
      })
      .addCase(postShiftDetails.rejected, (state, action) => {
        state.isPostingShiftDetail = false;
        state.postShiftDetailError = action.payload;
      })

      // delete shift
      .addCase(deleteShift.pending, (state) => {
        state.isDeletingShift = true;
      })
      .addCase(deleteShift.fulfilled, (state) => {
        state.isDeletingShift = false;
      })
      .addCase(deleteShift.rejected, (state, action) => {
        state.isDeletingShift = false;
        state.shiftError = action.payload;
      });
  },
});

export const { setShiftError } = shiftSlice.actions;
export default shiftSlice.reducer;
