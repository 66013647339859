import React, { FC, useEffect } from "react";
import { TransitionGroup } from "react-transition-group";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";

// MUI
import {
  Grid,
  Checkbox,
  Collapse,
  IconButton,
  Divider,
  CircularProgress,
} from "@mui/material";

import TimePickerInput from "../base/BaseTimePicker";
import BaseButton from "../base/BaseButton";
import { useShiftHoursForm } from "../../helpers/formik";

// icons
import { ReactComponent as AddIcon } from "../../assets/icons/add-icon.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/check-icon.svg";

import { postShiftDetails } from "../../redux/shifts/shift.actions";
import { useAppSelector } from "../../hooks/redux.hooks";
import { dayFunc, groupBy } from "../../helpers/utils";

export type IHoursType = {
  id: number;
  startTime: string;
  endTime: string;
  dayOfWeek: number;
};

export type IDayType = {
  id: number;
  day: string;
  name: string;
  hours: IHoursType[] | [];
};

interface ShiftHoursCardPropsType {
  shiftId: number | string;
  setExpandedShift?: any;
  shiftDetail?: Object;
}

export const weekDays: IDayType[] = [
  { id: 1, day: "Sunday", name: "sun", hours: [] },
  { id: 2, day: "Monday", name: "mon", hours: [] },
  { id: 3, day: "Tuesday", name: "tue", hours: [] },
  { id: 4, day: "Wednesday", name: "wed", hours: [] },
  { id: 5, day: "Thursday", name: "thu", hours: [] },
  { id: 6, day: "Friday", name: "fri", hours: [] },
  { id: 7, day: "Saturday", name: "sat", hours: [] },
];

const ShiftHoursCard: FC<ShiftHoursCardPropsType> = ({
  shiftId,
  setExpandedShift,
  shiftDetail,
}) => {
  // hooks
  const dispatch = useDispatch<AppDispatch>();
  const { isPostingShiftDetail } = useAppSelector((state) => state.shift);
  const { user } = useAppSelector((state) => state.user);

  // states
  const [checkedDays, setCheckedDays] = React.useState<string[]>([]);
  const [weekData, setWeekData] = React.useState<IDayType[]>(weekDays);

  const checkDayHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setCheckedDays([...checkedDays, e.target.value]);
    } else {
      const newCheckedDay = checkedDays.filter((el) => el !== e.target.value);
      setCheckedDays(newCheckedDay);
    }
  };

  const addShiftHour = (shift: any) => {
    const newWeekData = weekData.map((day) => {
      const numberOfRows = day.hours.length;
      let nextRowId = 0;

      // Find next row id
      if (numberOfRows !== 0) {
        const lastElementOfDay = day.hours[day.hours.length - 1];
        const keysOfLastElement = Object.keys(lastElementOfDay);
        let idOfLastElementInput = null;

        keysOfLastElement.forEach((key) => {
          const splitName = key.split("-");
          if (splitName.length === 3) {
            idOfLastElementInput = Number(splitName[2]);
          }
        });

        if (typeof idOfLastElementInput === "number") {
          nextRowId = idOfLastElementInput + 1;
        }
      }

      // Generate new row
      if (day.id === shift.id) {
        const startInputName = `${day.name}-s-${nextRowId}`;
        const endInputName = `${day.name}-e-${nextRowId}`;

        //@ts-ignore
        day.hours.push({
          id: nextRowId,
          [startInputName]: "",
          [endInputName]: "",
          dayOfWeek: day.id,
        });
        formik.setFieldValue(startInputName, "");
        formik.setFieldValue(endInputName, "");
      }

      return day;
    });

    setWeekData(newWeekData);
  };

  const deleteShiftHour = (
    selectedDay: any,
    selectedShift: any,
    nameStart: any,
    nameEnd: any
  ) => {
    const existingItem = weekData.find((el) => el.id === selectedDay.id);

    if (existingItem) {
      const newWeekDays = weekData.map((day) =>
        day.id === selectedDay.id
          ? {
              ...day,
              hours: day.hours.filter((hour) => hour.id !== selectedShift.id),
            }
          : day
      );
      //@ts-ignore
      delete formik.values[nameStart];
      //@ts-ignore
      delete formik.values[nameEnd];
      setWeekData(newWeekDays);
    }
  };

  const submit = () => {
    const listShiftHours = [];

    for (const name in formik.values) {
      const splitName = name.split("-");
      if (splitName.length === 3 && splitName[1] === "s") {
        listShiftHours.push({
          startTime: formik.values[name],
          endTime: formik.values[`${splitName[0]}-e-${splitName[2]}`],
          dayOfWeek: weekData?.find(({ name }) => name === splitName[0])?.id,
        });
      }
    }

    const body = {
      lstShiftDetailHour: listShiftHours,
      shiftId: Number(shiftId),
      shopId: user?.shopId,
    };

    formik.isValid &&
      dispatch(postShiftDetails({ body, setWeekData, setExpandedShift }));
  };

  const formik = useShiftHoursForm(undefined, submit);

  const selectedShiftDetailData = () => {
    const groupedShiftHours = groupBy(shiftDetail, "dayOfWeek");

    const newShiftHoursMap = weekDays.map((day, idx) => {
      return {
        ...day,
        hours:
          groupedShiftHours[idx + 1]?.map((shift: any, index: number) => {
            const startTimeName = `${dayFunc(shift.dayOfWeek)}-s-${index}`;
            const endTimeName = `${dayFunc(shift.dayOfWeek)}-e-${index}`;

            //@ts-ignore
            formik.setFieldValue([startTimeName], shift.shiftStartTime);
            //@ts-ignore
            formik.setFieldValue([endTimeName], shift.shiftEndTime);
            return {
              ...shift,
              [startTimeName]: shift.shiftStartTime,
              [endTimeName]: shift.shiftEndTime,
            };
          }) ?? [],
      };
    });

    // mapping new fetched shift details
    setWeekData(newShiftHoursMap);

    // checkBox manage section
    const newCheckedDay = newShiftHoursMap.map((item) =>
      item.hours.length > 0 ? item.name : false
    );

    //@ts-ignore
    setCheckedDays([...new Set(newCheckedDay)]);
  };

  useEffect(() => {
    shiftDetail && selectedShiftDetailData();
  }, []);

  return (
    <Grid
      gap={2}
      sx={{
        display: "grid",
        gridTemplateColumns: { md: "1fr 1fr", xl: "repeat(3,1fr)" },
      }}
    >
      {weekData.map((day, idx) => {
        return (
          <Grid
            container
            justifyContent="space-between"
            alignItems="start"
            bgcolor="white"
            borderRadius="10px"
            height="max-content"
            key={day.id}
          >
            <Grid item xs={2.25} container alignItems="center">
              <Checkbox
                onChange={checkDayHandler}
                checked={checkedDays.indexOf(day.name) > -1}
                value={day.name}
              />
              {day.name}
            </Grid>
            <Grid item xs={8.75}>
              <TransitionGroup>
                {day.hours.map((hour: any, index) => {
                  let nameStart = null;
                  let nameEnd = null;
                  for (const key in hour) {
                    const splitKey = key.split("-");
                    if (splitKey.length > 2) {
                      if (splitKey[1] === "s") nameStart = key;
                      else nameEnd = key;
                    }
                  }

                  return (
                    <Collapse key={hour.id}>
                      <TimePickerInput
                        disabled={checkedDays.indexOf(day.name) < 0}
                        hour={hour}
                        nameStart={nameStart as string}
                        nameEnd={nameEnd as string}
                        formik={formik}
                        day={day}
                        handleDelete={deleteShiftHour}
                      />
                      {index < day.hours.length - 1 && (
                        <Divider
                          sx={{ borderColor: "grey.200", width: "98%" }}
                        />
                      )}
                    </Collapse>
                  );
                })}
              </TransitionGroup>
            </Grid>
            <Grid item container xs={1} justifyContent="center" gap={2}>
              <IconButton
                onClick={() => addShiftHour(day)}
                sx={{ svg: { width: 24, height: 24 } }}
              >
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
        );
      })}
      <Grid item container>
        <Grid item position="relative">
          <BaseButton
            onClick={() => {
              submit();
            }}
            startIcon={<CheckIcon />}
            variant={isPostingShiftDetail ? "outlined" : "contained"}
            sx={{
              svg: {
                width: 24,
                height: 24,
                "& path": {
                  fill: isPostingShiftDetail ? "#785FDC" : "white",
                },
              },
              zIndex: 10,
              color: isPostingShiftDetail ? "primary.main" : "white",
              width: 150,
            }}
          >
            Save
          </BaseButton>
          {isPostingShiftDetail && (
            <CircularProgress
              size={35}
              sx={{
                position: "absolute",
                left: 5,
                top: 5,
                zIndex: 10,
              }}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ShiftHoursCard;
