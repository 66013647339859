import React, { FC, useState, useEffect } from "react";
import { TransitionGroup } from "react-transition-group";
import { FormikProps } from "formik";
import { useParams } from "react-router-dom";
import axiosConfig from "../../axiosConfig";
import { useAppSelector } from "../../hooks/redux.hooks";

// MUI
import { Grid, Checkbox, Collapse, IconButton, Divider } from "@mui/material";
import TimePickerInput from "../base/BaseTimePicker";
import { IStaffHoursFormTypes } from "../../helpers/formik";

// icons
import { ReactComponent as AddIcon } from "../../assets/icons/add-icon.svg";
import { dayFunc, groupBy } from "../../helpers/utils";

export type IHoursType = {
  id: number;
  startTime: string;
  endTime: string;
  dayOfWeek: number;
};

export type IDayType = {
  id: number;
  day: string;
  name: string;
  hours: IHoursType[] | [];
};

interface StaffHoursCardPropsType {
  formik: FormikProps<IStaffHoursFormTypes>;
  shiftDetail?: Object;
  isFetchingShiftDetail: boolean;
  weekData: IDayType[];
  setWeekData: any;
  weekDays: IDayType[];
  shiftId: number;
}

const StaffHoursCard: FC<StaffHoursCardPropsType> = ({
  shiftDetail,
  formik,
  weekData,
  setWeekData,
  weekDays,
  shiftId,
}) => {
  // hooks
  const { staffById } = useAppSelector((state) => state.staff);

  // states
  const [checkedDays, setCheckedDays] = useState<string[]>([]);

  const checkDayHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setCheckedDays([...checkedDays, e.target.value]);
    } else {
      const newCheckedDay = checkedDays.filter((el) => el !== e.target.value);
      setCheckedDays(newCheckedDay);
    }
  };

  const addShiftHour = (shift: any) => {
    const newWeekData = weekData.map((day) => {
      const numberOfRows = day.hours.length;
      let nextRowId = 0;

      // Find next row id
      if (numberOfRows !== 0) {
        const lastElementOfDay = day.hours[day.hours.length - 1];
        const keysOfLastElement = Object.keys(lastElementOfDay);
        let idOfLastElementInput = null;

        keysOfLastElement.forEach((key) => {
          const splitName = key.split("-");
          if (splitName.length === 3) {
            idOfLastElementInput = Number(splitName[2]);
          }
        });

        if (typeof idOfLastElementInput === "number") {
          nextRowId = idOfLastElementInput + 1;
        }
      }

      // Generate new row
      if (day.id === shift.id) {
        const startInputName = `${day.name}-s-${nextRowId}`;
        const endInputName = `${day.name}-e-${nextRowId}`;

        //@ts-ignore
        day.hours.push({
          id: nextRowId,
          [startInputName]: "",
          [endInputName]: "",
          dayOfWeek: day.id,
        });
        formik.setFieldValue(startInputName, "");
        formik.setFieldValue(endInputName, "");
      }

      return day;
    });

    setWeekData(newWeekData);
  };

  const deleteShiftHour = (
    selectedDay: any,
    selectedShift: any,
    nameStart: any,
    nameEnd: any
  ) => {
    const existingItem = weekData.find((el) => el.id === selectedDay.id);

    if (existingItem) {
      const newWeekDays = weekData.map((day) =>
        day.id === selectedDay.id
          ? {
              ...day,
              hours: day.hours.filter((hour) => hour.id !== selectedShift.id),
            }
          : day
      );
      //@ts-ignore
      delete formik.values[nameStart];
      //@ts-ignore
      delete formik.values[nameEnd];
      setWeekData(newWeekDays);
    }
  };

  const selectedShiftDetailData = () => {
    const groupedShiftHours = groupBy(shiftDetail, "dayOfWeek");

    const newShiftHoursMap = weekDays.map((day, idx) => {
      return {
        ...day,
        hours:
          groupedShiftHours[idx + 1]?.map((shift: any, index: number) => {
            const startTimeName = `${dayFunc(shift.dayOfWeek)}-s-${index}`;
            const endTimeName = `${dayFunc(shift.dayOfWeek)}-e-${index}`;

            //@ts-ignore
            formik.setFieldValue([startTimeName], shift.shiftStartTime);
            //@ts-ignore
            formik.setFieldValue([endTimeName], shift.shiftEndTime);
            return {
              ...shift,
              [startTimeName]: shift.shiftStartTime,
              [endTimeName]: shift.shiftEndTime,
            };
          }) ?? [],
      };
    });

    // mapping new fetched shift details
    setWeekData(newShiftHoursMap);

    // checkBox manage section
    const newCheckedDay = newShiftHoursMap.map((item) =>
      item.hours.length > 0 ? item.name : false
    );

    //@ts-ignore
    setCheckedDays([...new Set(newCheckedDay)]);
  };

  const selectedStaffHoursData = () => {
    const groupedShiftHours = groupBy(staffById?.staffHourList, "dayOfWeek");

    const newShiftHoursMap = weekDays.map((day, idx) => {
      return {
        ...day,
        hours:
          groupedShiftHours[idx + 1]?.map((hour: any, index: number) => {
            const startTimeName = `${dayFunc(hour.dayOfWeek)}-s-${index}`;
            const endTimeName = `${dayFunc(hour.dayOfWeek)}-e-${index}`;

            //@ts-ignore
            formik.setFieldValue([startTimeName], hour.startTime);
            //@ts-ignore
            formik.setFieldValue([endTimeName], hour.endTime);
            return {
              ...hour,
              [startTimeName]: hour.startTime,
              [endTimeName]: hour.endTime,
            };
          }) ?? [],
      };
    });

    // mapping new fetched shift details
    setWeekData(newShiftHoursMap);

    // checkBox manage section
    const newCheckedDay = newShiftHoursMap.map((item) =>
      item.hours.length > 0 ? item.name : false
    );

    //@ts-ignore
    setCheckedDays([...new Set(newCheckedDay)]);
  };

  useEffect(() => {
    if (shiftDetail) selectedShiftDetailData();
  }, [shiftDetail]);

  useEffect(() => {
    if (
      staffById?.staffHourList?.length &&
      staffById?.staffHourList?.length > 0 &&
      !shiftId
    )
      selectedStaffHoursData();
  }, [staffById, shiftId]);

  // useEffect(() => {
  //   if (
  //     staffById?.staffHourList?.length &&
  //     staffById?.staffHourList?.length > 0
  //   )
  //     selectedStaffHoursData();
  // }, [shiftId]);

  return (
    <>
      {weekData.map((day, idx) => {
        return (
          <Grid
            display="grid"
            gridTemplateColumns="80px 1fr auto"
            bgcolor="inputBackground.main"
            borderRadius="10px"
            height="max-content"
            key={day.id}
          >
            <Grid item alignItems="center" fontSize={16}>
              <Checkbox
                onChange={checkDayHandler}
                checked={checkedDays.indexOf(day.name) > -1}
                value={day.name}
              />
              {day.name}
            </Grid>
            <Grid>
              <TransitionGroup>
                {day.hours.map((hour: any, index) => {
                  let nameStart = null;
                  let nameEnd = null;
                  for (const key in hour) {
                    const splitKey = key.split("-");
                    if (splitKey.length > 2) {
                      if (splitKey[1] === "s") nameStart = key;
                      else nameEnd = key;
                    }
                  }

                  return (
                    <Collapse key={hour.id}>
                      <TimePickerInput
                        disabled={checkedDays.indexOf(day.name) < 0}
                        hour={hour}
                        nameStart={nameStart as string}
                        nameEnd={nameEnd as string}
                        formik={formik}
                        day={day}
                        handleDelete={deleteShiftHour}
                      />
                      {index < day.hours.length - 1 && (
                        <Divider
                          sx={{ borderColor: "grey.200", width: "98%" }}
                        />
                      )}
                    </Collapse>
                  );
                })}
              </TransitionGroup>
            </Grid>

            <Grid item container alignItems="start" justifyContent="center">
              <Divider orientation="vertical" sx={{ height: 30, mt: 1 }} />
              <IconButton
                onClick={() => addShiftHour(day)}
                sx={{ svg: { width: 24, height: 24 } }}
              >
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

export default StaffHoursCard;
