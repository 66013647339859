import React, { useState, FC, useEffect } from "react";
import { useAppSelector } from "../../hooks/redux.hooks";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";

// MUI
import {
  Grid,
  Typography,
  Divider,
  IconButton,
  Tooltip,
  styled,
} from "@mui/material";

import PrePaymentCard from "../cards/PrePayment.cards";
import InvoiceModal from "../modals/Invoice.modals";
import DiscountModal from "../modals/Discount.modals";

// icons
import { ReactComponent as CashIcon } from "../../assets/icons/cash-icon.svg";
import { ReactComponent as CreditIcon } from "../../assets/icons/credit-icon.svg";
import { ReactComponent as PrintIcon } from "../../assets/icons/print-icon.svg";
import { ReactComponent as DiscountIcon } from "../../assets/icons/discount-icon.svg";
import {
  IBasketPaymentBody,
  IPostBasketPaymentParams,
  postBasketPayment,
} from "../../redux/order/order.actions";
import { DateObject } from "react-multi-date-picker";
import { RotatingLines } from "react-loader-spinner";
import { useSearchParams } from "react-router-dom";

const BasketStep: FC = () => {
  //hooks
  const dispatch = useDispatch<AppDispatch>();

  // states
  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
  const [openDiscountModal, setOpenDiscountModal] = useState(false);

  // handlers
  const handleOpenInvoiceModal = () => setOpenInvoiceModal(true);
  const handleCloseInvoiceModal = () => setOpenInvoiceModal(false);

  const handleOpenDiscountModal = () => setOpenDiscountModal(true);
  const handleCloseDiscountModal = () => setOpenDiscountModal(false);

  const { orderBasket, isPostingPayment } = useAppSelector(
    (state) => state.order
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const submitPayment = (methodId: number) => {
    const body: IBasketPaymentBody = {
      bookingId: orderBasket?.id as number,
      amount: orderBasket?.totalForPay as number,
      payMethod: methodId,
      date: new DateObject().format("YYYY-MM-dd"),
      intentId: "string",
    };
    const parameters: IPostBasketPaymentParams = {
      body,
    };
    dispatch(postBasketPayment(parameters));
  };

  useEffect(() => {
    if (orderBasket?.itemList.length === 0) {
      searchParams.set("active", "1");
      setSearchParams(searchParams);
    }
  }, [orderBasket?.itemList]);

  return (
    <Grid display="grid" gridTemplateRows="auto 1fr" gap={1} width="100%">
      <Grid>
        <Typography fontWeight={500}>Invoice</Typography>
      </Grid>
      <Grid
        container
        gap={2}
        bgcolor="white"
        sx={{ overflowY: "auto", overflowX: "hidden" }}
        p={1}
        borderRadius={2}
      >
        <Grid
          container
          gap={2}
          sx={{ overflowY: "auto", overflowX: "hidden" }}
          p={2}
          borderRadius={2}
          height="75vh"
          alignContent="start"
          position="relative"
        >
          {orderBasket?.itemList.map((item, index) => {
            return (
              <React.Fragment key={index}>
                {index === 0 && (
                  <Grid
                    display="grid"
                    gridTemplateColumns="80px repeat(2, 1fr) 2fr repeat(2,1fr) 80px"
                    bgcolor="primary.main"
                    width="100%"
                    p={1.5}
                    gap={2}
                    position="sticky"
                    top={0}
                    zIndex={1}
                    borderRadius={2}
                  >
                    <Typography color="white">ID</Typography>
                    <Typography color="white">Service</Typography>
                    <Typography color="white">Date</Typography>
                    <Typography color="white">Time</Typography>
                    <Typography color="white">Amount</Typography>
                    <Typography color="white">Pre Payment</Typography>
                  </Grid>
                )}
                <PrePaymentCard key={item.bookingDetailId} item={item} />
              </React.Fragment>
            );
          })}
        </Grid>
        <Divider sx={{ width: "100%" }} />
        <Grid
          display="grid"
          gridTemplateColumns="repeat(4,auto) 220px"
          width="100%"
          gap={1}
          alignItems="center"
        >
          <Typography
            fontWeight={500}
            fontSize={17}
            display="flex"
            gap={1}
            color="grey.600"
          >
            Discount :
            <span>&#163; {orderBasket?.totalDiscount.toFixed(2)}</span>
          </Typography>
          <Typography
            fontWeight={500}
            fontSize={17}
            display="flex"
            gap={1}
            color="grey.600"
          >
            Header Discount :
            <span>&#163; {orderBasket?.headerDiscount.toFixed(2)}</span>
          </Typography>
          <Typography
            fontWeight={500}
            fontSize={17}
            display="flex"
            gap={1}
            color="grey.600"
          >
            Pre Payment :
            <span>&#163; {orderBasket?.totalPrePayment.toFixed(2)}</span>
          </Typography>
          <Typography fontWeight={600} fontSize={17} display="flex" gap={1}>
            For Pay :<span>&#163; {orderBasket?.totalForPay?.toFixed(2)}</span>
          </Typography>

          <Grid container gap={1} flexWrap="nowrap" justifyContent="end">
            {/* <Tooltip title="discount" placement="top">
              <CustomIconButton onClick={handleOpenDiscountModal}>
                <DiscountIcon />
              </CustomIconButton>
            </Tooltip> */}
            <Tooltip title="cash" placement="top">
              <CustomIconButton
                sx={{
                  width: 48,
                }}
                onClick={() => submitPayment(170)}
              >
                {isPostingPayment ? (
                  <Grid
                    sx={{
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      zIndex: 10,
                      transform: "translate(-50%, -50%)",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <RotatingLines
                      strokeColor="grey"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="30"
                      visible
                    />
                  </Grid>
                ) : (
                  <CashIcon />
                )}
              </CustomIconButton>
            </Tooltip>
            <Tooltip title="credit" placement="top">
              <CustomIconButton
                onClick={() => submitPayment(171)}
                sx={{ width: 48 }}
              >
                {isPostingPayment ? (
                  <Grid
                    sx={{
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      zIndex: 10,
                      transform: "translate(-50%, -50%)",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <RotatingLines
                      strokeColor="grey"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="30"
                      visible
                    />
                  </Grid>
                ) : (
                  <CreditIcon />
                )}
              </CustomIconButton>
            </Tooltip>
            <Tooltip title="print" placement="top">
              <IconButton onClick={handleOpenInvoiceModal}>
                <PrintIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <InvoiceModal
        open={openInvoiceModal}
        handleClose={handleCloseInvoiceModal}
      />
      <DiscountModal
        open={openDiscountModal}
        handleClose={handleCloseDiscountModal}
      />
    </Grid>
  );
};

export const CustomIconButton = styled(IconButton)(({ theme }) =>
  theme.unstable_sx({
    svg: {
      width: 32,
      height: 32,
    },
  })
);

export default BasketStep;
