import { useRef, useEffect, FC } from "react";
import { useAppSelector } from "../../hooks/redux.hooks";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { NavContainer } from "./Calendar.cards";

// MUI
import { Grid, IconButton, Typography, styled } from "@mui/material";
import { IOrderAdditionalForm } from "../../helpers/formik";

// icons
import { ReactComponent as DurationIcon } from "../../assets/icons/duration-icon.svg";
import { ReactComponent as PriceIcon } from "../../assets/icons/price-staff-icon.svg";
import { ReactComponent as LeftIcon } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as RightIcon } from "../../assets/icons/arrow-right.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/checkbox-checked-icon.svg";
import { ReactComponent as UnCheckIcon } from "../../assets/icons/checkbox-icon.svg";

import BaseCountInput from "../base/BaseCountInput";
import { FormikProps } from "formik";

type IOrderAdditional = {
  formik: FormikProps<IOrderAdditionalForm>;
};

const OrderAdditional: FC<IOrderAdditional> = ({ formik }) => {
  //hooks
  const { orderAvailableData } = useAppSelector((state) => state.order);

  const selectedServiceMaxGuestsNum = orderAvailableData?.maxNumberOfGuest;

  const navigationPrev = useRef(null);
  const navigationNext = useRef(null);

  const makeFormikValues = () => {
    orderAvailableData?.additionalServiceList.map((item) => {
      setFieldValue(String(item.id), false);
      setFieldValue(
        `max-${item.id}`,
        item.additionalServiceMaxCount > 0 ? 1 : 0
      );
    });
    setFieldValue("guests", 0);
  };

  useEffect(() => {
    resetForm();
    makeFormikValues();
  }, [orderAvailableData]);

  const { values, setFieldValue, resetForm } = formik;

  return (
    <Grid width="100%">
      <Typography fontSize={20} fontWeight={500} color="primary.main" mb={3}>
        Select Additional Service
      </Typography>

      <Grid
        sx={{
          width: "100%",
          ".swiper-slide": {
            height: "85%",
            overflow: "hidden",
          },
          position: "relative",
        }}
      >
        <Swiper
          onInit={(swiper: any) => {
            swiper.params.navigation.prevEl = navigationPrev.current;
            swiper.params.navigation.nextEl = navigationNext.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          slidesPerView={3}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            0: {
              slidesPerView: 2,
              spaceBetween: 20,
              slidesPerGroup: 1,
            },
            1400: {
              slidesPerView: 3,
              spaceBetween: 20,
              slidesPerGroup: 2,
            },
            1650: {
              slidesPerView: 4,
              spaceBetween: 20,
              slidesPerGroup: 2,
            },
          }}
          navigation={{
            prevEl: navigationPrev.current,
            nextEl: navigationNext.current,
          }}
          modules={[Navigation]}
          style={{
            width: "calc(100% - 80px)",
            padding: "0 4px",
          }}
        >
          {orderAvailableData?.additionalServiceList &&
          orderAvailableData?.additionalServiceList.length > 0 ? (
            orderAvailableData?.additionalServiceList.map((item, i) => {
              return (
                <SwiperSlide key={i}>
                  <Staffs
                    sx={{
                      bgcolor: values[item.id] ? "primary.main" : "white",
                      color: values[item.id] ? "white" : "initial",
                    }}
                    gap={1}
                  >
                    <Grid container justifyContent="space-between">
                      <Typography fontWeight={400} fontSize={18}>
                        {item.additionalServiceTitle}
                      </Typography>
                      <IconButton
                        onClick={() =>
                          setFieldValue(
                            String(item.id),
                            values[item.id] ? false : true
                          )
                        }
                        sx={{
                          width: "max-content",
                          color: values[item.id] ? "white" : "initial",
                          svg: {
                            width: 20,
                            height: 20,
                            path: {
                              fill: values[item.id] ? "white" : null,
                            },
                          },
                        }}
                      >
                        {values[item.id] ? <CheckIcon /> : <UnCheckIcon />}
                      </IconButton>
                    </Grid>

                    <Typography
                      fontWeight={400}
                      fontSize={18}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        svg: {
                          width: 20,
                          height: 20,
                          path: {
                            fill: values[item.id] ? "" : "#777",
                          },
                        },
                      }}
                    >
                      <DurationIcon /> {item.additionalServiceDuration}
                      <PriceIcon />
                      {item.additionalServiceAmount}
                    </Typography>
                    {typeof values[`max-${item.id}`] === "number" && (
                      <BaseCountInput
                        value={Number(values[`max-${item.id}`])}
                        name={`max-${item.id}`}
                        handleChange={setFieldValue}
                        label="Quantity"
                        limit={item.additionalServiceMaxCount}
                        isActive={!!values[item.id]}
                      />
                    )}
                  </Staffs>
                </SwiperSlide>
              );
            })
          ) : (
            <SwiperSlide>
              <Grid
                bgcolor="white"
                height={133}
                container
                justifyContent="center"
                alignItems="center"
                borderRadius={2}
              >
                <Typography fontSize={18} fontWeight={500}>
                  No Data
                </Typography>
              </Grid>
            </SwiperSlide>
          )}
        </Swiper>
        <NavContainer sx={{ left: 0 }} ref={navigationPrev}>
          <LeftIcon />
        </NavContainer>
        <NavContainer sx={{ right: 0 }} ref={navigationNext}>
          <RightIcon />
        </NavContainer>
      </Grid>
      <Typography fontSize={20} fontWeight={500} color="primary.main" my={3}>
        Number of Guests
      </Typography>
      {typeof values.guests === "number" && (
        <Grid sx={{ width: "calc(100% - 80px)", padding: "0px 4px" }}>
          <Grid width={200} mx={5}>
            <BaseCountInput
              value={Number(values.guests)}
              name="guests"
              handleChange={setFieldValue}
              bgcolor="white"
              limit={selectedServiceMaxGuestsNum}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const Staffs = styled(Grid)(({ theme }) =>
  theme.unstable_sx({
    minWidth: 60,
    color: "grey.800",
    borderRadius: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    bgcolor: "white",
    alignItems: "stretch",
    fontSize: 16,
    fontWeight: 500,
    flexWrap: "nowrap",
    p: 1,
  })
);

export default OrderAdditional;
