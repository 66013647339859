import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { useSearchParams } from "react-router-dom";

// MUI
import { Grid, Typography, IconButton } from "@mui/material";

import {
  IBasketAdditional,
  deleteBasketAdditional,
} from "../../redux/order/order.actions";

// icons
import { ReactComponent as DurationIcon } from "../../assets/icons/duration-icon.svg";
import { ReactComponent as RemoveIcon } from "../../assets/icons/remove-icon.svg";
import ConfirmModal from "../modals/Confirm.modals";
import { useAppSelector } from "../../hooks/redux.hooks";

type IPrePaymentAdditionalProps = {
  additional: IBasketAdditional;
};

const PrePaymentAdditionalCard: FC<IPrePaymentAdditionalProps> = ({
  additional,
}) => {
  // hooks
  const dispatch = useDispatch<AppDispatch>();
  const { isDeletingBasket } = useAppSelector((state) => state.order);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);

  const [searchParams, _] = useSearchParams();
  const bookingSearchParam = searchParams.get("bookingId");

  // handlers
  const handleOpenConfirmModal = () => setOpenConfirmModal(true);
  const handleCloseConfirmModal = () => setOpenConfirmModal(false);

  return (
    <Grid gridColumn="3/4" p={1.5} bgcolor="white" borderRadius={2}>
      <Grid
        display="grid"
        gridTemplateColumns="repeat(4,1fr) 40px"
        alignItems="center"
      >
        <Typography color="grey.700" fontSize={16}>
          {additional.additionalServiceTitle}
        </Typography>
        <Typography color="grey.700" fontSize={16}>
          count : {additional.additionalServiceCount}
        </Typography>
        <Typography
          color="grey.700"
          fontSize={16}
          display="flex"
          alignItems="center"
          gap={1}
          sx={{
            svg: {
              width: 20,
              height: 20,
            },
          }}
        >
          <DurationIcon /> {additional.additionalServiceDuration}
        </Typography>
        <Typography color="grey.700" fontSize={16}>
          &#163; {additional.totalPayFor.toFixed(2)}
        </Typography>
        <IconButton
          onClick={() => {
            handleOpenConfirmModal();
            setDeleteId(additional.id);
          }}
          sx={{
            width: 30,
            height: 30,
            p: 0,
            svg: {
              width: 20,
              height: 20,
            },
          }}
        >
          <RemoveIcon />
        </IconButton>
      </Grid>
      <ConfirmModal
        isDeleting={isDeletingBasket}
        open={openConfirmModal}
        handleClose={handleCloseConfirmModal}
        action={() =>
          dispatch(
            deleteBasketAdditional({
              itemId: deleteId,
              bookingId: Number(bookingSearchParam),
              closeModalHandler: handleCloseConfirmModal,
            })
          )
        }
      />
    </Grid>
  );
};

export default PrePaymentAdditionalCard;
