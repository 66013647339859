import { createSlice } from "@reduxjs/toolkit";
import {
  IBasket,
  IOrderAvailableType,
  deleteBasketAdditional,
  deleteBasketItem,
  fetchBasket,
  fetchOrderServiceAvailableData,
  fetchServiceAvailableDays,
  postBasket,
  postBasketPayment,
} from "./order.actions";

type IInitialState = {
  // activeStep: number;
  orderCustomer: number;
  orderServiceCategory: number;
  orderService: number;
  isFetchingServiceAvailableDays: boolean;
  orderServiceAvailableDays: { status: number }[];
  orderDate: {
    year: number;
    month: number;
    day: number;
  } | null;
  orderAvailableData: IOrderAvailableType | null;
  orderCommonData: {
    customer: string;
    service: string;
  };
  isOrderAvailableDataFetching: boolean;
  fetchError: any;
  orderTime: {
    start: string;
    end: string;
  } | null;
  orderStaff: number;
  orderBasket: IBasket | null;
  isFetchingBasket: boolean;
  isPostingBasket: boolean;
  isDeletingBasket: boolean;
  postError: any;

  paymentData: any;
  isPostingPayment: boolean;
  paymentError: any;
};

const initialState: IInitialState = {
  // activeStep: 0,
  orderCustomer: 0,
  orderServiceCategory: 0,
  orderService: 0,
  isFetchingServiceAvailableDays: false,
  orderServiceAvailableDays: [],
  orderDate: null,
  orderAvailableData: null,
  orderCommonData: {
    customer: "",
    service: "",
  },
  isOrderAvailableDataFetching: false,
  fetchError: null,
  orderTime: null,
  orderStaff: 0,
  orderBasket: null,
  isFetchingBasket: false,
  isPostingBasket: false,
  isDeletingBasket: false,
  postError: null,
  // payment
  paymentData: null,
  isPostingPayment: false,
  paymentError: null,
};

const orderSlice = createSlice({
  initialState,
  name: "order",
  reducers: {
    // setActiveStep: (state, action) => {
    //   state.activeStep = action.payload;
    // },
    setOrderCustomer: (state, action) => {
      state.orderServiceCategory = 0;
      state.orderService = 0;
      state.orderDate = null;
      state.orderTime = null;

      state.orderCustomer = action.payload;
    },
    setOrderServiceCategory: (state, action) => {
      state.orderService = 0;
      state.orderDate = null;
      state.orderTime = null;

      state.orderServiceCategory = action.payload;
    },
    setOrderService: (state, action) => {
      state.orderDate = null;
      state.orderTime = null;

      state.orderService = action.payload;
    },
    setOrderCustomerName: (state, action) => {
      state.orderCommonData = {
        ...state.orderCommonData,
        customer: action.payload,
      };
    },
    setOrderServiceTitle: (state, action) => {
      state.orderCommonData = {
        ...state.orderCommonData,
        service: action.payload,
      };
    },
    setOrderDate: (state, action) => {
      state.orderTime = null;
      state.orderDate = action.payload;
      state.orderAvailableData = null;
    },
    setOrderTime: (state, action) => {
      state.orderStaff = 0;

      state.orderTime = action.payload;
    },
    setOrderStaff: (state, action) => {
      state.orderStaff = action.payload;
    },

    clearBasket: (state) => {
      state.orderBasket = null;
    },
    // goToCheckout: (state) => {
    //   state.orderTime = null;
    //   state.activeStep++;
    // },
  },

  extraReducers(builder) {
    builder
      .addCase(fetchOrderServiceAvailableData.pending, (state) => {
        state.isOrderAvailableDataFetching = true;
      })
      .addCase(fetchOrderServiceAvailableData.fulfilled, (state, action) => {
        state.isOrderAvailableDataFetching = false;
        state.orderAvailableData = action.payload;
      })
      .addCase(fetchOrderServiceAvailableData.rejected, (state, action) => {
        state.isOrderAvailableDataFetching = false;
        state.fetchError = action.payload;
      })

      // fetch service available days
      .addCase(fetchServiceAvailableDays.pending, (state) => {
        state.isFetchingServiceAvailableDays = true;
      })
      .addCase(fetchServiceAvailableDays.fulfilled, (state, action) => {
        state.isFetchingServiceAvailableDays = false;
        state.orderServiceAvailableDays = action.payload;
      })
      .addCase(fetchServiceAvailableDays.rejected, (state, action) => {
        state.isFetchingServiceAvailableDays = false;
        state.fetchError = action.payload;
      })

      // post basket
      .addCase(postBasket.pending, (state) => {
        state.isPostingBasket = true;
      })
      .addCase(postBasket.fulfilled, (state) => {
        state.isPostingBasket = false;
      })
      .addCase(postBasket.rejected, (state, action) => {
        state.isPostingBasket = false;
        state.postError = action.payload;
      })

      // fetch basket
      .addCase(fetchBasket.pending, (state) => {
        state.isFetchingBasket = true;
      })
      .addCase(fetchBasket.fulfilled, (state, action) => {
        state.isFetchingBasket = false;
        state.orderBasket = action.payload;
      })
      .addCase(fetchBasket.rejected, (state, action) => {
        state.isFetchingBasket = false;
        state.fetchError = action.payload;
      })

      // delete basket items
      .addCase(deleteBasketItem.pending, (state) => {
        state.isDeletingBasket = true;
      })
      .addCase(deleteBasketItem.fulfilled, (state) => {
        state.isDeletingBasket = false;
      })
      .addCase(deleteBasketItem.rejected, (state) => {
        state.isDeletingBasket = false;
      })

      // delete basket additional
      .addCase(deleteBasketAdditional.pending, (state) => {
        state.isDeletingBasket = true;
      })
      .addCase(deleteBasketAdditional.fulfilled, (state) => {
        state.isDeletingBasket = false;
      })
      .addCase(deleteBasketAdditional.rejected, (state) => {
        state.isDeletingBasket = false;
      })

      // payment
      .addCase(postBasketPayment.pending, (state) => {
        state.isPostingPayment = true;
      })
      .addCase(postBasketPayment.fulfilled, (state, action) => {
        state.isPostingPayment = false;
        state.paymentData = action.payload;
      })
      .addCase(postBasketPayment.rejected, (state, action) => {
        state.isPostingPayment = false;
        state.paymentError = action.payload;
      });
  },
});

export const {
  setOrderCustomer,
  setOrderServiceCategory,
  setOrderService,
  setOrderCustomerName,
  setOrderServiceTitle,
  setOrderDate,
  setOrderTime,
  setOrderStaff,
  clearBasket,
} = orderSlice.actions;
export default orderSlice.reducer;
