import React, { useState, ChangeEvent } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

// MUI
import {
  Grid,
  InputAdornment,
  SelectChangeEvent,
  Popover,
} from "@mui/material";

// icons
import { ReactComponent as SearchIcon } from "../../assets/icons/Search.svg";
import { ReactComponent as ChevronIcon } from "../../assets/icons/chevron-icon.svg";
import BaseInput from "../base/BaseInput";
import BaseButton from "../base/BaseButton";
import AvailableListFilterCard from "../cards/AvailableListFilter.cards";

const AvailabilityListHeader = () => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const addAvailability = () => {
    navigate("create-availability");
  };

  const handleClickFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilters = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Grid
      p={1.25}
      gap={2}
      display="grid"
      gridTemplateColumns="350px 1fr"
      justifyItems="end"
    >
      {/* <BaseInput
        variant="outlined"
        p={1.25}
        fullWidth
        value={search}
        onChange={handleSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ zIndex: 10 }}>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        placeholder="Search among availability"
      /> */}
      {/* <Grid display="grid" gridTemplateColumns="auto 150px 150px" gap={2}>
        <Grid gridColumn="1/2">
          <BaseButton sx={{ minWidth: 150 }} onClick={addAvailability}>
            add availability
          </BaseButton>
        </Grid>
        <Grid>
          <BaseButton
            variant="outlined"
            onClick={handleClickFilters}
            sx={{
              justifyContent: "space-around",
              "&:hover": {
                border: 2,
              },
            }}
          >
            Filters
            <ChevronIcon />
          </BaseButton>
        </Grid>
        <BaseButton
          variant="outlined"
          sx={{
            "&:hover": {
              border: 2,
            },
          }}
        >
          export
        </BaseButton>
      </Grid> */}
      <Grid
        display="grid"
        gridTemplateColumns="auto 150px 150px"
        gridColumn="2/3"
        gap={2}
      >
        <Grid gridColumn="1/2">
          <BaseButton sx={{ minWidth: 150 }} onClick={addAvailability}>
            add Availability
          </BaseButton>
        </Grid>
        <Grid>
          <BaseButton
            variant="outlined"
            onClick={handleClickFilters}
            sx={{
              justifyContent: "space-around",
              "&:hover": {
                border: 2,
              },
            }}
          >
            Filters
            <ChevronIcon />
          </BaseButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleCloseFilters}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            PaperProps={{ sx: { borderRadius: "5px" } }}
            sx={{ zIndex: 100 }}
          >
            <AvailableListFilterCard closePopover={handleCloseFilters} />
          </Popover>
        </Grid>
        <BaseButton
          variant="outlined"
          sx={{
            "&:hover": {
              border: 2,
            },
          }}
        >
          export
        </BaseButton>
      </Grid>
    </Grid>
  );
};

export default AvailabilityListHeader;
