import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { useAppSelector } from "../../hooks/redux.hooks";
import { useSearchParams } from "react-router-dom";

// MUI
import { Grid, Divider, Typography } from "@mui/material";

import CalendarCard from "../cards/Calendar.cards";
import OrderTimeCard from "../cards/OrderTime.cards";
import OrderStaffCard from "../cards/OrderStaff.cards";
import BaseButton from "../base/BaseButton";
import OrderAdditional from "../cards/OrderAdditional.cards";
import BasketCard from "../cards/Basket.cards";
import { IPostBasketParams, postBasket } from "../../redux/order/order.actions";
import { useOrderAdditionalForm } from "../../helpers/formik";

// icons
import { ReactComponent as EmptyBasketIcon } from "../../assets/icons/empty-basket.svg";
import { RotatingLines } from "react-loader-spinner";

const DateAndTimeStep = () => {
  // hooks
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams, setSearchParams] = useSearchParams();

  const bookingSearchParam = searchParams.get("bookingId");
  const customerSearchParam = searchParams.get("customerId");
  const serviceIdSearchParam = searchParams.get("serviceId");
  const timeSearchParam = searchParams.get("time");
  const staffSearchParam = searchParams.get("staff");

  const { orderBasket, orderDate, orderAvailableData, isFetchingBasket } =
    useAppSelector((state) => state.order);

  const splitTime = timeSearchParam?.split("_");

  const { user } = useAppSelector((state) => state.user);

  const formik = useOrderAdditionalForm();
  const { values } = formik;

  const addAnotherServiceHandler = (mode: "another" | "payment") => {
    const additionalServiceList = [];
    for (const key in formik.values) {
      const additionalId = key.split("-");
      if (additionalId.length === 1 && additionalId[0] !== "guests") {
        if (formik.values[key])
          additionalServiceList.push({
            additionalServiceId: Number(key),
            quantity: formik.values[`max-${key}`],
          });
      }
    }

    const basketBody = {
      itemList: [
        {
          staffId: Number(staffSearchParam),
          serviceId: Number(serviceIdSearchParam),
          startDate:
            orderDate &&
            `${orderDate?.year}-${orderDate?.month}-${orderDate?.day}`,
          endDate:
            orderDate &&
            `${orderDate?.year}-${orderDate?.month}-${orderDate?.day}`,
          startTime: splitTime && splitTime[0].split("-").join(":"),
          endTime: splitTime && splitTime[1].split("-").join(":"),
          numberOfGuest: values.guests as number,
        },
      ],
      additionalServiceList: additionalServiceList,
      productsList: [
        // {
        //   serviceProductId: 0,
        //   quantity: 0,
        // },
      ],
      bookingId: Number(bookingSearchParam) || 0,
      customerId: Number(customerSearchParam),
      shopId: user?.shopId as number,
      discountId: 0,
      headerDiscount: 0,
    };

    const parameters: IPostBasketParams = {
      mode,
      body: basketBody,
      setSearchParams: setSearchParams,
      searchParams: searchParams,
    };

    dispatch(postBasket(parameters));
  };

  const splitTimeSearchParam = timeSearchParam?.split("_");
  const selectedStaffs =
    splitTimeSearchParam && orderAvailableData?.serviceFreeList
      ? orderAvailableData?.serviceFreeList?.filter(
          (item) =>
            item.durationStart.split(":")[0] +
              ":" +
              item.durationStart.split(":")[1] ===
            splitTimeSearchParam[0].split("-").join(":")
        )
      : [];

  const isSelectedStaffAvailable = selectedStaffs.filter(
    (staff) => staff.staffId === Number(staffSearchParam)
  )[0]?.isAvailable;

  return (
    <Grid
      display="grid"
      gridTemplateColumns="1fr 5px 450px"
      gap={2}
      position="relative"
      sx={{ overflowY: "auto" }}
    >
      <Grid
        container
        sx={{ overflowY: "auto", overflowX: "hidden" }}
        flexDirection="column"
        flexWrap="nowrap"
        gap={2}
      >
        <CalendarCard />
        <OrderTimeCard />
        <OrderStaffCard />
        <OrderAdditional formik={formik} />
      </Grid>
      <Divider
        orientation="vertical"
        sx={{ borderRight: 2, borderColor: "white" }}
      />
      {isFetchingBasket ? (
        <Grid position="relative">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            position="absolute"
            zIndex={1000}
            bgcolor="rgba(0,0,0, 0.05)"
            borderRadius={1}
            height="100%"
          >
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
            />
          </Grid>
        </Grid>
      ) : orderBasket?.itemList && orderBasket?.itemList.length > 0 ? (
        <BasketCard />
      ) : (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          sx={{
            svg: { height: 160 },
            flexDirection: "column",
            gap: 2,
            height: "100%",
          }}
        >
          <EmptyBasketIcon />
          <Typography fontWeight={500}>Empty Basket</Typography>
        </Grid>
      )}

      <Grid
        mt="auto"
        position="sticky"
        bgcolor="white"
        bottom={10}
        container
        alignItems="center"
        justifyContent="center"
        gridColumn="1/4"
        zIndex={10}
        gap={2}
        p={2}
        borderRadius={2}
        flexWrap="nowrap"
      >
        <BaseButton
          sx={{
            fontSize: 18,
            "&: disabled": {
              color: "grey.200",
              borderColor: "grey.400",
              bgcolor: "grey.400",
            },
          }}
          variant="text"
          disabled={staffSearchParam ? !isSelectedStaffAvailable : true}
          onClick={() => addAnotherServiceHandler("another")}
        >
          + Add Another service
        </BaseButton>
        <BaseButton
          sx={{
            fontSize: 18,
            "&: disabled": {
              color: "grey.400",
              borderColor: "grey.400",
            },
          }}
          disabled={staffSearchParam ? !isSelectedStaffAvailable : true}
          onClick={() => addAnotherServiceHandler("payment")}
        >
          Checkout
        </BaseButton>
      </Grid>
    </Grid>
  );
};

export default DateAndTimeStep;
