import React, { useEffect, useState } from "react";
import { DateObject } from "react-multi-date-picker";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import axiosConfig from "../../axiosConfig";
// MUI
import { FormControlLabel, Grid, Switch, Typography } from "@mui/material";

import BaseInput from "../../components/base/BaseInput";
import { useBookingForm } from "../../helpers/formik";
import BaseButton from "../../components/base/BaseButton";
import OneSectionLayout from "../../layout/OneSection.layouts";
import { NumericFormatCustom } from "../../components/base/BaseMaskInput";
import BaseDatePicker from "../../components/base/BaseDatePicker";

import { IEditActionsApiMethodType } from "../../redux/shifts/shift.actions";
import { postBooking } from "../../redux/booking/booking.actions";
import { AppDispatch } from "../../redux/store";
import { RotatingLines } from "react-loader-spinner";
import { useAppSelector } from "../../hooks/redux.hooks";
import { toast } from "react-toastify";

const CreateBooking = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { bookingId } = useParams();

  const { isPostingBooking } = useAppSelector((state) => state.booking);

  // states
  const [booking, setBooking] = useState();
  const [fetchEnd, setFetchEnd] = useState(true);

  const fetchBookingById = async () => {
    setFetchEnd(false);
    try {
      const { data } = await axiosConfig.get(`Order/${bookingId}`);
      setFetchEnd(true);
      setBooking(data.data);
    } catch (error) {
      setFetchEnd(true);
      console.log(error);
    }
  };

  useEffect(() => {
    if (bookingId) fetchBookingById();
  }, [bookingId]);

  const submit = () => {
    const body = {
      ...values,
      bookingRegDate: new DateObject(values.bookingRegDate).format(),
    };
    validateForm();

    const parameters = {
      type: bookingId
        ? IEditActionsApiMethodType.PATCH
        : IEditActionsApiMethodType.POST,
      body,
    };

    if (isValid)
      toast.promise(dispatch(postBooking(parameters)).unwrap(), {
        pending: "pending",
        success: "resolved 👌",
        error: "rejected 🤯",
      });
  };
  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    setFieldValue,
    validateForm,
    isValid,
  } = useBookingForm(booking, submit);

  return (
    <OneSectionLayout>
      <Grid
        container
        item
        xs={12}
        zIndex={1}
        position="relative"
        top={-16}
        justifyContent="space-between"
      >
        <Typography
          height={70}
          display="flex"
          alignItems="center"
          color="grey.100"
        >
          {bookingId ? "Edit" : "Add"} Order
        </Typography>
        <Grid
          item
          container
          xs={12}
          md={8}
          gap={2}
          alignItems="center"
          justifyContent="end"
          position="unset"
          bottom={-5}
          left={0}
          bgcolor="transparent"
          p={0}
          zIndex={10}
        >
          <BaseButton
            sx={{
              width: 150,
              bgcolor: "grey.100",
              color: "primary.main",
              "&:hover": {
                bgcolor: "grey.100",
              },
              border: 0,
              fontWeight: 500,
            }}
            onClick={() => handleSubmit()}
            disabled={isPostingBooking}
          >
            {isPostingBooking ? (
              <Grid
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  zIndex: 10,
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="30"
                  visible
                />
              </Grid>
            ) : (
              "Save"
            )}
          </BaseButton>
          <BaseButton
            sx={{
              width: 150,
              border: 0,
              display: "none",
            }}
            variant="text"
            onClick={() => navigate(-1)}
          >
            Discard
          </BaseButton>
          <BaseButton
            sx={{
              width: 150,
              border: 0,
              display: "flex",
              boxShadow: 0,
            }}
            onClick={() => navigate(-1)}
          >
            Discard
          </BaseButton>
        </Grid>
      </Grid>
      <Grid
        display="grid"
        gridTemplateColumns="1fr 1fr"
        gap={2}
        position="relative"
      >
        {!fetchEnd && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            position="absolute"
            zIndex={1000}
            bgcolor="rgba(0,0,0, 0.05)"
            borderRadius={1}
            height="100%"
          >
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
            />
          </Grid>
        )}
        <BaseInput
          value={values.totalDiscount}
          name="totalDiscount"
          onChange={handleChange}
          variant="outlined"
          label="Total Discount"
          placeholder="Total Discount"
          InputProps={{
            inputComponent: NumericFormatCustom as any,
          }}
        />
        <BaseInput
          value={values.totalForPay}
          variant="outlined"
          name="totalForPay"
          onChange={handleChange}
          label="Total For Pay"
          placeholder="Total For Pay"
          InputProps={{
            inputComponent: NumericFormatCustom as any,
          }}
        />
        <BaseInput
          value={values.totalPrePayment}
          variant="outlined"
          name="totalPrePayment"
          onChange={handleChange}
          placeholder="Total Pre Payment"
          label="Total Pre Payment"
          InputProps={{
            inputComponent: NumericFormatCustom as any,
          }}
        />
        <BaseInput
          value={values.headerDiscount}
          variant="outlined"
          name="headerDiscount"
          onChange={handleChange}
          placeholder="Header Discount"
          label="Header Discount"
          InputProps={{
            inputComponent: NumericFormatCustom as any,
          }}
        />
        <BaseDatePicker
          setValue={(value: any) => setFieldValue("bookingRegDate", value)}
        />
        <FormControlLabel
          label={<Typography fontWeight={400}>Is Deleted</Typography>}
          control={
            <Switch
              checked={values.isDeleted ? true : false}
              name="isDeleted"
              onChange={() =>
                setFieldValue("isDeleted", values.isDeleted ? 0 : 1)
              }
              size="small"
            />
          }
          labelPlacement="start"
          sx={{
            width: "100%",
            justifyContent: "space-between",
            mx: 0,
            bgcolor: "inputBackground.main",
            px: 2,
            py: 1,
            borderRadius: 2,
          }}
        />
        <FormControlLabel
          label={<Typography fontWeight={400}>Status</Typography>}
          control={
            <Switch
              checked={values.bookingStatus ? true : false}
              name="bookingStatus"
              onChange={() =>
                setFieldValue("bookingStatus", values.bookingStatus ? 0 : 1)
              }
              size="small"
            />
          }
          labelPlacement="start"
          sx={{
            width: "100%",
            justifyContent: "space-between",
            mx: 0,
            bgcolor: "inputBackground.main",
            px: 2,
            py: 1,
            borderRadius: 2,
          }}
        />
      </Grid>
    </OneSectionLayout>
  );
};

export default CreateBooking;
