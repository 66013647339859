import axios from "axios";
import { logoutUser } from "./redux/user/user.reducer";
import { navigateV } from "./App";

let store;

export function injectStore(_store) {
  store = _store;
}

const baseURL = "https://newbooking.weeorder.co.uk/api/v1/";

const axiosConfig = axios.create({});

axiosConfig.interceptors.request.use(
  (config) => {
    const token = store.getState()?.user?.user?.pToken;

    if (token) config.headers.Authorization = `Bearer ${token}`;
    config.baseURL = baseURL;
    config.headers["Cache-Control"] = "no-cache";
    config.headers["pragma"] = "no-cache";
    config.headers["Expires"] = "0";

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosConfig.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error?.response?.status;
    if (status === 401) {
      store.dispatch(logoutUser());
    }

    if (status === 403) {
      navigateV("AccessDenied");
    }

    if (error.code === "ERR_NETWORK") {
      navigateV("/broken");
    }
    return Promise.reject(error);
  }
);

export default axiosConfig;
