import { useEffect, useState, FC } from "react";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";

import ThreeSectionLayout from "../../layout/ThreeSection.layouts";
import ShiftListHeader from "../../components/headers/ShiftList.headers";
import { useAppSelector } from "../../hooks/redux.hooks";
import { useShiftForm } from "../../helpers/formik";

// antd
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";

// MUI
import { Grid, Box, IconButton, styled, Typography } from "@mui/material";

import {
  IEditActionsApiMethodType,
  IPostShiftActionParam,
  ShiftDataType,
  deleteShift,
  fetchAllShifts,
  postShift,
} from "../../redux/shifts/shift.actions";

// icons
import { ReactComponent as EditIcon } from "../../assets/icons/edit-icon.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/check-icon.svg";
import { ReactComponent as CancelIcon } from "../../assets/icons/cancel-icon.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash-icon.svg";

import BaseInput from "../../components/base/BaseInput";
import ShiftHoursCard from "../../components/cards/ShiftHours.cards";
import BaseButton from "../../components/base/BaseButton";
import axiosConfig from "../../axiosConfig";
import { Watch } from "react-loader-spinner";
import Paging from "../../components/table/Paging";
import { useNavigate } from "react-router-dom";
import { DateObject } from "react-multi-date-picker";
import { IPagination } from "../staffs/List.staffs";
import BaseDatePicker from "../../components/base/BaseDatePicker";
import ConfirmModal from "../../components/modals/Confirm.modals";
import { setShiftError } from "../../redux/shifts/shift.reducer";

type IListShiftProps = {
  pagination: IPagination;
  setPagination: any;
};

const ListShift: FC<IListShiftProps> = ({ pagination, setPagination }) => {
  // selectors
  const { shiftsData, isFetchingShift, isDeletingShift } = useAppSelector(
    (state) => state.shift
  );

  // states
  const [expandedShift, setExpandedShift] = useState<number[]>([]);
  const [fetchEnd, setFetchEnd] = useState(false);
  const [shiftDetail, setShiftDetail] = useState();

  const [isEditing, setIsEditing] = useState(0);
  const [editingItem, setEditingItem] = useState<ShiftDataType>();

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);

  // handlers
  const handleOpenConfirmModal = () => setOpenConfirmModal(true);
  const handleCloseConfirmModal = () => setOpenConfirmModal(false);

  function getTableParentHeight() {
    const tableParentElement =
      document.getElementById("tableParent")?.clientHeight;

    return tableParentElement;
  }

  const [tableParentHeight, setTableParentHeight] = useState(
    getTableParentHeight()
  );

  // hooks
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const parameters = {
    ...pagination,
  };

  const submitHandler = () => {
    const parameters: IPostShiftActionParam = {
      body: {
        ...values,
        shiftExpireDate: new DateObject(
          values.shiftExpireDate as string
        ).format(),
      },
      type: editingItem?.id
        ? IEditActionsApiMethodType.PATCH
        : IEditActionsApiMethodType.POST,
      fetchParams: { ...pagination },
    };

    dispatch(postShift(parameters));
  };
  const { values, handleChange, handleSubmit, setFieldValue } = useShiftForm(
    editingItem,
    submitHandler
  );

  useEffect(() => {
    dispatch(fetchAllShifts(parameters));
    setTableParentHeight(getTableParentHeight());
  }, [pagination]);

  const columns: ColumnsType<ShiftDataType> = [
    {
      title: "Title",
      dataIndex: "shiftTitle",
      sorter: (a, b) => a.shiftTitle.localeCompare(b.shiftTitle),
      render: (...record) => {
        return (
          <Box
            onDoubleClick={() => {
              setIsEditing(record[1].id);
              setEditingItem(record[1]);
            }}
          >
            {isEditing === record[1].id ? (
              <BaseInput
                variant="outlined"
                value={values.shiftTitle}
                name="shiftTitle"
                onChange={handleChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setIsEditing(0);
                  }
                }}
                p={0}
              />
            ) : (
              record[0]
            )}
          </Box>
        );
      },
    },

    {
      title: "Expire Date",
      dataIndex: "shiftExpireDate",
      render: (value, record) => {
        return (
          <Box
            onDoubleClick={() => {
              setIsEditing(record.id);
              setEditingItem(record);
            }}
          >
            {isEditing === record.id ? (
              <BaseDatePicker
                value={values.shiftExpireDate}
                setValue={(value: any) =>
                  setFieldValue("shiftExpireDate", value)
                }
              />
            ) : (
              new DateObject(record.shiftExpireDate).format()
            )}
          </Box>
        );
      },
    },
    {
      title: "Status",
      width: 100,
      render: (value, record) => {
        return (
          <Typography
            color={record.shiftStatus ? "success.main" : "error.main"}
            fontSize={16}
            fontWeight={500}
            bgcolor={record.shiftStatus ? "success.light" : "error.light"}
            py={0.5}
            px={1}
            textAlign="center"
            borderRadius={2}
            maxWidth={100}
          >
            {record.shiftStatus ? "Active" : "InActive"}
          </Typography>
        );
      },
    },
    Table.EXPAND_COLUMN,

    {
      title: "Action",
      key: "action",
      render: (value, record) => (
        <Box display="flex" justifyContent="end">
          {isEditing === record.id && (
            <>
              <CustomIconButton
                onClick={() => {
                  handleSubmit();
                  setIsEditing(0);
                }}
              >
                <CheckIcon />
              </CustomIconButton>
              <CustomIconButton onClick={() => setIsEditing(0)}>
                <CancelIcon />
              </CustomIconButton>
            </>
          )}
          <CustomIconButton
            onClick={() => navigate(`/shifts/edit-shift/${record.id}`)}
          >
            <EditIcon />
          </CustomIconButton>
          <CustomIconButton
            onClick={() => {
              handleOpenConfirmModal();
              setDeleteId(record.id);
            }}
          >
            <TrashIcon />
          </CustomIconButton>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    const windowResizeEventCallback = (e: any) => {
      const tableParentElement = getTableParentHeight();
      setTableParentHeight(tableParentElement);
    };
    window?.addEventListener("resize", windowResizeEventCallback);
    return () =>
      window.removeEventListener("resize", windowResizeEventCallback);
  }, []);

  const fetchShiftDetail = async (id: number) => {
    try {
      const { data } = await axiosConfig.get(`Shift/GetShiftDetailList/${id}`);
      setShiftDetail(data.data);
      setFetchEnd(true);
    } catch (error: any) {
      dispatch(setShiftError(error.response.status));
      setFetchEnd(true);
    }
  };

  useEffect(() => {
    expandedShift[0] && fetchShiftDetail(expandedShift[0]);
  }, [expandedShift]);

  return (
    <ThreeSectionLayout
      header={<ShiftListHeader />}
      paging={
        <Paging
          data={shiftsData}
          pagination={pagination}
          setPagination={setPagination}
        />
      }
    >
      {tableParentHeight && (
        <Table
          loading={isFetchingShift}
          columns={columns}
          dataSource={shiftsData?.data ? shiftsData.data : []}
          pagination={false}
          scroll={
            (shiftsData?.data.length as number) > 0
              ? { x: 800, y: (tableParentHeight as number) - 70 }
              : { x: undefined, y: undefined }
          }
          className="shiftTable"
          rowKey="id"
          expandable={{
            expandedRowRender: (record) =>
              fetchEnd ? (
                <ShiftHoursCard
                  shiftId={record.id}
                  setExpandedShift={setExpandedShift}
                  shiftDetail={shiftDetail}
                />
              ) : (
                <Grid container justifyContent="center">
                  <Watch
                    height="50"
                    width="50"
                    radius="48"
                    color="#785FDC"
                    ariaLabel="watch-loading"
                    visible={true}
                  />
                </Grid>
              ),
            expandIcon: ({ expanded, onExpand, record }) => (
              <BaseButton
                onClick={(e: any) => {
                  onExpand(record, e);
                }}
                variant="text"
                sx={{ height: 30 }}
              >
                shift details
              </BaseButton>
            ),
            columnWidth: 165,
            expandedRowKeys: expandedShift,
            onExpand(expanded, record) {
              const keys = [];
              if (expanded) {
                keys.push(record.id);
                setFetchEnd(false);
              }
              setExpandedShift(keys);
            },
          }}
          // onRow={(record) => {
          //   return {
          //     style: { opacity: record.shiftStatus === 0 ? 0.5 : 1 },
          //   };
          // }}
        />
      )}
      <ConfirmModal
        open={openConfirmModal}
        handleClose={handleCloseConfirmModal}
        isDeleting={isDeletingShift}
        action={() =>
          dispatch(
            deleteShift({
              shiftId: deleteId,
              fetchParams: parameters,
              closeModalHandler: handleCloseConfirmModal,
            })
          )
        }
      />
    </ThreeSectionLayout>
  );
};

const CustomIconButton = styled(IconButton)(({ theme }) =>
  theme.unstable_sx({
    svg: {
      width: 24,
      height: 24,
    },
  })
);

export default ListShift;
