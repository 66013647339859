import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosConfig from "../../axiosConfig";
import { weekDays } from "../../components/cards/ShiftHours.cards";
import { toast } from "react-toastify";
import { navigateV } from "../../App";

export type ShiftDataType = {
  id: number;
  shiftTitle: string;
  shiftStatus: number;
  shopId: number;
  shiftExpireDate: string;
};

type IFetchShiftParams = {
  pageNumber?: number;
  pageSize?: number;
};

export type IShiftType = {
  count: number;
  data: ShiftDataType[];
  id?: number;
};

export enum IEditActionsApiMethodType {
  POST = "post",
  PATCH = "patch",
}

export const fetchAllShifts = createAsyncThunk(
  "shifts/fetchAllShifts",
  async (parameters: IFetchShiftParams, ThunkApi: any) => {
    const { pageNumber, pageSize } = parameters;
    const { shopId } = ThunkApi.getState().user.user;
    try {
      const { data } = await axiosConfig.get<IShiftType>(
        `Shift/${pageNumber || 1}/${pageSize || 1000}/${shopId}`
      );

      return data as IShiftType;
    } catch (error: any) {
      return error.response.resultMessage;
    }
  }
);

export type IPostShiftActionParam = {
  type: IEditActionsApiMethodType;
  body: Object;
  fetchParams?: IFetchShiftParams;
};

export const postShift = createAsyncThunk(
  "shifts/postShift",
  async (parameters: IPostShiftActionParam, ThunkApi) => {
    const { type, body, fetchParams } = parameters;
    const promiseToast = toast.loading("Saving...");
    try {
      const { data } = await axiosConfig[type]("shift", body);
      fetchParams && ThunkApi.dispatch(fetchAllShifts(fetchParams));
      toast.update(promiseToast, {
        render: data.resultMessage,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      navigateV(-1);
      return data;
    } catch (error: any) {
      toast.update(promiseToast, {
        render: error.response ? error.response.data.title : "Request Timeout!",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
      return error.response.resultMessage;
    }
  }
);

export type IDeleteShiftParams = {
  shiftId: number;
  fetchParams: IFetchShiftParams;
  closeModalHandler: any;
};

export const deleteShift = createAsyncThunk(
  "shift/deleteShift",
  async (parameters: IDeleteShiftParams, ThunkApi) => {
    const { shiftId, fetchParams, closeModalHandler } = parameters;
    const promiseToast = toast.loading("Deleting...");
    try {
      const { data } = await axiosConfig.delete(`Shift/${shiftId}`);
      toast.update(promiseToast, {
        render: data.resultMessage,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      ThunkApi.dispatch(fetchAllShifts(fetchParams));
      closeModalHandler();
      return data;
    } catch (error: any) {
      toast.update(promiseToast, {
        render: error.response ? error.response.data.title : "Request Timeout!",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
      return error.response.resultMessage;
    }
  }
);

export type IPostShiftDetailActionParam = {
  body: Object;
  setWeekData: any;
  setExpandedShift: any;
};

export const postShiftDetails = createAsyncThunk(
  "shifts/postShiftDetail",
  async (parameters: IPostShiftDetailActionParam) => {
    const { body, setWeekData, setExpandedShift } = parameters;
    const promiseToast = toast.loading("Saving...");
    try {
      const { data } = await axiosConfig.post("Shift/AddShiftDetail", body);
      setWeekData(weekDays);
      setExpandedShift([]);
      toast.update(promiseToast, {
        render: data.resultMessage,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      return data;
    } catch (error: any) {
      toast.update(promiseToast, {
        render: error.response ? error.response.data.title : "Request Timeout!",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
      return error.response.resultMessage;
    }
  }
);
