import { createSlice } from "@reduxjs/toolkit";

import {
  IAvailabilityType,
  deleteAvailability,
  fetchAllAvailability,
  postAvailability,
} from "./availability.actions";

type IState = {
  availabilityData: IAvailabilityType | null;
  isFetchingAvailability: boolean;
  isPostingAvailability: boolean;
  isDeletingAvailability: boolean;
  availabilityError: any;
};

const initialState: IState = {
  availabilityData: null,
  isFetchingAvailability: false,
  isPostingAvailability: false,
  isDeletingAvailability: false,
  availabilityError: null,
};

const availabilitySlice = createSlice({
  initialState,
  name: "availability",
  reducers: {
    setAvailabilityError: (state, action) => {
      state.availabilityError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch all availability
      .addCase(fetchAllAvailability.pending, (state) => {
        state.isFetchingAvailability = true;
      })
      .addCase(fetchAllAvailability.fulfilled, (state, action) => {
        state.isFetchingAvailability = false;
        state.availabilityData = action.payload;
      })
      .addCase(fetchAllAvailability.rejected, (state, action) => {
        state.isFetchingAvailability = false;
        state.availabilityError = action.payload;
      })

      // post/patch availability
      .addCase(postAvailability.pending, (state) => {
        state.isPostingAvailability = true;
      })
      .addCase(postAvailability.fulfilled, (state) => {
        state.isPostingAvailability = false;
      })
      .addCase(postAvailability.rejected, (state, action) => {
        state.isPostingAvailability = false;
        state.availabilityError = action.payload;
      })

      // delete service
      .addCase(deleteAvailability.pending, (state) => {
        state.isDeletingAvailability = true;
      })
      .addCase(deleteAvailability.fulfilled, (state) => {
        state.isDeletingAvailability = false;
      })
      .addCase(deleteAvailability.rejected, (state, action) => {
        state.isDeletingAvailability = false;
        state.availabilityError = action.payload;
      });
  },
});

export const { setAvailabilityError } = availabilitySlice.actions;
export default availabilitySlice.reducer;
