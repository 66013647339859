import { useState, useEffect, FC } from "react";

//
import { Box, IconButton, Typography } from "@mui/material";

import ThreeSectionLayout from "../../../../layout/ThreeSection.layouts";
import { Table } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { useAppSelector } from "../../../../hooks/redux.hooks";
import { IPagination } from "../../../staffs/List.staffs";
import type { ColumnsType } from "antd/es/table";

// icons
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit-icon.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/icons/trash-icon.svg";

import {
  deleteSupplementarySubField,
  fetchSupplementaryById,
} from "../../../../redux/services/services.actions";
import SupplementarySubFieldListHeader from "../../../../components/headers/SupplementarySubFieldList.headers";
import ConfirmModal from "../../../../components/modals/Confirm.modals";
import { ISubFieldFormType } from "../../../../helpers/formik";

type IListSubFieldProps = {
  pagination: IPagination;
  setPagination: any;
};

const ListSubField: FC<IListSubFieldProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { supplementaryId } = useParams();

  const {
    supplementaryById,
    isFetchingSupplementary,
    isDeletingSupplementarySubField,
  } = useAppSelector((state) => state.service);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);

  // handlers
  const handleOpenConfirmModal = () => setOpenConfirmModal(true);
  const handleCloseConfirmModal = () => setOpenConfirmModal(false);

  function getTableParentHeight() {
    const tableParentElement =
      document.getElementById("tableParent")?.clientHeight;

    return tableParentElement;
  }

  const [tableParentHeight, setTableParentHeight] = useState(
    getTableParentHeight()
  );

  const columns: ColumnsType<ISubFieldFormType> = [
    {
      title: "ID",
      dataIndex: "id",
      width: 60,
    },
    {
      title: "Title",
      dataIndex: "supplementarySubFieldTitle",
      width: 130,
      ellipsis: true,
    },
    {
      title: "Default",
      dataIndex: "isDefault",
      width: 130,
      render(value, record, index) {
        return (
          <Typography
            color={record.isDefault ? "success.main" : "error.main"}
            fontSize={16}
            fontWeight={500}
            bgcolor={record.isDefault ? "success.light" : "error.light"}
            py={0.5}
            px={1}
            textAlign="center"
            borderRadius={2}
            maxWidth={100}
          >
            {record.isDefault ? "Active" : "InActive"}
          </Typography>
        );
      },
    },
    {
      title: "Action",
      width: 130,
      render: (value, record) => (
        <Box display="flex" justifyContent="end">
          <IconButton
            sx={{
              svg: {
                width: 26,
                height: 26,
              },
            }}
            onClick={() => navigate(`edit-subField/${record.id}`)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            sx={{
              svg: {
                width: 26,
                height: 26,
              },
            }}
            // onClick={() =>
            //   dispatch(
            //     deleteServiceSupplementarySubField({
            //       supplementarySubFieldId: record.id,
            //       fetchParams: { ...parameters },
            //     })
            //   )
            // }
            onClick={() => {
              handleOpenConfirmModal();
              setDeleteId(record.id);
            }}
          >
            <TrashIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    if (supplementaryId)
      dispatch(
        fetchSupplementaryById({ supplementaryId: Number(supplementaryId) })
      );
    setTableParentHeight(getTableParentHeight());
  }, []);

  useEffect(() => {
    const windowResizeEventCallback = (e: any) => {
      const tableParentElement = getTableParentHeight();
      setTableParentHeight(tableParentElement);
    };
    window?.addEventListener("resize", windowResizeEventCallback);
    return () =>
      window.removeEventListener("resize", windowResizeEventCallback);
  }, []);
  return (
    <ThreeSectionLayout header={<SupplementarySubFieldListHeader />}>
      {tableParentHeight && (
        <Table
          loading={isFetchingSupplementary}
          columns={columns}
          dataSource={supplementaryById?.supplementarySubFieldList}
          pagination={false}
          scroll={
            (supplementaryById?.supplementarySubFieldList?.length as number) > 0
              ? { x: 800, y: (tableParentHeight as number) - 70 }
              : { x: undefined, y: undefined }
          }
          className="shiftTable"
          rowKey="id"
        />
      )}
      <ConfirmModal
        isDeleting={isDeletingSupplementarySubField}
        open={openConfirmModal}
        handleClose={handleCloseConfirmModal}
        action={() =>
          dispatch(
            deleteSupplementarySubField({
              supplementarySubFieldId: deleteId,
              fetchParams: { supplementaryId: Number(supplementaryId) },
              closeModalHandler: handleCloseConfirmModal,
            })
          )
        }
      />
    </ThreeSectionLayout>
  );
};

export default ListSubField;
