import { useEffect, useState, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";

// MUI
import {
  Grid,
  Typography,
  FormControlLabel,
  Switch,
  styled,
} from "@mui/material";
import OneSectionLayout from "../../layout/OneSection.layouts";
import BaseButton from "../../components/base/BaseButton";
import BaseFileInput from "../../components/base/BaseFileInput";
import { useServiceForm } from "../../helpers/formik";

// icons
import { ReactComponent as ImageInputIcon } from "../../assets/icons/image-input-icon.svg";
import BaseInput from "../../components/base/BaseInput";
import BaseSelect from "../../components/base/BaseSelect";
import BaseTextInput from "../../components/base/BaseTextInput";
import BaseCountInput from "../../components/base/BaseCountInput";
import {
  IPostServiceActionParam,
  fetchServiceCommonData,
} from "../../redux/services/services.actions";
import { IEditActionsApiMethodType } from "../../redux/shifts/shift.actions";
import { postService } from "../../redux/services/services.actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { useAppSelector } from "../../hooks/redux.hooks";
import { selectInputItemsFormatter } from "../../helpers/utils";
import BaseDatePicker from "../../components/base/BaseDatePicker";
import { DateObject } from "react-multi-date-picker";
import { RotatingLines } from "react-loader-spinner";
import { NumericFormatCustom } from "../../components/base/BaseMaskInput";
import SelectTimeCard from "../../components/cards/SelectTime.cards";

const CreateService = () => {
  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { user } = useAppSelector((state) => state.user);
  const { isFetchingService, isPostingService, commonData } = useAppSelector(
    (state) => state.service
  );

  // states
  const [attachment, setAttachment] = useState();

  const submitHandler = () => {
    const formData = new FormData();
    for (const key in values) {
      if (
        key !== "serviceExpireDate" &&
        key !== "bookableBeforDay" &&
        key !== "serviceLogoImage"
      )
        //@ts-ignore
        formData.append(key, values[key]);
    }
    formData.append(
      "serviceExpireDate",
      new DateObject(values.serviceExpireDate as string).format("YYYY-MM-DD")
    );
    if (user) formData.append("shopId", String(user.shopId));
    if (attachment) formData.append("image", attachment);
    const parameters: IPostServiceActionParam = {
      type: IEditActionsApiMethodType.POST,
      body: formData,
    };

    validateForm();
    if (isValid) dispatch(postService(parameters));
  };

  useEffect(() => {
    dispatch(fetchServiceCommonData());
  }, []);

  const serviceCategoryInputItems = selectInputItemsFormatter(
    commonData?.serviceCategoryList,
    "serviceCategoryTitle",
    "id"
  );

  const serviceTypeInputItems = selectInputItemsFormatter(
    commonData?.serviceTypeList,
    "lookupValueTitle",
    "id"
  );

  const payTypeInputItems = selectInputItemsFormatter(
    commonData?.payTypeList,
    "lookupValueTitle",
    "id"
  );

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    isValid,
    validateForm,
  } = useServiceForm(null, submitHandler);

  const [serviceDuration, setServiceDuration] = useState([
    {
      id: 1,
      type: "hour",
      title: "Hour",
      value: values.duration > 50 ? Math.floor(values.duration / 60) : 0,
    },
    {
      id: 2,
      type: "minute",
      title: "Minute",
      value: values.duration <= 50 ? values.duration : values.duration % 60,
    },
  ]);

  const [bookableBeforeTime, setBookableBeforeTime] = useState([
    {
      id: 1,
      type: "day",
      title: "Day",
      value: values.bookableBeforDay ? values.bookableBeforDay : 0,
    },
    {
      id: 3,
      type: "hour",
      title: "Hour",
      value:
        values.bookableBeforHours > 60
          ? Math.floor(values.bookableBeforHours / 60)
          : 0,
    },
    // {
    //   id: 2,
    //   type: "minute",
    //   title: "Minute",
    //   value:
    //     values.bookableBeforHours <= 60
    //       ? values.bookableBeforHours
    //       : values.bookableBeforHours % 60,
    // },
  ]);

  const [gapTime, setGapTime] = useState([
    {
      id: 1,
      type: "hour",
      title: "Hour",
      value:
        values.timeBetweenTwoServ > 55
          ? Math.floor(values.timeBetweenTwoServ / 60)
          : 0,
    },
    {
      id: 2,
      type: "minute",
      title: "Minute",
      value:
        values.timeBetweenTwoServ <= 55
          ? values.timeBetweenTwoServ
          : values.timeBetweenTwoServ % 60,
    },
  ]);

  // setting formik duration
  useEffect(() => {
    const finalResult = serviceDuration;
    let totalMin = 0;
    finalResult.map((item) => {
      if (item.type === "hour") {
        totalMin += item.value * 60;
      } else if (item.type === "minute") {
        totalMin += item.value;
      }
    });
    setFieldValue("duration", totalMin);
  }, [serviceDuration]);

  // setting formik day and hours
  useEffect(() => {
    const finalResult = bookableBeforeTime;
    let totalHour = 0;
    // let totalDay = 0;
    finalResult.map((item) => {
      if (item.type === "hour") {
        totalHour += item.value;
      }
      // else if (item.type === "minute") {
      //   totalMin += item.value;
      // }
      else if (item.type === "day") {
        totalHour += item.value * 24;
      }
    });

    setFieldValue("bookableBeforHours", totalHour);
    // setFieldValue("bookableBeforDay", totalDay);
  }, [bookableBeforeTime]);

  // setting formik gap time
  useEffect(() => {
    const finalResult = gapTime;
    let totalMin = 0;
    finalResult.map((item) => {
      if (item.type === "hour") {
        totalMin += item.value * 60;
      } else if (item.type === "minute") {
        totalMin += item.value;
      }
    });
    setFieldValue("timeBetweenTwoServ", totalMin);
  }, [gapTime]);

  return (
    <OneSectionLayout>
      <Grid
        container
        item
        xs={12}
        zIndex={1}
        position="relative"
        top={-16}
        justifyContent="space-between"
      >
        <Typography
          height={70}
          display="flex"
          alignItems="center"
          color="grey.100"
        >
          Add Service
        </Typography>
        <Grid
          item
          container
          xs={12}
          md={8}
          gap={2}
          alignItems="center"
          justifyContent="end"
          position="unset"
          bottom={-5}
          left={0}
          bgcolor="transparent"
          p={0}
          zIndex={10}
        >
          <BaseButton
            sx={{
              width: 150,
              bgcolor: "white",
              color: "primary.main",
              "&:hover": {
                bgcolor: "white",
              },
              border: 0,
              fontWeight: 500,
              height: 45,
            }}
            onClick={() => handleSubmit()}
            disabled={isPostingService}
          >
            {isPostingService ? (
              <Grid
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  zIndex: 10,
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="30"
                  visible
                />
              </Grid>
            ) : (
              "Save"
            )}
          </BaseButton>

          <BaseButton
            sx={{
              width: 150,
              border: 0,
              display: "flex",
              boxShadow: 0,
            }}
            onClick={() => navigate(-1)}
          >
            Discard
          </BaseButton>
        </Grid>
      </Grid>
      <Grid container rowGap={2} maxWidth={1300}>
        {/* Service section */}
        <Grid item xs={12}>
          <Typography color="primary" fontWeight={500}>
            Service Info
          </Typography>
        </Grid>
        <Grid container flexWrap="nowrap" gap={2} justifyContent="center">
          <Grid item flexBasis={120}>
            <BaseFileInput
              attachment={attachment}
              setAttachment={setAttachment}
              icon={<ImageInputIcon />}
            />
          </Grid>
          <Grid
            item
            md={6}
            display="grid"
            gridTemplateColumns="1fr 1fr"
            gap={2}
          >
            <BaseInput
              variant="outlined"
              value={values.serviceTitle}
              name="serviceTitle"
              onChange={handleChange}
              placeholder="Title"
              label="Title"
            />
            <BaseSelect
              value={values.serviceCategoryId}
              name="serviceCategoryId"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFieldValue("serviceCategoryId", e.target.value)
              }
              sx={{ bgcolor: "grey.100" }}
              defaultVal="Service Category"
              label="category"
              inputItems={serviceCategoryInputItems}
              isFetching={isFetchingService}
            />
            <BaseSelect
              value={values.serviceType}
              name="serviceType"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFieldValue("serviceType", e.target.value)
              }
              sx={{ bgcolor: "grey.100" }}
              defaultVal="Service Type"
              label="Service Type"
              inputItems={serviceTypeInputItems}
            />
            <BaseDatePicker
              value={values.serviceExpireDate}
              setValue={(value: any) =>
                setFieldValue("serviceExpireDate", value)
              }
            />
          </Grid>
          <Grid item flexGrow={1} position="relative">
            <BaseTextInput
              value={values.serviceDescription}
              onChange={handleChange}
              name="serviceDescription"
              label="Description"
            />
          </Grid>
        </Grid>
        {/* Additional Details */}
        <Grid item xs={12}>
          <Typography color="primary" fontWeight={500}>
            Additional Details
          </Typography>
        </Grid>
        <Grid item xs={12} container gap={2} flexWrap="nowrap">
          <Grid item xs={4} container gap={2}>
            <Grid item xs={12}>
              <BaseCountInput
                value={values.countInTime}
                name="countInTime"
                handleChange={setFieldValue}
                label="Count In Time"
              />
            </Grid>
            <Grid item xs={12}>
              <BaseCountInput
                value={values.confirmDeadLine}
                name="confirmDeadLine"
                handleChange={setFieldValue}
                label="Confirm Deadline"
              />
            </Grid>
          </Grid>
          <Grid item xs={4} position="relative">
            <BaseTextInput
              value={values.agreementText}
              onChange={handleChange}
              name="agreementText"
              label="Agreement Note"
            />
            <Typography
              position="absolute"
              fontSize={12}
              top={values.agreementText ? -8 : 14}
              left={values.agreementText ? 8 : 15}
              sx={{ transition: "ease all 0.3s" }}
            >
              Agreement Note
            </Typography>
          </Grid>
          <Grid item xs={4} container gap={2}>
            <Grid
              item
              xs={12}
              bgcolor="inputBackground.main"
              position="relative"
              borderRadius="5px"
              container
              alignItems="center"
              height={45}
            >
              <Typography color="grey.500" pl={1}>
                color
              </Typography>
              <CustomColorInput>
                <Typography>{values.serviceCalendarColor}</Typography>
                <input
                  type="color"
                  style={{
                    border: 0,
                    backgroundColor: "transparent",
                    width: 30,
                    height: 30,
                    borderRadius: 5,
                  }}
                  id="input-color"
                  value={values.serviceCalendarColor}
                  onChange={handleChange}
                  name="serviceCalendarColor"
                />
              </CustomColorInput>
            </Grid>
            <Grid item xs={12}>
              <BaseCountInput
                value={values.maxNumberOfGuest}
                name="maxNumberOfGuest"
                handleChange={setFieldValue}
                label="Max Num Of Guests"
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Duration */}
        <Grid item xs={12}>
          <Typography color="primary" fontWeight={500}>
            Duration
          </Typography>
        </Grid>
        <Grid
          display="grid"
          gridTemplateColumns="repeat(4, 1fr)"
          gap={2}
          width="100%"
        >
          <SelectTimeCard
            timeDataState={serviceDuration}
            timeDateSetState={setServiceDuration}
            label="Service Duration"
          />
          <SelectTimeCard
            timeDataState={gapTime}
            timeDateSetState={setGapTime}
            label="Gap Time"
          />
          <SelectTimeCard
            timeDataState={bookableBeforeTime}
            timeDateSetState={setBookableBeforeTime}
            label="Bookable Before Time"
          />
        </Grid>

        {/* Price */}
        <Grid item xs={12}>
          <Typography color="primary" fontWeight={500}>
            Price
          </Typography>
        </Grid>
        <Grid
          display="grid"
          gridTemplateColumns="repeat(4,1fr)"
          gap={2}
          width="100%"
        >
          <BaseSelect
            value={values.paymentType}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFieldValue("paymentType", e.target.value)
            }
            defaultVal="Payment Type"
            inputItems={payTypeInputItems}
            sx={{ bgcolor: "grey.100", height: "fit-content" }}
            label="Payment Type"
          />
          <BaseInput
            variant="outlined"
            value={values.amount}
            name="amount"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFieldValue("amount", Number(e.target.value));
            }}
            placeholder="Amount"
            InputProps={{
              inputComponent: NumericFormatCustom as any,
            }}
            label="Amount"
          />
          <BaseInput
            variant="outlined"
            value={values.prePayment}
            name="prePayment"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFieldValue("prePayment", Number(e.target.value));
            }}
            placeholder="prePayment"
            InputProps={{
              inputComponent: NumericFormatCustom as any,
            }}
            label="Pre Payment"
          />
          <BaseInput
            variant="outlined"
            value={values.cancellationFee}
            name="cancellationFee"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFieldValue("cancellationFee", Number(e.target.value));
            }}
            placeholder="Cancellation Fee"
            InputProps={{
              inputComponent: NumericFormatCustom as any,
            }}
            label="Cancellation Fee"
          />
        </Grid>
        {/* Notification */}
        <Grid item xs={12}>
          <Typography color="primary" fontWeight={500}>
            Notification
          </Typography>
        </Grid>
        <Grid
          display="grid"
          gridTemplateColumns="repeat(4,1fr)"
          gap={2}
          width="100%"
        >
          <FormControlLabel
            control={
              <Switch
                checked={values.serviceStatus}
                size="small"
                name="serviceStatus"
                onChange={handleChange}
              />
            }
            label={<Typography fontWeight={400}>Status</Typography>}
            labelPlacement="start"
            sx={{
              width: "100%",
              justifyContent: "space-between",
              mx: 0,
              bgcolor: "inputBackground.main",
              p: 1,
              borderRadius: 2,
            }}
          />
          <FormControlLabel
            label={<Typography fontWeight={400}>Repeatable</Typography>}
            control={
              <Switch
                checked={values.isRepeatable}
                name="isRepeatable"
                onChange={handleChange}
                size="small"
              />
            }
            labelPlacement="start"
            sx={{
              width: "100%",
              justifyContent: "space-between",
              mx: 0,
              bgcolor: "inputBackground.main",
              p: 1,
              borderRadius: 2,
            }}
          />
          <FormControlLabel
            label={<Typography fontWeight={400}>Staff Based</Typography>}
            control={
              <Switch
                checked={values.isStaffBased}
                name="isStaffBased"
                onChange={handleChange}
                size="small"
              />
            }
            labelPlacement="start"
            sx={{
              width: "100%",
              justifyContent: "space-between",
              mx: 0,
              bgcolor: "inputBackground.main",
              p: 1,
              borderRadius: 2,
            }}
          />
          <FormControlLabel
            label={<Typography fontWeight={400}>Must Confirm</Typography>}
            control={
              <Switch
                checked={values.confirmType}
                name="confirmType"
                onChange={handleChange}
                size="small"
              />
            }
            labelPlacement="start"
            sx={{
              width: "100%",
              justifyContent: "space-between",
              mx: 0,
              bgcolor: "inputBackground.main",
              p: 1,
              borderRadius: 2,
            }}
          />
        </Grid>
      </Grid>
    </OneSectionLayout>
  );
};

export const CustomColorInput = styled(Grid)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    gap: 1,
    bgcolor: "#fff",
    position: "absolute",
    top: "50%",
    right: 10,
    transform: "translateY(-50%)",
    borderRadius: "5px",
    p: 0.2,
    minWidth: 125,
    "#input-color::-webkit-color-swatch": {
      border: "none",
      borderRadius: "5px",
      padding: 0,
    },
  })
);

export default CreateService;
