import { FC } from "react";

// MUI
import { Grid, Modal, Typography } from "@mui/material";

// components
import BaseButton from "../base/BaseButton";

// icons
import { ReactComponent as CancelIcon } from "../../assets/icons/cancel-icon.svg";
import { RotatingLines } from "react-loader-spinner";

type IConfirmModalProps = {
  open: boolean;
  handleClose: any;
  action: any;
  isDeleting: boolean;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  overflowY: "auto",
  maxHeight: "80%",
  p: 2,
};

const ConfirmModal: FC<IConfirmModalProps> = ({
  action,
  isDeleting,
  open,
  handleClose,
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Grid sx={style}>
        <Grid container justifyContent="space-between" alignItems="flex-start">
          <Grid
            item
            xs={12}
            mb={4}
            textAlign="center"
            sx={{ svg: { width: 28, height: 28 } }}
            container
            alignItems="center"
            justifyContent="center"
            gap={2}
          >
            <CancelIcon />
            <Typography>Are you sure?</Typography>
          </Grid>
          <Grid item xs={5}>
            <BaseButton
              disabled={isDeleting}
              fullWidth
              color="error"
              onClick={action}
              sx={{
                height: 44,
                borderColor: "error.main",
                "&:hover": {
                  borderColor: "transparent",
                },
              }}
            >
              {isDeleting ? (
                <Grid
                  sx={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    zIndex: 10,
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="30"
                    visible
                  />
                </Grid>
              ) : (
                "Yes"
              )}
            </BaseButton>
          </Grid>
          <Grid item xs={5}>
            <BaseButton fullWidth variant="text" onClick={handleClose}>
              Discard
            </BaseButton>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ConfirmModal;
