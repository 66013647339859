import { useState, useEffect } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { useAppSelector } from "../../hooks/redux.hooks";
// layout
import OneSectionLayout from "../../layout/OneSection.layouts";

// components
import EditService from "./Edit.services";
import DurationService from "./Duration.services";
import PriceService from "./Price.services";
import { DateObject } from "react-multi-date-picker";
import Additional from "./additional/Additional";

// types
import { ISubRoutes } from "../../App";
import { IEditActionsApiMethodType } from "../../redux/shifts/shift.actions";

// icons
import { ReactComponent as DurationIcon } from "../../assets/icons/duration-icon.svg";
import { ReactComponent as PaymentIcon } from "../../assets/icons/payment-icon.svg";
import { ReactComponent as OverviewIcon } from "../../assets/icons/overview-icon.svg";
import { ReactComponent as SupplementaryIcon } from "../../assets/icons/supplementary-icon.svg";
import { ReactComponent as AdditionalIcon } from "../../assets/icons/additional-icon.svg";

import {
  IPostServiceActionParam,
  fetchServiceById,
  postService,
} from "../../redux/services/services.actions";

import { useServiceForm } from "../../helpers/formik";
import SupplementaryService from "./Supplementary.services";
import PageNotFound from "../errors/PageNotFound.error";

const serviceSubRoutes: ISubRoutes[] = [
  { id: 0, name: "overview", url: "", icon: <OverviewIcon /> },
  {
    id: 1,
    name: "Duration",
    url: "duration",
    icon: <DurationIcon />,
  },
  {
    id: 2,
    name: "price",
    url: "price",
    icon: <PaymentIcon />,
  },
  {
    id: 3,
    name: "supplementary",
    url: "supplementary",
    icon: <SupplementaryIcon />,
  },
  {
    id: 4,
    name: "additional",
    url: "additional",
    icon: <AdditionalIcon />,
  },
];

const OverviewService = () => {
  // hooks
  const dispatch = useDispatch<AppDispatch>();
  const { serviceId } = useParams();

  const { user } = useAppSelector((state) => state.user);
  const { serviceById } = useAppSelector((state) => state.service);

  // states
  const [attachment, setAttachment] = useState();

  useEffect(() => {
    if (serviceId) dispatch(fetchServiceById({ serviceId }));
  }, [serviceId]);

  const submitHandler = () => {
    const formData = new FormData();
    for (const key in values) {
      if (
        key !== "serviceExpireDate" &&
        key !== "bookableBeforDay" &&
        key !== "serviceLogoImage"
      )
        //@ts-ignore
        formData.append(key, values[key]);
    }
    formData.append(
      "serviceExpireDate",
      new DateObject(values.serviceExpireDate as string).format("YYYY-MM-DD")
    );
    if (user) formData.append("shopId", String(user.shopId));
    if (attachment) formData.append("image", attachment);
    const parameters: IPostServiceActionParam = {
      type: IEditActionsApiMethodType.PATCH,
      body: formData,
    };

    dispatch(postService(parameters));
  };

  const formik = useServiceForm(serviceById, submitHandler);
  const { values } = formik;

  return (
    <OneSectionLayout routes={serviceSubRoutes}>
      <Routes>
        <Route
          element={
            <EditService
              formik={formik}
              attachment={attachment}
              setAttachment={setAttachment}
            />
          }
          path=""
        />
        <Route element={<DurationService formik={formik} />} path="duration" />
        <Route element={<PriceService formik={formik} />} path="price" />
        <Route element={<SupplementaryService />} path="supplementary/*" />
        <Route element={<Additional />} path="additional/*" />
        <Route element={<PageNotFound />} path="/*" />
      </Routes>
    </OneSectionLayout>
  );
};

export default OverviewService;
