import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosConfig from "../../axiosConfig";
import { IEditActionsApiMethodType } from "../shifts/shift.actions";

export type IBookingData = {
  id: number;
  customerId: number;
  customerTitle: string;
  bookingStatus: number;
  bookingRegDate: string;
  shopId: number;
  totalDiscount: number;
  totalForPay: number;
  headerDiscount: number;
  totalPrePayment: number;
  discountId: number;
  isDeleted: number;
  firstName: string;
  lastName: string;
  bookingStatusTitle: string;
  additionalServiceList: Object[];
  customerStripeId: null;
  itemList: Object[];
  productsList: Object[];
  supplementaryFieldList: Object[];
};

export type IBooking = {
  data: IBookingData[];
  count: number;
};

export type IBookingFetchAllParams = {
  pageNumber?: number;
  pageSize?: number;
  customerId?: string | null;
  fromDate?: string | null;
  toDate?: string | null;
};

export const fetchAllBooking = createAsyncThunk(
  "booking/fetchAllBooking",
  async (parameters: IBookingFetchAllParams, ThunkApi: any) => {
    const { pageNumber, pageSize, customerId, fromDate, toDate } = parameters;
    const { shopId } = ThunkApi.getState().user.user;
    const baseUrl = `Order/${pageNumber || 1}/${pageSize || 1000}/${shopId}`;

    const requestUrlSearchParams = new URLSearchParams();
    if (customerId) {
      requestUrlSearchParams.set("customerId", customerId);
    }
    if (fromDate) {
      requestUrlSearchParams.set("fromdate", fromDate);
    }
    if (toDate) {
      requestUrlSearchParams.set("todate", toDate);
    }

    const requestUrl = `${baseUrl}${
      requestUrlSearchParams.toString()
        ? `?${requestUrlSearchParams.toString()}`
        : ""
    }`;
    try {
      const { data } = await axiosConfig.get(requestUrl);

      return data as IBooking;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

export type IBookingPostParams = {
  type: IEditActionsApiMethodType;
  body: Object;
};

export const postBooking = createAsyncThunk(
  "booking/postBooking",
  async (parameters: IBookingPostParams, ThunkApi) => {
    const { type, body } = parameters;

    try {
      const { data } = await axiosConfig[type]("Booking", body);

      return data.data;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

export type IDeleteBookingParams = {
  bookingId: number;
  fetchParams: IBookingFetchAllParams;
};

export const deleteBooking = createAsyncThunk(
  "booking/deleteBooking",
  async (parameters: IDeleteBookingParams, ThunkApi) => {
    const { bookingId, fetchParams } = parameters;

    try {
      const { data } = await axiosConfig.delete(`Booking/${bookingId}`);
      ThunkApi.dispatch(fetchAllBooking(fetchParams));
      return data.data;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

export type IBookingDetailData = {
  id: number;
  bookingId: number;
  staffId: number;
  serviceId: number;
  shopId: number;
  bookingDetailStartDate: string;
  bookingDetailEndDate: string;
  bookingDetailStartTime: string;
  bookingDetailEndTime: string;
  numberOfGuest: number;
  bookingDetailStatus: number;
  agreementConfirmed: boolean;
  totalPayFor: number;
  discountId: number;
  discountAmount: number;
  prePayment: number;
};

export type IBookingDetail = {
  data: IBookingDetailData[];
  count: number;
};

export type IBookingDetailFetchAllParams = {
  pageNumber?: number;
  pageSize?: number;
  bookingId: number;
};

export const fetchAllBookingDetail = createAsyncThunk(
  "booking/fetchAllBookingDetail",
  async (parameters: IBookingDetailFetchAllParams, ThunkApi: any) => {
    const { pageNumber, pageSize, bookingId } = parameters;

    try {
      const { data } = await axiosConfig.get(
        `BookingDetail/Getbybookingid/${bookingId}/${pageNumber}/${pageSize}`
      );

      return data as IBookingDetail;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

export type IBookingDetailPostParams = {
  type: IEditActionsApiMethodType;
  body: Object;
};

export const postBookingDetail = createAsyncThunk(
  "booking/postBookingDetail",
  async (parameters: IBookingDetailPostParams, ThunkApi: any) => {
    const { type, body } = parameters;

    try {
      const { data } = await axiosConfig[type]("BookingDetail", body);
      return data;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

export type IDeleteBookingDetailParams = {
  bookingDetailId: number;
  fetchParams: IBookingDetailFetchAllParams;
};

export const deleteBookingDetail = createAsyncThunk(
  "booking/deleteBookingDetail",
  async (parameters: IDeleteBookingDetailParams, ThunkApi) => {
    const { bookingDetailId, fetchParams } = parameters;

    try {
      const { data } = await axiosConfig.delete(`Booking/${bookingDetailId}`);
      ThunkApi.dispatch(fetchAllBookingDetail(fetchParams));
      return data.data;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);
