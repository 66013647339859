import { useRef, useState, useEffect, FC } from "react";
import { useSearchParams } from "react-router-dom";
import { AppDispatch } from "../../redux/store";
import { DateObject } from "react-multi-date-picker";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../hooks/redux.hooks";

// MUI
import {
  Grid,
  styled,
  Typography,
  IconButton,
  Breadcrumbs,
  Skeleton,
} from "@mui/material";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Navigation, FreeMode } from "swiper";

// icons
import { ReactComponent as LeftIcon } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as RightIcon } from "../../assets/icons/arrow-right.svg";

import { setOrderDate } from "../../redux/order/order.reducer";
import { fetchServiceAvailableDays } from "../../redux/order/order.actions";
import { staffOrderTimesDummyData } from "../../helpers/data";

type IInsideSwiperProps = {
  date: any;
  active: number;
  today: any;
};

const InsideSwiper: FC<IInsideSwiperProps> = ({ date, active, today }) => {
  const swiper = useSwiper();
  const { orderDate, isFetchingServiceAvailableDays } = useAppSelector(
    (state) => state.order
  );
  const month = date.month.number;

  useEffect(() => {
    if (today.month.number === month) {
      if (orderDate) {
        swiper.slideTo(orderDate.day - 1);
      } else if (active) {
        swiper.slideTo(active - 2);
      }
    } else swiper.slideTo(0);
  }, [active, isFetchingServiceAvailableDays]);

  return <div />;
};

const CalendarCard = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { user } = useAppSelector((state) => state.user);
  const {
    orderDate,
    orderServiceAvailableDays,
    isFetchingServiceAvailableDays,
  } = useAppSelector((state) => state.order);

  const [searchParams, setSearchParams] = useSearchParams();

  const bookingSearchParam = searchParams.get("bookingId");
  const customerSearchParam = searchParams.get("customerId");
  const customerNameSearchParam = searchParams.get("customer");
  const serviceCategorySearchParam = searchParams.get("serviceCategory");
  const serviceSearchParam = searchParams.get("service");
  const serviceIdSearchParam = searchParams.get("serviceId");
  const dateSearchParam = searchParams.get("date");

  const [now, setNow] = useState(
    new DateObject(dateSearchParam ? dateSearchParam : "")
  );
  const [days, setDays] = useState<number[]>(
    Array(now.months[now.monthIndex].length)
      .fill(null)
      .map((item, i) => i + 1)
  );
  const [activeDate, setActiveDate] = useState(0);
  const navigationPrev = useRef(null);
  const navigationNext = useRef(null);
  const [swiperProps, setSwiperProps] = useState({
    activeIndex: 0,
    isEnd: false,
  });

  const initialDate = `${now.year}-${now.month.number}-1`;

  const nextPrevButtonHandler = (type: number) => () => {
    const newDateObject = new DateObject(now);
    if (
      type === -1 &&
      swiperProps.activeIndex === 0 &&
      now.month.number > today.month.number
    ) {
      setNow(newDateObject.subtract(1, "month"));
    }
    if (swiperProps.isEnd) {
      setNow(newDateObject.add(1, "month"));
    }
  };

  const today = new DateObject();

  const renderCalendar = () => {
    const days = Array(now.months[now.monthIndex].length)
      .fill(null)
      .map((item, i) => i + 1);

    setDays(days);
  };

  useEffect(() => {
    renderCalendar();
  }, [now.month.number]);

  useEffect(() => {
    setActiveDate(today.day);
  }, []);

  // useEffect(() => {
  //   if (!orderDate)
  //     dispatch(
  //       setOrderDate({
  //         year: today.year,
  //         month: today.month.number,
  //         day: today.day,
  //       })
  //     );
  // }, []);

  useEffect(() => {
    const newDays = Array(now.months[now.monthIndex].length)
      .fill(null)
      .map((item, i) => i + 1);

    setDays(newDays);

    if (days.length)
      dispatch(
        fetchServiceAvailableDays({
          serviceId: String(serviceIdSearchParam),
          shopId: Number(user?.shopId),
          dateTime: initialDate,
          seed: newDays.length,
        })
      );
  }, [now.month.number]);

  const handleDatePicker = (date: Object) => {
    dispatch(setOrderDate(date));
  };

  let nextSearchParams = {};
  if (bookingSearchParam) {
    nextSearchParams = {
      bookingId: bookingSearchParam,
      customerId: String(customerSearchParam),
      customer: String(customerNameSearchParam),
      serviceCategory: String(serviceCategorySearchParam),
      serviceId: String(serviceIdSearchParam),
      service: String(serviceSearchParam),
    };
  } else {
    nextSearchParams = {
      customerId: String(customerSearchParam),
      customer: String(customerNameSearchParam),
      serviceCategory: String(serviceCategorySearchParam),
      serviceId: String(serviceIdSearchParam),
      service: String(serviceSearchParam),
    };
  }

  return (
    <Grid width="100%">
      <Grid container alignItems="center" justifyContent="space-between" mb={2}>
        <Typography fontSize={20} fontWeight={500} color="primary.main">
          Select Date
        </Typography>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Typography>{customerNameSearchParam}</Typography>
          <Typography>{serviceSearchParam}</Typography>
        </Breadcrumbs>
        <Grid display="flex" alignItems="center" gap={2}>
          <IconButton
            disabled={now.month.number <= today.month.number}
            onClick={() => {
              // @ts-ignore
              const newDateObject = new DateObject(now);
              setNow(newDateObject.add(-1, "month"));
            }}
            sx={{
              svg: {
                width: 20,
                height: 20,
                cursor: "pointer",
                path: {
                  stroke:
                    now.month.number > today.month.number
                      ? "#785FDC"
                      : "#a1a1a1",
                },
              },
            }}
          >
            <LeftIcon />
          </IconButton>

          <Typography fontSize={22} fontWeight={500}>
            {now.month.shortName}
          </Typography>

          <Typography fontSize={22} fontWeight={500}>
            {now.year}
          </Typography>
          <IconButton
            sx={{
              svg: {
                width: 20,
                height: 20,
                cursor: "pointer",
                path: {
                  stroke: "#785FDC",
                },
              },
            }}
            onClick={() => {
              const newDateObject = new DateObject(now);
              setNow(newDateObject.add(1, "month"));
            }}
          >
            <RightIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Grid
        sx={{
          width: "100%",
          ".swiper-slide": {
            height: "85%",
            width: 100,
            overflow: "hidden",
          },
          position: "relative",
        }}
      >
        <Swiper
          onInit={(swiper: any) => {
            swiper.params.navigation.prevEl = navigationPrev.current;
            swiper.params.navigation.nextEl = navigationNext.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          onSlideChange={(swiper) => {
            setSwiperProps({
              activeIndex: swiper.activeIndex,
              isEnd: swiper.isEnd,
            });
          }}
          freeMode={true}
          breakpoints={{
            0: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1500: {
              slidesPerView: 7,
              spaceBetween: 20,
            },
          }}
          navigation={{
            prevEl: navigationPrev.current,
            nextEl: navigationNext.current,
          }}
          modules={[Navigation, FreeMode]}
          style={{
            width: "calc(100% - 80px)",
            padding: "0 4px",
          }}
        >
          <InsideSwiper
            date={now}
            active={now.monthIndex === today.monthIndex ? activeDate : 0}
            today={today}
          />
          {isFetchingServiceAvailableDays
            ? staffOrderTimesDummyData.map((item, index) => (
                <SwiperSlide key={index}>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={80}
                    sx={{ borderRadius: 2 }}
                  />
                </SwiperSlide>
              ))
            : days.map((i) => {
                const disabledDays =
                  orderServiceAvailableDays[i - 1]?.status !== 1;
                const disabled = disabledDays;

                const key = `${now.year}-${now.month.number}-${i}`;

                const dayName = new DateObject().set({
                  year: now.year,
                  month: now.month.number,
                  day: i,
                }).weekDay.shortName;

                return (
                  <SwiperSlide key={key}>
                    <Days
                      sx={{
                        color:
                          orderDate?.year === now.year &&
                          orderDate?.month === now.month.number &&
                          orderDate?.day === i
                            ? "white"
                            : "grey.800",
                        border: 2,
                        borderColor:
                          i === today.day && today.monthIndex === now.monthIndex
                            ? "primary.main"
                            : "transparent",
                        bgcolor:
                          orderDate?.year === now.year &&
                          orderDate?.month === now.month.number &&
                          orderDate?.day === i
                            ? "primary.main"
                            : "white",

                        "&:hover": {
                          bgcolor:
                            orderDate?.year === now.year &&
                            orderDate?.month === now.month.number &&
                            orderDate?.day === i
                              ? "primary.light"
                              : "grey.200",
                        },
                        opacity: disabled ? "0.6" : 1,
                        cursor: disabled ? "not-allowed" : "pointer",
                      }}
                      onClick={() => {
                        if (!disabled) {
                          handleDatePicker({
                            year: now.year,
                            month: now.month.number,
                            day: i,
                          });
                          Object.assign(nextSearchParams, {
                            date: `${now.year}-${now.month.number}-${i}`,
                            active: "2",
                          });
                          setSearchParams(nextSearchParams);
                        }
                      }}
                    >
                      <Typography
                        fontWeight={
                          i === activeDate &&
                          today.monthIndex === now.monthIndex
                            ? "bold"
                            : 500
                        }
                      >
                        {dayName}
                      </Typography>
                      <Typography
                        fontWeight={
                          i === activeDate &&
                          today.monthIndex === now.monthIndex
                            ? "bold"
                            : 500
                        }
                      >
                        {i}
                      </Typography>
                    </Days>
                    {/* <BaseButton>
                      <Typography>{}</Typography>
                      <Typography>{dayName}</Typography>
                    </BaseButton> */}
                  </SwiperSlide>
                );
              })}
        </Swiper>
        <NavContainer
          ref={navigationPrev}
          onClick={nextPrevButtonHandler(-1)}
          // disabled={
          // swiperProps.activeIndex === 0 &&
          // now.month.number <= today.month.number
          // }
          sx={{
            left: 0,
            svg: {
              path: {
                stroke:
                  swiperProps.activeIndex === 0 &&
                  now.month.number <= today.month.number
                    ? "#a1a1a1"
                    : "#785FDC",
              },
            },
          }}
        >
          <LeftIcon />
        </NavContainer>
        <NavContainer
          sx={{
            right: 0,
            svg: {
              path: {
                stroke: "#785FDC",
              },
            },
          }}
          ref={navigationNext}
          onClick={nextPrevButtonHandler(1)}
        >
          <RightIcon />
        </NavContainer>
      </Grid>
    </Grid>
  );
};

const Days = styled(Grid)(({ theme }) =>
  theme.unstable_sx({
    minWidth: 60,
    height: 80,
    borderRadius: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  })
);

export const NavContainer = styled(Grid)(({ theme }) =>
  theme.unstable_sx({
    justifyContent: "space-between",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 10,
    svg: {
      width: 25,
      height: 25,
      cursor: "pointer",
      path: {
        stroke: theme.palette.primary.main,
      },
    },
  })
);

export default CalendarCard;
