import { useState, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import OverviewStaff from "./Overview.staffs";
import CreateStaff from "./Create.staffs";
import { IPagination } from "./List.staffs";
import PageNotFound from "../errors/PageNotFound.error";
import { useAppSelector } from "../../hooks/redux.hooks";

const LazyList = lazy(() => import("./List.staffs"));

const Staff = () => {
  const [pagination, setPagination] = useState<IPagination>({
    pageNumber: 1,
    pageSize: 10,
  });

  const { staffError } = useAppSelector((state) => state.staff);

  return (
    <Routes>
      <Route
        element={
          <LazyList pagination={pagination} setPagination={setPagination} />
        }
        path="/"
      />
      <Route element={<CreateStaff />} path="/create-staff" />
      <Route
        element={staffError === 404 ? <PageNotFound /> : <OverviewStaff />}
        path="/edit-staff/:staffId/*"
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Staff;
