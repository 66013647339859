import React, { useState, ChangeEvent, useEffect } from "react";
import {
  URLSearchParamsInit,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

// MUI
import {
  Grid,
  InputAdornment,
  SelectChangeEvent,
  Popover,
} from "@mui/material";

// icons
import { ReactComponent as SearchIcon } from "../../assets/icons/Search.svg";
import { ReactComponent as ChevronIcon } from "../../assets/icons/chevron-icon.svg";
import BaseInput from "../base/BaseInput";
import BaseButton from "../base/BaseButton";
import CustomerListFilterCard from "../cards/CustomerListFilter.cards";

const CustomerListHeader = () => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  // states
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("lastName");
  const [searchInputValue, setSearchInputValue] = useState<string>(
    search ? search : ""
  );
  const mobile = searchParams.get("mobile");
  const customerStatus = searchParams.get("customerStatus");

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchInputText = e.target.value;

    setSearchInputValue(searchInputText);
  };

  const addCustomer = () => {
    navigate("create-customer");
  };

  const handleClickFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilters = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const changeDisplayNameDelay = setTimeout(() => {
      const nextSearchParams: URLSearchParamsInit = searchInputValue
        ? { lastName: searchInputValue }
        : {};

      if (mobile !== null) Object.assign(nextSearchParams, { mobile: mobile });
      if (customerStatus !== null)
        Object.assign(nextSearchParams, { customerStatus: customerStatus });

      setSearchParams(nextSearchParams);
    }, 1000);

    return () => {
      clearTimeout(changeDisplayNameDelay);
    };
  }, [searchInputValue]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Grid
      p={1.25}
      gap={2}
      display="grid"
      gridTemplateColumns="350px 1fr"
      justifyItems="end"
    >
      <BaseInput
        variant="outlined"
        p={1.25}
        fullWidth
        value={searchInputValue}
        onChange={handleSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ zIndex: 10 }}>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        placeholder="Search among customers"
      />
      <Grid display="grid" gridTemplateColumns="auto 150px 150px" gap={2}>
        <Grid gridColumn="1/2">
          <BaseButton sx={{ minWidth: 150 }} onClick={addCustomer}>
            Add Customer
          </BaseButton>
        </Grid>
        <Grid>
          <BaseButton
            variant="outlined"
            onClick={handleClickFilters}
            sx={{
              justifyContent: "space-around",
              "&:hover": {
                border: 2,
              },
            }}
          >
            Filters
            <ChevronIcon />
          </BaseButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleCloseFilters}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            PaperProps={{ sx: { borderRadius: "5px" } }}
          >
            <CustomerListFilterCard closePopover={handleCloseFilters} />
          </Popover>
        </Grid>
        <BaseButton
          variant="outlined"
          sx={{
            "&:hover": {
              border: 2,
            },
          }}
        >
          export
        </BaseButton>
      </Grid>
    </Grid>
  );
};

export default CustomerListHeader;
