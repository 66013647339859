import React from "react";
import { Route, Routes } from "react-router-dom";
import ListBookingDetail from "./List.bookingDetail";
import CreateBookingDetail from "./Create.bookingDetail";

const BookingDetail = () => {
  return (
    <Routes>
      <Route element={<ListBookingDetail />} path="/" />
      <Route element={<CreateBookingDetail />} path="/create-bookingDetail" />
    </Routes>
  );
};

export default BookingDetail;
