import { useState, useEffect } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { useAppSelector } from "../../hooks/redux.hooks";

// layout
import OneSectionLayout from "../../layout/OneSection.layouts";

// components
import HoursStaff from "./Hours.staffs";
import PaymentStaff from "./Payment.staffs";
import EditStaff from "./Edit.staffs";
import ServicesStaff from "./Services.staffs";

// icons
import { ReactComponent as DurationIcon } from "../../assets/icons/duration-icon.svg";
import { ReactComponent as PaymentIcon } from "../../assets/icons/payment-icon.svg";
import { ReactComponent as OverviewIcon } from "../../assets/icons/overview-icon.svg";
import { ReactComponent as ExpertiseIcon } from "../../assets/icons/expertise-icon.svg";

import { ISubRoutes } from "../../App";
import { useStaffForm } from "../../helpers/formik";
import {
  IPostStaffParams,
  fetchStaffById,
  postStaff,
} from "../../redux/staffs/staff.actions";
import { IEditActionsApiMethodType } from "../../redux/shifts/shift.actions";
import PageNotFound from "../errors/PageNotFound.error";

const staffSubRoutes: ISubRoutes[] = [
  {
    id: 0,
    name: "overview",
    url: "",
    icon: <OverviewIcon />,
  },
  {
    id: 1,
    name: "staff hours",
    url: "hours",
    icon: <DurationIcon />,
  },
  {
    id: 2,
    name: "payment",
    url: "payment",
    icon: <PaymentIcon />,
  },
  {
    id: 3,
    name: "staff services",
    url: "services",
    icon: <ExpertiseIcon />,
  },
];

const OverviewStaff = () => {
  // hooks
  const dispatch = useDispatch<AppDispatch>();

  const { user } = useAppSelector((state) => state.user);
  const { staffById } = useAppSelector((state) => state.staff);

  const { staffId } = useParams();
  // states
  const [attachment, setAttachment] = useState(null);

  useEffect(() => {
    if (staffId) dispatch(fetchStaffById({ staffId }));
  }, [staffId]);

  const submitHandler = () => {
    const formData = new FormData();
    for (const key in values) {
      if (key !== "staffImage" && key !== "image")
        //@ts-ignore
        formData.append(key, values[key]);
    }
    if (user) formData.append("shopId", String(user.shopId));
    if (attachment) formData.append("image", attachment);
    const parameters: IPostStaffParams = {
      type: IEditActionsApiMethodType.POST,
      body: formData,
    };
    validateForm();
    if (isValid) dispatch(postStaff(parameters));
  };

  const formik = useStaffForm(staffById, submitHandler);
  const { values, validateForm, isValid } = formik;
  return (
    <OneSectionLayout routes={staffSubRoutes}>
      <Routes>
        <Route
          element={
            <EditStaff
              formik={formik}
              attachment={attachment}
              setAttachment={setAttachment}
            />
          }
          path=""
        />
        <Route element={<HoursStaff />} path="hours" />
        <Route element={<PaymentStaff formik={formik} />} path="payment" />
        <Route element={<ServicesStaff />} path="services" />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </OneSectionLayout>
  );
};

export default OverviewStaff;
