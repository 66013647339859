import { ICustomerData } from "../redux/customer/customer.actions";
import { IOrderAvailableData } from "../redux/order/order.actions";

export const customerOrderDummyData: ICustomerData[] = Array(8)
  .fill(null)
  .map((item, i) => ({
    id: i,
    firstName: "test edit",
    lastName: "test last",
    userName: "t",
    password: "t",
    customerStatus: 1,
    shopId: 1,
    mobile: "914456",
    phone: "36",
    address: "test 45",
    postCode: "1",
    email: "t@gmail.com",
  }));

export const staffOrderTimesDummyData: IOrderAvailableData[] = Array(8)
  .fill(null)
  .map((item, i) => ({
    id: i,
    durationEnd: "08:55:00",
    durationStart: "08:00:00",
    isAvailable: false,
    mobile: "string",
    serviceId: 6,
    staffAmount: 100,
    staffDuration: 15,
    staffFamily: "kh",
    staffId: 14,
    staffImage: "638225279488192849.jpg",
    staffName: "h",
  }));
