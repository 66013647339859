import React, { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// MUI
import { Grid, Typography, Divider, Popover, Button } from "@mui/material";
import { RouteType } from "../../App";

// icons
import { ReactComponent as ClientsIcon } from "../../assets/icons/clients-icon.svg";
import { ReactComponent as Logo } from "../../assets/icons/logo-icon.svg";

import ProfileCard from "../cards/Profile.cards";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { clearBasket, setOrderDate } from "../../redux/order/order.reducer";

export type SideBarPropsTypes = {
  routes: RouteType[];
};

const MainSidebar: FC<SideBarPropsTypes> = ({ routes }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  // const [searchParams, setSearchParams] = useSearchParams();

  // state
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleProfile = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const navigateHandler = (url: string) => {
    if (url === "/booking") {
      navigate(url + "?active=0");
      dispatch(setOrderDate(null));
      dispatch(clearBasket());
    } else navigate(url);
  };

  return (
    <Grid
      item
      height="100%"
      bgcolor="primary.main"
      container
      alignItems="center"
      justifyContent="center"
      width={120}
      py={2}
      flexDirection="column"
      flexWrap="nowrap"
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        pb={2}
        sx={{ svg: { width: 80, height: 80, path: { fill: "white" } } }}
      >
        <Logo />
      </Grid>
      <Divider sx={{ width: "75%", borderColor: "white" }} />
      <Grid
        container
        alignItems="center"
        justifyContent="start"
        flexDirection="column"
        rowGap={3}
        py={3}
        flexWrap="nowrap"
        sx={{
          overflowY: "auto",
        }}
      >
        {routes.map((route) => (
          <Grid
            item
            key={route.id}
            textAlign="center"
            onClick={() => {
              navigateHandler(
                route.subRoutes ? route.subRoutes[0].url : route.url
              );
            }}
            sx={{
              cursor: "pointer",
              svg: {
                height: 32,
                path: {
                  fill:
                    "/" + pathname.split("/")[1] === route.url
                      ? "white"
                      : "#d7c2fe",
                },
              },
            }}
          >
            {route.icon}
            <Typography
              fontSize={14}
              fontWeight={500}
              color={
                "/" + pathname.split("/")[1] === route.url ? "white" : "#d7c2fe"
              }
              textTransform="capitalize"
            >
              {route.name}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Divider sx={{ width: "80%", borderColor: "white", mt: "auto" }} />
      <Grid
        item
        textAlign="center"
        // onClick={() => dispatch(logoutUser())}
        // onClick={handleProfile}
        sx={{
          cursor: "pointer",
          svg: { height: 32 },
          "& svg path": {
            fill: "white",
          },
        }}
        pt={2}
      >
        <Button
          sx={{ boxShadow: 0, display: "block", p: 0 }}
          onClick={handleProfile}
        >
          <ClientsIcon />

          <Typography
            fontSize={14}
            fontWeight={500}
            textTransform="capitalize"
            color="white"
          >
            Account
          </Typography>
        </Button>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <ProfileCard handleClose={handleClose} />
        </Popover>
      </Grid>
    </Grid>
  );
};

export default MainSidebar;
