import { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../hooks/redux.hooks";
import { DateObject } from "react-multi-date-picker";

// MUI
import { Grid, Typography, FormControlLabel, Switch } from "@mui/material";

import OneSectionLayout from "../../../layout/OneSection.layouts";
import BaseButton from "../../../components/base/BaseButton";
import BaseFileInput from "../../../components/base/BaseFileInput";
import BaseInput from "../../../components/base/BaseInput";
import BaseDatePicker from "../../../components/base/BaseDatePicker";
import { useServiceCategoryForm } from "../../../helpers/formik";

// icons
import { ReactComponent as ImageInputIcon } from "../../../assets/icons/image-input-icon.svg";
import { ReactComponent as SubtractIcon } from "../../../assets/icons/subtract-icon.svg";
import { ReactComponent as AddIcon } from "../../../assets/icons/add-icon.svg";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import {
  IPostServiceCategoryParam,
  IServiceCategoryData,
  postServiceCategory,
} from "../../../redux/services/services.actions";
import { IEditActionsApiMethodType } from "../../../redux/shifts/shift.actions";
import axiosConfig from "../../../axiosConfig";
import { setServiceCategoryError } from "../../../redux/services/services.reducer";

const CreateServiceCategory = () => {
  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { serviceCategoryId } = useParams();

  const { isPostingServiceCategory } = useAppSelector((state) => state.service);
  const { user } = useAppSelector((state) => state.user);

  // states
  const [attachment, setAttachment] = useState();
  const [serviceCategoryById, setServiceCategoryById] =
    useState<IServiceCategoryData>();
  const [isFetchingServiceCategoryById, setIsFetchingServiceCategoryById] =
    useState(false);

  const fetchServiceCategoryById = async () => {
    setIsFetchingServiceCategoryById(true);
    try {
      const { data } = await axiosConfig.get(
        `ServiceCategory/${serviceCategoryId}`
      );
      setIsFetchingServiceCategoryById(false);
      setServiceCategoryById(data.data);
    } catch (error: any) {
      setIsFetchingServiceCategoryById(false);
      dispatch(setServiceCategoryError(error.response.status));
      console.log(error);
    }
  };

  const nextPrevButtonHandler = (day: number) => () => {
    const newDateObject = new DateObject(values.expireDate as string);
    newDateObject.add(day, "days");
    setFieldValue("expireDate", newDateObject);
  };

  const submit = () => {
    const formData = new FormData();
    for (const key in values) {
      //@ts-ignore
      formData.append(key, values[key]);
    }
    if (user) formData.append("shopId", String(user.shopId));
    if (attachment) formData.append("image", attachment);
    const parameters: IPostServiceCategoryParam = {
      type: serviceCategoryId
        ? IEditActionsApiMethodType.PATCH
        : IEditActionsApiMethodType.POST,
      body: formData,
    };
    validateForm();
    if (isValid) dispatch(postServiceCategory(parameters));
  };

  useEffect(() => {
    if (serviceCategoryId) fetchServiceCategoryById();
  }, [serviceCategoryId]);

  const {
    values,
    handleSubmit,
    setFieldValue,
    handleChange,
    errors,
    validateForm,
    isValid,
  } = useServiceCategoryForm(serviceCategoryById, submit);

  return (
    <OneSectionLayout>
      <Grid
        container
        item
        xs={12}
        zIndex={1}
        position="relative"
        top={-16}
        justifyContent="space-between"
      >
        <Typography
          height={70}
          display="flex"
          alignItems="center"
          color="grey.100"
        >
          {serviceCategoryId ? "Edit " : "Add"} Service Category{" "}
          {serviceCategoryId && values.serviceCategoryTitle}
        </Typography>
        <Grid
          item
          container
          xs={12}
          md={8}
          gap={2}
          alignItems="center"
          justifyContent="end"
          position="unset"
          bottom={-5}
          left={0}
          bgcolor="transparent"
          p={0}
          zIndex={10}
        >
          <BaseButton
            sx={{
              width: 150,
              bgcolor: "white",
              color: "primary.main",
              "&:hover": {
                bgcolor: "white",
              },
              border: 0,
              fontWeight: 500,
              height: 45,
            }}
            onClick={() => handleSubmit()}
            disabled={isPostingServiceCategory}
          >
            {isPostingServiceCategory ? (
              <Grid
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  zIndex: 10,
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="30"
                  visible
                />
              </Grid>
            ) : (
              "Save"
            )}
          </BaseButton>
          <BaseButton
            sx={{
              width: 150,
              border: 0,
              display: "flex",
            }}
            onClick={() => navigate("/services/categories")}
          >
            Discard
          </BaseButton>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          lg={8}
          display="grid"
          gridTemplateColumns="150px 1fr"
          gap={2}
          position="relative"
        >
          {isFetchingServiceCategoryById && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              position="absolute"
              zIndex={1000}
              bgcolor="rgba(0,0,0, 0.05)"
              borderRadius={1}
              height="100%"
            >
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
              />
            </Grid>
          )}
          <BaseFileInput
            attachment={attachment}
            setAttachment={setAttachment}
            icon={<ImageInputIcon />}
            hasImage={
              serviceCategoryById
                ? `https://booking.weeorder.co.uk/uploadfiles/Images/${serviceCategoryById.categoryLogoImage}`
                : null
            }
          />
          <Grid display="grid" gridTemplateColumns="200px 1fr" gap={2}>
            <BaseInput
              variant="outlined"
              value={values.serviceCategoryTitle}
              name="serviceCategoryTitle"
              onChange={handleChange}
              placeholder="Category Title"
              label="Category Title"
              error={errors.serviceCategoryTitle ? true : false}
              helperText={errors.serviceCategoryTitle}
            />
            <Grid
              display="grid"
              gridTemplateColumns="auto 1fr auto"
              gap={1}
              borderRadius={1}
            >
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                px={1}
                borderRadius={2}
                sx={{
                  svg: { width: 24, height: 24 },
                  bgcolor: "inputBackground.main",
                }}
                onClick={nextPrevButtonHandler(-1)}
              >
                <SubtractIcon />
              </Grid>
              <BaseDatePicker
                value={new DateObject(values.expireDate as string)}
                setValue={(value: any) => setFieldValue("expireDate", value)}
                calendarPosition="bottom"
                format="DD MMM YYYY"
              />
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                px={1}
                borderRadius={2}
                sx={{
                  svg: { width: 24, height: 24 },
                  bgcolor: "inputBackground.main",
                }}
                onClick={nextPrevButtonHandler(1)}
              >
                <AddIcon />
              </Grid>
            </Grid>
            <FormControlLabel
              label={<Typography fontWeight={400}>Available</Typography>}
              control={
                <Switch
                  checked={values.serviceCategoryStatus ? true : false}
                  size="small"
                  name="serviceCategoryStatus"
                  onChange={() =>
                    setFieldValue(
                      "serviceCategoryStatus",
                      values.serviceCategoryStatus ? 0 : 1
                    )
                  }
                />
              }
              labelPlacement="start"
              sx={{
                p: 1,
                width: "100%",
                justifyContent: "space-between",
                mx: 0,
                bgcolor: "inputBackground.main",
                height: "100%",
                borderRadius: 1,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </OneSectionLayout>
  );
};

export default CreateServiceCategory;
