import { combineReducers } from "redux";
import userReducer from "./user/user.reducer";
import staffReducer from "./staffs/staff.reducer";
import shiftReducer from "./shifts/shift.reducer";
import servicesReducer from "./services/services.reducer";
import availabilityReducer from "./availability/availability.reducer";
import customerReducer from "./customer/customer.reducer";
import bookingReducer from "./booking/booking.reducer";
import orderReducer from "./order/order.reducer";
import lookupReducer from "./lookup/lookup.reducer";
import shopsReducer from "./shops/shops.reducer";

const rootReducer = combineReducers({
  user: userReducer,
  staff: staffReducer,
  shift: shiftReducer,
  service: servicesReducer,
  availability: availabilityReducer,
  customer: customerReducer,
  booking: bookingReducer,
  order: orderReducer,
  lookup: lookupReducer,
  shop: shopsReducer,
});

export default rootReducer;
