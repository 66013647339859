import React, { FC, useEffect } from "react";
import { Grid, Typography, Skeleton, Button } from "@mui/material";

import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right.svg";
import BaseSelect, { IInputType } from "../base/BaseSelect";
import { IPagination } from "../../pages/staffs/List.staffs";
import { IStaffType } from "../../redux/staffs/staff.actions";
import {
  IServiceCategoryType,
  ISupplementaryType,
  IServiceType,
} from "../../redux/services/services.actions";
import { IShiftType } from "../../redux/shifts/shift.actions";
import { IAvailabilityType } from "../../redux/availability/availability.actions";
import { ICustomerType } from "../../redux/customer/customer.actions";
import { IBooking, IBookingDetail } from "../../redux/booking/booking.actions";

interface PagingPropsType {
  pagination: IPagination;
  setPagination: React.Dispatch<React.SetStateAction<IPagination>>;
  data:
    | IStaffType
    | IServiceType
    | ISupplementaryType
    | IShiftType
    | IAvailabilityType
    | ICustomerType
    | IBooking
    | IBookingDetail
    | IServiceCategoryType
    | null;
}

const Paging: FC<PagingPropsType> = ({ pagination, setPagination, data }) => {
  const pageCount =
    Math.ceil((data?.count as number) / pagination.pageSize) || 1;

  const handlePage = (e: any) => {
    setPagination({ ...pagination, pageNumber: e.target.value });
  };

  const handleLimit = (e: any) => {
    setPagination({ ...pagination, pageSize: e.target.value });
  };

  let pageInputItems: IInputType[] = [{ id: 1, title: "1", value: 1 }];

  for (let i = 0; i < pageCount - 1; i++) {
    pageInputItems.push({
      id: i + 2,
      title: `${i + 2}`,
      value: i + 2,
    });
  }

  useEffect(() => {
    if (pagination.pageSize > (data?.count as number))
      setPagination({ ...pagination, pageNumber: 1 });
  }, [pagination.pageSize]);

  const isPrevDisabled = pagination.pageNumber === 1;
  const isNextDisabled = pagination.pageNumber === pageCount;

  return (
    <Grid container justifyContent="space-between" p={1.25}>
      <Grid
        bgcolor="grey.100"
        display="grid"
        gridTemplateColumns="60px 120px 50px"
        justifyContent="space-around"
        p={1}
        gap={1}
        borderRadius={2}
      >
        <Button
          disabled={isPrevDisabled}
          sx={{
            cursor: "pointer",
            gap: 1,
            color: "grey.800",
            svg: { path: { stroke: isPrevDisabled ? "#ccc" : "#333" } },
          }}
          variant="text"
          onClick={() =>
            setPagination({
              ...pagination,
              pageNumber: pagination.pageNumber - 1,
            })
          }
        >
          <ArrowLeft />
          <Typography
            fontWeight={500}
            fontSize={16}
            display="block"
            onClick={() =>
              setPagination({
                ...pagination,
                pageNumber: pagination.pageNumber - 1,
              })
            }
          >
            Prev
          </Typography>
        </Button>
        <Grid width="100%">
          {typeof data?.count === "number" ? (
            <BaseSelect
              value={pagination.pageNumber}
              onChange={handlePage}
              inputItems={pageInputItems}
              isPaginate
              totalPage={pageCount}
              border={2}
            />
          ) : (
            <Skeleton
              variant="rounded"
              sx={{ bgcolor: "#F3F0FF" }}
              height={50}
            />
          )}
        </Grid>
        <Button
          disabled={isNextDisabled}
          sx={{
            cursor: "pointer",
            gap: 1,
            color: "grey.800",
            svg: { path: { stroke: isNextDisabled ? "#ccc" : "#333" } },
          }}
          variant="text"
          onClick={() =>
            setPagination({
              ...pagination,
              pageNumber: pagination.pageNumber + 1,
            })
          }
        >
          <Typography fontWeight={500} fontSize={16} display="block">
            Next
          </Typography>
          <ArrowRight />
        </Button>
      </Grid>
      <Grid
        bgcolor="grey.100"
        display="grid"
        gridTemplateColumns="110px auto"
        borderRadius={2}
        gap={1}
        p={1}
      >
        <BaseSelect
          value={pagination.pageSize}
          onChange={handleLimit}
          border={2}
        />
        {data?.count ? (
          <Grid item container alignItems="center" width="max-content" gap={1}>
            <Typography display="block" fontSize={16} fontWeight={500}>
              Showing
            </Typography>
            <Typography fontSize={16} fontWeight={500}>
              {pagination.pageSize > data.count
                ? data.count
                : pagination.pageSize}{" "}
              of {data?.count}
            </Typography>
            <Typography display="block" fontSize={16} fontWeight={500}>
              entries
            </Typography>
          </Grid>
        ) : (
          <Typography
            display="flex"
            alignItems="center"
            fontSize={16}
            fontWeight={500}
          >
            No item
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default Paging;
