import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axiosConfig from "../axiosConfig";
import { useAppSelector } from "../hooks/redux.hooks";

const Dashboard = () => {
  // const options = {
  //   // passing the client secret obtained from the server
  //   clientSecret: "{{CLIENT_SECRET}}",
  // };

  // const { orderBasket } = useAppSelector((state) => state.order);

  // const fetchStripeKeys = async () => {
  //   try {
  //     const { data } = await axiosConfig.get(
  //       `Basket/onlinePayment/1/${orderBasket?.id}`
  //     );

  //     console.log(data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   if (orderBasket) fetchStripeKeys();
  //   // return () => fetchStripeKeys;
  // }, [orderBasket]);
  // const [state, setState] = useState({ otp: "" });

  // useEffect(() => {
  //   if ("OTPCredential" in window) {
  //     const ac = new AbortController();

  //     navigator.credentials
  //       .get({
  //         //@ts-ignore
  //         otp: { transport: ["sms"] },
  //         signal: ac.signal,
  //       })
  //       .then((otp) => {
  //         //@ts-ignore
  //         setState({ otp: otp?.code });
  //         ac.abort();
  //       })
  //       .catch((err) => {
  //         ac.abort();
  //         console.log(err);
  //       });
  //   }
  // }, []);

  return <></>;
};

export default Dashboard;
