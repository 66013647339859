import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosConfig from "../../axiosConfig";
import { toast } from "react-toastify";
import { IAdditionalServiceData } from "../services/services.actions";
import { setOrderDate } from "./order.reducer";
import { RootState } from "../store";

export type IOrderAvailableData = {
  durationEnd: string;
  durationStart: string;
  isAvailable: boolean;
  mobile: string;
  serviceId: number;
  staffAmount: number;
  staffDuration: number;
  staffFamily: string;
  staffId: number;
  staffImage: string;
  staffName: string;
};

export type IOrderAvailableParamsType = {
  searchDate: string;
  orderService: number | string;
};

export type IOrderAvailableType = {
  additionalServiceList: IAdditionalServiceData[];
  serviceFreeList: IOrderAvailableData[];
  maxNumberOfGuest: number;
};

export const fetchOrderServiceAvailableData = createAsyncThunk(
  "order/fetchOrderServiceAvailableData",
  async (parameters: IOrderAvailableParamsType) => {
    const { searchDate, orderService } = parameters;
    try {
      const { data } = await axiosConfig.get(
        `/Service/Serviceid/${searchDate}?serviceId=${orderService}`
      );
      return data.data;
    } catch (error: any) {
      return error.response.resultMessage;
    }
  }
);

export type IPostBasketParams = {
  mode: "another" | "payment" | "discount";
  body: Object;
  closeModal?: any;
  searchParams?: any;
  setSearchParams?: any;
};

export const postBasket = createAsyncThunk(
  "order/postBasket",
  async (parameters: IPostBasketParams, ThunkApi) => {
    const { mode, body, closeModal, searchParams, setSearchParams } =
      parameters;
    const promiseToast = toast.loading("Adding to basket...");

    try {
      const { data } = await axiosConfig.post("Basket", body);
      const customerId = searchParams.get("customerId");
      const customerName = searchParams.get("customer");
      toast.update(promiseToast, {
        render: data.resultMessage,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });

      if (mode === "payment") {
        setSearchParams({
          bookingId: data.bookingId,
          customerId,
          customer: customerName,
          active: "3",
        });
      } else if (mode === "another") {
        setSearchParams({
          bookingId: data.bookingId,
          customerId,
          customer: customerName,
          active: "1",
        });
      } else {
        closeModal();
      }

      ThunkApi.dispatch(fetchBasket({ bookingId: data.bookingId }));

      ThunkApi.dispatch(setOrderDate(null));
      return data.bookingId;
    } catch (error: any) {
      toast.update(promiseToast, {
        render: error.response ? error.response.title : "Request Timeout!",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

export type IBasketAdditional = {
  id: number;
  additionalServiceId: number;
  additionalServiceTitle: string;
  additionalServiceDuration: number;
  totalPayFor: number;
  additionalServiceCount: number;
  shopId: number;
};

export type IBasket = {
  itemList: IBasketData[];
  additionalServiceList: IBasketAdditional[];
  productsList: any[];
  supplementaryFieldList: any[];
  id: number;
  customerId: number;
  customerTitle: string;
  customerStripId: any;
  bookingStatus: number;
  bookingStatusTitle: string;
  bookingRegDate: string;
  shopId: number;
  totalDiscount: number;
  totalForPay: number;
  headerDiscount: number;
  totalPrePayment: number;
  discountId: number;
  isDeleted: number;
};

export type IBasketData = {
  amount: number;
  bookingDetailId: number;
  bookingId: number;
  discountAmount: number;
  endDate: string;
  endTime: string;
  numberOfGuest: number;
  prePayment: number;
  serviceId: number;
  serviceTitle: string;
  staffId: number;
  staffname: string;
  startDate: string;
  startTime: string;
  serviceLogoImage: string;
  additionalServiceList: IBasketAdditional[];
};

export type IFetchBasketParams = {
  bookingId: number | string;
  customer?: string;
  printInvoice?: () => void;
};

export const fetchBasket = createAsyncThunk(
  "order/fetchBasket",
  async (parameters: IFetchBasketParams, ThunkApi) => {
    const { bookingId, printInvoice } = parameters;
    try {
      const { data } = await axiosConfig.get(`Basket/${bookingId}`);
      printInvoice && printInvoice();

      return data.data as IBasket;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

export type IDeleteBasketParams = {
  itemId: number;
  bookingId: number;
  closeModalHandler: any;
};

export const deleteBasketItem = createAsyncThunk(
  "order/deleteBasketItem",
  async (parameters: IDeleteBasketParams, ThunkApi) => {
    const { itemId, bookingId, closeModalHandler } = parameters;
    const promiseToast = toast.loading("removing order item...");
    try {
      const { data } = await axiosConfig.delete(
        `Basket/deleteBasketItem/${bookingId}/${itemId}`
      );
      toast.update(promiseToast, {
        render: data.resultMessage,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      ThunkApi.dispatch(fetchBasket({ bookingId }));
      closeModalHandler();
      return data.data;
    } catch (error: any) {
      toast.update(promiseToast, {
        render: error.response ? error.response.data.title : "Request Timeout!",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

export const deleteBasketAdditional = createAsyncThunk(
  "order/deleteBasketAdditional",
  async (parameters: IDeleteBasketParams, ThunkApi) => {
    const { itemId, closeModalHandler, bookingId } = parameters;
    const promiseToast = toast.loading("removing order Additional...");
    try {
      const { data } = await axiosConfig.delete(
        `Basket/deletebasketadditionalservice/${bookingId}/${itemId}`
      );
      toast.update(promiseToast, {
        render: data.resultMessage,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      ThunkApi.dispatch(fetchBasket({ bookingId }));
      closeModalHandler();
      return data.data;
    } catch (error: any) {
      toast.update(promiseToast, {
        render: error.response ? error.response.data.title : "Request Timeout!",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

// payment
export type IBasketPaymentBody = {
  bookingId: number;
  amount: number;
  payMethod: number;
  date: string;
  intentId: string;
};

export type IPostBasketPaymentParams = {
  body: IBasketPaymentBody;
};

export const postBasketPayment = createAsyncThunk(
  "order/postPayment",
  async (parameters: IPostBasketPaymentParams, ThunkApi) => {
    const { body } = parameters;
    const promiseToast = toast.loading("Checkout...");
    try {
      const { data } = await axiosConfig.post(`Basket/Payment`, body);
      toast.update(promiseToast, {
        render: data.resultMessage,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      return data.data;
    } catch (error: any) {
      toast.update(promiseToast, {
        render: error.response ? error.response.data.title : "Request Timeout!",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

// service available calendar
export type IFetchServiceAvailableDaysParams = {
  shopId: number;
  serviceId: string;
  dateTime: string;
  seed: number;
};

export const fetchServiceAvailableDays = createAsyncThunk(
  "order/serviceAvailableDays",
  async (parameters: IFetchServiceAvailableDaysParams, ThunkApi) => {
    const { shopId, serviceId, dateTime, seed } = parameters;
    try {
      const { data } = await axiosConfig.get(
        `Service/servicestatus/${shopId}/${serviceId}/${dateTime}/${seed}`
      );

      return data.data;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);
