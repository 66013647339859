import { createSlice } from "@reduxjs/toolkit";
import {
  IShopData,
  IShopType,
  deleteShop,
  fetchAllShops,
  fetchShopById,
  postShop,
} from "./shops.actions";

type IState = {
  shopsData: IShopType | null;
  shopById: IShopData | null;
  isFetchingShop: boolean;
  shopError: null | string | any;
  isPostingShop: boolean;
  isDeletingShop: boolean;
};

const initialState: IState = {
  shopsData: null,
  shopById: null,
  isFetchingShop: false,
  isPostingShop: false,
  shopError: null,
  isDeletingShop: false,
};

const shopSlice = createSlice({
  initialState,
  name: "shop",
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetch all shops
      .addCase(fetchAllShops.pending, (state) => {
        state.isFetchingShop = true;
      })
      .addCase(fetchAllShops.fulfilled, (state, action) => {
        state.isFetchingShop = false;
        state.shopsData = action.payload;
      })
      .addCase(fetchAllShops.rejected, (state, action) => {
        state.isFetchingShop = false;
        state.shopError = action.payload;
      })

      // fetch shop by id
      .addCase(fetchShopById.pending, (state) => {
        state.isFetchingShop = true;
      })
      .addCase(fetchShopById.fulfilled, (state, action) => {
        state.isFetchingShop = false;
        state.shopById = action.payload;
      })
      .addCase(fetchShopById.rejected, (state, action) => {
        state.isFetchingShop = false;
        state.shopError = action.payload;
      })

      // post shop
      .addCase(postShop.pending, (state) => {
        state.isPostingShop = true;
      })
      .addCase(postShop.fulfilled, (state) => {
        state.isPostingShop = false;
      })
      .addCase(postShop.rejected, (state, action) => {
        state.isPostingShop = false;
        state.shopError = action.payload;
      })

      // delete shift
      .addCase(deleteShop.pending, (state) => {
        state.isDeletingShop = true;
      })
      .addCase(deleteShop.fulfilled, (state) => {
        state.isDeletingShop = false;
      })
      .addCase(deleteShop.rejected, (state, action) => {
        state.isDeletingShop = false;
        state.shopError = action.payload;
      });
  },
});

export default shopSlice.reducer;
