import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          scrollBehavior: "smooth",
          ".shiftTable .ant-table-header .ant-table-thead .ant-table-cell": {
            backgroundColor: "#785FDC",
            color: "white",
            zIndex: 2,
            borderBottom: 0,
          },
          ".shiftTable .ant-table-header .ant-table-thead td.ant-table-cell": {
            visibility: "hidden",
          },
        },
        body: {
          margin: 0,
          ".ant-table-wrapper table": {
            scrollbarColor: "#6b6b6b #2b2b2b",
          },
          ".ant-table-body::-webkit-scrollbar": {
            backgroundColor: "rgba(76, 73, 220, 0.1)",
            width: 7,
            height: 6,
          },
          ".ant-table-body::-webkit-scrollbar-thumb": {
            borderRadius: 30,
            backgroundColor: "rgba(76, 73, 220, 0.5)",
            minHeight: 24,
            height: 8,
          },
          ".rmdp-container": {
            width: "100%",
          },
          ".Toastify__toast-body": {
            fontSize: 16,
          },
          scrollbarColor: "#6b6b6b #2b2b2b",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "rgba(76, 73, 220, 0.1)",
            width: 7,
            height: 6,
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 30,
            backgroundColor: "rgba(76, 73, 220, 0.5)",
            minHeight: 24,
            height: 8,
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "rgba(76, 73, 220, .5)",
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#2b2b2b",
          },

          ".ant-table-wrapper .ant-table-tbody > tr > td": {
            borderBottom: "0.25px solid #d7c2fe73",
            padding: 8,
          },
          ".ant-table-wrapper tr.ant-table-expanded-row >td, .ant-table-wrapper tr.ant-table-expanded-row >td: hover":
            {
              backgroundColor: "#F3F0FF",
            },
          ".ant-table-wrapper .ant-table": {
            backgroundColor: "white",
          },
          ".ant-table-wrapper .ant-table-column-sorter": {
            zIndex: 2,
          },
          "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus":
            {
              WebkitBackgroundClip: "text",
            },
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#785FDC",
      light: "#D7C2FE",
    },
    grey: {
      900: "#101010",
      800: "#303030",
      700: "#505050",
      600: "#707070",
      500: "#8f8f8f",
      400: "#afafaf",
      300: "#cfcfcf",
      200: "#efefef",
      100: "#FFFFFF",
    },
    success: {
      main: "#3CB933",
      light: "#cfecd8",
    },
    error: {
      main: "#EB5757",
      light: "#FFCCCC",
    },
    warning: {
      main: "#F28F32",
      light: "#FFD2A9",
    },
    info: {
      main: "#2F80ED",
      light: "#8CC8EA",
    },
    disable: {
      main: "rgb(138, 141, 147)",
      light: "rgba(138, 141, 147, 0.12)",
    },
    inputBackground: {
      main: "#f7f6ff",
    },
    listBackground: {
      main: "#fff",
    },
  },
  typography: {
    fontFamily: [
      "Product Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontSize: 16,
    h1: {
      fontSize: 32,
      fontWeight: 700,
      lineHeight: "37.5px",
      color: "#000",
    },
    h2: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: "28px",
      color: "#000",
    },
    h3: {
      fontSize: 20,
      fontWeight: 700,
      lineHeight: "23px",
      color: "#000",
    },
    h4: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: "19px",
      color: "#000",
    },
    h5: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: "23.87px",
      letterSpacing: "0.38px",
      color: "#000",
    },
    h6: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "14px",
      color: "#000",
    },
    button: {
      textTransform: "nones",
    },
  },
});

export default theme;
