import { FC, useState, useEffect, ReactNode, Suspense } from "react";

// MUI
import { Grid } from "@mui/material";

import MainSidebar from "../components/sidebars/Main.sidebars";
// import MainHeader from "../components/headers/Main.headers";
import { RouteType } from "../App";
import SubSidebar from "../components/sidebars/Sub.sidebars";
import { useAppSelector } from "../hooks/redux.hooks";
import { RotatingLines } from "react-loader-spinner";
import Offline from "../components/error-boundary/Offline";

type LayoutProps = {
  routes: RouteType[];
  children: ReactNode;
};

const Layout: FC<LayoutProps> = ({ routes, children }) => {
  const { hasSubRoute } = useAppSelector((state) => state.user);

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    function onlineHandler() {
      setIsOnline(true);
    }

    function offlineHandler() {
      setIsOnline(false);
    }

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  return (
    <Grid
      sx={{
        // backgroundImage: "url(/background.jpg)",
        height: "100vh",
        width: "100vw",
      }}
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={11.75} height="97.5%" container alignItems="flex-end">
        {/* <MainHeader /> */}
        <Grid
          item
          xs={12}
          height="100%"
          bgcolor="inputBackground.main"
          borderRadius={3.5}
          container
          justifyContent="space-between"
          flexWrap="nowrap"
          alignItems="start"
        >
          <Grid
            container
            flexWrap="nowrap"
            item
            width="max-content"
            maxWidth={240}
            bgcolor="#6D56CA"
            borderRadius={3.5}
            height="100%"
            overflow="hidden"
          >
            <MainSidebar routes={routes} />
            <SubSidebar routes={routes} />
          </Grid>

          <Grid
            item
            sx={{ transition: "all 0.5s" }}
            p={2.5}
            flexBasis={
              hasSubRoute ? "calc(100% - 200px)" : "calc(100% - 120px)"
            }
            maxWidth={hasSubRoute ? "calc(100% - 200px)" : "calc(100% - 120px)"}
            height="100%"
          >
            <Suspense
              fallback={
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 400,
                    width: "100%",
                  }}
                >
                  <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="30"
                    visible
                  />
                </Grid>
              }
            >
              {isOnline ? children : <Offline />}
            </Suspense>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Layout;
