import React, { FC } from "react";
import {
  Checkbox,
  FormControlLabel,
  CheckboxProps,
  IconButton,
} from "@mui/material";

// icons
import { ReactComponent as UnCheckIcon } from "../../assets/icons/checkbox-icon.svg";
import { ReactComponent as CheckedIcon } from "../../assets/icons/checkbox-checked-icon.svg";

interface ICheckbox extends CheckboxProps {
  label?: string | JSX.Element;
}

const BaseCheckbox: FC<ICheckbox> = ({ label, ...props }) => {
  return (
    <FormControlLabel
      sx={{
        mr: 0,
        justifyContent: "space-around",
        svg: {
          width: 20,
          height: 20,
        },
      }}
      control={
        <Checkbox
          icon={<UnCheckIcon />}
          checkedIcon={<CheckedIcon />}
          {...props}
        />
      }
      label={label}
    />
  );
};

export default BaseCheckbox;
