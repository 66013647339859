import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import ListAvailability from "./List.availability";
import CreateAvailability from "./Create.availability";
import { IPagination } from "../staffs/List.staffs";
import PageNotFound from "../errors/PageNotFound.error";
import { useAppSelector } from "../../hooks/redux.hooks";

const Availability = () => {
  // states
  const [pagination, setPagination] = useState<IPagination>({
    pageNumber: 1,
    pageSize: 10,
  });

  const { availabilityError } = useAppSelector((state) => state.availability);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ListAvailability
            pagination={pagination}
            setPagination={setPagination}
          />
        }
      />
      <Route path="/create-availability" element={<CreateAvailability />} />
      <Route
        path="/edit-availability/:availableId"
        element={
          availabilityError === 404 ? <PageNotFound /> : <CreateAvailability />
        }
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Availability;
