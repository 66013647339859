import { createSlice } from "@reduxjs/toolkit";
import {
  ILookupValuesData,
  fetchLookupValuesByLookupId,
} from "./lookup.actions";

export type IInitialState = {
  lookupValuesData: ILookupValuesData[] | null;
  isFetchingLookupValues: boolean;
  lookupError: any;
};

const initialState: IInitialState = {
  lookupValuesData: null,
  isFetchingLookupValues: false,
  lookupError: null,
};

const lookupSlice = createSlice({
  initialState,
  name: "lookup",
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchLookupValuesByLookupId.pending, (state) => {
      state.isFetchingLookupValues = true;
    });
    builder.addCase(fetchLookupValuesByLookupId.fulfilled, (state, action) => {
      state.isFetchingLookupValues = false;
      state.lookupValuesData = action.payload;
    });
    builder.addCase(fetchLookupValuesByLookupId.rejected, (state, action) => {
      state.isFetchingLookupValues = false;
      state.lookupError = action.payload;
    });
  },
});

export default lookupSlice.reducer;
