import { useState, useEffect } from "react";
import axiosConfig from "../../axiosConfig";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { RotatingLines } from "react-loader-spinner";

// components
import OneSectionLayout from "../../layout/OneSection.layouts";

// MUI
import { Grid, Typography } from "@mui/material";
import BaseButton from "../../components/base/BaseButton";

// types
import { useStaffHoursForm } from "../../helpers/formik";
import { useNavigate, useParams } from "react-router-dom";
import BaseSelect from "../../components/base/BaseSelect";
import { fetchAllShifts } from "../../redux/shifts/shift.actions";
import { selectInputItemsFormatter } from "../../helpers/utils";
import { useAppSelector } from "../../hooks/redux.hooks";
import StaffHoursCard, {
  IDayType,
} from "../../components/cards/StaffHours.cards";
import { postStaffHours } from "../../redux/staffs/staff.actions";

export const weekDays: IDayType[] = [
  { id: 1, day: "Sunday", name: "sun", hours: [] },
  { id: 2, day: "Monday", name: "mon", hours: [] },
  { id: 3, day: "Tuesday", name: "tue", hours: [] },
  { id: 4, day: "Wednesday", name: "wed", hours: [] },
  { id: 5, day: "Thursday", name: "thu", hours: [] },
  { id: 6, day: "Friday", name: "fri", hours: [] },
  { id: 7, day: "Saturday", name: "sat", hours: [] },
];

const HoursStaff = () => {
  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { staffId } = useParams();

  const { shiftsData, isFetchingShift } = useAppSelector(
    (state) => state.shift
  );

  const { isPostingStaffHours, isFetchingStaff } = useAppSelector(
    (state) => state.staff
  );

  const [shiftId, setShiftId] = useState(0);
  const [isFetchingShiftDetail, setIsFetchingShiftDetail] = useState(false);
  const [shiftDetail, setShiftDetail] = useState();
  const [shiftInputItems, setShiftInputItems] = useState();
  const [weekData, setWeekData] = useState<IDayType[]>(weekDays);

  const fetchShiftDetail = async () => {
    setIsFetchingShiftDetail(true);
    try {
      const { data } = await axiosConfig.get(
        `Shift/GetShiftDetailList/${shiftId}`
      );
      setShiftDetail(data.data);
      setIsFetchingShiftDetail(false);
    } catch (error) {
      console.log(error);
      setIsFetchingShiftDetail(false);
    }
  };

  useEffect(() => {
    dispatch(fetchAllShifts({}));
  }, []);

  useEffect(() => {
    shiftsData &&
      setShiftInputItems(
        selectInputItemsFormatter(shiftsData?.data, "shiftTitle", "id")
      );
  }, [shiftsData]);

  useEffect(() => {
    shiftId && fetchShiftDetail();
  }, [shiftId]);

  const submitHandler = () => {
    const listStaffHours = [];

    for (const name in formik.values) {
      const splitName = name.split("-");
      if (splitName.length === 3 && splitName[1] === "s") {
        listStaffHours.push({
          startTime: formik.values[name],
          endTime: formik.values[`${splitName[0]}-e-${splitName[2]}`],
          dayOfWeek: weekData?.find(({ name }) => name === splitName[0])?.id,
        });
      }
    }

    const body = {
      lstStaffHours: listStaffHours,
      staffId: Number(staffId),
    };

    formik.isValid && dispatch(postStaffHours({ body }));
  };

  const formik = useStaffHoursForm(submitHandler);
  const { handleSubmit } = formik;

  return (
    <OneSectionLayout>
      <Grid
        container
        item
        xs={12}
        zIndex={1}
        position="relative"
        top={-16}
        justifyContent="space-between"
      >
        <Typography
          height={70}
          display="flex"
          alignItems="center"
          color="grey.100"
        >
          Staff Hours
        </Typography>
        <Grid
          item
          container
          xs={12}
          md={8}
          gap={2}
          alignItems="center"
          justifyContent="end"
          position="unset"
          bottom={-5}
          left={0}
          bgcolor="transparent"
          p={0}
          zIndex={10}
        >
          <BaseButton
            sx={{
              width: 150,
              bgcolor: "white",
              color: "primary.main",
              "&:hover": {
                bgcolor: "white",
              },
              border: 0,
              fontWeight: 500,
              height: 45,
            }}
            onClick={() => handleSubmit()}
            disabled={isPostingStaffHours}
          >
            {isPostingStaffHours ? (
              <Grid
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  zIndex: 10,
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="30"
                  visible
                />
              </Grid>
            ) : (
              "Save"
            )}
          </BaseButton>

          <BaseButton
            sx={{
              width: 150,
              border: 0,
              display: "flex",
              boxShadow: 0,
            }}
            onClick={() => navigate("/staffs")}
          >
            Discard
          </BaseButton>
        </Grid>
      </Grid>

      <Grid
        display="grid"
        gridTemplateColumns="1fr 1fr"
        gap={2}
        position="relative"
      >
        {(isFetchingStaff || isFetchingShiftDetail) && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            position="absolute"
            zIndex={1000}
            bgcolor="rgba(0,0,0, 0.05)"
            borderRadius={1}
            height="100%"
          >
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
            />
          </Grid>
        )}

        <BaseSelect
          value={shiftId}
          onChange={(e: any) => setShiftId(e.target.value)}
          name="shiftId"
          sx={{ bgcolor: "grey.100", height: "fit-content" }}
          defaultVal="Select Shift"
          inputItems={shiftInputItems}
          isFetching={isFetchingShift}
          label="shift"
        />
        <StaffHoursCard
          shiftDetail={shiftDetail}
          shiftId={shiftId}
          formik={formik}
          isFetchingShiftDetail={isFetchingShiftDetail}
          weekData={weekData}
          setWeekData={setWeekData}
          weekDays={weekDays}
        />
      </Grid>
    </OneSectionLayout>
  );
};

export default HoursStaff;
