import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import ListBooking from "./List.booking";
import CreateBooking from "./Create.booking";
import BookingDetail from "./bookingDetail/BookingDetail";
import CreateBookingDetail from "./bookingDetail/Create.bookingDetail";
import { IPagination } from "../staffs/List.staffs";
import PageNotFound from "../errors/PageNotFound.error";

const Booking = () => {
  const [pagination, setPagination] = useState<IPagination>({
    pageNumber: 1,
    pageSize: 10,
  });
  return (
    <Routes>
      <Route
        element={
          <ListBooking pagination={pagination} setPagination={setPagination} />
        }
        path="/"
      />
      <Route element={<CreateBookingDetail />} path="/create-bookingDetail" />
      <Route element={<CreateBooking />} path="/edit-booking/:bookingId" />
      <Route element={<BookingDetail />} path=":bookingId/booking-detail/*" />
      {/* <Route element={<PageNotFound />} path="/*" /> */}
    </Routes>
  );
};

export default Booking;
