import "./InternalServer.error.css";

const InternalServerError = () => (
  <div className="body">
    <span>Server Error</span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="-8.5 9.5 560 250"
    >
      <defs>
        <clipPath id="a">
          <path d="M242.7 52.3c-45.4 0-82.3 36.9-82.3 82.3s36.9 82.3 82.3 82.3S325 180 325 134.6c0-45.3-36.9-82.3-82.3-82.3zm186 0c-45.4 0-82.3 36.9-82.3 82.3s36.9 82.3 82.3 82.3S511 180 511 134.6c0-45.3-36.9-82.3-82.3-82.3z" />
        </clipPath>
        <clipPath id="c">
          <path d="M451 37.4h48.2V93H451z" />
        </clipPath>
        <path
          id="b"
          fill="#FB6A4F"
          d="m485.128 48.46 2.828 2.83-2.828 2.827-2.828-2.828z"
        />
      </defs>
      <path
        fill="#263D52"
        d="M88.1 109.9c16.5 0 29.6 4.6 39.3 13.9 9.7 9.2 14.6 21.9 14.6 38 0 19-5.9 33.7-17.6 43.9-11.7 10.2-28.5 15.3-50.3 15.3-19 0-34.3-3.1-45.9-9.2v-31.1c6.1 3.3 13.3 5.9 21.4 8 8.2 2.1 15.9 3.1 23.2 3.1 22 0 33-9 33-27 0-17.2-11.4-25.7-34.1-25.7-4.1 0-8.7.4-13.6 1.2-5 .8-9 1.7-12.1 2.6l-14.3-7.7L38 48.3h92.4v30.5H69.5l-3.1 33.4 4.1-.8c4.7-1 10.6-1.5 17.6-1.5z"
      />
      <path
        fill="#2C495E"
        d="M242.7 219c-46.5 0-84.3-37.8-84.3-84.3s37.8-84.3 84.3-84.3S327 88.2 327 134.7 289.2 219 242.7 219z"
      />
      <path
        fill="#27424F"
        d="M242.7 57.3c44.6 0 80.9 35.5 82.3 79.8 0-.8.1-1.7.1-2.5 0-45.5-36.9-82.3-82.3-82.3s-82.3 36.9-82.3 82.3c0 .8 0 1.7.1 2.5 1.1-44.2 37.4-79.8 82.1-79.8z"
        opacity={0.5}
      />
      <path
        fill="#263D52"
        d="M242.7 52.3c45.5 0 82.3 36.9 82.3 82.3S288.1 217 242.7 217s-82.3-36.9-82.3-82.3 36.8-82.4 82.3-82.4m0-4c-47.6 0-86.3 38.7-86.3 86.3s38.7 86.3 86.3 86.3c47.6 0 86.3-38.7 86.3-86.3s-38.7-86.3-86.3-86.3z"
      />
      <path
        fill="#2C495E"
        d="M428.7 219c-46.5 0-84.3-37.8-84.3-84.3s37.8-84.3 84.3-84.3S513 88.2 513 134.7 475.2 219 428.7 219z"
      />
      <path
        fill="#263D52"
        d="M428.7 52.3c45.5 0 82.3 36.9 82.3 82.3S474.1 217 428.7 217s-82.3-36.9-82.3-82.3 36.8-82.4 82.3-82.4m0-4c-47.6 0-86.3 38.7-86.3 86.3s38.7 86.3 86.3 86.3c47.6 0 86.3-38.7 86.3-86.3s-38.7-86.3-86.3-86.3z"
      />
      <g clipPath="url(#a)">
        <path
          className="glow"
          opacity={0.25}
          fill="#FF8D8D"
          d="M378.7 198.2c-48.8 0-88.5-39.7-88.5-88.5s39.7-88.5 88.5-88.5 88.5 39.7 88.5 88.5-39.7 88.5-88.5 88.5z"
        />
        <circle
          className="glow"
          opacity={0.35}
          fill="red"
          cx={378.7}
          cy={109.8}
          r={55.2}
        />
        <circle
          className="glow"
          opacity={0.35}
          fill="red"
          cx={378.7}
          cy={109.8}
          r={28.3}
        />
      </g>
      <g clipPath="url(#a)">
        <path fill="#F2F8FC" d="M396.5 48.3H530V228H396.5z" />
        <path
          fill="#263D52"
          d="M531 229H395.5V47.3H531V229zm-133.5-2H529V49.3H397.5V227z"
        />
        <path fill="#8BA8B0" d="M514 71v157h-99V71z" />
        <path
          fill="#2D495E"
          d="M515 228H414V70h101v158zm-99-2h97V72h-97v154z"
        />
        <path fill="#CCD7DC" d="M415.8 48.3h23.7v11.8h-23.7z" />
        <path
          fill="#2D495E"
          d="M439.5 61.1h-23.7c-.6 0-1-.4-1-1V48.3c0-.6.4-1 1-1h23.7c.6 0 1 .4 1 1v11.8c0 .6-.4 1-1 1zm-22.7-2h21.7v-9.8h-21.7v9.8z"
        />
        <path
          fill="#E7EDEF"
          stroke="#2D495E"
          strokeWidth={2}
          strokeMiterlimit={10}
          d="M415.1 177H514v32.2h-98.9z"
        />
        <circle
          fill="#E7EDEF"
          stroke="#255B6C"
          strokeWidth={2}
          strokeMiterlimit={10}
          cx={433.5}
          cy={193.1}
          r={5.8}
        />
        <path fill="#2D495E" d="M415.1 209.2h18.4v5.8h-18.4z" />
        <path
          fill="#195063"
          d="M465.7 186.2h2.3V200h-2.3zm6.9 0h2.3V200h-2.3z"
        />
        <path
          fill="#0DB58A"
          d="M479.5 186.2h2.3V200h-2.3zm6.9 0h2.3V200h-2.3zm6.9 0h2.3V200h-2.3z"
        />
        <path fill="#8E8E8E" d="M500.2 186.2h2.3V200h-2.3z" />
        <path
          fill="#E7EDEF"
          stroke="#2D495E"
          strokeWidth={2}
          strokeMiterlimit={10}
          d="M415.1 135.1H514v32.2h-98.9z"
        />
        <circle
          fill="#E7EDEF"
          stroke="#2D495E"
          strokeWidth={2}
          strokeMiterlimit={10}
          cx={497.5}
          cy={151.2}
          r={5.8}
        />
        <path fill="#0DB58A" d="M448.4 145.4h16.1v2.3h-16.1z" />
        <path
          fill="#195063"
          d="M427.7 145.4h16.1v2.3h-16.1zm0 4.6h36.8v2.3h-36.8z"
        />
        <path
          fill="#00B284"
          d="M427.7 154.6h2.3v2.3h-2.3zm4.6 0h2.3v2.3h-2.3z"
        />
        <path
          fill="#0DB58A"
          d="M436.9 154.6h2.3v2.3h-2.3zm4.6 0h2.3v2.3h-2.3zm4.6 0h2.3v2.3h-2.3z"
        />
        <path fill="#195063" d="M450.7 154.6h9.2v2.3h-9.2z" />
        <path fill="#00B284" d="M462.2 154.6h2.3v2.3h-2.3z" />
        <path
          fill="#E7EDEF"
          stroke="#2D495E"
          strokeWidth={2}
          strokeMiterlimit={10}
          d="M415.1 94.1H514v32.2h-98.9z"
        />
        <path fill="#B9CAD0" d="M416.2 95.2h3.3v30.1h-3.3z" />
        <circle
          fill="#E7EDEF"
          stroke="#2D495E"
          strokeWidth={2}
          strokeMiterlimit={10}
          cx={433.5}
          cy={110.2}
          r={5.8}
        />
        <path
          fill="#00B284"
          d="M465.7 103.3h2.3v13.8h-2.3zm6.9 0h2.3v13.8h-2.3z"
        />
        <path fill="#195063" d="M479.5 103.3h2.3v13.8h-2.3z" />
        <path fill="#00B284" d="M486.4 103.3h2.3v13.8h-2.3z" />
        <path
          fill="#195063"
          d="M493.3 103.3h2.3v13.8h-2.3zm6.9 0h2.3v13.8h-2.3z"
        />
      </g>
      <path
        fill="red"
        d="M378.3 97.9h7.8V119h-7.8c-5.8 0-10.6-4.8-10.6-10.6 0-5.7 4.8-10.5 10.6-10.5z"
      />
      <path
        fill="#FF4E1F"
        d="M377.5 103.5h7.6v10h-7.6c-2.8 0-5-2.2-5-5 0-2.7 2.2-5 5-5z"
      />
      <path fill="#8BA8B0" d="M386.1 90.7h10.3v35.7h-10.3z" />
      <path
        fill="#263D52"
        d="M396.4 127.3h-10.3c-.6 0-1-.4-1-1V90.7c0-.6.4-1 1-1h10.3c.6 0 1 .4 1 1v35.7c0 .5-.5.9-1 .9zm-9.3-2h8.3V91.7h-8.3v33.6z"
      />
      <path
        fill="#FFF"
        fillOpacity={0.1}
        d="M378.3 125c-9.1 0-16.6-7.4-16.6-16.5S369.2 92 378.3 92h7.7v33h-7.7z"
      />
      <path
        fill="#263D52"
        d="M387 126h-8.7c-9.7 0-17.6-7.9-17.6-17.5S368.6 91 378.3 91h8.7v35zm-8.7-33c-8.6 0-15.6 7-15.6 15.5s7 15.5 15.6 15.5h6.7V93h-6.7z"
      />
    </svg>
  </div>
);

export default InternalServerError;
