import { useState, useEffect, ChangeEvent } from "react";
import { useAppSelector } from "../../hooks/redux.hooks";
import { useSearchParams } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";

// MUI
import {
  Grid,
  Skeleton,
  Typography,
  InputAdornment,
  Divider,
} from "@mui/material";
import BaseInput from "../base/BaseInput";
import { customerOrderDummyData } from "../../helpers/data";

import {
  IServiceCategoryData,
  IServiceData,
  fetchAllServiceCategory,
  fetchAllServices,
} from "../../redux/services/services.actions";
import {
  setOrderDate,
  setOrderServiceTitle,
} from "../../redux/order/order.reducer";

// icons
import { ReactComponent as SearchIcon } from "../../assets/icons/Search.svg";
import { ReactComponent as EmptyBasketIcon } from "../../assets/icons/empty-basket.svg";
import BasketCard from "../cards/Basket.cards";

const ServiceStep = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    serviceCategory,
    isFetchingServiceCategory,
    servicesData,
    isFetchingService,
  } = useAppSelector((state) => state.service);

  const { isFetchingBasket, orderBasket } = useAppSelector(
    (state) => state.order
  );

  const [search, setSearch] = useState("");
  const [finalSearch, setFinalSearch] = useState("");

  const bookingSearchParam = searchParams.get("bookingId");
  const customerSearchParam = searchParams.get("customerId");
  const customerNameSearchParam = searchParams.get("customer");
  const serviceCategorySearchParam = searchParams.get("serviceCategory");
  const serviceIdSearchParam = searchParams.get("serviceId");

  useEffect(() => {
    dispatch(fetchAllServiceCategory({}));
  }, []);

  useEffect(() => {
    if (serviceCategorySearchParam)
      dispatch(
        fetchAllServices({
          serviceCategoryId: serviceCategorySearchParam,
          search: finalSearch,
        })
      );
  }, [serviceCategorySearchParam, finalSearch]);

  useEffect(() => {
    const searchDebounce = setTimeout(() => {
      setFinalSearch(search);
    }, 1000);

    return () => clearTimeout(searchDebounce);
  }, [search]);

  let nextSearchParams = {};

  if (bookingSearchParam) {
    nextSearchParams = {
      bookingId: bookingSearchParam,
      customerId: String(customerSearchParam),
      customer: String(customerNameSearchParam),
    };
  } else {
    nextSearchParams = {
      customerId: String(customerSearchParam),
      customer: String(customerNameSearchParam),
    };
  }

  const serviceCategoryHandler = (serviceCategory: IServiceCategoryData) => {
    Object.assign(nextSearchParams, {
      serviceCategory: String(serviceCategory.id),
      active: "1",
    });
    setSearchParams(nextSearchParams);
    dispatch(setOrderDate(null));
  };

  const serviceHandler = (service: IServiceData) => {
    Object.assign(nextSearchParams, {
      serviceCategory: String(serviceCategorySearchParam),
      serviceId: String(service.id),
      service: String(service.serviceTitle),
      active: "2",
    });
    setSearchParams(nextSearchParams);
    dispatch(setOrderDate(null));
  };

  return (
    <Grid container sx={{ overflowY: "auto" }} px={2}>
      <Grid
        display="grid"
        gridTemplateColumns="1fr 5px 450px"
        gap={2}
        width="100%"
        position="relative"
        sx={{ overflowY: "auto" }}
      >
        <Grid
          display="grid"
          gridTemplateColumns={{ xs: "repeat(4, 1fr)", xl: "repeat(7,1fr)" }}
          gap={2}
          alignContent="start"
          position="relative"
          width="100%"
        >
          <Grid gridColumn={{ xs: "1/5", xl: "1/8" }} mt={2}>
            <BaseInput
              value={search}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setSearch(e.target.value)
              }
              variant="outlined"
              bgcolor="white"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ zIndex: 10 }}>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              placeholder="Search among service"
            />
          </Grid>
          <Typography
            gridColumn={{ xs: "1/5", xl: "1/8" }}
            fontWeight={500}
            fontSize={20}
            color="primary"
          >
            1 - Service Category
          </Typography>

          {isFetchingServiceCategory
            ? customerOrderDummyData.map((item) => (
                <Skeleton
                  key={item.id}
                  animation="wave"
                  variant="rectangular"
                  height={135}
                  sx={{ borderRadius: 2 }}
                  width="100%"
                />
              ))
            : serviceCategory?.data.map((item) => (
                <Grid
                  key={item.id}
                  p={1}
                  container
                  justifyContent="space-between"
                  bgcolor="white"
                  borderRadius={2}
                  sx={{
                    cursor: "pointer",
                    border: 3,
                    borderColor:
                      serviceCategorySearchParam === String(item.id)
                        ? "primary.main"
                        : "transparent",
                  }}
                  onClick={() => serviceCategoryHandler(item)}
                >
                  <Grid container justifyContent="center">
                    <img
                      src={`https://booking.weeorder.co.uk/uploadfiles/Images/${item.categoryLogoImage}`}
                      alt=""
                      width={80}
                      height={80}
                      style={{ borderRadius: "50%" }}
                      loading="lazy"
                    />
                  </Grid>
                  <Grid item container justifyContent="center">
                    <Grid item xs={12}>
                      <Typography
                        textAlign="center"
                        fontWeight={500}
                        fontSize={20}
                        textTransform="capitalize"
                        noWrap
                      >
                        {item.serviceCategoryTitle}
                      </Typography>
                    </Grid>

                    {/* <Grid item xs={12}>
                    <Typography textAlign="center">{item}</Typography>
                  </Grid> */}
                  </Grid>
                </Grid>
              ))}

          {serviceCategorySearchParam ? (
            <Typography
              fontSize={20}
              color="primary"
              fontWeight={500}
              gridColumn={{ xs: "1/5", xl: "1/8" }}
            >
              2 - Service
            </Typography>
          ) : null}

          {serviceCategorySearchParam ? (
            isFetchingService ? (
              customerOrderDummyData.map((item) => (
                <Skeleton
                  key={item.id}
                  animation="wave"
                  variant="rectangular"
                  height={135}
                  sx={{ borderRadius: 2 }}
                  width="100%"
                />
              ))
            ) : servicesData?.data && servicesData?.data.length > 0 ? (
              servicesData?.data.map((item) => (
                <Grid
                  key={item.id}
                  p={1}
                  container
                  justifyContent="space-between"
                  bgcolor="white"
                  borderRadius={2}
                  sx={{
                    cursor: "pointer",
                    border: 3,
                    borderColor:
                      serviceIdSearchParam === String(item.id)
                        ? "primary.main"
                        : "transparent",
                  }}
                  onClick={() => serviceHandler(item)}
                >
                  <Grid container justifyContent="center">
                    <img
                      src={`https://booking.weeorder.co.uk/uploadfiles/Images/${item.serviceLogoImage}`}
                      alt=""
                      width={80}
                      height={80}
                      style={{ borderRadius: "50%" }}
                      loading="lazy"
                    />
                  </Grid>
                  <Grid item container justifyContent="center">
                    <Grid item xs={12}>
                      <Typography
                        textAlign="center"
                        fontWeight={500}
                        fontSize={20}
                        textTransform="capitalize"
                        noWrap
                      >
                        {item.serviceTitle}
                      </Typography>
                    </Grid>

                    {/* <Grid item xs={12}>
                    <Typography textAlign="center">{item}</Typography>
                  </Grid> */}
                  </Grid>
                </Grid>
              ))
            ) : (
              <Grid
                bgcolor="white"
                height={100}
                container
                justifyContent="center"
                alignItems="center"
                borderRadius={2}
              >
                <Typography fontSize={18} fontWeight={500}>
                  No Data
                </Typography>
              </Grid>
            )
          ) : null}
        </Grid>
        <Divider
          orientation="vertical"
          sx={{ borderRight: 2, borderColor: "white" }}
        />
        {isFetchingBasket ? (
          <Grid position="relative">
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              position="absolute"
              zIndex={1000}
              bgcolor="rgba(0,0,0, 0.05)"
              borderRadius={1}
              height="100%"
            >
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
              />
            </Grid>
          </Grid>
        ) : orderBasket?.itemList && orderBasket?.itemList.length > 0 ? (
          <BasketCard />
        ) : (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{
              svg: { height: 160 },
              flexDirection: "column",
              gap: 2,
              height: "100%",
            }}
          >
            <EmptyBasketIcon />
            <Typography fontWeight={500}>Empty Basket</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ServiceStep;
