import { toast } from "react-toastify";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosConfig from "../../axiosConfig";

type ILoginParams = {
  body: Object;
  navigate: any;
};

export type IUserData = {
  pToken: string;
  otpCode: string;
  fullname: string;
  userPicture: string;
  firstName: string;
  lastName: string;
  userId: number;
  shopId: number;
};

export const loginUser = createAsyncThunk(
  "user/login",
  async (parameters: ILoginParams, thunkApi) => {
    const { body, navigate } = parameters;

    try {
      const { data } = await axiosConfig.post("Login", body);

      toast.success("Login Successful!");
      navigate(-1);
      return data.data as IUserData;
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
