import React, { useEffect, useState, ChangeEvent } from "react";
import { DateObject } from "react-multi-date-picker";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import axiosConfig from "../../../axiosConfig";
import { toast } from "react-toastify";
// MUI
import {
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  Divider,
} from "@mui/material";

import BaseInput from "../../../components/base/BaseInput";
import { useBookingDetailForm } from "../../../helpers/formik";
import BaseButton from "../../../components/base/BaseButton";
import OneSectionLayout from "../../../layout/OneSection.layouts";
import { NumericFormatCustom } from "../../../components/base/BaseMaskInput";
import BaseDatePicker from "../../../components/base/BaseDatePicker";

import { IEditActionsApiMethodType } from "../../../redux/shifts/shift.actions";
import {
  postBooking,
  postBookingDetail,
} from "../../../redux/booking/booking.actions";
import { AppDispatch } from "../../../redux/store";
import { RotatingLines } from "react-loader-spinner";
import { useAppSelector } from "../../../hooks/redux.hooks";

import BaseSelect from "../../../components/base/BaseSelect";
import {
  fetchAllServiceCategory,
  fetchAllServices,
} from "../../../redux/services/services.actions";
import { selectInputItemsFormatter } from "../../../helpers/utils";
import { fetchAllStaffs } from "../../../redux/staffs/staff.actions";

// icons
import { ReactComponent as AddIcon } from "../../../assets/icons/add-icon.svg";
import { ReactComponent as SubtractIcon } from "../../../assets/icons/subtract-icon.svg";

const CreateBookingDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { bookingDetailId } = useParams();

  const { isPostingBookingDetail } = useAppSelector((state) => state.booking);
  const {
    servicesData,
    isFetchingService,
    serviceCategory,
    isFetchingServiceCategory,
  } = useAppSelector((state) => state.service);
  const { staffsData, isFetchingStaff } = useAppSelector(
    (state) => state.staff
  );

  // states
  const [booking, setBooking] = useState();
  const [fetchEnd, setFetchEnd] = useState(true);
  const [serviceCatId, setServiceCatId] = useState(0);

  const fetchBookingById = async () => {
    setFetchEnd(false);
    try {
      const { data } = await axiosConfig.get(`Booking/${bookingDetailId}`);
      setFetchEnd(true);
      setBooking(data.data);
    } catch (error) {
      setFetchEnd(true);
      console.log(error);
    }
  };

  useEffect(() => {
    if (bookingDetailId) fetchBookingById();
  }, [bookingDetailId]);

  useEffect(() => {
    if (serviceCatId) fetchAllServices({ serviceCategoryId: serviceCatId });
  }, [serviceCatId]);

  const submit = () => {
    const body = {
      ...values,
      bookingDetailStartDate: new DateObject(
        values.bookingDetailStartDate
      ).format(),
      bookingDetailEndDate: new DateObject(
        values.bookingDetailEndDate
      ).format(),
    };

    const parameters = {
      type: bookingDetailId
        ? IEditActionsApiMethodType.PATCH
        : IEditActionsApiMethodType.POST,
      body,
    };

    validateForm();

    if (isValid)
      toast.promise(dispatch(postBookingDetail(parameters)).unwrap(), {
        pending: "pending",
        success: "resolved 👌",
        error: "rejected 🤯",
      });
  };
  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    setFieldValue,
    validateForm,
    isValid,
  } = useBookingDetailForm(booking, submit);

  useEffect(() => {
    dispatch(fetchAllServiceCategory({}));
    dispatch(fetchAllStaffs({}));
  }, []);

  const serviceCategoryInputItems = selectInputItemsFormatter(
    serviceCategory?.data,
    "serviceCategoryTitle",
    "id"
  );

  const serviceInputItems = selectInputItemsFormatter(
    servicesData?.data,
    "serviceTitle",
    "id"
  );

  const staffInputItems = selectInputItemsFormatter(
    staffsData?.data,
    "staffName",
    "staffId"
  );

  return (
    <OneSectionLayout>
      <Grid
        container
        item
        xs={12}
        zIndex={1}
        position="relative"
        top={-16}
        justifyContent="space-between"
      >
        <Typography
          height={70}
          display="flex"
          alignItems="center"
          color="grey.100"
        >
          Add Booking Detail
        </Typography>
        <Grid
          item
          container
          xs={12}
          md={8}
          gap={2}
          alignItems="center"
          justifyContent="end"
          position="unset"
          bottom={-5}
          left={0}
          bgcolor="transparent"
          p={0}
          zIndex={10}
        >
          <BaseButton
            sx={{
              width: 150,
              bgcolor: "grey.100",
              color: "primary.main",
              "&:hover": {
                bgcolor: "grey.100",
              },
              border: 0,
              fontWeight: 500,
            }}
            onClick={() => handleSubmit()}
            disabled={isPostingBookingDetail}
          >
            {isPostingBookingDetail ? (
              <Grid
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  zIndex: 10,
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="30"
                  visible
                />
              </Grid>
            ) : (
              "Save"
            )}
          </BaseButton>
          <BaseButton
            sx={{
              width: 150,
              border: 0,
              display: "none",
            }}
            variant="text"
            onClick={() => navigate(-1)}
          >
            Discard
          </BaseButton>
          <BaseButton
            sx={{
              width: 150,
              border: 0,
              display: "flex",
              boxShadow: 0,
            }}
            onClick={() => navigate(-1)}
          >
            Discard
          </BaseButton>
        </Grid>
      </Grid>
      <Grid display="grid" gap={2} gridTemplateColumns="1fr 1fr">
        <Grid
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gap={2}
          position="relative"
        >
          {!fetchEnd && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              position="absolute"
              zIndex={1000}
              bgcolor="rgba(0,0,0, 0.05)"
              borderRadius={1}
              height="100%"
            >
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
              />
            </Grid>
          )}
          <BaseSelect
            value={values.bookingDetailStartTime}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFieldValue("bookingDetailStartTime", e.target.value)
            }
            name="bookingDetailStartTime"
            label="Customer"
            defaultVal="Select Customer"
            isFetching={isFetchingStaff}
            // inputItems={staffInputItems}
          />
          <BaseSelect
            value={serviceCatId}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setServiceCatId(Number(e.target.value));
              dispatch(fetchAllServices({ serviceCategoryId: e.target.value }));
            }}
            name="serviceCatId"
            label="Service Category"
            defaultVal="Service Category"
            isFetching={isFetchingServiceCategory}
            inputItems={serviceCategoryInputItems}
          />
          <BaseSelect
            value={values.serviceId}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFieldValue("serviceId", e.target.value)
            }
            name="serviceId"
            label="Service"
            defaultVal="Select Service"
            isFetching={isFetchingService}
            inputItems={serviceInputItems}
          />
          <BaseDatePicker
            value={new DateObject(values.bookingDetailStartDate as string)}
            setValue={(value: any) =>
              setFieldValue("bookingDetailStartDate", value)
            }
            calendarPosition="bottom"
            format="DD MMM YYYY"
            label="Start Date"
          />
          <BaseSelect
            value={values.bookingDetailStartTime}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFieldValue("bookingDetailStartTime", e.target.value)
            }
            name="bookingDetailStartTime"
            label="Time"
            defaultVal="Select Time"
            isFetching={isFetchingStaff}
            // inputItems={staffInputItems}
          />
          <BaseSelect
            value={values.staffId}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFieldValue("staffId", e.target.value)
            }
            name="staffId"
            label="Staff"
            defaultVal="Select Staff"
            isFetching={isFetchingStaff}
            inputItems={staffInputItems}
          />

          <BaseInput
            value={values.numberOfGuest}
            name="numberOfGuest"
            onChange={handleChange}
            variant="outlined"
            label="Number Of Guest"
            placeholder="Number Of Guest"
          />

          <BaseInput
            value={values.discountAmount}
            variant="outlined"
            name="discountAmount"
            onChange={handleChange}
            placeholder="Discount Amount"
            label="Discount Amount"
            InputProps={{
              inputComponent: NumericFormatCustom as any,
            }}
          />
          <BaseInput
            value={values.prePayment}
            variant="outlined"
            name="prePayment"
            onChange={handleChange}
            placeholder="Pre Payment"
            label="Pre Payment"
            InputProps={{
              inputComponent: NumericFormatCustom as any,
            }}
          />
          <FormControlLabel
            label={
              <Typography fontSize={16} fontWeight={400}>
                Status
              </Typography>
            }
            control={
              <Switch
                checked={values.bookingDetailStatus ? true : false}
                name="bookingDetailStatus"
                onChange={() =>
                  setFieldValue(
                    "bookingDetailStatus",
                    values.bookingDetailStatus ? 0 : 1
                  )
                }
                size="small"
              />
            }
            labelPlacement="start"
            sx={{
              width: "100%",
              justifyContent: "space-between",
              mx: 0,
              bgcolor: "inputBackground.main",
              px: 2,
              py: 1,
              borderRadius: 2,
            }}
          />
          <FormControlLabel
            label={
              <Typography fontSize={16} fontWeight={400}>
                Agreement Confirmed
              </Typography>
            }
            control={
              <Switch
                checked={values.agreementConfirmed}
                name="agreementConfirmed"
                onChange={handleChange}
                size="small"
              />
            }
            labelPlacement="start"
            sx={{
              width: "100%",
              justifyContent: "space-between",
              mx: 0,
              bgcolor: "inputBackground.main",
              px: 2,
              py: 1,
              borderRadius: 2,
            }}
          />
          <BaseSelect
            value={values.discountId}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFieldValue("discountId", e.target.value)
            }
            name="discountId"
            label="Discount"
            defaultVal="Select Discount"
            isFetching={isFetchingService}
            inputItems={serviceInputItems}
          />
        </Grid>
      </Grid>
    </OneSectionLayout>
  );
};

export default CreateBookingDetail;
