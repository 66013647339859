import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import ListSubField from "./List.subField";
import CreateSubField from "./Create.subField";
import { IPagination } from "../../../staffs/List.staffs";
import { useAppSelector } from "../../../../hooks/redux.hooks";
import PageNotFound from "../../../errors/PageNotFound.error";

const SubField = () => {
  const [pagination, setPagination] = useState<IPagination>({
    pageNumber: 1,
    pageSize: 10,
  });

  const { supplementarySubFieldError } = useAppSelector(
    (state) => state.service
  );

  return (
    <Routes>
      <Route
        element={
          <ListSubField pagination={pagination} setPagination={setPagination} />
        }
        path="/"
      />
      <Route element={<CreateSubField />} path="/create-subField" />
      <Route
        element={
          supplementarySubFieldError === 404 ? (
            <PageNotFound />
          ) : (
            <CreateSubField />
          )
        }
        path="/edit-subField/:supplementarySubFieldId"
      />
    </Routes>
  );
};

export default SubField;
