import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/redux.hooks";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";

// MUI
import { Grid, Avatar, Typography, Divider } from "@mui/material";

// icons
import { ReactComponent as ProfileSettingIcon } from "../../assets/icons/profile-setting-icon.svg";
import { ReactComponent as ShopIcon } from "../../assets/icons/shop-icon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout-icon.svg";

import { logoutUser } from "../../redux/user/user.reducer";

type IProfileCardProps = {
  handleClose: () => void;
};

const ProfileCard: FC<IProfileCardProps> = ({ handleClose }) => {
  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  // states
  const { user } = useAppSelector((state) => state.user);

  const logoutHandler = () => {
    dispatch(logoutUser());
  };

  return (
    <Grid container px={2} py={1} gap={2} maxWidth={300}>
      <Grid item xs={12} container gap={2} flexWrap="nowrap">
        <Grid item>
          <Avatar
            alt={user?.fullname}
            src={`https://booking.weeorder.co.uk/uploadfiles/Images/${user?.userPicture}`}
            sx={{ width: 75, height: 75 }}
          />
        </Grid>

        <Grid item flexGrow={1}>
          <Typography fontSize={18} fontWeight={500}>
            {user?.firstName} - {user?.lastName}
          </Typography>
          <Typography color="grey.700">{user?.fullname}</Typography>
        </Grid>
      </Grid>

      <Divider sx={{ width: "100%" }} />
      <Grid
        container
        gap={1.5}
        alignItems="center"
        sx={{
          svg: { width: 24, height: 24, path: { fill: "#777" } },
          cursor: "pointer",
        }}
        onClick={() => {
          handleClose();
        }}
      >
        <ProfileSettingIcon />
        <Typography fontSize={16} fontWeight={400}>
          Setting
        </Typography>
      </Grid>
      <Grid
        container
        gap={1.5}
        alignItems="center"
        sx={{
          svg: { width: 24, height: 24, path: { fill: "#777" } },
          cursor: "pointer",
        }}
        onClick={() => {
          navigate(`/shop/${user?.shopId}`);
          handleClose();
        }}
      >
        <ShopIcon />
        <Typography fontSize={16} fontWeight={400}>
          shop
        </Typography>
      </Grid>
      <Grid
        container
        gap={1.5}
        alignItems="center"
        sx={{
          svg: { width: 24, height: 24, path: { fill: "#777" } },
          cursor: "pointer",
        }}
        onClick={logoutHandler}
      >
        <LogoutIcon />
        <Typography fontSize={16} fontWeight={400}>
          Log Out
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ProfileCard;
