import { Route, Routes } from "react-router-dom";
import ListAdditional from "./ListAdditional";

const Additional = () => {
  return (
    <Routes>
      <Route path="/" element={<ListAdditional />} />
      {/* <Route path="/create-additional" element={<CreateAdditional />} />
      <Route
        path="/edit-additional/:additionalServiceId"
        element={<CreateAdditional />}
      /> */}
    </Routes>
  );
};

export default Additional;
