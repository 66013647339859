import { useState, useEffect, FC } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppDispatch } from "../../redux/store";
import { useAppSelector } from "../../hooks/redux.hooks";
import { IPagination } from "../staffs/List.staffs";

import type { ColumnsType } from "antd/es/table";

// MUI
import { Typography, Box, IconButton } from "@mui/material";

import Table from "antd/es/table";

import {
  ICustomerData,
  ICustomerFetchAllParams,
  deleteCustomer,
  fetchAllCustomer,
} from "../../redux/customer/customer.actions";

import ThreeSectionLayout from "../../layout/ThreeSection.layouts";
import CustomerListHeader from "../../components/headers/CustomerList.headers";
import Paging from "../../components/table/Paging";

// icons
import { ReactComponent as EditIcon } from "../../assets/icons/edit-icon.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash-icon.svg";
import ConfirmModal from "../../components/modals/Confirm.modals";

type IListCustomerProps = {
  pagination: IPagination;
  setPagination: any;
};

const ListCustomer: FC<IListCustomerProps> = ({
  pagination,
  setPagination,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { customerData, isFetchingCustomer, isDeletingCustomer } =
    useAppSelector((state) => state.customer);
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("lastName");
  const mobile = searchParams.get("mobile");
  const customerStatus = searchParams.get("customerStatus");

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);

  // handlers
  const handleOpenConfirmModal = () => setOpenConfirmModal(true);
  const handleCloseConfirmModal = () => setOpenConfirmModal(false);

  function getTableParentHeight() {
    const tableParentElement =
      document.getElementById("tableParent")?.clientHeight;

    return tableParentElement;
  }

  const [tableParentHeight, setTableParentHeight] = useState(
    getTableParentHeight()
  );

  const parameters: ICustomerFetchAllParams = {
    pageNumber: pagination.pageNumber,
    pageSize: pagination.pageSize,
    mobile,
    lastName: search,
    customerStatus,
  };

  const columns: ColumnsType<ICustomerData> = [
    {
      title: "ID",
      dataIndex: "id",
      width: 60,
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      width: 130,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      width: 150,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      width: 130,
      ellipsis: true,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      width: 130,
      ellipsis: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 130,
      ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 130,
      render(value, record, index) {
        return (
          <Typography
            color={record.customerStatus ? "success.main" : "error.main"}
            fontSize={16}
            fontWeight={500}
            bgcolor={record.customerStatus ? "success.light" : "error.light"}
            py={0.5}
            px={1}
            textAlign="center"
            borderRadius={2}
            maxWidth={100}
          >
            {record.customerStatus ? "Active" : "InActive"}
          </Typography>
        );
      },
    },
    {
      title: "Action",
      width: 150,
      render: (value, record) => (
        <Box display="flex" justifyContent="end">
          <IconButton
            sx={{
              svg: {
                width: 26,
                height: 26,
              },
            }}
            onClick={() => navigate(`edit-customer/${record.id}`)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            sx={{
              svg: {
                width: 26,
                height: 26,
              },
            }}
            onClick={() => {
              handleOpenConfirmModal();
              setDeleteId(record.id);
            }}
          >
            <TrashIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchAllCustomer(parameters));
    setTableParentHeight(getTableParentHeight());
  }, [
    pagination.pageNumber,
    pagination.pageSize,
    mobile,
    customerStatus,
    search,
  ]);

  useEffect(() => {
    const windowResizeEventCallback = (e: any) => {
      const tableParentElement = getTableParentHeight();
      setTableParentHeight(tableParentElement);
    };
    window?.addEventListener("resize", windowResizeEventCallback);
    return () =>
      window.removeEventListener("resize", windowResizeEventCallback);
  }, []);

  return (
    <ThreeSectionLayout
      header={<CustomerListHeader />}
      paging={
        <Paging
          data={customerData}
          pagination={pagination}
          setPagination={setPagination}
        />
      }
    >
      {tableParentHeight && (
        <Table
          loading={isFetchingCustomer}
          columns={columns}
          dataSource={customerData?.data}
          pagination={false}
          scroll={
            (customerData?.data.length as number) > 0
              ? { x: 800, y: (tableParentHeight as number) - 70 }
              : { x: undefined, y: undefined }
          }
          className="shiftTable"
          rowKey="id"
        />
      )}
      <ConfirmModal
        isDeleting={isDeletingCustomer}
        open={openConfirmModal}
        handleClose={handleCloseConfirmModal}
        action={() =>
          dispatch(
            deleteCustomer({
              customerId: deleteId,
              fetchParams: parameters,
              closeModalHandler: handleCloseConfirmModal,
            })
          )
        }
      />
    </ThreeSectionLayout>
  );
};

export default ListCustomer;
