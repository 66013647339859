import { ChangeEvent, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../hooks/redux.hooks";

// MUI
import { Grid, InputAdornment, Skeleton, Typography } from "@mui/material";

import BaseInput from "../base/BaseInput";
import BaseButton from "../base/BaseButton";

// icons
import { ReactComponent as SearchIcon } from "../../assets/icons/Search.svg";
import { ReactComponent as NoDataIcon } from "../../assets/icons/noData-icon.svg";

import { customerOrderDummyData } from "../../helpers/data";
import {
  ICustomerData,
  fetchAllCustomer,
} from "../../redux/customer/customer.actions";

import {
  setOrderCustomerName,
  setOrderDate,
} from "../../redux/order/order.reducer";

const CustomerStep = () => {
  // hooks
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { customerData, isFetchingCustomer } = useAppSelector(
    (state) => state.customer
  );

  const [search, setSearch] = useState("");
  const bookingSearchParam = searchParams.get("bookingId");
  const customerSearchParam = searchParams.get("customerId");

  useEffect(() => {
    if (!search) dispatch(fetchAllCustomer({}));
    const fetchWithDelay = setTimeout(() => {
      if (search) dispatch(fetchAllCustomer({ lastName: search }));
    }, 750);

    return () => clearTimeout(fetchWithDelay);
  }, [search]);

  const handleOrderCustomer = (customer: ICustomerData) => {
    if (bookingSearchParam)
      setSearchParams({
        bookingId: String(bookingSearchParam),
        customerId: String(customer.id),
        customer: String(customer.lastName),
        active: "1",
      });
    else
      setSearchParams({
        customerId: String(customer.id),
        customer: String(customer.lastName),
        active: "1",
      });

    dispatch(setOrderCustomerName(customer.lastName));
    dispatch(setOrderDate(null));
  };

  return (
    <Grid
      display="grid"
      gridTemplateColumns="repeat(7,1fr)"
      gap={2}
      alignContent="start"
      position="relative"
      sx={{ overflowY: "auto" }}
      px={2}
    >
      <Grid container gridColumn="1/8" flexWrap="nowrap" gap={2}>
        <Grid flexGrow={1}>
          <BaseInput
            value={search}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSearch(e.target.value)
            }
            variant="outlined"
            bgcolor="white"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ zIndex: 10 }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder="Search among customer"
          />
        </Grid>
        <BaseButton
          sx={{ flexBasis: 200 }}
          onClick={() => navigate("/customer/create-customer")}
        >
          add customer
        </BaseButton>
      </Grid>

      {isFetchingCustomer ? (
        customerOrderDummyData.map((item) => (
          <Skeleton
            key={item.id}
            animation="wave"
            variant="rectangular"
            height={100}
            sx={{ borderRadius: 2 }}
            width="100%"
          />
        ))
      ) : customerData?.data && customerData?.data.length > 0 ? (
        customerData?.data.map((item) => (
          <Grid
            key={item.id}
            p={1}
            container
            justifyContent="center"
            bgcolor="white"
            borderRadius={2}
            sx={{
              cursor: "pointer",
              border: 2,
              borderColor:
                customerSearchParam === String(item.id)
                  ? "primary.main"
                  : "transparent",
            }}
            onClick={() => handleOrderCustomer(item)}
          >
            {/* <Grid container justifyContent="center" height={100} width={100}>
                <img
                  src={`https://robohash.org/ba${item.firstName}`}
                  alt=""
                  width="100%"
                  height="100%"
                  style={{ borderRadius: "50%" }}
                />
              </Grid> */}
            <Grid item container justifyContent="center">
              <Grid item xs={12} container justifyContent="center">
                <Typography
                  textAlign="center"
                  fontWeight={500}
                  fontSize={18}
                  textTransform="capitalize"
                  noWrap
                  width={100}
                >
                  {item.firstName}
                </Typography>
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <Typography
                  textAlign="center"
                  fontWeight={500}
                  fontSize={18}
                  textTransform="capitalize"
                  noWrap
                  width={100}
                >
                  {item.lastName}
                </Typography>
              </Grid>

              <Grid item xs={12} container justifyContent="center">
                <Typography textAlign="center" noWrap width={100}>
                  {item.phone}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))
      ) : (
        <Grid
          sx={{
            gridColumn: "1/8",
            svg: { width: 200, height: 200 },
            fontWeight: 500,
            fontSize: 20,
          }}
          container
          alignItems="center"
          flexDirection="column"
        >
          <NoDataIcon />
          No Data
        </Grid>
      )}
    </Grid>
  );
};

export default CustomerStep;
